//Library
import React, { useState, useEffect } from "react";
import { FormRow } from "../UI/FormDetail";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { format } from "react-string-format";
import { Input } from "reactstrap";
import { Redirect } from "react-router-dom";
import { useForm, FormContext, useFormContext } from "react-hook-form";
import * as yup from "yup";
//Component
import * as LinkModal from "./../UI/LinkText/LinkModal";
import DatePicker from "./../UI/DatePicker";
import * as Combo from "../UI/combobox/Combo";
import Modal from "../UI/CommonForm";
import Machine from "./Machine";
import Engineers from "./Engineers";
import Tools from "./Tools";
import DetailBox from "../UI/DetailBox";
import * as constant from "../CommonFunction/constant";
import * as common from "../CommonFunction/common-function";
import {
  InformationOKDialog,
  validateDateRage,
} from "../CommonFunction/common-function";
//Store
import * as JobStore from "../../store/jobStore";
moment.locale("en-GB");
const ValidateJobSchema = yup.object().shape({
  DateFrom: yup.string().required("This field is required."),
  DateTo: yup.string().required("This field is required."),
});
const fieldDate = ["DateFrom", "DateTo"];
export const JobScreen = (props) => {
  //Redux
  const dispatch = useDispatch();
  const Jobdata = useSelector((state) => state.jobReducer.Form.Jobdata);
  //Props

  //Declare State
  const JobForm = useForm({ validationSchema: ValidateJobSchema });
  const [buttons, setButtons] = useState();
  // -----------------------------------------------------------------------------------------
  const updateJob = async (JobStatusParam) => {
    let data = GetdataForm();
    if (!validate(data)) {
      return;
    }
    if (!(await validateEngineer(data))) {
      return;
    }
    common.SaveWithConfirm(() =>
      JobStore.actionCreators.UpdateJob(dispatch, {
        ...data,
        JobStatus: JobStatusParam || data.JobStatus,
      })
    );
  };
  // -----------------------------------------------------------------------------------------
  const CreateJob = () => {
    if (!validate(GetdataForm())) {
      return;
    }
    common.SaveWithConfirm(() =>
      JobStore.actionCreators.CreateJob(dispatch, {
        ...GetdataForm(),
        JobStatus: common.Constants.DRAFT,
      })
    );
  };
  // -----------------------------------------------------------------------------------------
  const saveHandle = () => {
    updateJob();
  };
  const cancel = () => {
    updateJob(constant.CANCELED);
  };
  const commit = () => {
    updateJob(constant.COMMITED);
  };
  // -----------------------------------------------------------------------------------------
  const createbutton = {
    text: "Create",
    function: JobForm.handleSubmit(CreateJob),
  };
  const savebutton = {
    text: "Save",
    function: JobForm.handleSubmit(saveHandle),
  };
  const cancelButton = { text: "Cancel Job", function: cancel };
  const commitButton = {
    text: "Commit Job",
    function: JobForm.handleSubmit(commit),
  };
  //Effect
  useEffect(() => {
    function getButton() {
      switch (Jobdata && Jobdata.JobStatus) {
        case constant.DRAFT:
          return [savebutton, cancelButton, commitButton];
        case constant.COMMITED:
          return [savebutton, cancelButton];
        case constant.PENDING:
          return [savebutton];
        case constant.ONDUTY:
          return [savebutton];
        case constant.CLOSE:
          return [];
        default:
          return [createbutton];
      }
    }
    setButtons(getButton());
  }, [Jobdata]);
  //Event+function
  const validate = (data) => {
    let err = [];
    if (data.Brief && data.Brief.length > 1000) {
      InformationOKDialog("Brief text is too long.");
      return false;
    }

    var DateFrom = moment.utc(data.DateFrom).toDate();
    var DateTo = moment.utc(data.DateTo).toDate();

    if (
      DateFrom > DateTo ||
      common.isEmptyStr(data.DateFrom) ^ common.isEmptyStr(data.DateTo)
    ) {
      // ^ diff is true
      err.push({ type: "range", name: "DateTo", message: "invalid Range" });
    }

    if (data.Remark && data.Remark.length > 500) {
      InformationOKDialog("Remark text is too long.");
      return false;
    }

    if (!common.isEmptyArray(err)) {
      JobForm.setError(err);
      return false;
    }
    JobForm.clearError();
    return true;
  };
  const validateEngineer = async (data) => {
    let EngineerInfo = await JobStore.actionCreators.GetEmployeeAllocateList(
      Jobdata.JobId
    );
    let planDateFrom = moment(data.DateFrom).format("DD-MMM-YYYY");
    let planDateTo = moment(data.DateTo).format("DD-MMM-YYYY");
    let messageEmpty =
      "Please select " +
      '<div style="display: inline-block; color: red;">{0}</div>';
    let messageRage =
      '<div style="display: inline-block; color: red;">{0}</div>' +
      "<br/> must between <br/>" +
      '<div style="display: inline-block; font-weight: bold;">{1}</div>' +
      " to " +
      '<div style="display: inline-block; font-weight: bold;">{2}</div>';

    for (let index = 0; index < EngineerInfo.length; index++) {
      let item = EngineerInfo[index];

      if (
        common.isEmptyObj(item.EmployeeDateFrom) ||
        common.isEmptyObj(item.EmployeeDateTo)
      ) {
        InformationOKDialog(format(messageEmpty, "Engineer Date From - To"));
        return false;
      }

      let empDateFrom = moment(item.EmployeeDateFrom).format("DD-MMM-YYYY");
      let empDateTo = moment(item.EmployeeDateTo).format("DD-MMM-YYYY");

      if (
        !validateDateRage(planDateFrom, planDateTo, empDateFrom) ||
        !validateDateRage(planDateFrom, planDateTo, empDateTo)
      ) {
        InformationOKDialog(
          format(
            messageRage,
            "Engineer Date From - To",
            planDateFrom,
            planDateTo
          )
        );
        return false;
      }
    }
    return true;
  };
  const GetdataForm = () => {
    let data = {
      ...Jobdata,
      ...JobForm.getValues(),
      UpdatedBy: common.GetUserId(),
    };
    return data;
  };
  //SubComponent

  return (
    <Modal
      onBack={() => {
        dispatch({ type: JobStore.ActionType.CLEAR_DETAIL });
      }}
      ButtonJson={buttons}
      BackLink={
        props.Backlink ||
        (props.location.state && props.location.state.BackLink) ||
        "/JobMonitor"
      }
    >
      {Jobdata.JobId || Jobdata.JobType === common.Constants.EVENT ? null : (
        <Redirect to="/dashboard" />
      )}
      <DetailBox HeaderText="JobDetail">
        <FormContext {...JobForm}>
          <JobDetail />
        </FormContext>
      </DetailBox>
      {Jobdata.CustomerId ? (
        <DetailBox HeaderText="Machine">
          <Machine />
        </DetailBox>
      ) : null}
      {Jobdata.JobId ? (
        <>
          <DetailBox HeaderText="Engineers">
            <Engineers />
          </DetailBox>
          {Jobdata.JobType === common.Constants.EVENT ? null : (
            <DetailBox HeaderText="Tools">
              <Tools />
            </DetailBox>
          )}
        </>
      ) : null}
    </Modal>
  );
};

export const JobDetail = () => {
  //Redux
  const jobdata = useSelector((state) => state.jobReducer.Form.Jobdata);
  //Props
  //Declare State
  const { register, errors, setValue, triggerValidation } = useFormContext();
  const [link, setlink] = useState();
  //Effect
  useEffect(() => {
    setValue(common.setDataToFormHook(jobdata, fieldDate));
  }, [jobdata]);
  //Event+function
  const trigger = (e) => {
    //for clear err from maunal trigger => field in validate schema
    if (errors[e.target.name]) {
      triggerValidation(e.target.name);
    }
  };
  //SubComponent

  return (
    <React.Fragment>
      <FormRow label="Job Number">
        <label htmlFor="Job_Number_Value">{jobdata.JobNumber}</label>
      </FormRow>
      <FormRow label="Job Type">
        <label htmlFor="Job_Type_Value">{jobdata.JobType}</label>
      </FormRow>
      <FormRow label="Status">
        <label htmlFor="Status_Value">{jobdata.JobStatus}</label>
      </FormRow>
      <FormRow label="Customer">
        <LinkModal.Customer
          Text={jobdata && jobdata.CustomerName}
          Value={jobdata && jobdata.CustomerId}
        />
      </FormRow>
      <FormRow label="PO No.">
        <LinkModal.ServiceRequest
          Text={jobdata && jobdata.PoNumber}
          Value={jobdata && jobdata.ServiceRequestId}
        />
      </FormRow>
      <FormRow label="Brief" errors={errors.Brief}>
        <Input
          type="textarea"
          name="Brief"
          id="Brief"
          style={{ height: 150 }}
          innerRef={register}
          invalid={errors.Brief ? true : false}
          readOnly={jobdata.PreventEdit}
        />
      </FormRow>
      <FormRow label="Planned Date" errors={errors.DateFrom} required>
        <DatePicker
          name="DateFrom"
          id="DateFrom"
          innerRef={register}
          invalid={errors.DateFrom ? true : false}
          onChange={trigger}
          readOnly={jobdata.PreventEdit}
        />
      </FormRow>
      <FormRow label="To Date" errors={errors.DateTo} required>
        <DatePicker
          name="DateTo"
          id="DateTo"
          innerRef={register}
          invalid={errors.DateTo ? true : false}
          onChange={trigger}
          readOnly={jobdata.PreventEdit}
        />
      </FormRow>
      <FormRow label="Link">
        <Input
          type="text"
          name="Link"
          id="Link"
          innerRef={register}
          onChange={(e) => setlink(e.target.value)}
          readOnly={jobdata.PreventEdit}
        />
        <a target="_blank" rel="noopener noreferrer" href={link}>
          {link}
        </a>
      </FormRow>
      <FormRow label="Onsite By">
        <Combo.OnsiteBy
          name="OnsiteBy"
          innerRef={register}
          bsSize="sm"
          readOnly={jobdata.PreventEdit}
        />
      </FormRow>
      <FormRow label="Remark" errors={errors.Remark}>
        <Input
          type="textarea"
          name="Remark"
          id="Remark"
          invalid={errors.Remark ? true : false}
          style={{ height: 150 }}
          innerRef={register}
          readOnly={jobdata.PreventEdit}
        />
      </FormRow>
      <FormRow label="Remark (PO)">
        <Input
          type="textarea"
          name="ServiceRemark"
          id="ServiceRemark"
          style={{ height: 150 }}
          innerRef={register}
          readOnly={true}
        />
      </FormRow>
    </React.Fragment>
  );
};
export const JobServiceRequest = () => {
  return <JobScreen Backlink="/RequestForm" />;
};
export const JobCalendar = () => {
  return <JobScreen Backlink="/calendar" />;
};

export default JobScreen;
