//Library
import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//Component
import CommonTable from "../UI/CommonTables";
import ResultBox from "../UI/ResultBox";
import { formatDate } from "./../CommonFunction/common-function";
import { SideNavBar } from "../UI/SideNavBar/SideNavBar";
import "../../assets/css/Table.css";
import * as LinkModal from "./../UI/LinkText/LinkModal";
import * as common from "../CommonFunction/common-function";
import MainScreen from "../UI/MainScreen";
//Store
import * as ServiceRequestStore from "../../store/ServiceRequestStore";
import { PerissionScreenContext } from "./../Permission";

const ServiceRequest = () => {
  //Redux
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const SearchCriteria = useSelector(
    (state) => state.ReportFilterReducer.SearchCriteria
  );
  const JobTypeFilterList = useSelector(
    (state) => state.ReportFilterReducer.JobTypeFilterList
  );
  const JobStatusFilterList = useSelector(
    (state) => state.ReportFilterReducer.JobStatusFilterList
  );
  const CustomersFilterList = useSelector(
    (state) => state.ReportFilterReducer.CustomersFilterList
  );
  //
  const criteria = {
    JobTypes: JobTypeFilterList.map((el) => el.JobType),
    JobStatus: JobStatusFilterList.map((el) => el.JobStatus),
    CustomerIds: CustomersFilterList.map((el) => el.CustomerId),
    Tags: SearchCriteria.Tags,
    PONumber: SearchCriteria.PoNumber,
    RequestedDateFrom: SearchCriteria.RequestDateFrom,
    RequestedDateTo: SearchCriteria.RequestDateTo,
    DueDateFrom: SearchCriteria.DueDateFrom,
    DueDateTo: SearchCriteria.DueDateTo,
  };
  //Effect
  useEffect(() => {
    ServiceRequestStore.actionCreators.SearchServiceRequest(dispatch, criteria);
  }, [dispatch]);

  const Add = () => {
    setIsOpen(true);
    dispatch({ type: ServiceRequestStore.ActionType.ClearDetail });
  };
  const Search = () => {
    let criteria = {
      JobTypes: JobTypeFilterList.map((el) => el.JobType),
      JobStatus: JobStatusFilterList.map((el) => el.JobStatus),
      CustomerIds: CustomersFilterList.map((el) => el.CustomerId),
      Tags: SearchCriteria.Tags,
      PONumber: SearchCriteria.PoNumber,
      RequestedDateFrom: SearchCriteria.RequestDateFrom,
      RequestedDateTo: SearchCriteria.RequestDateTo,
      DueDateFrom: SearchCriteria.DueDateFrom,
      DueDateTo: SearchCriteria.DueDateTo,
    };

    ServiceRequestStore.actionCreators.SearchServiceRequest(dispatch, criteria);
  };

  return (
    <React.Fragment>
      <SideNavBar
        collapse
        Search={Search}
        Customer
        JobType
        JobStatus
        PONumber
        RequestDate
        DueDate
        Tags
      >
        <MainScreen>
          {isOpen ? <Redirect to="/RequestForm" /> : null}
          <ResultBox
            HeaderText="Service Request (PO)"
            ButtonAddHidden={false}
            onButtonAddClick={Add}
          >
            <TableServiceRequest Search={Search} />
          </ResultBox>
        </MainScreen>
      </SideNavBar>
    </React.Fragment>
  );
};
const TableServiceRequest = (props) => {
  //Redux
  const dispatch = useDispatch();
  const dataTable = useSelector(
    (state) => state.ServiceRequestReducer.ServiceRequestData
  );
  const { Function: fn_lst } = useContext(PerissionScreenContext);
  //Props
  //Declare State
  const [isOpenModal, setIsOpenModal] = useState(false);
  //Effect

  //Event+function
  const onDelete = (id) => {
    common.ConfirmDelete(async () => {
      await ServiceRequestStore.actionCreators.deleteServiceRequest(id);

      if (props.Search) {
        props.Search();
      }
    });
  };

  const onClickCol = {
    onClick: async (e, column, columnIndex, row, rowIndex) => {
      await ServiceRequestStore.actionCreators.getDataServiceRequest(
        dispatch,
        row.ServiceRequestId
      );
      setIsOpenModal(true);
    },
  };
  const Icondelete = common.getIconTag(common.Icontype.ION, "IoMdClose");
  const columns = [
    {
      dataField: "JobType",
      text: "Type",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "CustomerName",
      text: "Customer",
      sort: true,
      formatter: (cell, row) => {
        return (
          <LinkModal.Customer Text={row.CustomerName} Value={row.CustomerId} />
        );
      },
    },
    {
      dataField: "PoNumber",
      text: "Po No.",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "CurrentJobStatus",
      text: "Status",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "RequestedDate",
      text: "Requested Date",
      sort: true,
      events: onClickCol,
      formatter: (cell, row) => {
        return formatDate(row.RequestedDate);
      },
    },
    {
      dataField: "DueDate",
      text: "Due Date",
      sort: true,
      events: onClickCol,
      formatter: (cell, row) => {
        return formatDate(row.DueDate);
      },
    },
    //{
    //    dataField: 'a',
    //    text: '',
    //    formatter: (cell, row) => {
    //        return (
    //            <button onClick={async () => {
    //                await ServiceRequestStore.actionCreators.getDataServiceRequest(dispatch, row.ServiceRequestId)
    //                setIsOpenModal(true)
    //            }} className="btn-transparent"><Iconmore /></button>
    //        );
    //    }
    //},
    {
      dataField: "b",
      text: "",
      formatter: (cell, row) => {
        if (
          fn_lst.includes("Delete") &&
          row.CurrentJobStatus === common.Constants.DRAFT
        ) {
          return (
            <button
              onClick={() => {
                onDelete(row.ServiceRequestId);
              }}
              className="btn-transparent"
            >
              <Icondelete />
            </button>
          );
        } else {
          return <></>;
        }
      },
    },
  ];

  return (
    <React.Fragment>
      {isOpenModal ? <Redirect to="/RequestForm" /> : null}
      <CommonTable
        keyField="ServiceRequestId"
        data={dataTable}
        columns={columns}
        id="ServiceRequestTable"
      />
    </React.Fragment>
  );
};
export default ServiceRequest;
