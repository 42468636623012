//Library
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, FormGroup, Label, Input } from "reactstrap";
import * as common from "./../CommonFunction/common-function";

//Component
import MainScreen from "../UI/MainScreen";
import CommonTable from "../UI/CommonTables";
import ResultBox from "../UI/ResultBox";
import Modal from "../UI/MainModal";
import * as Combo from "../UI/combobox/Combo";
import CriteriaBox from "../UI/CriteriaBox";
//Store
import * as MasterStore from "../../store/MasterStore";
import FormRowInput from "../UI/FormDetail";
import { PerissionScreenContext } from "./../Permission";
export const MachineMaster = () => {
  const [isOpen, setIsOpen] = useState(false);
  const criteria = useSelector((state) => state.MasterReducer.SearchCriteria);
  const { Function: fn_lst } = useContext(PerissionScreenContext);
  const dispatch = useDispatch();
  const Add = () => {
    setIsOpen(true);
  };
  const search = () => {
    MasterStore.actionCreators.SearchMachine(dispatch, criteria);
  };
  const clear = () => {
    dispatch({ type: MasterStore.ActionType.criteriaClear });
  };
  return (
    <MainScreen>
      <CriteriaBox
        onSearch={search}
        hiddenClear={false}
        onClear={clear}
        hidden={true}
        hiddenClose={true}
      >
        <MachineCriteriaSearch search={search} />
      </CriteriaBox>
      <ResultBox
        HeaderText="Machine"
        ButtonAddHidden={common.disableEdit(fn_lst)}
        onButtonAddClick={Add}
      >
        <MachineTable></MachineTable>
      </ResultBox>
      <MachineModal
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
      ></MachineModal>
    </MainScreen>
  );
};

export const MachineCriteriaSearch = (props) => {
  const SearchCriteria = useSelector(
    (state) => state.MasterReducer.SearchCriteria
  );
  const [dataItem, setDataItem] = useState(SearchCriteria);
  const dispatch = useDispatch();

  useEffect(() => {
    setDataItem(SearchCriteria);
  }, [SearchCriteria]);
  useEffect(() => {}, [dataItem]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDataItem({ ...dataItem, [name]: value });
    dispatch({
      type: MasterStore.ActionType.criteriaChange,
      param: { ...dataItem, [name]: value },
    });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      props.search();
    }
  };
  return (
    <React.Fragment>
      <Form
        className="criteria-div"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormGroup row>
          <Label className="Label-font" for="SearchFor" size="sm">
            Search For
          </Label>
          <Col md={4}>
            <Input
              type="text"
              bsSize="sm"
              name="FullText"
              id="FullText"
              value={dataItem.FullText}
              onKeyPress={handleKeyPress}
              onChange={handleInputChange}
            />
          </Col>
        </FormGroup>
      </Form>
    </React.Fragment>
  );
};

export const MachineTable = () => {
  //Redux
  const dispatch = useDispatch();
  const criteria = useSelector((state) => state.MasterReducer.MachineCriteria);
  const MachineList = useSelector((state) => state.MasterReducer.MachineList);
  const { Function: fn_lst } = useContext(PerissionScreenContext);
  // const search = () => {
  //     MasterStore.actionCreators.SearchMachine(dispatch, criteria)
  // }

  //Declare State
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    MasterStore.actionCreators.SearchMachine(dispatch, criteria);
  }, [dispatch, criteria]);
  //SubComponent

  const Icondelete = common.getIconTag(common.Icontype.ION, "IoMdClose");
  const onClickCol = {
    onClick: async (e, column, columnIndex, row, rowIndex) => {
      if (common.disableEdit(fn_lst)) return;
      await MasterStore.actionCreators.GetMachineData(dispatch, row.MachineId);
      setIsOpen(true);
    },
  };

  const columns = [
    {
      dataField: "MachineName",
      text: "Name",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "MachineType",
      text: "Type",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "MachineBrand",
      text: "Brand",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "MachineModel",
      text: "Model",
      sort: true,
      events: onClickCol,
    },
    //{
    //    dataField: 'a',
    //    text: '',
    //    formatter: (cell, row) => {
    //        return (
    //            <button onClick={async () => {
    //                await MasterStore.actionCreators.GetMachineData(dispatch, row.MachineId)
    //                setIsOpen(true)
    //            }} className="btn-transparent"><Iconmore /></button>
    //        );
    //    }
    //},
    {
      dataField: "b",
      text: "",
      formatter: (cell, row) => {
        return (
          <button
            onClick={() => {
              MasterStore.actionCreators
                .CheckValidateDeleteMachine(row)
                .then((result) => {
                  if (result.isValid) {
                    common.ConfirmDelete(() => {
                      MasterStore.actionCreators.DeleteMachine(
                        dispatch,
                        row.MachineId,
                        criteria
                      );
                    });
                  } else {
                    common.InformationOKDialog(
                      result.Messages && common.ArrayTextReduce(result.Messages)
                    );
                  }
                });
            }}
            className="btn-transparent"
            disabled={common.disableEdit(fn_lst)}
          >
            <Icondelete />
          </button>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <CommonTable keyField="MachineId" data={MachineList} columns={columns} />
      <MachineModal
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
      ></MachineModal>
    </React.Fragment>
  );
};

export const MachineDetail = () => {
  //Redux
  const dispatch = useDispatch();
  const MachineData = useSelector((state) => state.MasterReducer.MachineData);
  //Props
  //Declare State
  const [dataItem, setDataItem] = useState(MachineData);
  //Effect
  useEffect(() => {
    dispatch({ type: MasterStore.ActionType.GetMachineForm, param: dataItem });
  }, [dataItem, dispatch]);
  useEffect(() => {
    setDataItem(MachineData);
  }, [MachineData]);
  //Event+function
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDataItem({ ...dataItem, [name]: value });
  };

  return (
    <div>
      <FormRowInput label="Machine Name" required>
        <Input
          type="text"
          name="MachineName"
          id="MachineName"
          bsSize="sm"
          maxLength="50"
          value={dataItem.MachineName || ""}
          onChange={handleInputChange}
          required
        />
      </FormRowInput>
      <FormRowInput label="Machine Type" required>
        <Combo.MachineType
          name="MachineType"
          value={dataItem.MachineType || ""}
          onChange={handleInputChange}
          bsSize="sm"
          required={true}
        />
      </FormRowInput>
      <FormRowInput label="Machine Brand" required>
        <Combo.MachineBrand
          name="MachineBrand"
          value={dataItem.MachineBrand || ""}
          onChange={handleInputChange}
          bsSize="sm"
          required={true}
        />
      </FormRowInput>
      <FormRowInput label="Machine Model" required>
        <Combo.MachineModel
          name="MachineModel"
          value={dataItem.MachineModel || ""}
          onChange={handleInputChange}
          bsSize="sm"
          required={true}
        />
      </FormRowInput>
      <FormRowInput label="Remark">
        <Input
          type="textarea"
          name="Remark"
          id="Remark"
          maxLength="500"
          style={{ height: 150 }}
          value={dataItem.Remark || ""}
          onChange={handleInputChange}
        />
      </FormRowInput>
    </div>
  );
};

export const MachineModal = (props) => {
  //Redux
  const dispatch = useDispatch();
  const MachineInput = useSelector((state) => state.MasterReducer.MachineInput);
  const criteria = useSelector((state) => state.MasterReducer.MachineCriteria);

  const save = () => {
    MasterStore.actionCreators.SaveMachine(
      dispatch,
      { ...MachineInput, UpdatedBy: common.GetUserId() },
      criteria
    );
  };

  const [txtHeader, settxtHeader] = useState();
  useEffect(() => {
    let txt;
    if (!common.isEmptyStr(MachineInput.MachineId)) {
      txt = "Edit ";
    } else txt = "Add ";
    settxtHeader(txt + "Machine");
  }, [MachineInput]);

  return (
    <Modal
      isOpen={props.isOpen}
      onSave={() => {
        MasterStore.actionCreators
          .CheckDupplicateMachine(MachineInput)
          .then((result) => {
            if (result.isValid) {
              common.SaveWithConfirm(save);
            } else {
              common.InformationOKDialog(
                result.Messages && common.ArrayTextReduce(result.Messages)
              );
            }
          });
      }}
      onCancel={() => {
        props.onCancel();
        dispatch({ type: MasterStore.ActionType.ClearMachineData });
      }}
      ModalHeaderText={txtHeader}
    >
      <MachineDetail />
    </Modal>
  );
};

export default MachineMaster;
