import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Downshift from "downshift";
import * as common from "../CommonFunction/common-function";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
}));

export default function TagsInput({ ...props }) {
  const classes = useStyles();
  const {
    selectedTags,
    placeholder,
    tags,
    required = false,
    isEmail = false,
    ...other
  } = props;
  const [inputValue, setInputValue] = React.useState("");
  const [selectedItem, setSelectedItem] = React.useState([]);

  useEffect(() => {
    setSelectedItem(tags);
  }, [tags]);

  useEffect(() => {
    selectedTags(selectedItem);
  }, [selectedItem, selectedTags]);

  const addSelectItem = (item) => {
    const newSelectedItem = [...selectedItem];
    const duplicatedValues = newSelectedItem.indexOf(item);

    if (duplicatedValues !== -1) {
      setInputValue("");
      return;
    }
    if (!item.replace(/\s/g, "").length) return;

    newSelectedItem.push(item);
    setSelectedItem(newSelectedItem);
    setInputValue("");
  };

  function handleKeyDown(event) {
    // add
    if (event.key === "Enter" || event.key === " ") {
      const inputText = event.target.value.trim();
      if (!inputText) return;

      if (isEmail && !common.isValidEmail(inputText)) {
        setInputValue("");
        return;
      }
      addSelectItem(inputText);
    }
    // delete
    if (
      selectedItem.length &&
      !inputValue.length &&
      event.key === "Backspace"
    ) {
      setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
    }
  }
  function handleChange(item) {
    let newSelectedItem = [...selectedItem];
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item];
    }
    setInputValue("");
    setSelectedItem(newSelectedItem);
  }

  const handleDelete = (item) => () => {
    const newSelectedItem = [...selectedItem];
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
    setSelectedItem(newSelectedItem);
  };

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  function handleInputBlur(event) {
    const inputText = event.target.value.trim();
    if (!inputText) return;

    if (isEmail && !common.isValidEmail(inputText)) {
      setInputValue("");
      return;
    }
    addSelectItem(inputText);
  }

  const selectedChips =
    selectedItem != null
      ? selectedItem.map((item) => (
          <Chip
            key={item}
            tabIndex={-1}
            label={item}
            className={classes.chip}
            onDelete={handleDelete(item)}
          />
        ))
      : [];

  const maxWidth = 910;
  const autoWidth = selectedChips.reduce(
    (totalWidth, chip) => totalWidth + chip.props.label.length * 10,
    selectedChips.length * 10
  );

  const inputWidth =
    selectedChips.length > 0
      ? autoWidth > maxWidth
        ? autoWidth
        : maxWidth
      : maxWidth;

  return (
    <React.Fragment>
      <Downshift
        id="downshift-multiple"
        inputValue={inputValue}
        onChange={handleChange}
        selectedItem={selectedItem}
      >
        {({ getInputProps }) => {
          const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
            onKeyDown: handleKeyDown,
            placeholder,
          });
          var err = required && selectedItem && selectedItem.length === 0;
          var message = "Please enter at least one email address.";
          return (
            <div>
              <TextField
                error={err}
                helperText={err && message}
                InputProps={{
                  startAdornment: selectedChips,
                  style: { width: inputWidth },
                  onBlur: (event) => {
                    handleInputBlur(event);
                    onBlur(event);
                  },
                  onChange: (event) => {
                    handleInputChange(event);
                    onChange(event);
                  },
                  onFocus,
                }}
                {...other}
                {...inputProps}
              />
            </div>
          );
        }}
      </Downshift>
    </React.Fragment>
  );
}
TagsInput.defaultProps = {
  tags: [],
};
TagsInput.propTypes = {
  selectedTags: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
};
