//Library
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, FormGroup, Label, Input } from "reactstrap";
import * as common from "./../CommonFunction/common-function";
import { useForm, useFormContext, FormContext } from "react-hook-form";
import * as yup from "yup";
//Component
import MainScreen from "../UI/MainScreen";
import CommonTable from "../UI/CommonTables";
import ResultBox from "../UI/ResultBox";
import Modal from "../UI/MainModal";
import * as Combo from "../UI/combobox/Combo";
import CriteriaBox from "../UI/CriteriaBox";
//Store
import * as MasterStore from "../../store/MasterStore";
import FormRowInput from "../UI/FormDetail";
import { PerissionScreenContext } from "./../Permission";
import { actionCreators } from "../../store/comboStore";
const ValidateSchema = yup.object().shape({
  Code: yup
    .string()
    .nullable()
    .trim()
    .required("This field is required."),
    Value: yup
    .string()
    .nullable()
    .trim()
    .required("This field is required."),
    Display: yup
    .string()
    .nullable()
    .trim()
    .required("This field is required."),
})
export const MachineCategoriesMaster = () => {
  const [isOpen, setIsOpen] = useState(false);
  const criteria = useSelector((state) => state.MasterReducer.SearchCriteria);
  const CriteriaForm = useForm({defaultValues:criteria});
  const { getValues, reset } = CriteriaForm;
  const dispatch = useDispatch();
  const { Function: fn_lst } = useContext(PerissionScreenContext);

  const Add = () => {
    setIsOpen(true);
  };
  const search = () => {
    MasterStore.actionCreators.SearchMachineCategories(dispatch, getValues());
  };
  const clear = () => {
    dispatch({ type: MasterStore.ActionType.criteriaClear });
  };
  useEffect(() => {
    search()
  }, [])
  return (
    <MainScreen>
      <FormContext {...{ CriteriaForm: CriteriaForm }}>
        <CriteriaBox
          onSearch={search}
          hiddenClear={false}
          onClear={clear}
          hidden={true}
          hiddenClose={true}
        >
          <CriteriaSearch search={search} />
        </CriteriaBox>
        <ResultBox
          HeaderText="Machine Categories"
          ButtonAddHidden={common.disableEdit(fn_lst)}
          onButtonAddClick={Add}
          ButtonAddHidden={false}
        >
          <CategoryTable search={search} />
        </ResultBox>
      </FormContext>
      <MachineCategoryModal
        search={search}
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
      />
    </MainScreen>
  );
};

export const CriteriaSearch = (props) => {
  const { CriteriaForm } = useFormContext();
  const { register } = CriteriaForm;
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      props.search();
    }
  };
  return (
    <React.Fragment>
      <Form
        className="criteria-div"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormGroup row>
          <Label className="Label-font" for="SearchFor" size="sm">
            Search For
          </Label>
          <Col md={4}>
            <Input
              type="text"
              bsSize="sm"
              name="FullText"
              id="FullText"
              innerRef={register}
              onKeyPress={handleKeyPress}
            />
          </Col>
        </FormGroup>
      </Form>
    </React.Fragment>
  );
};

export const CategoryTable = ({ search = () => {} }) => {
  //Redux
  const dispatch = useDispatch();
  const MachineCategoriesList = useSelector(
    (state) => state.MasterReducer.MachineCategoriesList
  );
  //Declare State
  const [isOpen, setIsOpen] = useState(false);
  const [Id, setId] = useState(0);
  //Effect
  //Event+function
  //SubComponent
  const Icondelete = common.getIconTag(common.Icontype.ION, "IoMdClose");
  const onClickCol = {
    onClick: async (e, column, columnIndex, row, rowIndex) => {
      setId(row.Id);
      setIsOpen(true);
    },
  };
  const columns = [
    {
      dataField: "Code",
      text: "Machine Category",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "Value",
      text: "Name",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "b",
      text: "",
      formatter: (cell, row) => {
        return (
          <button
            onClick={async () => {
              let result = await MasterStore.actionCreators.CheckValidateDeleteMachineCategories(
                row
              );
              if (result.isValid) {
                common.ConfirmDelete(async () => {
                  await MasterStore.actionCreators.DeleteMachineCategory({
                    id: row.Id,
                  });
                  search();
                });
              } else {
                common.InformationOKDialog(
                  result.Messages && common.ArrayTextReduce(result.Messages)
                );
              }
            }}
            className="btn-transparent"
          >
            <Icondelete />
          </button>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <CommonTable
        keyField="Id"
        data={MachineCategoriesList}
        columns={columns}
      />
      <MachineCategoryModal
        search={search}
        catid={Id}
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
      />
    </React.Fragment>
  );
};
export const MachineCategoryModal = ({ catid, search = () => {}, ...props }) => {
  //Redux
  const myform =  useForm({
    validationSchema: ValidateSchema
  });
  const  { handleSubmit, getValues, reset } = myform
  const validate = async (data) => {
    let result = await MasterStore.actionCreators.ValidateSaveMachineCategories(
      data
    );
    if (result == undefined) return;
    if (result.isValid) {
      return true;
    } else {
      common.InformationOKDialog(
        result.Messages && common.ArrayTextReduce(result.Messages)
      );
    }
  };
  const save = async () => {
    if (!(await validate(getdata()))) {
      return;
    }
    const save = async () => {
      let data = await MasterStore.actionCreators.SaveMachineCategories(getdata());
      setDetail(data)
      search();
    };
    common.SaveWithConfirm(save);
  };
  const [txtHeader, settxtHeader] = useState();
  const [Detail, setDetail] = useState({});
  useEffect(() => {
    let txt;
    if (Detail.Id) {
      txt = "Edit ";
    } else txt = "Add ";
    settxtHeader(txt + "Machine Category");
  }, [Detail]);
  useEffect(() => {
    setDetail({})
    if (!props.isOpen) return;
    (async function () {
      let data = await MasterStore.actionCreators.GetMachineCategories({
        id: catid,
      });
      setDetail(data);
      console.log(data)
    })();
  }, [catid, props.isOpen]);
  const getdata = () => {
    return { ...Detail, ...getValues(), UpdatedBy: common.GetUserId() };
  };
  return (
    <Modal
      isOpen={props.isOpen}
      onSave={handleSubmit(save)}
      onCancel={() => {
        props.onCancel();
      }}
      ModalHeaderText={txtHeader}
    >
    <FormContext {...myform}>
      <MachineCategoryDetail data={Detail} />
      </FormContext>
    </Modal>
  );
};

export const MachineCategoryDetail = ({ data }) => {
  //Redux
  const {
    register,
    setValue,
    errors,
    getValues,
    clearError,
  } = useFormContext();
  //Props
  //Declare State
  const [dataItem, setDataItem] = useState();
  //Effect
  useEffect(() => {
    console.log(data)
    setDataItem(data);
    setValue(common.setDataToFormHook(data));
  }, [data]);
  //Event+function
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDataItem({ ...dataItem, [name]: value });
  };
  //SubComponent
  return (
    <div>
    {console.log(errors)}
      <FormRowInput label="Code" required errors={errors.Code}>
        <Combo.MachineCategoryType
          name="Code"
          invalid={errors.Code ? true : false}
          innerRef={register}
          value={dataItem&&dataItem.Code || ""}
          onChange={handleInputChange}
          bsSize="sm"
        />
      </FormRowInput>
      <FormRowInput label="Value" required errors={errors.Value}>
        <Input
          type="text"
          name="Value"
          id="Value"
          maxLength="20"
          invalid={errors.Value ? true : false}
          innerRef={register}
          bsSize="sm"
        />
      </FormRowInput>
      <FormRowInput label="Display" required errors={errors.Display}>
        <Input
          type="text"
          name="Display"
          id="Display"
          maxLength="20"
          invalid={errors.Display ? true : false}
          innerRef={register}
          bsSize="sm"
        />
      </FormRowInput>
      <FormRowInput label="Remark">
        <Input
          type="textarea"
          name="Remark"
          id="Remark"
          maxLength="500"
          style={{ height: 150 }}
          innerRef={register}
          onChange={handleInputChange}
        />
      </FormRowInput>
    </div>
  );
};
export default MachineCategoriesMaster;
