import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, FormGroup, Label, Input } from "reactstrap";
import * as common from "./../CommonFunction/common-function";
import { useForm, useFormContext, FormContext } from "react-hook-form";
import * as yup from "yup";
//Component
import MainScreen from "../UI/MainScreen";
import CommonTable from "../UI/CommonTables";
import ResultBox from "../UI/ResultBox";
import Modal from "../UI/MainModal";
import CriteriaBox from "../UI/CriteriaBox";
//Store
import * as MasterStore from "../../store/MasterStore";
import FormRowInput from "../UI/FormDetail";
import { PerissionScreenContext } from "./../Permission";
const ValidateSkillSchema = yup.object().shape({
  Skill: yup.string().nullable().trim().required("This field is required."),
});
export const SkillMaster = () => {
  //Redux Global
  const { Function: fn_lst } = useContext(PerissionScreenContext);
  const SkillForm = useForm({
    validationSchema: ValidateSkillSchema,
  });
  const [isOpen, setIsOpen] = useState(false);
  const criteria = useSelector((state) => state.MasterReducer.SearchCriteria);
  const dispatch = useDispatch();
  const Add = () => {
    setIsOpen(true);
  };
  const search = () => {
    MasterStore.actionCreators.SearchSkill(dispatch, criteria);
  };
  const clear = () => {
    dispatch({ type: MasterStore.ActionType.criteriaClear });
  };
  return (
    <MainScreen>
      <FormContext {...SkillForm}>
        <CriteriaBox
          onSearch={search}
          hiddenClear={false}
          onClear={clear}
          hidden={true}
          hiddenClose={true}
        >
          <SkillCriteriaSearch search={search} />
        </CriteriaBox>
        <ResultBox
          HeaderText="Skill"
          ButtonAddHidden={common.disableEdit(fn_lst)}
          onButtonAddClick={Add}
        >
          <SkillTable />
        </ResultBox>
        <SkillModal
          isOpen={isOpen}
          onCancel={() => {
            setIsOpen(false);
          }}
        />
      </FormContext>
    </MainScreen>
  );
};

export const SkillCriteriaSearch = (props) => {
  const SearchCriteria = useSelector(
    (state) => state.MasterReducer.SearchCriteria
  );
  const [dataItem, setDataItem] = useState(SearchCriteria);
  const dispatch = useDispatch();

  useEffect(() => {
    setDataItem(SearchCriteria);
  }, [SearchCriteria]);
  useEffect(() => {}, [dataItem]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDataItem({ ...dataItem, [name]: value });
    dispatch({
      type: MasterStore.ActionType.criteriaChange,
      param: { ...dataItem, [name]: value },
    });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      props.search();
    }
  };
  return (
    <React.Fragment>
      <Form
        className="criteria-div"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormGroup row>
          <Label className="Label-font" for="SearchFor" size="sm">
            Search For
          </Label>
          <Col md={4}>
            <Input
              type="text"
              bsSize="sm"
              name="FullText"
              id="FullText"
              value={dataItem.FullText}
              onKeyPress={handleKeyPress}
              onChange={handleInputChange}
            />
          </Col>
        </FormGroup>
      </Form>
    </React.Fragment>
  );
};

export const SkillTable = () => {
  //Redux
  const dispatch = useDispatch();
  const criteria = useSelector((state) => state.MasterReducer.SkillCriteria);
  const SkillList = useSelector((state) => state.MasterReducer.SkillList);
  const { Function: fn_lst } = useContext(PerissionScreenContext);
  //Declare State
  const [isOpen, setIsOpen] = useState(false);
  //Effect
  useEffect(() => {
    Search();
  }, []);
  //Event+function
  const Search = () => {
    MasterStore.actionCreators.SearchSkill(dispatch, criteria);
  };

  //SubComponent
  const Icondelete = common.getIconTag(common.Icontype.ION, "IoMdClose");
  //const header = (
  //    <React.Fragment>
  //        <th className="th-table">Skill</th>
  //        <th className="th-table">Remark</th>
  //        <th className="th-table">{' '}</th>
  //    </React.Fragment>
  //)
  //const row = SkillList && SkillList.map(item => (
  //    <tr key={item.Skill} className="tr-table">
  //        <td>{item.Skill}</td>
  //        <td> {item.Remark}</ td>
  //        <td><button onClick={() => {
  //            MasterStore.actionCreators.GetSkillData(dispatch, item.Skill)
  //            setTimeout(() => {
  //                setIsOpen(true)
  //            }, 1000)
  //        }} className="btn-transparent"><Iconmore /></button>
  //            <button onClick={() => {
  //                common.ConfirmDelete(() => { MasterStore.actionCreators.DeleteSkill(dispatch, item.Skill, criteria) })
  //            }} className="btn-transparent"><Icondelete /></button>
  //        </td>
  //    </tr>
  //))

  const onClickCol = {
    onClick: async (e, column, columnIndex, row, rowIndex) => {
      if (common.disableEdit(fn_lst)) return;
      await MasterStore.actionCreators.GetSkillData(dispatch, row.SkillId);
      setIsOpen(true);
    },
  };

  const columns = [
    {
      dataField: "Skill",
      text: "Skill",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "Remark",
      text: "Remark",
      sort: true,
      events: onClickCol,
    },
    //{
    //    dataField: 'a',
    //    text: '',
    //    formatter: (cell, row) => {
    //        return (
    //            <button onClick={async () => {
    //                await MasterStore.actionCreators.GetSkillData(dispatch, row.Skill)
    //                setIsOpen(true)
    //            }} className="btn-transparent"><Iconmore /></button>
    //        );
    //    }
    //},
    {
      dataField: "b",
      text: "",
      formatter: (cell, row) => {
        return (
          <button
            onClick={async () => {
              let result =
                await MasterStore.actionCreators.CheckValidateDeleteSkill(row);
              if (result === undefined || result === null) return;
              if (!result.isValid) {
                common.InformationOKDialog(
                  result.Messages && common.ArrayTextReduce(result.Messages)
                );
                return;
              }
              common.ConfirmDelete(() => {
                MasterStore.actionCreators.DeleteSkill(
                  dispatch,
                  row.SkillId,
                  criteria
                );
              });
            }}
            className="btn-transparent"
            disabled={common.disableEdit(fn_lst)}
          >
            <Icondelete />
          </button>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <CommonTable keyField="SkillId" data={SkillList} columns={columns} />
      <SkillModal
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
      />
    </React.Fragment>
  );
};

export const SkillModal = (props) => {
  //Redux
  const dispatch = useDispatch();
  const SkillData = useSelector((state) => state.MasterReducer.SkillData);
  const criteria = useSelector((state) => state.MasterReducer.SkillCriteria);
  const { handleSubmit, getValues } = useFormContext();
  const validate = async (data) => {
    let result = await MasterStore.actionCreators.ValidateSaveSkill(data);
    if (result === undefined || result === null) return;
    if (!result.isValid) {
      common.InformationOKDialog(
        result.Messages && common.ArrayTextReduce(result.Messages)
      );
      return false;
    }
    return true;
  };
  const save = async () => {
    if (!(await validate(getdata()))) {
      return;
    }
    const save = () => {
      MasterStore.actionCreators.SaveSkill(dispatch, getdata(), criteria);
    };
    common.SaveWithConfirm(save);
  };

  const [txtHeader, settxtHeader] = useState();
  useEffect(() => {
    let txt;
    if (!common.isEmptyStr(SkillData.SkillId)) {
      txt = "Edit ";
    } else txt = "Add ";
    settxtHeader(txt + "Skill");
  }, [SkillData]);
  const getdata = () => {
    return { ...SkillData, ...getValues(), UpdatedBy: common.GetUserId() };
  };
  return (
    <Modal
      isOpen={props.isOpen}
      onSave={handleSubmit(save)}
      onCancel={() => {
        props.onCancel();
        dispatch({ type: MasterStore.ActionType.ClearSkillData });
      }}
      ModalHeaderText={txtHeader}
    >
      <SkillDetail />
    </Modal>
  );
};

export const SkillDetail = () => {
  //Redux
  const SkillData = useSelector((state) => state.MasterReducer.SkillData);
  const [isReadonly, setReadonly] = useState(false);
  const { register, errors, setValue } = useFormContext();
  //Props
  //Declare State

  //Effect

  useEffect(() => {
    setValue(common.setDataToFormHook(SkillData));
  }, [SkillData]);

  useEffect(() => {
    if (!common.isEmptyStr(SkillData.SkillId)) {
      setReadonly(true);
    } else {
      setReadonly(false);
    }
  }, [SkillData]);
  //Event+function

  return (
    <div>
      <FormRowInput label="Skill" required errors={errors.LeaveStarSkilltDate}>
        <Input
          type="text"
          name="Skill"
          id="Skill"
          readOnly={isReadonly}
          innerRef={register}
        />
      </FormRowInput>
      <FormRowInput label="Remark">
        <Input
          type="textarea"
          name="Remark"
          id="Remark"
          style={{ height: 150 }}
          innerRef={register}
        />
      </FormRowInput>
    </div>
  );
};

export default SkillMaster;
