import React from 'react';
import { Container, Row, Col } from 'reactstrap';
export const MainScreen = (props) => {

    return (
        <Container fluid>
            <Row>
                <Col sm="12" md="12" lg="12">
                    {props.children}
                </Col>
            </Row>
        </Container>
    )
}






export default MainScreen