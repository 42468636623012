import React, { useEffect } from "react";
import Modal from "../UI/MainModal";
//Store
import * as MachineStore from "../../store/MachineStore";

//Component
import CommonTable from "../UI/CommonTables";
export const ImportActiveMachine = (props) => {
  const { isOpen, onClose, listResult } = props;

  useEffect(() => {
    if (!isOpen) return;
    if (!listResult) return;
  }, [isOpen, listResult]);

  const columns = [
    {
      dataField: "NameBox",
      text: "Name Box",
      sort: true,
      style: (colum, colIndex) => {
        return {
          width: "50px",
          maxWidth: "50px",
        };
      },
    },
    {
      dataField: "Message",
      text: "Message",
      sort: true,
      style: (colum, colIndex) => {
        return {
          width: "300px",
          maxWidth: "300px",
        };
      },
    },
  ];

  const downloadLog = async ()=>{
    let param = {
      listData: listResult,
    };
    await MachineStore.actionCreators.DownLoadLogFile(param);
  }
 
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClosed={onClose}
        CancelText={"Close"}
        SaveText={"Download"}
        onSave={downloadLog}
        ModalHeaderText={"Result : Import Active Machine"}
        Size={"xl"}
        maxHeight={"600px"}
      >
        <div style={{ marginTop: "15px" }}>
          <CommonTable
            keyField="ActiveMachineID"
            data={listResult}
            columns={columns}
            id="ActiveMachineID"
          />
        </div>
      </Modal>
    </>
  );
};
export default ImportActiveMachine;
