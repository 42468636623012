import React, { useEffect, useState } from "react";
import CommonTable from "../UI/CommonTables";
import Modal from "../UI/MainModal";
import * as Constants from "./../../store/constants";
import * as common from "./../CommonFunction/common-function";
import Axios from "axios";
import { FormRow } from "../UI/FormDetail";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import DateRangeIcon from "@material-ui/icons/DateRange";

export const EngineerModal = (props) => {
  //Redux
  //Props
  const defaultProps = {
    onClosed: () => {},
  };
  const currentProps = {
    ...defaultProps,
    ...props,
  };
  //Declare State
  const [HistoryData, setHistoryData] = useState();
  //Effect
  useEffect(() => {
    let didCancel = false;
    async function fetchData() {
      if (!didCancel)
        try {
          if (props.Data) {
            let url = `${Constants.API_URL}/api/Employee/GetEmployeeAllocateList`;
            Axios.post(url, { id: props.Data.EmployeeId })
              .then((response) => {
                if (response.data) {
                  var mappedEmployeeAllocate =
                    common.groupAndMapEmployeeAllocate(response.data);
                  !didCancel && setHistoryData(mappedEmployeeAllocate);
                }
              })
              .catch((error) => {
                common.apiError(error);
              });
          }
        } catch (error) {
          // Do something with error
        } finally {
        }
    }
    fetchData();
    return () => {
      didCancel = true;
    };
  }, [props]);

  //Event+function

  //SubComponent
  const getFormattedItemList = (dateServices) => {
    return (
      <List>
        {dateServices.map((service, index) => {
          const startDate = common.getFormattedDate(service.Date[0]);
          const endDate = common.getFormattedDate(service.Date[1]);

          let listItem;
          if (startDate === endDate) {
            listItem = (
              <ListItem key={index}>
                <DateRangeIcon style={{ fontSize: 15, marginRight: 5 }} />
                {startDate}
              </ListItem>
            );
          } else {
            listItem = (
              <ListItem key={index}>
                <DateRangeIcon style={{ fontSize: 15, marginRight: 5 }} />
                {startDate} to {endDate}
              </ListItem>
            );
          }
          return <React.Fragment key={index}>{listItem}</React.Fragment>;
        })}
      </List>
    );
  };

  const columns = [
    {
      dataField: "JobNumber",
      text: "Job Number",
      sort: true,
      style: {
        minWidth: "150px",
      },
    },
    {
      dataField: "DateServices",
      text: "Date Service",
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const firstDateA = a[0].Date[0];
        const firstDateBฺ = b[0].Date[0];
        const dateA = new Date(firstDateA);
        const dateB = new Date(firstDateBฺ);
        if (order === "asc") {
          return dateA - dateB;
        }
        return dateB - dateA;
      },
      formatter: (cell, row) => {
        return getFormattedItemList(row.DateServices);
      },
      style: {
        minWidth: "310px",
        verticalAlign: "middle",
      },
    },
    {
      dataField: "CustomerName",
      text: "Customer",
      sort: true,
    },
    {
      dataField: "JobStatus",
      text: "Status",
      sort: true,
      style: {
        minWidth: "100px",
      },
    },
  ];

  return (
    <Modal
      isOpen={props.isOpen}
      SaveHidden={true}
      CancelHidden={true}
      onClosed={() => {
        currentProps.onCancel();
      }}
      ModalHeaderText="Engineer Information"
    >
      <FormRow label="Engineers">
        <label htmlFor="Engineers_Value">
          {props.Data && props.Data.EmployeeName}
        </label>
      </FormRow>
      <FormRow label="Skills">
        <label htmlFor="Skills_Value">{props.Data && props.Data.Tags}</label>
      </FormRow>

      <CommonTable keyField="JobId" data={HistoryData} columns={columns} />
    </Modal>
  );
};

export default EngineerModal;
