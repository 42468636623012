import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../UI/MainModal";
import { Input, CustomInput } from "reactstrap";
import { ReactTable } from "../UI/CommonTables";
import * as AddComponentStore from "./../../store/AddComponentStore";
import FormRowInput from "../UI/FormDetail";
import * as common from "./../CommonFunction/common-function";
import { PlandateDisplay } from "./../addmodal";
export const AddEngineerModal = (props) => {
  //Props
  const defaultProps = {
    onClosed: () => {},
  };
  const currentProps = {
    ...defaultProps,
    ...props,
  };
  //Declare
  const [isOpen, setIsOpen] = useState(false);
  const [EngineersID, setEngineersID] = useState([]);
  const [dataItem, setDataItem] = useState({ FullText: "" });
  //Redux
  const dispatch = useDispatch();
  const Engineers = useSelector(
    (state) => state.AddComponentReducer.EmployeeList
  );
  //Effect
  useEffect(() => {
    //OpenModal
    let information = {
      DateFrom: props.Jobdata.DateFrom,
      DateTo: props.Jobdata.DateTo,
      JobId: props.Jobdata.JobId,
    };
    if (props.Jobdata) {
      setDataItem((v) => ({ ...v, ...information }));
    }
    AddComponentStore.actionCreators.GetEmployeeWithAssignList(
      dispatch,
      information
    );
    setIsOpen(props.isOpen);
  }, [props, dispatch]);
  useEffect(() => {
    //ForSearch
    AddComponentStore.actionCreators.GetEmployeeWithAssignList(
      dispatch,
      dataItem
    );
  }, [dataItem]);
  useEffect(() => {
    //GetValueSelected
    setEngineersID(props.SelectedData);
  }, [props.SelectedData]);
  //Event
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDataItem({ ...dataItem, [name]: value });
  };
  const get = () => {
    //For Return Value
    props.GetValue(EngineersID);
    // props.onCancel()
  };
  const handleChange = (e, EmployeeId) => {
    if (e.target.checked) {
      setEngineersID(EngineersID.concat(EmployeeId));
    } else {
      setEngineersID(
        EngineersID.filter((id) => {
          return id !== EmployeeId;
        })
      );
    }
  };

  const header = (
    <React.Fragment>
      <th></th>
      <th>Name</th>
      <th>Skill</th>
      <th>Customer</th>
    </React.Fragment>
  );

  const row =
    Engineers &&
    Engineers.map((item) => (
      <tr key={item.EmployeeId} style={item.isBusy ? { color: "red" } : {}}>
        <td>
          <CustomInput
            type="checkbox"
            className="table-checkbox"
            id={item.EmployeeId || common.uuidv4()}
            checked={EngineersID.includes(item.EmployeeId)}
            onChange={(e) => {
              handleChange(e, item.EmployeeId);
            }}
          />
        </td>
        <td>{item.EmployeeName + (item.isBusy ? " (ไม่ว่าง)" : "")}</td>
        <td>{item.Tags}</td>
        <td>{item.Customer}</td>
      </tr>
    ));

  return (
    <Modal
      onSave={get}
      isOpen={isOpen}
      onCancel={props.onCancel}
      onClosed={() => {
        currentProps.onClosed();
      }}
      SaveText="OK"
      className="modal-en"
      ModalHeaderText={`Add Engineers ${PlandateDisplay(
        props.Jobdata && props.Jobdata.DateFrom,
        props.Jobdata && props.Jobdata.DateTo
      )}`}
    >
      <FormRowInput label="Engineers">
        <Input
          type="text"
          name="FullText"
          id="FullText"
          value={dataItem.FullText}
          onChange={handleInputChange}
          bsSize="sm"
        />
      </FormRowInput>
      <div className="col-12 col-md-12">
        <ReactTable Header={header} Row={row} />
      </div>
    </Modal>
  );
};
export default AddEngineerModal;
