import {formatDate} from '../CommonFunction/common-function'

export const PlandateDisplay=(from,to)=>{
    if(from&&to){
        return`Plan Date: ${formatDate(from)} to ${formatDate(to)}`
    }
    else {
        return `Plan Date: ยังไม่กำหนด`
    }
        
}