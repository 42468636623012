import React, { useEffect, useState } from "react";
import ResultBox from "../UI/ResultBox";
import MainScreenSubMenu from "../UI/MainScreenSubMenu";
import JobMonitorTable from "./JobMonitorTable";
import { useDispatch, useSelector } from "react-redux";
import * as JobMonitorStore from "../../store/JobMonitorStore";
import "../../assets/css/common-style.css";
import { Col, Form, FormGroup, Label, Input } from "reactstrap";
import { SideNavBar } from "../UI/SideNavBar/SideNavBar";
//Store
import * as UserStore from "../../store/UserStore";
import * as common from "../../components/CommonFunction/common-function";
//
const Dashboard = () => {
  const dispatch = useDispatch();
  const JobList = useSelector(
    (state) => state.JobMointorReducer.JobMointorData
  );
  // const criteria = useSelector(
  //   (state) => state.JobMointorReducer.SearchCriteria
  // );

  const EmployeeFilterList = useSelector(
    (state) => state.ReportFilterReducer.EmployeeFilterList
  );
  const JobTypeFilterList = useSelector(
    (state) => state.ReportFilterReducer.JobTypeFilterList
  );
  const JobStatusFilterList = useSelector(
    (state) => state.ReportFilterReducer.JobStatusFilterList
  );
  const CustomersFilterList = useSelector(
    (state) => state.ReportFilterReducer.CustomersFilterList
  );
  const MachineFilterList = useSelector(
    (state) => state.ReportFilterReducer.MachineFilterList
  );
  const MachineModelFilterList = useSelector(
    (state) => state.ReportFilterReducer.MachineModelFilterList
  );
  const ToolFilterList = useSelector(
    (state) => state.ReportFilterReducer.ToolFilterList
  );
  const SearchCriteria = useSelector(
    (state) => state.ReportFilterReducer.SearchCriteria
  );

  let criterianav = {
    JobTypes: JobTypeFilterList.map((el) => el.JobType),
    JobStatus: JobStatusFilterList.map((el) => el.JobStatus),
    Customers: CustomersFilterList.map((el) => el.CustomerId),
    EngineerIds: EmployeeFilterList.map((el) => el.EmployeeId),
    MachineIds: MachineFilterList.map((el) => el.MachineId),
    ToolIds: ToolFilterList.map((el) => el.ToolsId),
    SerialsNo: SearchCriteria.SerialNo,
    PONumber: SearchCriteria.PoNumber,
    PlanDate: SearchCriteria.PlanDate,
    Tags: SearchCriteria.Tags,
    MachineModels: MachineModelFilterList.map((el) => el.Value),
  };
  useEffect(() => {
    const fetchData = async () => {
      let roleList = [];
      let currentUser = await UserStore.actionCreators.GetUserDataByUserID(
        common.GetUserId()
      );
      if (currentUser) {
        roleList = await UserStore.actionCreators.GetUserRoleDataList(
          currentUser.Id
        );
      }
      let IsTheFirstDefaultFilter = common.GetTheFirstDefaultFilter();
      ////////////////////////////////////////////////////////////
      JobMonitorStore.actionCreators.SearchConflict(dispatch);
      if (roleList && roleList.some((x) => x.RoleId === "Engineers") && IsTheFirstDefaultFilter) {
        JobMonitorStore.actionCreators.SearchDefualtByEngineer(
          dispatch,
          criterianav,
          common.GetEmpId()
        );
        common.RemoveTheFirstDefaultFilter();
      } else {
        JobMonitorStore.actionCreators.Search(dispatch, criterianav);
      }
    };
    fetchData();
  }, [dispatch]);
  const searchnav = () => {
    JobMonitorStore.actionCreators.Search(dispatch, criterianav);
  };

  return (
    <>
      <SideNavBar
        Search={searchnav}
        Employee
        Customer
        Machine
        MachineModels
        JobType
        JobStatus
        Tools
        SerialNo
        PONumber
        Plandate
        Tags
        collapse
      >
        <MainScreenSubMenu>
          <ResultBox HeaderText="Job Monitor">
            <JobMonitorTable dataTable={JobList} />
          </ResultBox>
        </MainScreenSubMenu>
      </SideNavBar>
    </>
  );
};
export const CriteriaDashboard = (props) => {
  const SearchCriteria = useSelector(
    (state) => state.JobMointorReducer.SearchCriteria
  );
  const [dataItem, setDataItem] = useState(SearchCriteria);
  const dispatch = useDispatch();

  useEffect(() => {
    setDataItem(SearchCriteria);
  }, [SearchCriteria]);
  useEffect(() => {
    //dispatch({ type: JobMonitorStore.ActionType.criteriaChange, param: dataItem })
  }, [dataItem]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDataItem({ ...dataItem, [name]: value });
    dispatch({
      type: JobMonitorStore.ActionType.criteriaChange,
      param: { ...dataItem, [name]: value },
    });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      props.search();
    }
  };
  return (
    <React.Fragment>
      <Form
        className="criteria-div"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormGroup row>
          <Label className="Label-font" for="SearchFor" size="sm">
            Search For
          </Label>
          <Col md={4}>
            <Input
              type="text"
              bsSize="sm"
              name="FullText"
              id="FullText"
              value={dataItem.FullText}
              onKeyPress={handleKeyPress}
              onChange={handleInputChange}
            />
          </Col>
        </FormGroup>
      </Form>
    </React.Fragment>
  );
};

export default Dashboard;
