import * as Constants from "./constants";
import Axios from "axios";
import * as common from "./../components/CommonFunction/common-function";
export const ActionType = {
  GetUserList: "MasterReducer_GetUserList",
  GetRolesList: "MasterReducer_GetRolesList",
  GetUserData: "MasterReducer_GetUserData",
  GetUserRolesData: "MasterReducer_GetUserRolesData",
  ClearUserData: "MasterReducer_ClearUserData",
  GetUserCriteria: "MasterReducer_GetUserCriteria",
  GetUserForm: "MasterReducer_GetUserForm",
  GetSelectedRoleInput: "MasterReducer_GetSelectedRoleInput",
  //Search
  criteriaChange: "MasterReducer_criteriaChange",
  criteriaClear: "MasterReducer_criteriaClear",
};

const initial_state = {
  UserCriteria: {},
  UserList: [],
  RolesList: [],
  UserData: {
    Id: "",
    UserId: "",
    ReferenceId: "",
    EmployeeName: "",
    Password: "",
    Active: true,
    CreatedDate: "",
    CreatedBy: "",
    UpdatedDate: "",
    UpdatedBy: "",
  },
  UserRoleList: [],
  UserInput: {},
  RolesInput: [],
  SearchCriteriaInitial: {
    FullText: "",
  },
  SearchCriteria: {
    FullText: "",
  },
};

export const actionCreators = {
  SearchUser: (dispatch, param) => {
    let url = `${Constants.API_URL}/api/User/GetUserList`;
    Axios.post(url, param)
      .then((response) => {
        if (response.data) {
          //let resultsort = response.data.sort((a, b) => (a.CreatedDate < b.CreatedDate) ? 1 : -1)
          dispatch({ type: ActionType.GetUserList, param: response.data });
        }
      })
      .catch((error) => {
        //common.apiError(error)
      });
  },
  GetRoleList: async (dispatch, param) => {
    let url = `${Constants.API_URL}/api/User/GetRoleList`;
    var { result, error } = await common.to(Axios.post(url, {}));
    if (error) {
      return;
    }
    dispatch({ type: ActionType.GetRolesList, param: result.data });
  },
  GetUserDataByToken: async (dispatch) => {
    let url = `${Constants.API_URL}/api/User/GetUserByToken`;
    var { result, error } = await common.to(Axios.post(url, {}));
    if (error) {
      return;
    }
    dispatch({ type: ActionType.GetUserData, param: result.data });
  },
  GetUserData: async (dispatch, id) => {
    let url = `${Constants.API_URL}/api/User/GetUser`;
    var { result, error } = await common.to(Axios.post(url, { id: id }));
    if (error) {
      return;
    }
    dispatch({ type: ActionType.GetUserData, param: result.data });
    url = `${Constants.API_URL}/api/User/GetUserRoleList`;
    var { result, error } = await common.to(Axios.post(url, { id: id }));
    if (error) {
      return;
    }
    dispatch({ type: ActionType.GetUserRolesData, param: result.data });
    if (result.data) return result.data;
  },
  GetUserDataByUserID: async (user) => {
    let url = `${Constants.API_URL}/api/User/GetUserByUserID`;
    var { result, error } = await common.to(Axios.post(url, { user: user }));
    if (error) {
      return;
    }
    if (result.data) return result.data;
  },
  GetUserRoleDataList: async (id) => {
    let url = `${Constants.API_URL}/api/User/GetUserRoleList`;
    var { result, error } = await common.to(Axios.post(url, { id: id }));
    if (error) {
      return;
    }
    if (result.data) return result.data;
  },
  ValidateUser: (param) => {
    return common.to(
      Axios.post(`${Constants.API_URL}/api/User/ValidateUser`, param)
    );
  },
  SaveUser: async (dispatch, param, role, criteria) => {
    let url;
    if (param.Id !== "") {
      url = `${Constants.API_URL}/api/User/UpdateUser`;
    } else {
      url = `${Constants.API_URL}/api/User/CreateUser`;
    }

    let { result: save_result, error: save_error } = await common.to(
      Axios.post(url, param)
    );
    if (save_error) {
      return;
    }
    if (save_result.data) {
      dispatch({ type: ActionType.GetUserData, param: save_result.data });
      let id = save_result.data.Id;
      actionCreators.SearchUser(dispatch, criteria);
      //Role
      if (role && role.length > 0) {
        url = `${Constants.API_URL}/api/User/UpdateUserRole`;
        let UserRole = role.map((el) => ({ Id: id, RoleId: el }));
        let { result: role_result, error: role_error } = await common.to(
          Axios.post(url, UserRole)
        );
        if (role_error) {
          return;
        }
        if (role_result.data) {
          dispatch({
            type: ActionType.GetUserRolesData,
            param: role_result.data,
          });
        }
      } else {
        url = `${Constants.API_URL}/api/User/DeleteAllUserRole`;
        let { result: del_role_res, error: del_error } = await common.to(
          Axios.post(url, param)
        );
        if (del_error) {
          return;
        }
        dispatch({
          type: ActionType.GetUserRolesData,
          param: del_role_res.data,
        });
      }
    }
  },
  CheckPassword: (dispatch, param) => {
    let url = `${Constants.API_URL}/api/User/CheckPassword`;

    return Axios.post(url, param)
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  ChangePassword: (dispatch, param) => {
    let url = `${Constants.API_URL}/api/User/ChangePassword`;
    return Axios.post(url, param)
      .then((response) => {
        if (response.data) {
          dispatch({ type: ActionType.GetUserData, param: response.data });
        }
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  DeleteUser: (dispatch, id, criteria) => {
    let url = `${Constants.API_URL}/api/User/DeleteUser`;
    Axios.post(url, { id: id })
      .then((response) => {
        actionCreators.SearchUser(dispatch, criteria);
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  ValidateLastChangePassword: (data) => {
    let url = `${Constants.API_URL}/api/User/ValidateLastChangePassword`;
    let param = { UserId: data.UserId };
    return Axios.post(url, param)
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
};

//reducer Name
export const UserReducer = (
  state = JSON.parse(JSON.stringify(initial_state)),
  action
) => {
  switch (action.type) {
    case ActionType.GetUserList:
      state.UserList = action.param;
      break;
    case ActionType.GetRolesList:
      state.RolesList = action.param;
      break;
    case ActionType.GetUserData:
      state.UserData = action.param;
      state.UserInput = action.param;
      break;
    case ActionType.ClearUserData:
      state.UserData = initial_state.UserData;
      state.UserInput = initial_state.UserInput;
      break;
    case ActionType.GetUserCriteria:
      state.UserCriteria = action.param;
      break;
    case ActionType.GetUserForm:
      state.UserInput = action.param;
      break;
    case ActionType.GetSelectedRoleInput:
      state.RolesInput = action.param;
      break;
    case ActionType.GetUserRolesData:
      state.UserRoleList = action.param;
      break;
    //Search
    case ActionType.criteriaChange:
      state.SearchCriteria = action.param;
      break;
    case ActionType.criteriaClear:
      state.SearchCriteria = initial_state.SearchCriteriaInitial;
      state.SearchCriteriaInitial = {
        ...initial_state.SearchCriteriaInitial,
        Ran: common.uuidv4(),
      };
      break;
    default:
      break;
  }
  return state;
};
