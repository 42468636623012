import React, { useState, useEffect } from "react";
import Modal from "../UI/MainModal";
import * as common from "../CommonFunction/common-function";
import { useDispatch, useSelector } from "react-redux";
import { Input, CustomInput } from "reactstrap";
import { ReactTable } from "../UI/CommonTables";
import * as AddComponentStore from "./../../store/AddComponentStore";
import FormRowInput from "../UI/FormDetail";
import { PlandateDisplay } from "./../addmodal";
export const ToolModal = (props) => {
  //Props
  const defaultProps = {
    onClosed: () => {},
  };
  const currentProps = {
    ...defaultProps,
    ...props,
  };

  //Declare
  const [isOpen, setIsOpen] = useState(false);
  const [Tools, setTools] = useState([]);
  const [dataItem, setDataItem] = useState({ FullText: "" });

  //Redux
  const dispatch = useDispatch();
  const ActiveTools = useSelector((state) => state.AddComponentReducer.Tools);

  //Effect
  useEffect(() => {
    //OpenModal
    if (props.Jobdata) {
      setDataItem({
        ...dataItem,
        DateFrom: props.Jobdata.DateFrom,
        DateTo: props.Jobdata.DateTo,
        JobId: props.Jobdata.JobId,
      });
    }
    setIsOpen(props.isOpen);
    AddComponentStore.actionCreators.GetActiveToolsWithAssignList(dispatch, {
      ...dataItem,
      IsActive: true,
    });
  }, [props]);
  useEffect(() => {
    AddComponentStore.actionCreators.GetActiveToolsWithAssignList(dispatch, {
      ...dataItem,
      IsActive: true,
    });
  }, [dataItem]);
  useEffect(() => {
    //GetValueSelected
    setTools(props.SelectedData);
  }, [props.SelectedData]);

  //Event
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDataItem({ ...dataItem, [name]: value });
  };
  const get = () => {
    props.GetValue(Tools);
    // props.onCancel()
  };
  const handleChange = (e, SerialNo) => {
    if (e.target.checked) {
      setTools(Tools.concat(SerialNo));
    } else {
      setTools(
        Tools.filter((id) => {
          return id !== SerialNo;
        })
      );
    }
  };

  //SubComponent

  const header = (
    <React.Fragment>
      <th></th>
      <th>Serial No.</th>
      <th>Type</th>
      <th>Name</th>
      <th>Assigned</th>
    </React.Fragment>
  );

  const row =
    ActiveTools &&
    ActiveTools.map((item) => (
      <tr key={common.uuidv4()} style={item.isBusy ? { color: "red" } : {}}>
        <td>
          {" "}
          <CustomInput
            type="checkbox"
            className="table-checkbox"
            id={item.SerialNo || common.uuidv4()}
            checked={Tools.includes(item.SerialNo)}
            onChange={(e) => {
              handleChange(e, item.SerialNo);
            }}
          />
        </td>
        <td>{item.SerialNo + (item.isBusy ? " (ไม่ว่าง)" : "")} </td>
        <td>{item.ToolsType}</td>
        <td>{item.ToolsName}</td>
        <td>{item.Assigned}</td>
      </tr>
    ));

  return (
    <Modal
      onCancel={currentProps.onCancel}
      isOpen={isOpen}
      onSave={get}
      SaveText="OK"
      onClosed={() => {
        currentProps.onClosed();
      }}
      ModalHeaderText={`Add Tools ${PlandateDisplay(
        props.Jobdata && props.Jobdata.DateFrom,
        props.Jobdata && props.Jobdata.DateTo
      )} `}
    >
      <FormRowInput label="Tools">
        <Input
          type="text"
          name="FullText"
          id="FullText"
          value={dataItem.FullText}
          onChange={handleInputChange}
          bsSize="sm"
        />
      </FormRowInput>

      <ReactTable Header={header} Row={row} />
    </Modal>
  );
};

export default ToolModal;
