// export const API_LOCAL_URL = 'https://localhost:44323';
// export const API_LOCAL_MEDIA_URL = 'https://localhost:44334';
// export const API_LOCAL_AUTHEN = 'https://localhost:44394/api/User/SignIn';

// export const API_PROD_URL = 'http://awake-it.ddns.me:7801';
// export const API_PROD_MEDIA_URL = 'http://awake-it.ddns.me:7802';
// export const API_PROD_AUTHEN = 'http://awake-it.ddns.me:7810/api/User/SignIn';

// export const API_TEST_URL = 'http://localhost:7801';
// export const API_TEST_MEDIA_URL = 'http://localhost:7802';
// export const API_TEST_AUTHEN = 'http://localhost:7810/api/User/SignIn';

// export const API_KONG_URL = 'http://192.168.1.200:7600';
// export const API_KONG_URL_LOCAL = 'http://localhost:8000'

// export const API_AWAKE_URL = 'http://awake-it.ddns.me:7601';
// export const API_AWAKE_MEDIA_URL = 'http://awake-it.ddns.me:7602';

// export const API_UAT_URL = 'http://desktop-l8p858v:7801';
// export const API_UAT_MEDIA_URL = 'http://desktop-l8p858v:7802';
// export const API_UAT_AUTHEN = 'http://desktop-l8p858v:7810/api/User/SignIn';

//Local
//export const API_URL = API_TEST_URL;
//export const API_URL_MEDIA = API_TEST_MEDIA_URL;
//export const API_AUTHEN = API_TEST_AUTHEN;

export const API_URL = window.config.API_CORE;
export const API_URL_MEDIA = window.config.API_Media;
export const API_AUTHEN = window.config.API_AUTHEN;
export const API_NOTIFICATION = window.config.API_NOTIFICATION;

//Local Tong
//export const API_URL = API_LOCAL_URL;
//export const API_URL = 'http://localhost:12333';
//export const API_URL_MEDIA = 'http://localhost:7700';
//export const API_AUTHEN = API_LOCAL_AUTHEN;
////export const API_AUTHEN = 'http://localhost:7800/api/User/SignIn';

//Production//
//export const API_URL = API_PROD_URL;
//export const API_URL_MEDIA = API_PROD_MEDIA_URL
//export const API_AUTHEN = API_PROD_AUTHEN;
