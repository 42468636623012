import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { Calendar } from "react-multi-date-picker";
import { InformationOKDialog } from "../CommonFunction/common-function";
//Function
import * as common from "./../CommonFunction/common-function";
const MainModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const defaultProps = {
    isOpen: false,
    toggle: () => {
      setIsOpen(!isOpen);
    },
    onClosed: () => {},
    ModalHeaderText: "",
    onCancel: () => {
      defaultProps.toggle();
    },
    CancelText: "Cancel",
    CancelHidden: false,
    CancelClass: "btn-default btnDefault btn-Temp",
    onSave: () => {},
    SaveText: "Save",
    SaveHidden: false,
    SaveClass: "btn-default btnDefault btn-Temp",
    onDelete: () => {},
    DeleteText: "Delete",
    DeleteHidden: true,
    DeleteClass: "btn-default btnDefault btn-Temp",
    onClear: () => {},
    ClearText: "Clear",
    ClearHidden: true,
    ClearClass: "btn-default btnDefault btn-Temp",
    onbutton1Click: () => {},
    button1txt: "Edit Job",
    button1hidden: true,
    Size: "lg",
    maxHeight: "400px",
  };
  const currentProps = {
    ...defaultProps,
    ...props,
  };
  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);
  const onSubmit = (event) => {
    event.preventDefault();
    //const { name, email } = this.state;

    currentProps.onSave();
  };
  const onKeyPress = (event) => {
    if (event.which === 13 /* Enter */) {
      //event.preventDefault();
    }
  };
  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={currentProps.toggle}
        size={currentProps.Size}
        backdrop="static"
        onClosed={currentProps.onClosed}
        className="modal-function-add"
      >
        <Form onSubmit={onSubmit} onKeyPress={onKeyPress}>
          <ModalHeader toggle={currentProps.onCancel}>
            {currentProps.ModalHeaderText}
          </ModalHeader>
          <ModalBody
            style={
              props.style || {
                minHeight: "250px",
                maxHeight: currentProps.maxHeight,
              }
            }
          >
            {props.children}
          </ModalBody>
          <ModalFooter>
            <input
              type="submit"
              className={`btn ${currentProps.SaveClass}`}
              value={currentProps.SaveText}
              hidden={currentProps.SaveHidden}
            />{" "}
            <input
              type="button"
              className={`btn ${currentProps.ClearClass}`}
              onClick={currentProps.onClear}
              value={currentProps.ClearText}
              hidden={currentProps.ClearHidden}
            />
            <input
              type="button"
              className={`btn ${currentProps.CancelClass}`}
              onClick={currentProps.onCancel}
              value={currentProps.CancelText}
              hidden={currentProps.CancelHidden}
            />
            <input
              type="button"
              className={`btn ${currentProps.DeleteClass}`}
              onClick={currentProps.onDelete}
              value={currentProps.DeleteText}
              hidden={currentProps.DeleteHidden}
            />
            <input
              type="button"
              className="btn btn-default btnDefault btn-Temp"
              onClick={currentProps.onbutton1Click}
              value={currentProps.button1txt}
              hidden={currentProps.button1hidden}
            />{" "}
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
export const SignatureMainModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const defaultProps = {
    isOpen: false,
    toggle: () => {
      setIsOpen(!isOpen);
    },
    onClosed: () => {},
    ModalHeaderText: "",
    onCancel: () => {
      defaultProps.toggle();
    },
    CancelText: "Cancel",
    CancelHidden: false,
    CancelClass: "btn-default btnDefault btn-Temp",
    onSave: () => {},
    SaveText: "Save",
    SaveHidden: false,
    SaveClass: "btn-default btnDefault btn-Temp",
    onDelete: () => {},
    DeleteText: "Delete",
    DeleteHidden: true,
    DeleteClass: "btn-default btnDefault btn-Temp",
    onClear: () => {},
    ClearText: "Clear",
    ClearHidden: true,
    ClearClass: "btn-default btnDefault btn-Temp",
    onbutton1Click: () => {},
    button1txt: "Edit Job",
    button1hidden: true,
  };
  const currentProps = {
    ...defaultProps,
    ...props,
  };
  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);
  const onSubmit = (event) => {
    event.preventDefault();
    //const { name, email } = this.state;

    currentProps.onSave();
  };
  const onKeyPress = (event) => {
    if (event.which === 13 /* Enter */) {
      //event.preventDefault();
    }
  };
  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={currentProps.toggle}
        size="lg"
        backdrop="static"
        onClosed={currentProps.onClosed}
        className="modal-function-add"
        style={{ height: "80vh" }}
      >
        <Form onSubmit={onSubmit} onKeyPress={onKeyPress}>
          <ModalHeader toggle={currentProps.onCancel}>
            {currentProps.ModalHeaderText}
          </ModalHeader>
          <ModalBody style={{ height: "60vh" }}>{props.children}</ModalBody>
          <ModalFooter>
            <input
              type="submit"
              className={`btn ${currentProps.SaveClass}`}
              value={currentProps.SaveText}
              hidden={currentProps.SaveHidden}
            />{" "}
            <input
              type="button"
              className={`btn ${currentProps.ClearClass}`}
              onClick={currentProps.onClear}
              value={currentProps.ClearText}
              hidden={currentProps.ClearHidden}
            />
            <input
              type="button"
              className={`btn ${currentProps.CancelClass}`}
              onClick={currentProps.onCancel}
              value={currentProps.CancelText}
              hidden={currentProps.CancelHidden}
            />
            <input
              type="button"
              className={`btn ${currentProps.DeleteClass}`}
              onClick={currentProps.onDelete}
              value={currentProps.DeleteText}
              hidden={currentProps.DeleteHidden}
            />
            <input
              type="button"
              className="btn btn-default btnDefault btn-Temp"
              onClick={currentProps.onbutton1Click}
              value={currentProps.button1txt}
              hidden={currentProps.button1hidden}
            />{" "}
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export const CalendarDatePickerModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dateValues, setDateValues] = useState([]);
  const [suggestDateList, setSuggestDateList] = useState([]);
  const [highlightRedDateList, setHighlightRedDateList] = useState([]);
  const [highlightOrangeDateList, setHighlightOrangeDateList] = useState([]);
  const {
    initData,
    required,
    initSuggestData,
    redTitle = "",
    initHighlightRedData,
    orangeTitle = "",
    initHighlightOrangeData,
  } = props;
  const defaultProps = {
    isOpen: false,
    toggle: () => {
      setIsOpen(!isOpen);
    },
    validateSave: () => {
      return false;
    },
    onSave: () => {},
    onClosed: () => {},
    onCancel: () => {
      defaultProps.toggle();
    },
    ModalHeaderText: "",
    className: "modal-en",
  };

  const currentProps = {
    ...defaultProps,
    ...props,
  };
  // Effect
  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    if (!isOpen) return;
    setDateValues(initData);
    setSuggestDateList(initSuggestData);
    setHighlightRedDateList(initHighlightRedData);
    setHighlightOrangeDateList(initHighlightOrangeData);
  }, [
    isOpen,
    initData,
    initSuggestData,
    initHighlightRedData,
    initHighlightOrangeData,
  ]);

  const handleSave = () => {
    if (required && common.isEmptyArray(dateValues)) {
      InformationOKDialog("Please Select Date Service");
      return;
    }

    if (!currentProps.validateSave(dateValues)) {
      return;
    }

    currentProps.onSave(dateValues);
    currentProps.toggle();
  };

  const handleHighlightDate = ({ date }) => {
    let props = {};

    // defualt
    props.style = {};

    const monthNumber =
      typeof date.month === "object" ? date.month.number : date.month;

    const formattedDate = `${date.year}-${monthNumber
      .toString()
      .padStart(2, "0")}-${date.day.toString().padStart(2, "0")}`;

    if (suggestDateList && suggestDateList.length > 0) {
      if (suggestDateList && suggestDateList.includes(formattedDate)) {
        props.style = {
          ...props.style,
          color: "#000",
          fontWeight: "bold",
        };
      } else {
        props.style = {
          ...props.style,
          color: "#CCC",
        };
      }
    }

    if (highlightRedDateList && highlightRedDateList.includes(formattedDate)) {
      props.title = redTitle;
      props.style = {
        ...props.style,
        color: "#FF0000",
      };
    } else if (
      highlightOrangeDateList &&
      highlightOrangeDateList.includes(formattedDate)
    ) {
      props.title = orangeTitle;
      props.style = {
        ...props.style,
        color: "#FFA366",
      };
    }

    return props;
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={currentProps.toggle}
        onClosed={currentProps.onClosed}
        className={currentProps.className}
        backdrop="static"
      >
        <ModalHeader toggle={currentProps.onCancel}>
          {currentProps.ModalHeaderText}
        </ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Calendar
              value={dateValues}
              onChange={setDateValues}
              range
              multiple
              format="DD-MM-YYYY"
              sort
              plugins={[<DatePanel sort="date" />]}
              shadow={false}
              mapDays={handleHighlightDate}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn btn-default btnDefault btn-Temp"
            onClick={handleSave}
          >
            Save
          </Button>{" "}
          <Button
            className="btn btn-default btnDefault btn-Temp"
            onClick={currentProps.onCancel}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
export default MainModal;
