import React, { useState } from "react";
import * as common from "./../CommonFunction/common-function";
import AddToolModal from "./../addmodal/AddToolModal";
import { useDispatch, useSelector } from "react-redux";
import * as JobStore from "./../../store/jobStore";

import { ChildTable } from "../UI/CommonTables";

function Tools() {
  //Props
  //Declare State
  const IoIosAdd = common.getIconTag(common.Icontype.ION, "IoIosAdd");
  const Icondelete = common.getIconTag(common.Icontype.ION, "IoMdClose");

  const [isOpen, setIsOpen] = useState();
  //Redux
  const dispatch = useDispatch();
  const Tools = useSelector((state) => state.jobReducer.Form.Tools);
  const Jobinfo = useSelector((state) => state.jobReducer.Form.Jobdata);

  //Effect

  //Event
  const Add = () => {
    setIsOpen(true);
  };
  const getValue = (toolsList) => {
    //New Api For List
    if (toolsList && toolsList.length > 0) {
      const toolsList2 = toolsList.map((item) => ({
        JobId: Jobinfo.JobId,
        SerialNo: item,
        UpdatedBy: common.GetUserId(),
      }));
      JobStore.actionCreators.UpdateJobToolsAllocate(
        dispatch,
        Jobinfo.JobId,
        toolsList2
      );
    } else {
      JobStore.actionCreators.RemoveAllTools(dispatch, Jobinfo.JobId);
    }
    setIsOpen(!isOpen);
  };
  //SubComponent
  //const row = Tools && Tools.map((item) => (
  //    <tr key={common.uuidv4()}>

  //        <td>{item.SerialNo}{item.isOverlap ? ' (ไม่ว่าง)' : ''}</td>
  //        <td>{item.ToolsType}</td>
  //        <td>{item.ToolsName}</td>
  //        <td>
  //            <button className="btn-transparent"><Iconmore /></button>
  //            <button onClick={() => {
  //                JobStore.actionCreators.RemoveTools(item)
  //                setTimeout(() => {
  //                    JobStore.actionCreators.dataToJobform(dispatch, Jobinfo.JobId)
  //                }, 1000);
  //            }} className="btn-transparent"><Icondelete /></button>
  //        </td>
  //    </tr>
  //))
  const columns = [
    {
      dataField: "SerialNo",
      text: "Serial No.",
      sort: true,
      formatter: (cell, row) => {
        return (
          <p style={row.isOverlap ? { color: "red" } : {}}>
            {row.SerialNo + (row.isOverlap ? " (ไม่ว่าง)" : "")}
          </p>
        );
      },
    },
    {
      dataField: "ToolsType",
      text: "Type",
      sort: true,
      formatter: (cell, row) => {
        return (
          <p style={row.isOverlap ? { color: "red" } : {}}>{row.ToolsType}</p>
        );
      },
    },
    {
      dataField: "ToolsName",
      text: "Name",
      sort: true,
      formatter: (cell, row) => {
        return (
          <p style={row.isOverlap ? { color: "red" } : {}}>{row.ToolsName}</p>
        );
      },
    },
    {
      dataField: "a",
      text: "",
      formatter: (cell, row) => {
        return (
          <button
            onClick={async () => {
              await JobStore.actionCreators.RemoveTools(row);
              JobStore.actionCreators.dataToJobform(dispatch, Jobinfo.JobId);
            }}
            className="btn-transparent"
          >
            <Icondelete />
          </button>
        );
      },
    },
  ];

  return (
    <div>
      {Jobinfo && Jobinfo.JobStatus !== "CLOSE" && (
        <div className="row detail-head">
          <div className="col-2 col-md-2">
            <button className="btn-transparent" onClick={Add}>
              <IoIosAdd className="icon" />
            </button>
          </div>
        </div>
      )}
      <ChildTable keyField="SerialNo" data={Tools} columns={columns} />
      <AddToolModal
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(!isOpen);
        }}
        SelectedData={Tools && Tools.map((a) => a.SerialNo)}
        GetValue={getValue}
        Jobdata={Jobinfo}
      />
    </div>
  );
}

export default Tools;
