//Library
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { useForm, FormContext } from "react-hook-form";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import LinearProgress from "@material-ui/core/LinearProgress";
import Alert from "@material-ui/lab/Alert";
//Component
import * as common from "../CommonFunction/common-function";
import SlideModal from "../UI/CommonForm";
import * as constant from "../CommonFunction/constant";
import { FormRow } from "../UI/FormDetail";
import * as LinkModal from "./../UI/LinkText/LinkModal";
import DetailBox from "../UI/DetailBox";
import JobActualResult, { Signature } from "./JobActualResult";
import InternalServiceReport from "./InternalServiceReport";
import Uploader from "../UI/Uploader";
import TagsInput from "../UI/TagsInput";
//Store
import * as JobMonitorStore from "../../store/JobMonitorStore";
import * as JobStore from "../../store/jobStore";
import { PerissionScreenContext } from "./../Permission";
//Function
import { useOpenWithDataDialog } from "./../functin-api/useOpenWithDataDialog";

export const JobMonitorScreen = (props) => {
  const dispatch = useDispatch();
  const emailControlDialog = useOpenWithDataDialog();
  const errorSendEmailDialog = useOpenWithDataDialog();
  const Jobdata = useSelector((state) => state.JobMointorReducer.Form.Jobdata);
  const Data = useSelector((state) => state.JobMointorReducer.Form);

  const ActualForm = useForm();
  const { Function: fn_lst } = useContext(PerissionScreenContext);

  const [buttons, setButtons] = useState();
  const [isLoading, setisLoading] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [emailTo, setEmailTo] = useState([]);
  const [emailCc, setEmailCc] = useState([]);
  const [errorSendEmail, setErrorSendEmail] = useState(null);
  const [errorDetailEmail, setErrorDetailEmail] = useState(null);

  const [serviceReport, setServiceReport] = React.useState(false);
  const [deliveryReport, setDeliveryReport] = React.useState(false);

  const denied = () => {
    return common.disableEdit(fn_lst);
  };

  useEffect(() => {
    let status = "";
    if (Jobdata) {
      status = Jobdata.JobStatus;
    } else return;

    const getactuallst = () => {
      return Data.JobActualInputList.map((item) => {
        return { ...item, UpdatedBy: common.GetUserId() };
      });
    };
    const getInternal = () => {
      return {
        ...Data.InternalInput,
        UpdatedBy: common.GetUserId(),
      };
    };
    const save = async () => {
      if (
        !(await JobMonitorStore.actionCreators.validate_actual(getactuallst()))
      ) {
        return;
      }

      if (!validate_interServiceReport(getInternal())) {
        return;
      }

      common.SaveWithConfirm(saveActualInternal);
    };

    const validate_interServiceReport = (data) => {
      if (data.ActionShort && data.ActionShort.length > 500) {
        common.InformationOKDialog(
          "[Internal Service Report] Action (Short) text is too long."
        );
        return false;
      }

      if (data.Awareness && data.Awareness.length > 1000) {
        common.InformationOKDialog(
          "[Internal Service Report] ข้อควรระวัง text is too long."
        );
        return false;
      }

      if (data.FollowUp && data.FollowUp.length > 1000) {
        common.InformationOKDialog(
          "[Internal Service Report] Follow up text is too long."
        );
        return false;
      }

      if (data.Opportunity && data.Opportunity.length > 1000) {
        common.InformationOKDialog(
          "[Internal Service Report] โอกาสทางการขาย text is too long."
        );
        return false;
      }

      if (data.Remark && data.Remark.length > 1000) {
        common.InformationOKDialog(
          "[Internal Service Report] Remark text is too long."
        );
        return false;
      }

      return true;
    };

    const saveActualInternal = async () => {
      var res1 = saveActual();
      var res2 = saveInternal();
      var res3 = Promise.allSettled([res1, res2]);
      await res3;
      JobMonitorStore.actionCreators.GetJob(dispatch, Jobdata.JobId);
    };
    const saveActual = () => {
      return JobMonitorStore.actionCreators.SaveActualList(
        dispatch,
        getactuallst()
      );
    };
    const saveInternal = () => {
      return JobMonitorStore.actionCreators.SaveInternal(
        dispatch,
        getInternal()
      );
    };
    const edit = async () => {
      await JobStore.actionCreators.dataToJobform(dispatch, Jobdata.JobId);

      setIsOpen(true);
    };
    const closeJob = async () => {
      if (
        !(await JobMonitorStore.actionCreators.validate_actual(getactuallst()))
      )
        return;
      let { result, error } =
        await JobMonitorStore.actionCreators.CheckCanClose(Jobdata);
      if (error) return;
      if (!result.data) {
        common.InformationOKDialog(
          "Cannot Close.Please upload report or signature"
        );
        return;
      }

      common.SaveWithConfirm(() => {
        JobMonitorStore.actionCreators.SaveJob(dispatch, {
          ...Jobdata,
          JobStatus: constant.CLOSE,
          UpdatedBy: common.GetUserId(),
        });
        saveActualInternal();
      });
    };
    const startJob = () => {
      common.SaveWithConfirm(() => {
        JobMonitorStore.actionCreators.SaveJob(dispatch, {
          ...Jobdata,
          JobStatus: constant.ONDUTY,
          UpdatedBy: common.GetUserId(),
        });
      });
    };
    const previewDelivery = async () => {
      JobMonitorStore.actionCreators.AddDeliveryReport(Jobdata.JobId);
    };
    const PendingJob = async () => {
      if (
        !(await JobMonitorStore.actionCreators.validate_actual(getactuallst()))
      )
        return;
      common.SaveWithConfirm(() => {
        JobMonitorStore.actionCreators.SaveJob(dispatch, {
          ...Jobdata,
          JobStatus: constant.PENDING,
          UpdatedBy: common.GetUserId(),
        });
        saveActualInternal();
      });
    };
    const Renew = async () => {
      if (
        !(await JobMonitorStore.actionCreators.validate_actual(getactuallst()))
      )
        return;
      common.SaveWithConfirm(() => {
        JobMonitorStore.actionCreators.SaveJob(dispatch, {
          ...Jobdata,
          JobStatus: constant.CARRIED,
          UpdatedBy: common.GetUserId(),
        });
        saveActualInternal();
      });
    };
    const savebutton = {
      text: "Save",
      function: () => {
        save();
      },

      disabled: denied(),
    };
    const saveInternalbutton = {
      text: "Save(Internal)",
      function: () => {
        saveInternal();
        common.DoneDialog();
      },
      disabled: denied(),
    };
    const previewReport = async () => {
      setisLoading(true);
      await JobMonitorStore.actionCreators.SendEmailActual(
        dispatch,
        Jobdata.JobId,
        false
      );
      setisLoading(false);
    };
    const SendReport = async () => {
      var { result, error } =
        await JobMonitorStore.actionCreators.CheckValidateSendEmail(
          Jobdata.JobId
        );
      if (error) {
        return;
      }
      if (result.data) {
        if (result.data.isValid) {
          setServiceReport(true);
          setDeliveryReport(false);
          emailControlDialog.clickOpen(result.data.Messages);
        } else {
          common.InformationOKDialog(
            result.data.Messages && common.ArrayTextReduce(result.data.Messages)
          );
        }
      }
    };
    const closeJobbutton = {
      text: "Close Job",
      function: () => {
        closeJob();
      },
      disabled: denied(),
    };
    const PendingJobbutton = {
      text: "Pending Job",
      function: () => {
        PendingJob();
      },
      disabled: denied(),
    };
    const StartButton = {
      text: "Start",
      function: () => {
        startJob();
      },
      disabled: denied(),
    };
    const PreviewDelivery = {
      text: "Preview Delivery Report",
      function: () => {
        previewDelivery();
      },
    };
    const renewButton = {
      text: "Re-New Job",
      function: () => {
        Renew();
      },
      disabled: denied(),
    };
    const editbutton = {
      text: "Edit Job",
      function: () => {
        edit();
      },
    };
    const PreviewService = {
      text: "Preview Service Report",
      function: () => {
        previewReport();
      },
    };
    const sendEmailbutton = {
      text: "Send Email",
      function: () => {
        SendReport();
      },
      disabled: denied(),
    };
    switch (status) {
      case constant.DRAFT:
        setButtons([editbutton]);
        break;
      case constant.COMMITED:
        setButtons([StartButton, PreviewDelivery, editbutton]);
        break;
      case constant.ONDUTY:
        setButtons([
          savebutton,
          closeJobbutton,
          PendingJobbutton,
          PreviewDelivery,
          PreviewService,
          sendEmailbutton,
          editbutton,
        ]);
        break;
      case constant.PENDING:
        setButtons([savebutton, renewButton, PreviewService, sendEmailbutton]);
        break;
      case constant.CARRIED:
        setButtons([savebutton, PreviewService, sendEmailbutton]);
        break;
      case constant.CLOSE:
        setButtons([
          saveInternalbutton,
          PreviewDelivery,
          PreviewService,
          sendEmailbutton,
        ]);
        break;
      default:
        setButtons([]);
        break;
    }
  }, [Jobdata, dispatch, Data.InternalInput, Data.JobActualInputList]);

  useEffect(() => {
    if (Jobdata.JobId) {
      JobMonitorStore.actionCreators.LoadDetail(dispatch, Jobdata.JobId);
    }
  }, [Jobdata.JobId, dispatch]);

  function handleSelecetedEmailTo(listEmail) {
    setEmailTo(listEmail);
  }

  function handleSelecetedEmailCc(listEmail) {
    setEmailCc(listEmail);
  }

  async function onSendReportToEmailList() {
    if (!serviceReport && !deliveryReport) {
      setErrorSendEmail("Please select a report.");
      return;
    } else {
      setErrorSendEmail(null);
    }
    setisLoading(true);
    var res = await JobMonitorStore.actionCreators.SendReportToEmailList(
      dispatch,
      Jobdata.JobId,
      emailTo,
      emailCc,
      serviceReport,
      deliveryReport
    );
    var isSuccessful = await validateResultEmail(res);
    setisLoading(false);

    if (isSuccessful) {
      emailControlDialog.onClose();
      common.DoneDialog("The report has been sent.");
    }
  }

  async function validateResultEmail(res) {
    try {
      if (common.isEmptyObj2(res)) {
        setErrorSendEmail("Delivery has failed. Please try again.");
        return false;
      }

      if (!common.isEmptyStr(res.ex)) {
        setErrorSendEmail(res.ex);
        return false;
      }

      if (common.isEmptyStr(res.MessageId)) {
        return true;
      }

      const result = await JobMonitorStore.actionCreators.MonitorInbox(
        res.MessageId
      );

      if (result && result.timeout) {
        setErrorSendEmail(result.message);
        return false;
      }

      if (result && result.status === constant.ERROR) {
        setErrorSendEmail(result.subject);
        setErrorDetailEmail(result.message);
        return false;
      }

      if (result && !common.isEmptyStr(result.message)) {
        setErrorSendEmail("Unexpected error: " + result.message);
        return false;
      }
      return true;
    } catch (e) {
      setErrorSendEmail("Error: " + e.message);
      return false;
    }
  }

  const showErrorDetailDialog = async () => {
    errorSendEmailDialog.clickOpen();
  };

  return (
    <React.Fragment>
      <FormContext {...{ ActualForm: ActualForm }}>
        <LoadingOverlay
          active={isLoading}
          spinner
          text={
            emailTo &&
            emailTo.length > 0 && (
              <h3
                style={{
                  zIndex: 9999,
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                Sending email...
              </h3>
            )
          }
          styles={{
            overlay: (base) => ({
              ...base,
              zIndex: 9998,
            }),
          }}
        >
          {
            //2Times data,button
          }
          {isOpen ? (
            <Redirect
              to={{
                pathname: "/Job",
                state: {
                  BackLink: {
                    pathname: "/JobMonitor",
                    state: {
                      BackLink:
                        props.location.state && props.location.state.BackLink,
                    },
                  },
                },
              }}
            />
          ) : null}
          {Jobdata.JobId ? null : <Redirect to="/dashboard" />}
          <SlideModal
            BackLink={
              props.BackLink ||
              (props.location.state && props.location.state.BackLink) ||
              "/dashboard"
            }
            onBack={() => {
              dispatch({ type: JobMonitorStore.ActionType.CLEAR_DETAIL });
            }}
            ButtonJson={buttons}
          >
            <JobMonitorForm />
          </SlideModal>
        </LoadingOverlay>
        <Dialog
          fullWidth
          maxWidth={"md"}
          open={emailControlDialog.isOpen}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Send Report</DialogTitle>
          <DialogContent>
            <TagsInput
              selectedTags={handleSelecetedEmailTo}
              variant="outlined"
              id="to"
              name="to"
              label="To"
              tags={emailControlDialog.Data}
              isEmail
            />
            <br />
            <TagsInput
              selectedTags={handleSelecetedEmailCc}
              variant="outlined"
              id="cc"
              name="cc"
              label="Cc"
              isEmail
            />
            <br />
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={serviceReport}
                    onChange={(event) => setServiceReport(event.target.checked)}
                  />
                }
                label="Service Report"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={deliveryReport}
                    onChange={(event) =>
                      setDeliveryReport(event.target.checked)
                    }
                  />
                }
                label="Delivery Report"
              />
            </FormGroup>
            {isLoading && <LinearProgress />}
            {errorSendEmail && (
              <Alert
                severity="error"
                action={
                  errorDetailEmail && (
                    <Button
                      color="inherit"
                      size="small"
                      onClick={showErrorDetailDialog}
                    >
                      Detail
                    </Button>
                  )
                }
              >
                {errorSendEmail}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={async () => {
                setErrorSendEmail(null);
                setErrorDetailEmail(null);
                emailControlDialog.onClose();
              }}
              size="small"
              color="primary"
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              onClick={async () => {
                setErrorSendEmail(null);
                setErrorDetailEmail(null);
                await onSendReportToEmailList();
              }}
              size="small"
              variant="contained"
              color="primary"
              disabled={(emailTo && emailTo.length === 0) || isLoading}
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth={"md"}
          open={errorSendEmailDialog.isOpen}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{errorSendEmail}</DialogTitle>
          <DialogContent>{errorDetailEmail}</DialogContent>
          <DialogActions>
            <Button
              onClick={async () => {
                errorSendEmailDialog.onClose();
              }}
              size="small"
              color="primary"
              disabled={isLoading}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </FormContext>
    </React.Fragment>
  );
};
const JobMonitorForm = React.memo((props) => {
  //Redux
  const CanEdit = useSelector(
    (state) =>
      (state.JobMointorReducer.Form.Jobdata &&
        state.JobMointorReducer.Form.Jobdata.canEdit) ||
      false
  );
  const Data = useSelector(
    (state) => state.JobMointorReducer.Form.JobActualList
  );
  //Props
  //Declare State

  //Effect
  //Event+function

  //SubComponent //
  const Actual =
    Data &&
    Data.map((item, idx) => (
      <DetailBox
        key={common.uuidv4()}
        HeaderText={`Job Actual Result : ${item.SerialNo}`}
        button2hidden={false}
      >
        <JobActualResult Data={item} index={idx} />
      </DetailBox>
    ));
  const compEdit = (
    <React.Fragment>
      {Actual}
      <DetailBox HeaderText="Customer Signature">
        <Signature />
      </DetailBox>
      <DetailBox HeaderText="Engineer Signature">
        <Signature isEngineer />
      </DetailBox>
      <DetailBox HeaderText="Internal Service Report">
        <InternalServiceReport />
      </DetailBox>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <DetailBox HeaderText="Job Detail">
        <JobDetail />
      </DetailBox>
      {CanEdit ? compEdit : null}
    </React.Fragment>
  );
});
export function JobDetail() {
  //Redux
  const jobdata = useSelector((state) => state.JobMointorReducer.Form.Jobdata);
  const Form = useSelector((state) => state.JobMointorReducer.Form);
  const EngineersInfo = Form.Engineer;

  //Props

  //Declare State
  const [engineers, setEngineers] = useState([]);
  const dispatch = useDispatch();
  const [ImagesReport, setActualImagesReport] = useState([]);
  const attatchdataCertificate = {
    JobId: jobdata.JobId,
    Section: "Actual",
    DocumentType: "Image_Report",
  };

  //Effect
  useEffect(() => {
    var mappedEngineers = common.groupAndMapEngineers(EngineersInfo);
    setEngineers(mappedEngineers);
  }, [EngineersInfo]);

  useEffect(() => {
    if (jobdata) {
      if (jobdata.JobId === "") return;
    }
    async function fetchData() {
      JobMonitorStore.actionCreators.LoadAttachmentList_Return(
        { ...attatchdataCertificate },
        (data) => {
          setActualImagesReport(data);
        }
      );
    }
    fetchData();
  }, [jobdata]);
  //Event+function
  const LoadAttachment = () => {
    JobMonitorStore.actionCreators.LoadAttachmentList_Return(
      { ...attatchdataCertificate },
      (data) => {
        setActualImagesReport(data);
      }
    );
  };

  const Upload = async (FileInfo, base64, Tags, attatchdata) => {
    let attatchdata_withTags = { ...attatchdata, Tags: Tags };
    var result = await JobMonitorStore.actionCreators.SaveFile64(
      dispatch,
      base64,
      FileInfo,
      attatchdata_withTags
    );
    if (result) {
      LoadAttachment();
    } else {
      common.InformationOKDialog("Upload fail");
    }
  };

  //SubComponent
  function UploadImageReport() {
    return (
      <div className="row detail-form">
        <div className="col-12 col-md-4">
          <label htmlFor="photo" className="col-label-bold">
            Images
          </label>
        </div>
        <div className="col-12 col-md-8 col-detailForm">
          <div className="col-12 detail-form">
            <Uploader
              hiddenTags={true}
              acceptFile={"image/png, image/jpeg"}
              onUpload={(FileInfo, base64, Tags) =>
                Upload(FileInfo, base64, Tags, attatchdataCertificate)
              }
            />
          </div>
          {ImagesReport &&
            ImagesReport.map((item) => (
              <div key={common.uuidv4()}>
                <LinkModal.MediaFileName
                  Display={item.AttachmentName}
                  FileNameLink={item.FileNameLink}
                  Delete={() => {
                    JobMonitorStore.actionCreators
                      .DeleteAttachment(dispatch, item)
                      .then((res) => {
                        LoadAttachment();
                      });
                  }}
                />
              </div>
            ))}
        </div>
      </div>
    );
  }

  return (
    <div>
      <FormRow label="Job Number">
        <label htmlFor="Job_Number_Value">{jobdata.JobNumber}</label>
      </FormRow>
      <FormRow label="Job Type">
        <label htmlFor="Job_Type_Value">{jobdata && jobdata.JobType}</label>
      </FormRow>
      <FormRow label="Status">
        <label htmlFor="Status_Value">{jobdata && jobdata.JobStatus}</label>
      </FormRow>
      <FormRow label="Customer">
        <LinkModal.Customer
          Text={jobdata && jobdata.CustomerName}
          Value={jobdata && jobdata.CustomerId}
        />
      </FormRow>
      <FormRow label="PO No.">
        <LinkModal.ServiceRequest
          Text={jobdata && jobdata.PoNumber}
          Value={jobdata && jobdata.ServiceRequestId}
        />
      </FormRow>
      <FormRow label="Brief">
        <label htmlFor="Brief_Value">{jobdata && jobdata.Brief}</label>
      </FormRow>
      <FormRow label="Planned Date">
        <label htmlFor="Planned_Date_Value">
          {jobdata && common.formatDate(jobdata.DateFrom)}
        </label>
      </FormRow>
      <FormRow label="To Date">
        <label htmlFor="To_Value">
          {jobdata && common.formatDate(jobdata.DateTo)}
        </label>
      </FormRow>
      <div className="row detail-form">
        <div className="col-12 col-md-4">
          <label htmlFor="Machine" className="col-label-bold">
            Machine
          </label>
        </div>
        <div className="col-12 col-md-8 col-detailForm">
          {Form.Machine &&
            Form.Machine.map((item) => (
              <div className="col-12 detail-form" key={common.uuidv4()}>
                <label>{item.MachineName}</label>
                {`(${item.SerialNo})`}
                <br />
              </div>
            ))}
        </div>
      </div>
      <div className="row detail-form">
        <div className="col-12 col-md-4">
          <label htmlFor="Engineers" className="col-label-bold">
            Engineers
          </label>
        </div>
        <div className="col-12 col-md-8 col-detailForm">
          {engineers &&
            engineers.map((item) => (
              <div className="col-12 detail-form" key={common.uuidv4()}>
                <LinkModal.Engineer Value={item} />
                <br />
                <label htmlFor="Engineers_Value">{item.Telephone}</label>
              </div>
            ))}
        </div>
      </div>
      <div className="row detail-form">
        <div className="col-12 col-md-4">
          <label htmlFor="Tools" className="col-label-bold">
            Tools
          </label>
        </div>
        <div className="col-12 col-md-8 col-detailForm">
          {Form.Tools &&
            Form.Tools.map((item) => (
              <div className="col-12 detail-form" key={common.uuidv4()}>
                <label htmlFor="Tools_Value">{item.ToolsName}</label>
                <br />
                <label
                  className="text-detail"
                  htmlFor="Tools_Value"
                >{`${item.SerialNo}`}</label>
              </div>
            ))}
        </div>
      </div>
      <div></div>
      <FormRow label="Link">
        <a rel="noopener" href={jobdata.Link}>
          {jobdata.Link}
        </a>
      </FormRow>
      <FormRow label="Onsite By">
        <label htmlFor="OnsiteBy_Value">{jobdata && jobdata.OnsiteBy}</label>
      </FormRow>
      {jobdata && jobdata.JobStatus === constant.ONDUTY ? (
        <UploadImageReport />
      ) : (
        <></>
      )}
    </div>
  );
}
export default JobMonitorScreen;
