import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommonTable from '../UI/CommonTables';
import Modal from '../UI/MainModal';
import { Input } from 'reactstrap'
import * as constant from './../CommonFunction/constant'
import FormRowInput from '../UI/FormDetail'
import * as AddComponentStore from './../../store/AddComponentStore'

export const AddLoadFormTask = (props) => {
    //Redux
    const dispatch = useDispatch()
    const TaskList = useSelector(state => state.AddComponentReducer.TaskList)
    //Props
    const defaultProps = {
        onClosed: () => { },
    }
    const currentProps = {
        ...defaultProps, ...props
    }
    //Declare State
    const [isOpen, setIsOpen] = useState(false)
    const [dataItem, setDataItem] = useState({ FullText: "" })
    //Effect
    useEffect(() => {
        setIsOpen(props.isOpen)
        if (props.isOpen) {
            load()
        }
    }, [props])
    useEffect(() => {
        //ForSearch
        load()
    }, [dataItem])
    //Event+function
    const handleInputChange = event => {
        const { name, value } = event.target
        setDataItem({ ...dataItem, [name]: value })
    }
    const load = () => {
        AddComponentStore.actionCreators.GetTask(dispatch, { ...dataItem, JobStatus: [constant.PREPLAN, constant.CALLED] })
    }
    const get = (item) => {
        //For Return Value
        props.GetValue(item)
        props.onCancel()
    }

    //const header = (<React.Fragment>
    //    <th>Type</th>
    //    <th>Customer</th>
    //    <th>Machine</th>
    //    <th>Due Date</th>
    //</React.Fragment>)

    //const row = TaskList && TaskList.map((item) => (
    //    <tr key={item.JobId} onClick={() => { get(item) }}>
    //        <td>{item.JobType}</td>
    //        <td>{item.CustomerName}</td>
    //        <td>{item.MachineText}</td>
    //        <td>{item.DateFrom}</td>
    //    </tr>
    //)
    //)

    const onClickCol = {
        onClick: (e, column, columnIndex, row, rowIndex) => { get(row) }
        }
    

    const columns = [       
        {
            dataField: 'JobType',
            text: 'Type',
            sort: true,
            events: onClickCol
        },
        {
            dataField: 'CustomerName',
            text: 'Customer',
            sort: true,
            events: onClickCol
        },
        {
            dataField: 'MachineText',
            text: 'Machine',
            sort: true,
            events: onClickCol
        },
        {
            dataField: 'DateFrom',
            text: 'Due Date',
            sort: true,
            events: onClickCol
        },
    ]


    return (
        <Modal

            isOpen={isOpen}
            onCancel={props.onCancel}
            onClosed={() => { currentProps.onClosed() }}
            SaveText="OK"
            ModalHeaderText="Load Task List"
            SaveHidden
            CancelHidden

        >
            <FormRowInput label="Task">
                <Input type="text" name="FullText" id="FullText" value={dataItem.FullText} onChange={handleInputChange} bsSize="sm" />
            </FormRowInput>
            <CommonTable keyField="JobId" data={TaskList} columns={columns} />
        </Modal>)
}


export default AddLoadFormTask;