import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomInput } from "reactstrap";
import * as common from "../../CommonFunction/common-function";
//Store
import * as ReportFilterStore from "../../../store/ReportFilterStore";

const handleChange = (dispatch, e, data, add, remove) => {
  if (e.target.checked) {
    dispatch({ type: add, param: data });
  } else {
    dispatch({ type: remove, param: data });
  }
};
export const EmployeeCheckbox = ({ FilterText }) => {
  const dispatch = useDispatch();
  const EmployeeList = useSelector(
    (state) => state.ReportFilterReducer.EmployeeList
  );
  const EmployeeFilterList = useSelector(
    (state) => state.ReportFilterReducer.EmployeeFilterList
  );
  return (
    EmployeeList &&
    EmployeeList.filter((x) => {
      return (
        (x.FullNameEN_TH &&
          x.FullNameEN_TH.toLowerCase().includes(FilterText.toLowerCase())) ||
        common.isEmptyStr(FilterText)
      );
    }).map((item) => (
      <React.Fragment key={common.uuidv4()}>
        <li>
          <CustomInput
            type="checkbox"
            id={item.EmployeeId || common.uuidv4()}
            className="table-checkbox custom-control-label"
            label={item.FullNameEN_TH}
            checked={EmployeeFilterList.map((a) => a.EmployeeId).includes(
              item.EmployeeId
            )}
            onChange={(e) => {
              handleChange(
                dispatch,
                e,
                item,
                ReportFilterStore.ActionType.AddEmployeeFilterList,
                ReportFilterStore.ActionType.RemoveEmployeeFilterList
              );
            }}
          />
        </li>
      </React.Fragment>
    ))
  );
};

export const CustomerCheckbox = ({ FilterText }) => {
  const dispatch = useDispatch();
  const CustomersList = useSelector(
    (state) => state.ReportFilterReducer.CustomersList
  );
  const CustomersFilterList = useSelector(
    (state) => state.ReportFilterReducer.CustomersFilterList
  );
  return (
    CustomersList &&
    CustomersList.filter((x) => {
      return (
        (x.FullNameEN_TH &&
          x.FullNameEN_TH.toLowerCase().includes(FilterText.toLowerCase())) ||
        common.isEmptyStr(FilterText)
      );
    }).map((item) => (
      <React.Fragment key={common.uuidv4()}>
        <li>
          <CustomInput
            type="checkbox"
            id={item.CustomerId || common.uuidv4()}
            className="table-checkbox custom-control-label"
            label={item.FullNameEN_TH}
            checked={CustomersFilterList.map((a) => a.CustomerId).includes(
              item.CustomerId
            )}
            onChange={(e) => {
              handleChange(
                dispatch,
                e,
                item,
                ReportFilterStore.ActionType.AddCustomersFilterList,
                ReportFilterStore.ActionType.RemoveCustomersFilterList
              );
            }}
          />
        </li>
      </React.Fragment>
    ))
  );
};
export const MachineCheckbox = ({ FilterText }) => {
  const dispatch = useDispatch();
  const MachineList = useSelector(
    (state) => state.ReportFilterReducer.MachineList
  );
  const MachineFilterList = useSelector(
    (state) => state.ReportFilterReducer.MachineFilterList
  );
  return (
    MachineList &&
    MachineList.filter((x) => {
      let machine_display = `${x.MachineName} (${x.MachineModel})(${x.MachineType})(${x.MachineBrand})`;
      return (
        (machine_display &&
          machine_display.toLowerCase().includes(FilterText.toLowerCase())) ||
        common.isEmptyStr(FilterText)
      );
    }).map((item) => (
      <React.Fragment key={common.uuidv4()}>
        <li>
          <CustomInput
            type="checkbox"
            id={item.MachineId || common.uuidv4()}
            className="table-checkbox custom-control-label"
            label={`${item.MachineName} (${item.MachineModel})(${item.MachineType})(${item.MachineBrand})`}
            checked={MachineFilterList.map((a) => a.MachineId).includes(
              item.MachineId
            )}
            onChange={(e) => {
              handleChange(
                dispatch,
                e,
                item,
                ReportFilterStore.ActionType.AddMachineFilterList,
                ReportFilterStore.ActionType.RemoveMachineFilterList
              );
            }}
          />
        </li>
      </React.Fragment>
    ))
  );
};
export const MachineModelsCheckbox = ({ FilterText }) => {
  const dispatch = useDispatch();
  const MachineModelList = useSelector(
    (state) => state.ReportFilterReducer.MachineModelList
  );
  const MachineModelFilterList = useSelector(
    (state) => state.ReportFilterReducer.MachineModelFilterList
  );
  return (
    MachineModelList &&
    MachineModelList.filter((x) => {
      return (
        (x.Display &&
          x.Display.toLowerCase().includes(FilterText.toLowerCase())) ||
        common.isEmptyStr(FilterText)
      );
    }).map((item) => (
      <React.Fragment key={common.uuidv4()}>
        <li>
          <CustomInput
            type="checkbox"
            id={item.Value || common.uuidv4()}
            className="table-checkbox custom-control-label"
            label={`${item.Display}`}
            checked={MachineModelFilterList.map((a) => a.Value).includes(
              item.Value
            )}
            onChange={(e) => {
              handleChange(
                dispatch,
                e,
                item,
                ReportFilterStore.ActionType.AddMCModelFilterList,
                ReportFilterStore.ActionType.RemoveMCModelFilterList
              );
            }}
          />
        </li>
      </React.Fragment>
    ))
  );
};

export const JobTypeCheckbox = ({ FilterText }) => {
  const dispatch = useDispatch();
  const JobTypeList = useSelector(
    (state) => state.ReportFilterReducer.JobTypeList
  );
  const JobTypeFilterList = useSelector(
    (state) => state.ReportFilterReducer.JobTypeFilterList
  );
  return (
    JobTypeList &&
    JobTypeList.filter((x) => {
      return (
        (x.JobType &&
          x.JobType.toLowerCase().includes(FilterText.toLowerCase())) ||
        common.isEmptyStr(FilterText)
      );
    }).map((item) => (
      <React.Fragment key={common.uuidv4()}>
        <li>
          <CustomInput
            type="checkbox"
            id={item.JobType || common.uuidv4()}
            className="table-checkbox custom-control-label"
            label={item.JobType}
            checked={JobTypeFilterList.map((a) => a.JobType).includes(
              item.JobType
            )}
            onChange={(e) => {
              handleChange(
                dispatch,
                e,
                item,
                ReportFilterStore.ActionType.AddJobTypeFilterList,
                ReportFilterStore.ActionType.RemoveJobTypeFilterList
              );
            }}
          />
        </li>
      </React.Fragment>
    ))
  );
};
export const JobStatusCheckbox = ({ FilterText }) => {
  const dispatch = useDispatch();
  const JobStatusList = useSelector(
    (state) => state.ReportFilterReducer.JobStatusList
  );
  const JobStatusFilterList = useSelector(
    (state) => state.ReportFilterReducer.JobStatusFilterList
  );
  return (
    JobStatusList &&
    JobStatusList.filter((x) => {
      return (
        (x.JobStatus &&
          x.JobStatus.toLowerCase().includes(FilterText.toLowerCase())) ||
        common.isEmptyStr(FilterText)
      );
    }).map((item) => (
      <React.Fragment key={common.uuidv4()}>
        <li>
          <CustomInput
            type="checkbox"
            id={item.JobStatus || common.uuidv4()}
            className="table-checkbox custom-control-label"
    label={(<><span>{item.JobStatus}</span> <span style={{backgroundColor:item.Color,whiteSpace:"pre-wrap"}}>{'             '}</span></>)}
            checked={JobStatusFilterList.map((a) => a.JobStatus).includes(
              item.JobStatus
            )}
            onChange={(e) => {
              handleChange(
                dispatch,
                e,
                item,
                ReportFilterStore.ActionType.AddJobStatusFilterList,
                ReportFilterStore.ActionType.RemoveJobStatusFilterList
              );
            }}
          />
        </li>
        
      </React.Fragment>
    ))
  );
};
export const ToolCheckbox = ({ FilterText }) => {
  const dispatch = useDispatch();
  const ToolList = useSelector((state) => state.ReportFilterReducer.ToolList);
  const ToolFilterList = useSelector(
    (state) => state.ReportFilterReducer.ToolFilterList
  );
  return (
    ToolList &&
    ToolList.filter((x) => {
      let tool = `${x.ToolsName} (${x.ToolsType})`;
      return (
        (tool && tool.toLowerCase().includes(FilterText.toLowerCase())) ||
        common.isEmptyStr(FilterText)
      );
    }).map((item) => (
      <React.Fragment key={common.uuidv4()}>
        <li>
          <CustomInput
            type="checkbox"
            id={item.ToolsId || common.uuidv4()}
            className="table-checkbox custom-control-label"
            label={`${item.ToolsName} (${item.ToolsType})`}
            checked={ToolFilterList.map((a) => a.ToolsId).includes(
              item.ToolsId
            )}
            onChange={(e) => {
              handleChange(
                dispatch,
                e,
                item,
                ReportFilterStore.ActionType.AddToolFilterList,
                ReportFilterStore.ActionType.RemoveToolFilterList
              );
            }}
          />
        </li>
      </React.Fragment>
    ))
  );
};
