import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as common from "../../CommonFunction/common-function";
//Store
import * as ReportFilterStore from "../../../store/ReportFilterStore";

const Icondelete = common.getIconTag(common.Icontype.ION, "IoMdClose");
export const ListEmployeeFilter = ({ FilterText }) => {
    const dispatch = useDispatch();
    const EmployeeFilterList = useSelector(
        (state) => state.ReportFilterReducer.EmployeeFilterList
      );
    return EmployeeFilterList &&
      EmployeeFilterList.map((item) => (
        <div className="grid-item" key={item.EmployeeId}>
          {item.EmployeeName}
          <button
            className="btn-transparent"
            onClick={() => {
              dispatch({
                type: ReportFilterStore.ActionType.RemoveEmployeeFilterList,
                param: item,
              });
            }}
          >
            <Icondelete />
          </button>
        </div>
      ));
  };
  export const ListCustomerFilter = ({ FilterText }) => {
    const dispatch = useDispatch();
    const CustomersFilterList = useSelector(
        (state) => state.ReportFilterReducer.CustomersFilterList
      );
    return CustomersFilterList &&
      CustomersFilterList.map((item) => (
        <div key={item.CustomerId}>
          {item.FullName}
          <button
            className="btn-transparent"
            onClick={() => {
              dispatch({
                type: ReportFilterStore.ActionType.RemoveCustomersFilterList,
                param: item,
              });
            }}
          >
            <Icondelete />
          </button>
        </div>
      ));
  };
  export const ListMachineFilter = ({ FilterText }) => {
    const dispatch = useDispatch();
    const MachineFilterList = useSelector(
        (state) => state.ReportFilterReducer.MachineFilterList
      );
    return MachineFilterList &&
      MachineFilterList.map((item) => (
        <div key={item.MachineId}>
          {`${item.MachineName} (${item.MachineModel})`}
          <button
            className="btn-transparent"
            onClick={() => {
              dispatch({
                type: ReportFilterStore.ActionType.RemoveMachineFilterList,
                param: item,
              });
            }}
          >
            <Icondelete />
          </button>
        </div>
      ));
  };
  export const ListMachineModelsFilter = ({ FilterText }) => {
    const dispatch = useDispatch();
    const MachineModelFilterList = useSelector(
        (state) => state.ReportFilterReducer.MachineModelFilterList
      );
    return MachineModelFilterList &&
      MachineModelFilterList.map((item) => (
        <div key={item.Value}>
          {`${item.Display}`}
          <button
            className="btn-transparent"
            onClick={() => {
              dispatch({
                type: ReportFilterStore.ActionType.RemoveMCModelFilterList,
                param: item,
              });
            }}
          >
            <Icondelete />
          </button>
        </div>
      ));
  };
  export const ListJobTypeFilter = ({ FilterText }) => {
    const dispatch = useDispatch();
    const JobTypeFilterList = useSelector(
        (state) => state.ReportFilterReducer.JobTypeFilterList
      );
    return JobTypeFilterList &&
      JobTypeFilterList.map((item) => (
        <div key={item.JobType}>
          {item.JobType}
          <button
            className="btn-transparent"
            onClick={() => {
              dispatch({
                type: ReportFilterStore.ActionType.RemoveJobTypeFilterList,
                param: item,
              });
            }}
          >
            <Icondelete />
          </button>
        </div>
      ));
  };
  export const ListJobStatusFilter = ({ FilterText }) => {
    const dispatch = useDispatch();
    const JobStatusFilterList = useSelector(
        (state) => state.ReportFilterReducer.JobStatusFilterList
      );
    return JobStatusFilterList &&
      JobStatusFilterList.map((item) => (
        <div key={item.JobStatus}>
          {item.JobStatus}
          <button
            className="btn-transparent"
            onClick={() => {
              dispatch({
                type: ReportFilterStore.ActionType.RemoveJobStatusFilterList,
                param: item,
              });
            }}
          >
            <Icondelete />
          </button>
        </div>
      ));
  };
  export const ListToolFilter = ({ FilterText }) => {
    const dispatch = useDispatch();
    const ToolFilterList = useSelector(
        (state) => state.ReportFilterReducer.ToolFilterList
      );
    return ToolFilterList &&
      ToolFilterList.map((item) => (
        <div key={item.ToolsId}>
          {item.ToolsName}
          <button
            className="btn-transparent"
            onClick={() => {
              dispatch({
                type: ReportFilterStore.ActionType.RemoveToolFilterList,
                param: item,
              });
            }}
          >
            <Icondelete />
          </button>
        </div>
      ));
  };