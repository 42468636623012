import React, { useEffect, useState } from "react";
import CommonTable from "../UI/CommonTables";
import Modal from "../UI/MainModal";
import { Input } from "reactstrap";
import * as common from "./../CommonFunction/common-function";
import FormRowInput from "../UI/FormDetail";
import { useForm } from "react-hook-form";
import * as AddComponentStore from "./../../store/AddComponentStore";
export const SelectMachine = ({ isOpen, ...props }) => {
  //Redux
  const { register, getValues, watch } = useForm();
  const wcriteria = watch("FullText");
  //Props
  //Declare State
  const [machinelist, setmachinelist] = useState([]);
  //Effect
  useEffect(() => {
    if (isOpen) {
      load();
    }
  }, [isOpen]);
  useEffect(() => {
    load();
  }, [wcriteria]);
  //Event+function
  const load = async () => {
    let { result } = await AddComponentStore.actionCreators.GetMachineList(
      getValues()
    );
    if (result && result.data) setmachinelist(result.data);
  };
  const handleInputChange = () => {};
  const get = (item) => {
    //For Return Value
    common.isFunction(props.GetValue) && props.GetValue(item);
    common.isFunction(props.onCancel) && props.onCancel();
  };

  const onClickCol = {
    onClick: (e, column, columnIndex, row, rowIndex) => {
      get(row);
    },
  };

  const columns = [
    {
      dataField: "MachineId",
      text: "MachineId",
      sort: true,
      hidden: true,
      events: onClickCol,
    },
    {
      dataField: "MachineName",
      text: "Name",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "MachineType",
      text: "Type",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "MachineBrand",
      text: "Brand",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "MachineModel",
      text: "Model",
      sort: true,
      events: onClickCol,
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      onCancel={props.onCancel}
      onClosed={() => {
        if (common.isFunction(props.onClosed)) props.onClosed();
      }}
      SaveText="OK"
      ModalHeaderText="Machine list"
      SaveHidden
    >
      <FormRowInput label="Search">
        <Input
          type="text"
          name="FullText"
          id="FullText"
          innerRef={register}
          onChange={handleInputChange}
          bsSize="sm"
        />
      </FormRowInput>
      <CommonTable keyField="MachineId" data={machinelist} columns={columns} />
    </Modal>
  );
};

export default SelectMachine;
