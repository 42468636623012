import React from "react";
import { Card, CardTitle } from "reactstrap";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux'
import * as common from "../CommonFunction/common-function";
import "../../assets/css/MenuBox.css";
import * as ReportFilterStore from "./../../store/ReportFilterStore";
const MenuBox = (props) => {
  const listcard =
    props.data &&
    props.data.map((item) => {
      const Tag = common.getIconTag(item.icon.type, item.icon.name);
      return (
        <div className="col-xs-6 col-md-4" key={common.uuidv4()}>
          <Card
            tag={Link}
            to={item.link}
            className="menubox-br p-2 card-menubox-shadow"
            style={{ textDecoration: "none", color: "black" }}
          >
            <Container>
              <Row>
                <div className="col-sm-12 col-md-12 col-lg-2">
                  <Tag
                    size={30}
                    style={{
                      textDecoration: "none",
                      color: "black",
                      opacity: "0.5",
                    }}
                  />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-10">
                  <Container>
                    <Row className="row-equal-box">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <CardTitle className="text-font-menubox">
                          {item.header}
                        </CardTitle>
                      </div>
                    </Row>
                  </Container>
                </div>
              </Row>
            </Container>
          </Card>
        </div>
      );
    });
  return (
    <React.Fragment>
      <Card className="menubox-br p-2 card-menubox-shadow">
        <div style={{  paddingTop: '10px' }}>
          <div className="row">{listcard}</div>
        </div>
      </Card>
    </React.Fragment>
  );
};

export const SingleMenuBox = (props) => {
   const dispatch = useDispatch()
  const listcard =
    props.data &&
    props.data.map((item) => {
      const Tag = common.getIconTag(item.icon.type, item.icon.name);
      return (
        <Col sm="12" md="12" lg="4" key={common.uuidv4()}>
          <Card
            key={common.uuidv4()}
            tag={Link}
            onClick={()=>{dispatch({type:ReportFilterStore.ActionType.ClearFilter})}}
            to={item.link}
            className="menubox-br p-2 card-menubox-shadow"
            style={{ textDecoration: "none", color: "black" }}
          >
            <Container>
              <Row>
                <Col xs='1' sm="2" md="2" lg="2" xl="2">
                  <Tag
                    size={30}
                    style={{
                      textDecoration: "none",
                      color: "black",
                      opacity: "0.5",
                    }}
                  />
                </Col>
                <Col  xs='10' sm="10" md="10" lg="10" xl="10">
                  
                      <CardTitle className="text-font-menubox">
                        {item.header}
                      </CardTitle>
                    
                </Col>
              </Row>
            </Container>
          </Card>
        </Col>
      );
    });
  return (
    <React.Fragment>
      <Row>{listcard}</Row>
    </React.Fragment>
  );
};
export default MenuBox;

// Single box
//< Row >
//<CardSubtitle className="text-font-menulist">{item.detail}</CardSubtitle>
//</Row >

// MenuBox
//< Row className = "row-equal-box" >
//    <div className="col-sm-12 col-md-12 col-lg-12">
//        <CardSubtitle className="text-font-menulist">{item.detail}</CardSubtitle>
//    </div>
//</Row >
