import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import * as comboStore from "../../../store/comboStore";
import { Typeahead } from "react-bootstrap-typeahead";
// import CreatableSelect from "react-select/creatable";
import "react-bootstrap-typeahead/css/Typeahead.css";
//Ex.
// <Combo.Customer2
//         name=""
//         innerRef={register}
//         setControlValue={setControlValue}
//         invalid={errors. ? true : false}
//         value={dataItem.}
//       />

const handleChange = (value, action, setControlValue) => {
  const inputRef = action.name;
  const newValue = value && value.value;
  setControlValue && setControlValue(inputRef, newValue);
  // if(childSelect && )
};
const customStyles = (invalid) => {
  return {
    control: (base, state) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ? "#ddd" : invalid ? "#dc3545" : "#dddd",
      // overwrittes hover style
      "&:hover": {
        borderColor: state.isFocused ? "#ddd" : invalid ? "#dc3545" : "#ddd",
      },
    }),
    menuPortal: (base) => ({
      ...base,
      transform: "translateZ(0)",
    }),
  };
};
const CommonAutoComplete = ({
  options,
  onChange,
  setControlValue,
  readOnly,
  value,
  invalid,
  ...rest
}) => {
  return (
    <>
      <Select
        {...rest}
        // ref={register}
        value={options && options.find((option) => option.value === value)}
        isClearable
        options={options}
        onChange={(value, action) => {
          onChange && onChange(value);
          handleChange(value, action, setControlValue);
        }}
        isDisabled={readOnly}
        styles={customStyles(invalid)}

        // isMulti
      />
    </>
  );
};
export const CommonAutoCompleteCreate = ({
  options,
  setControlValue,
  readOnly,
  ...props
}) => {
  //Event+function
  const handleSearchChange = (selected) => {
    if (selected && selected.length > 0) {
      setControlValue(props.name, selected[0].value);
      // setValue([{ContactPerson:selected[0].ContactPerson}
      //     ,{ContactPhone: selected[0].Telephone}
      //     , {ContactEmail: selected[0].Email}
      //     , {Department: selected[0].Department}
      // ])
    }
  };
  const handleInputChangeAuto = (input, event) => {
    setControlValue(props.name, input);
    //setValue("ContactPerson",input)
  };
  //SubComponent
  return (
    <React.Fragment>
      <Typeahead
        labelKey="label"
        onChange={handleSearchChange}
        onInputChange={handleInputChangeAuto}
        options={options}
        selectHintOnEnter
        disabled={readOnly}
        {...props}
      />
    </React.Fragment>
  );
};
// const CommonCreatable =({options,onChange,setControlValue,readOnly,value,invalid,...rest})=>{
//   const handleChange2 = (value, name,setControlValue) => {
//     const inputRef = name;
//     const newValue = value && value.value;
//     setControlValue && setControlValue(inputRef, newValue);
//   };
//   const handleInputChange = (inputValue: any, actionMeta: any) => {
//     setControlValue(rest.name,inputValue)
//     // console.group('Input Changed');

//     // console.groupEnd();
//   };
//   return (
//     <>
//     <CreatableSelect
//       isClearable
//       onChange={(newValue,actionMeta)=>{
//         handleChange2(newValue,rest.name,setControlValue)
//       }}
//       onInputChange={handleInputChange}
//       value={options&&options.find((option) => option.value === value)}
//       options={options}
//       isDisabled={readOnly}
//     />

//     </>
//   );
// }
export const Customer = (props) => {
  //Redux
  const dispatch = useDispatch();
  const CutomerList = useSelector((state) => state.ComboReducer.CustomerList);
  //Props
  //Declare State
  //Effect
  useEffect(() => {
    comboStore.actionCreators.GetCustomer(dispatch, props.Criteria || {});
  }, [props.Criteria, dispatch]);
  //Event+function
  //SubComponent
  return (
    <>
      <CommonAutoComplete options={CutomerList} {...props} />
    </>
  );
};
export const Employee = (props) => {
  //Redux
  const dispatch = useDispatch();
  const EmployeeList = useSelector((state) => state.ComboReducer.EmployeeList);
  //Props

  //Declare State
  //Effect
  useEffect(() => {
    comboStore.actionCreators.GetEmployee(dispatch, props.Criteria || {});
  }, [props.Criteria, dispatch]);
  //Event+function
  //SubComponent
  return (
    <>
      <CommonAutoComplete options={EmployeeList} {...props} />
    </>
  );
};
export const ToolsName = ({ Criteria, ...props }) => {
  //Redux
  const dispatch = useDispatch();
  const ToolsNameList = useSelector(
    (state) => state.ComboReducer.ToolsNameList
  );
  //Props
  //Declare State
  //Effect
  useEffect(() => {
    comboStore.actionCreators.GetToolsName(dispatch, Criteria || {});
  }, [dispatch, Criteria]);
  //Event+function
  //SubComponent
  return <CommonAutoComplete options={ToolsNameList} {...props} />;
};
export const ToolsNameMisc = ({ Criteria, ...props }) => {
  //Redux
  const dispatch = useDispatch();
  const [Options, setOptions] = useState([]);
  //Props
  //Declare State
  //Effect
  useEffect(() => {
    const set = async () => {
      let res = await comboStore.actionCreators.GetToolsNameMisc(
        dispatch,
        Criteria || {}
      );
      setOptions(res);
    };
    set();
  }, [dispatch, Criteria]);
  //Event+function
  //SubComponent
  return (
    <>
      <CommonAutoComplete options={Options} {...props} />
    </>
  );
};
export const ToolsType = ({ Criteria, ...props }) => {
  //Redux
  const dispatch = useDispatch();
  const ToolsTypeIdList = useSelector(
    (state) => state.ComboReducer.ToolsTypeIdList
  );
  //Props
  //Declare State
  // const [Options, setOptions] = useState([])
  //Effect
  useEffect(() => {
    comboStore.actionCreators.GetToolsTypeWithID(dispatch, Criteria || {});
  }, [dispatch, Criteria]);

  //Event+function
  //SubComponent
  return <CommonAutoComplete options={ToolsTypeIdList} {...props} />;
};

export const ToolsTypeCreate = ({ Criteria, ...props }) => {
  //Redux
  const dispatch = useDispatch();
  const ToolsTypeList = useSelector(
    (state) => state.ComboReducer.ToolsTypeList
  );
  //Props
  //Declare State
  //Effect
  useEffect(() => {
    comboStore.actionCreators.GetToolsType(dispatch, Criteria || {});
  }, [dispatch, Criteria]);
  //Event+function
  //SubComponent
  return (
    <React.Fragment>
      <CommonAutoCompleteCreate options={ToolsTypeList} {...props} />
    </React.Fragment>
  );
};
export const ToolsNameCreate = ({ Criteria, ...props }) => {
  //Redux
  const dispatch = useDispatch();
  const ToolsNameList = useSelector(
    (state) => state.ComboReducer.ToolsNameList
  );
  //Props
  //Declare State
  //Effect
  useEffect(() => {
    comboStore.actionCreators.GetToolsName(dispatch, Criteria || {});
  }, [dispatch, Criteria]);
  //Event+function
  //SubComponent
  return (
    <React.Fragment>
      <CommonAutoCompleteCreate options={ToolsNameList} {...props} />
    </React.Fragment>
  );
};
export const ToolsSerial = ({ Criteria, ...props }) => {
  //Redux
  const dispatch = useDispatch();
  const ToolsSerialList = useSelector(
    (state) => state.ComboReducer.ToolsSerialList
  );
  //Props
  //Declare State
  //Effect
  useEffect(() => {
    comboStore.actionCreators.GetToolsSerialNo(dispatch, Criteria || {});
  }, [Criteria, dispatch]);
  //Event+function
  //SubComponent
  return <CommonAutoComplete options={ToolsSerialList} {...props} />;
};
export const CommonMultiAutoComplete = ({
  Criteria,
  options,
  setControlValue,
  ...props
}) => {
  //Props
  //Declare State
  //Effect
  //Event+function
  const handleChange = (e) => {
    let newValue = e.map((el) => el.value || el.label || el);
    setControlValue && setControlValue(props.name, newValue);
    // if(childSelect && )
  };
  //SubComponent
  return (
    <React.Fragment>
      <Typeahead
        onChange={handleChange}
        //selected
        multiple
        newSelectionPrefix="Add a new item: "
        options={options}
        placeholder=""
        {...props}
      />
    </React.Fragment>
  );
};
export const MultiSkill = (props) => {
  const [options, setoptions] = useState([]);
  useEffect(() => {
    const set = async () => {
      let result = await comboStore.actionCreators.GetSkill();
      setoptions(result);
    };
    set();
  }, []);
  return (
    <React.Fragment>
      <CommonMultiAutoComplete
        options={options}
        {...props}
      ></CommonMultiAutoComplete>
    </React.Fragment>
  );
};
