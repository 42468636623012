//Library
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Input, CustomInput, Form, FormGroup, Col, Label } from "reactstrap";
import * as common from "./../CommonFunction/common-function";
import {
  isEmptyStr,
  formatDate,
  Inform,
  DoneDialog,
} from "./../CommonFunction/common-function";
import moment from "moment";
import { useForm, useFormContext, FormContext } from "react-hook-form";
import * as yup from "yup";
import * as constant from "./../../components/CommonFunction/constant";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import Alert from "@material-ui/lab/Alert";
import { DropzoneDialog } from "material-ui-dropzone";
//Component
import ImportActiveMachine from "./ImportActiveMachine";
import MainScreen from "../UI/MainScreen";
import CriteriaBox from "../UI/CriteriaBox";
import CommonTable from "../UI/CommonTables";
import ResultBox from "../UI/ResultBox";
import Modal from "../UI/MainModal";
import FormRowInput from "../UI/FormDetail";
import * as Combo from "../UI/combobox/Combo";
import { AutoComplete } from "../UI/combobox";
import * as LinkModal from "./../UI/LinkText/LinkModal";
import { DatePicker } from "../UI/DatePicker";
import * as Tooltip from "../UI/LinkText/Tooltip";
import { ReactTable } from "../UI/CommonTables";
//Store
import * as MachineStore from "../../store/MachineStore";
import { PerissionScreenContext } from "./../Permission";
//style
import "../../assets/css/Table.css";
//function
import { useOpenWithDataDialog } from "./../functin-api/useOpenWithDataDialog";
import { useOpenDialog } from "./../functin-api/useOpenDialog";
moment.locale("en-GB");
const ValidateActiveMachineSchema = yup.object().shape({
  MachineId: yup.string().trim().required("This field is required."),
  SerialNo: yup.string().trim().required("This field is required."),
  CustomerId: yup
    .string()
    .nullable()
    .trim()
    .required("This field is required."),
  ServiceActiveFromDate: yup.string().when("isReshduleService", {
    is: true,
    then: yup.string().required("This field is required."),
  }),

  ServiceActiveToDate: yup.string().when("isReshduleService", {
    is: true,
    then: yup.string().required("This field is required."),
  }),
  CalibrateActiveFromDate: yup.string().when("isReshduleCalibrate", {
    is: true,
    then: yup.string().required("This field is required."),
  }),
  CalibrateActiveToDate: yup.string().when("isReshduleCalibrate", {
    is: true,
    then: yup.string().required("This field is required."),
  }),
  isReshduleService: yup.boolean(),
  isReshduleCalibrate: yup.boolean(),
});
const ValidateExportSchema = yup.object().shape({
  DateForm: yup.string().trim().required("This field is required."),
  DateTo: yup.string().trim().required("This field is required."),
});
const fieldDate = [
  "SetupDate",
  "ServiceActiveFromDate",
  "ServiceActiveToDate",
  "CalibrateActiveFromDate",
  "CalibrateActiveToDate",
];
export const ActiveMachine = () => {
  const dispatch = useDispatch();
  const { Function: fn_lst } = useContext(PerissionScreenContext);
  const CriteriaForm = useForm();
  const { getValues, reset } = CriteriaForm;
  const [isOpen, setIsOpen] = useState(false);

  const controlImportPopup = useOpenDialog();
  const controlDropzoneDialog = useOpenDialog();

  const [loading, setLoading] = useState(false);
  const [importResult, setImportResult] = useState([]);

  async function ImportData(files = []) {
    if (!files[0]) return;
    console.time("Time this");
    let form = new FormData();
    form.append("file", files[0]);
    if (!loading) {
      setLoading(true);
    }
    let res = await MachineStore.actionCreators.ImportActiveMachineData(form);
    setLoading(false);
    if (res.ResultCode === 10) {
      DoneDialog();
    } else if (res.ResultCode === 30) {
      if (!res.ImportResult) return;
      setImportResult(res.ImportResult);
      controlImportPopup.toggle();
    } else {
      Inform(res.Message);
    }
    console.timeEnd("Time this");
  }

  const Add = () => {
    setIsOpen(true);
  };
  const search = () => {
    MachineStore.actionCreators.SearchActiveMachine(dispatch, getValues());
  };

  const exportData = async () => {
    await MachineStore.actionCreators.ExportAllActivateMachine();
  };

  const tablecomponent = () => {
    return (
      <>
        <div className="col-xs-12 col-md-10">
          <div className="row justify-content-end">
            <div className="col-xs-12 col-md-auto text-right">
              <button
                className="btn btn-default btnDefault btn-Export"
                name="Search"
                value="Search"
                onClick={controlDropzoneDialog.clickOpen}
              >
                Import Activate Machine
              </button>
            </div>
            <div className="col-xs-12 col-md-auto text-right">
              <button
                className="btn btn-default btnDefault btn-Export"
                name="Search"
                value="Search"
                onClick={exportData}
              >
                Export All Activate Machine
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <MainScreen>
        <FormContext {...{ CriteriaForm: CriteriaForm }}>
          <CriteriaBox
            onSearch={search}
            hiddenClear={false}
            onClear={reset}
            hidden={true}
            hiddenClose={true}
          >
            <CriteriaSearch search={search} />
          </CriteriaBox>
          <ResultBox
            HeaderText="Activate Machine"
            ButtonAddHidden={common.disableEdit(fn_lst)}
            onButtonAddClick={Add}
            // headcomponent={tablecomponent}
          >
            <AcitiveMachineTable />
          </ResultBox>
          <AcitiveMachineModal
            isOpen={isOpen}
            onCancel={() => {
              setIsOpen(false);
            }}
          />
          <ImportActiveMachine
            {...controlImportPopup}
            listResult={importResult}
          />
          <DropzoneDialog
            acceptedFiles={[
              "text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ]}
            cancelButtonText={"cancel"}
            filesLimit={1}
            submitButtonText={"submit"}
            maxFileSize={52428800}
            open={controlDropzoneDialog.isOpen}
            onClose={() => controlDropzoneDialog.onClose()}
            onSave={(files) => {
              ImportData(files);
              controlDropzoneDialog.onClose();
            }}
            showPreviews={true}
            showFileNamesInPreview={true}
          />
        </FormContext>
      </MainScreen>
      <div id="container-backdrop">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  );
};
export const CriteriaSearch = (props) => {
  const { CriteriaForm } = useFormContext();
  const { register } = CriteriaForm;
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      props.search();
    }
  };
  return (
    <React.Fragment>
      <Form
        className="criteria-div"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormGroup row>
          <Label className="Label-font" for="SearchFor" size="sm">
            Search For
          </Label>
          <Col md={4}>
            <Input
              type="text"
              bsSize="sm"
              name="FullText"
              id="FullText"
              innerRef={register}
              onKeyPress={handleKeyPress}
            />
          </Col>
        </FormGroup>
      </Form>
    </React.Fragment>
  );
};
export const AcitiveMachineTable = () => {
  //Redux
  const dispatch = useDispatch();
  const { Function: fn_lst } = useContext(PerissionScreenContext);
  const ActiveMachineList = useSelector(
    (state) => state.MachineReducer.ActiveMachineList
  );
  const { CriteriaForm } = useFormContext();
  const { getValues } = CriteriaForm;
  //Declare State
  const [isOpen, setIsOpen] = useState(false);
  const [Is_export, setIs_export] = useState(false);
  const [Serial, setSerial] = useState("");
  //Effect
  useEffect(() => {
    MachineStore.actionCreators.SearchActiveMachine(dispatch, getValues());
  }, [dispatch, getValues]);
  //Event+function

  //SubComponent
  const Icondelete = common.getIconTag(common.Icontype.ION, "IoMdClose");
  const Icondownload = common.getIconTag(common.Icontype.ION, "IoMdDownload");
  const onClickCol = {
    onClick: async (e, column, columnIndex, row, rowIndex) => {
      if (common.disableEdit(fn_lst)) return;
      await MachineStore.actionCreators.GetActiveMachineData(
        dispatch,
        row.ActiveMachineId
      );
      setIsOpen(true);
    },
  };

  const columns = [
    {
      dataField: "bx",
      text: "export",
      align: "center",
      formatter: (cell, row) => {
        return (
          <button
            onClick={() => {
              setIs_export(true);
              setSerial(row.SerialNo);
            }}
            className="btn-transparent"
          >
            <Icondownload />
          </button>
        );
      },
    },
    {
      dataField: "MachineName",
      text: "Name",
      sort: true,
      //align: 'center',
      events: onClickCol,
    },
    {
      dataField: "MachineBrand",
      text: "Brand",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "MachineModel",
      text: "Model",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "SerialNo",
      text: "Serial No",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "CustomerName",
      text: "Customer",
      sort: true,
      formatter: (cell, row) => {
        return (
          <LinkModal.Customer Text={row.CustomerName} Value={row.CustomerId} />
        );
      },
    },
    {
      dataField: "ServiceMonths",
      text: "Service Months",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "ServiceActiveFromDate",
      text: "ServiceActiveFromDate",
      sort: true,
      events: onClickCol,
      formatter: (cell, row) => {
        return formatDate(row.ServiceActiveFromDate);
      },
    },
    {
      dataField: "ServiceActiveToDate",
      text: "ServiceActiveToDate",
      sort: true,
      events: onClickCol,
      formatter: (cell, row) => {
        return formatDate(row.ServiceActiveToDate);
      },
    },
    {
      dataField: "CalibrateMonths",
      text: "Calibration Months",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "CalibrateActiveFromDate",
      text: "CalibrateActiveFromDate",
      sort: true,
      events: onClickCol,
      formatter: (cell, row) => {
        return formatDate(row.CalibrateActiveFromDate);
      },
    },
    {
      dataField: "CalibrateActiveToDate",
      text: "CalibrateActiveToDate",
      sort: true,
      events: onClickCol,
      formatter: (cell, row) => {
        return formatDate(row.CalibrateActiveToDate);
      },
    },

    {
      dataField: "SetupDate",
      text: "Setup Date",
      sort: true,
      events: onClickCol,
      formatter: (cell, row) => {
        return formatDate(row.SetupDate);
      },
    },
    //{
    //    dataField: 'a',
    //    text: '',
    //    formatter: (cell, row) => {
    //        return (
    //            <button onClick={async () => {
    //               let res = await MachineStore.actionCreators.GetActiveMachineData(dispatch, row.ActiveMachineId)
    //               setIsOpen(true)
    //            }} className="btn-transparent"><Iconmore /></button>
    //        );
    //    }
    //},
    {
      dataField: "b",
      text: "",
      formatter: (cell, row) => {
        return (
          <button
            onClick={() => {
              MachineStore.actionCreators
                .CheckValidateDeleteActiveMachine(row)
                .then((result) => {
                  if (result.isValid) {
                    common.ConfirmDelete(() => {
                      MachineStore.actionCreators.DeleteActiveMachine(
                        dispatch,
                        row.ActiveMachineId,
                        getValues()
                      );
                    });
                  } else {
                    common.InformationOKDialog(
                      common.ArrayTextReduce(result.Messages)
                    );
                  }
                });
            }}
            className="btn-transparent"
            disabled={common.disableEdit(fn_lst)}
          >
            <Icondelete />
          </button>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <CommonTable
        keyField="ActiveMachineId"
        data={ActiveMachineList}
        columns={columns}
      />

      <AcitiveMachineModal
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
      />
      <HistoryModal
        isOpen={Is_export}
        onCancel={() => {
          setIs_export(false);
        }}
        SerialNo={Serial}
      />

      {/* <Button color="danger" onClick={async()=>{ 
        console.time("Time this");
        setDisable(true)
        await MachineStore.actionCreators.ReScheduleAll();
        setDisable(false)
        console.timeEnd("Time this");
      }} disabled={disable}>Reschdule All</Button> */}
    </React.Fragment>
  );
};

export const AcitiveMachineModal = (props) => {
  //Redux
  const dispatch = useDispatch();
  const ActiveMachineData = useSelector(
    (state) => state.MachineReducer.ActiveMachineData
  );
  //Props
  //Declare State
  const [txtHeader, settxtHeader] = useState();
  const ActiveMachineForm = useForm({
    validationSchema: ValidateActiveMachineSchema,
  });
  const { CriteriaForm } = useFormContext();
  const { getValues } = CriteriaForm;
  //Effect
  useEffect(() => {
    let txt;
    if (!common.isEmptyStr(ActiveMachineData.ActiveMachineId)) {
      txt = "Edit ";
    } else txt = "Add ";
    settxtHeader(txt + "Active Machine");
  }, [ActiveMachineData]);
  //Event+function
  const validate = async (e) => {
    //Field
    let err = [];
    if (e.ServiceActiveFromDate > e.ServiceActiveToDate) {
      err.push({
        type: "range",
        name: "ServiceActiveToDate",
        message: "invalid Range",
      });
    }
    if (e.CalibrateActiveFromDate > e.CalibrateActiveToDate) {
      err.push({
        type: "range",
        name: "CalibrateActiveToDate",
        message: "invalid Range",
      });
    }
    if (!common.isEmptyArray(err)) {
      ActiveMachineForm.setError(err);
      return false;
    } else {
      //Api
      let result = await MachineStore.actionCreators.CheckDupplicateSerial(e);
      if (result) {
        if (!result.isValid) {
          common.InformationOKDialog(
            result.Messages && common.ArrayTextReduce(result.Messages)
          );
          return false;
        }
      }
      return true;
    }
  };
  const savedata = async (data) => {
    await MachineStore.actionCreators.SaveActiveMachine(
      dispatch,
      data,
      getValues()
    );
  };
  const submitsave = async () => {
    let data = {
      ...ActiveMachineData,
      ...ActiveMachineForm.getValues(),
      UpdatedBy: common.GetUserId(),
    };
    if (await validate(data)) {
      common.SaveWithConfirm(() => savedata(data));
    }
  };
  const Reschdule = async (jobtype) => {
    let data = {
      ...ActiveMachineData,
      ...ActiveMachineForm.getValues(),
      UpdatedBy: common.GetUserId(),
    };
    if (await validate(data)) {
      if (!isEmptyStr(ActiveMachineData.ActiveMachineId)) {
        savedata(data);
        let promise = MachineStore.actionCreators.ReSchedule2(
          dispatch,
          data,
          jobtype
        );
        let { error } = await promise;
        if (!error) {
          common.DoneDialog("Reschdule complete");
        }
      } else {
        common.InformationOKDialog("Please save before reschdule");
      }
    }
  };
  //SubComponent
  return (
    <Modal
      isOpen={props.isOpen}
      onSave={ActiveMachineForm.handleSubmit(submitsave)}
      onCancel={() => {
        props.onCancel();
        dispatch({ type: MachineStore.ActionType.ClearActiveMachineData });
        ActiveMachineForm.reset();
      }}
      ModalHeaderText={txtHeader}
    >
      <FormContext {...ActiveMachineForm}>
        <MachineActivateDetail
          Reschdule={Reschdule}
          formdata={ActiveMachineForm}
        />
      </FormContext>
    </Modal>
  );
};

export const MachineActivateDetail = (props) => {
  //Redux
  const ActiveMachineData = useSelector(
    (state) => state.MachineReducer.ActiveMachineData
  );
  const MachineNameList = useSelector(
    (state) => state.ComboReducer.MachineNameList
  );

  //Props
  const { register, errors, setValue, getValues, triggerValidation, watch } =
    useFormContext();
  const wMachineId = watch("MachineId");
  //Declare State
  const [dataItem, setDataItem] = useState(ActiveMachineData);
  const [dataItem2, setDataItem2] = useState(ActiveMachineData);

  //Effect
  useEffect(() => {
    register({ name: "CustomerId" });
  });
  useEffect(() => {
    filterMachine(wMachineId);
  }, [wMachineId]);
  useEffect(() => {
    filterMachine(ActiveMachineData.MachineId);
    setValue(common.setDataToFormHook(ActiveMachineData, fieldDate));
  }, [ActiveMachineData, MachineNameList]);
  useEffect(() => {
    filterMachine(ActiveMachineData.MachineId);
    setValue(common.setDataToFormHook(ActiveMachineData, fieldDate));
  }, []);
  //Event+function
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (getValues().IsServiceCalibrate) {
      if (
        name === "ServiceActiveFromDate" ||
        name === "CalibrateActiveFromDate"
      ) {
        setValue([
          { ServiceActiveFromDate: value },
          { CalibrateActiveFromDate: value },
        ]);
      } else if (
        name === "ServiceActiveToDate" ||
        name === "CalibrateActiveToDate"
      ) {
        setValue([
          { ServiceActiveToDate: value },
          { CalibrateActiveToDate: value },
        ]);
      } else if (name === "ServiceMonths" || name === "CalibrateMonths") {
        setValue([{ ServiceMonths: value }, { CalibrateMonths: value }]);
      }
    }
    trigger(event);
  };
  const trigger = (e) => {
    //for clear err from maunal trigger => field in validate schema
    if (errors[e.target.name]) {
      triggerValidation(e.target.name);
    }
  };
  const handleInputInsChange = (event) => {
    const { value } = event.target;
    filterMachine(value);
    trigger(event);
  };
  const filterMachine = (value) => {
    let data = MachineNameList.filter((item) => item.value === value);
    if (data.length > 0)
      setDataItem2({
        MachineBrand: data[0].MachineBrand,
        MachineModel: data[0].MachineModel,
        MachineType: data[0].MachineType,
      });
  };
  const Reschdule = async (jobtype) => {
    let flag =
      jobtype === common.Constants.CALIBRATE
        ? "isReshduleCalibrate"
        : "isReshduleService";
    if (common.isFunction(props.Reschdule)) {
      setValue(flag, true);
      let res = await triggerValidation();
      if (res) props.Reschdule(jobtype);
      setValue(flag, false);
    }
  };
  const setControlValue = (name, value) => {
    setValue(name, value);
    setDataItem((prev) => ({ ...prev, [name]: value }));
  };
  const handleChangeCheckbox = (e, field) => {
    if (
      getValues().IsServiceCalibrate &&
      (field === "IsEndMonthService" || field === "IsEndMonthCalibrate")
    ) {
      setValue([
        { IsEndMonthService: e.target.checked },
        { IsEndMonthCalibrate: e.target.checked },
      ]);
    } else if (field === "IsServiceCalibrate") {
      setValue([
        { CalibrateActiveFromDate: getValues().ServiceActiveFromDate },
        { CalibrateActiveToDate: getValues().ServiceActiveToDate },
        { CalibrateMonths: getValues().ServiceMonths },
        { IsEndMonthCalibrate: getValues().IsEndMonthService },
      ]);
    }
  };

  return (
    <>
      <FormRowInput label="Machine Name" errors={errors.MachineId} required>
        <Combo.MachineName
          disabled
          name="MachineId"
          innerRef={register}
          onChange={handleInputInsChange}
          invalid={errors.MachineId ? true : false}
          setControlValue={setControlValue}
        />
      </FormRowInput>
      <FormRowInput label="Machine Type">
        <Input
          type="text"
          readOnly={true}
          name="MachineType"
          id="MachineType"
          value={dataItem2.MachineType || ""}
        />
      </FormRowInput>
      <FormRowInput label="Machine Brand">
        <Input
          type="text"
          readOnly={true}
          name="MachineBrand"
          id="MachineBrand"
          value={dataItem2.MachineBrand || ""}
        />
      </FormRowInput>
      <FormRowInput label="Machine Model">
        <Input
          type="text"
          readOnly={true}
          name="MachineModel"
          id="MachineModel"
          value={dataItem2.MachineModel || ""}
        />
      </FormRowInput>
      <FormRowInput label="Serial Number" errors={errors.SerialNo} required>
        <Input
          type="text"
          name="SerialNo"
          innerRef={register}
          invalid={errors.SerialNo ? true : false}
          onChange={trigger}
        />
      </FormRowInput>
      <FormRowInput label="Customer" errors={errors.CustomerId} required>
        <AutoComplete.Customer
          name="CustomerId"
          innerRef={register}
          invalid={errors.CustomerId ? true : false}
          value={dataItem.CustomerId}
          setControlValue={setControlValue}
        />
      </FormRowInput>
      <FormRowInput label="Setup Date">
        <DatePicker name="SetupDate" innerRef={register} id="SetupDate" />
      </FormRowInput>
      <div className="row">
        <div
          className="col-12 col-md-12"
          style={{ paddingLeft: "0px", paddingRight: "0px" }}
        >
          <CustomInput
            type="checkbox"
            id="IsServiceCalibrate"
            name="IsServiceCalibrate"
            label="Service (PM) เข้าให้บริการพร้อมกับ Calibrate"
            innerRef={register}
            onChange={(e) => handleChangeCheckbox(e, "IsServiceCalibrate")}
          />
          {getValues().IsServiceCalibrate ? (
            <CustomLink
              Text="Re-Schedule Service (PM) and Calibrate"
              onClick={() => {
                Reschdule(common.Constants.SERVICE_PM_CALIBRATE);
              }}
            />
          ) : null}
          <Input
            type="hidden"
            name="isReshduleService"
            innerRef={register}
            defaultValue={false}
          />
          <Input
            type="hidden"
            name="isReshduleCalibrate"
            innerRef={register}
            defaultValue={false}
          />
          <ResultBox HeaderText="Service (PM) ">
            <FormRowInput
              label="Active From"
              errors={errors.ServiceActiveFromDate}
            >
              <DatePicker
                name="ServiceActiveFromDate"
                id="ServiceActiveFromDate"
                innerRef={register}
                invalid={errors.ServiceActiveFromDate ? true : false}
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
            </FormRowInput>
            <FormRowInput label="Active To" errors={errors.ServiceActiveToDate}>
              <DatePicker
                name="ServiceActiveToDate"
                id="ServiceActiveToDate"
                innerRef={register}
                invalid={errors.ServiceActiveToDate ? true : false}
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
            </FormRowInput>
            <FormRowInput label="Service (PM) Months)">
              <Input
                min={0}
                max={100}
                type="number"
                step="1"
                name="ServiceMonths"
                id="ServiceMonth"
                innerRef={register}
                onChange={handleInputChange}
                bsSize="sm"
              />
            </FormRowInput>
            <CustomInput
              type="checkbox"
              name="IsEndMonthService"
              id="IsEndMonthService"
              innerRef={register}
              onChange={(e) => handleChangeCheckbox(e, "IsEndMonthService")}
              label="Every End of Month"
            />
            {!getValues().IsServiceCalibrate ? (
              <CustomLink
                Text="Re-Schedule Service (PM)"
                onClick={() => {
                  Reschdule(common.Constants.SERVICE_PM);
                }}
              />
            ) : null}
          </ResultBox>
          <ResultBox HeaderText="Calibrate">
            <FormRowInput
              label="Active From"
              errors={errors.CalibrateActiveFromDate}
            >
              <DatePicker
                name="CalibrateActiveFromDate"
                id="CalibrateActiveFromDate"
                innerRef={register}
                invalid={errors.CalibrateActiveFromDate ? true : false}
                onChange={handleInputChange}
              />
            </FormRowInput>
            <FormRowInput
              label="Active To"
              errors={errors.CalibrateActiveToDate}
            >
              <DatePicker
                name="CalibrateActiveToDate"
                id="CalibrateActiveToDate"
                innerRef={register}
                invalid={errors.CalibrateActiveToDate ? true : false}
                onChange={handleInputChange}
              />
            </FormRowInput>
            <FormRowInput label="Calibrate Months">
              <Input
                min={0}
                max={100}
                type="number"
                step="1"
                name="CalibrateMonths"
                id="CalibrateMonths"
                innerRef={register}
                onChange={handleInputChange}
                bsSize="sm"
              />
            </FormRowInput>
            <CustomInput
              type="checkbox"
              name="IsEndMonthCalibrate"
              id="IsEndMonthCalibrate"
              innerRef={register}
              onChange={(e) => handleChangeCheckbox(e, "IsEndMonthCalibrate")}
              label="Every End of Month"
            />
            {!getValues().IsServiceCalibrate ? (
              <CustomLink
                Text="Re-Schedule Calibrate"
                onClick={() => {
                  Reschdule(common.Constants.CALIBRATE);
                }}
              />
            ) : null}
          </ResultBox>
        </div>
      </div>
    </>
  );
};
export const CustomLink = (props) => {
  const Iconrefresh = common.getIconTag(common.Icontype.ION, "IoIosRefresh");
  return (
    <React.Fragment>
      <label
        className="textlink-detail"
        onClick={() => {
          if (common.isFunction(props.onClick)) {
            props.onClick();
          }
        }}
      >
        <Iconrefresh /> {props.Text}
      </label>
    </React.Fragment>
  );
};
const col_history = [
  {
    dataField: "DateFrom",
    text: "Operation Date",
    sort: true,
    formatter: (cell, row) => {
      if (row.DateFrom && row.DateTo) {
        if (row.DateFrom === row.DateTo) {
          return formatDate(row.DateFrom);
        }
        return `${formatDate(row.DateFrom)} to ${formatDate(row.DateTo)}`;
      }
      return "";
    },
  },
  {
    dataField: "CustomerName",
    text: "Customer",
    sort: true,
    formatter: (cell, row) => {
      return (
        <LinkModal.Customer Text={row.CustomerName} Value={row.CustomerId} />
      );
    },
  },
  {
    dataField: "JobType",
    text: "Type",
    sort: true,
    style: (colum, colIndex) => {
      return { width: "100px", maxWidth: "100px" };
    },
  },
  {
    dataField: "JobStatus",
    text: "Status",
    sort: true,
    formatter: (cell, row) => {
      return <Tooltip.JobStatusToolTip JobStatus={row.JobStatus} />;
    },
    style: (colum, colIndex) => {
      return { width: "100px", maxWidth: "100px" };
    },
  },
  {
    dataField: "PoNumber",
    text: "Po No.",
    sort: true,
    formatter: (cell, row) => {
      return (
        <LinkModal.ServiceRequest
          Text={row.PoNumber}
          Value={row.ServiceRequestId}
        />
      );
    },
    style: (colum, colIndex) => {
      return { width: "150px", maxWidth: "150px" };
    },
  },
];
let defaultJobStatus = [
  constant.CANCELED,
  constant.CLOSE,
  constant.ONDUTY,
  constant.CARRIED,
  constant.PENDING,
];
export const HistoryModal = ({
  isOpen,
  SerialNo,
  onCancel = () => {},
  ...props
}) => {
  //Redux
  const { register, errors, getValues } = useForm({
    validationSchema: ValidateExportSchema,
  });
  //Props
  const customerControlDialog = useOpenWithDataDialog();
  //Declare State
  const [isLoading, setisLoading] = useState();
  const [errorExport, setErrorExport] = useState(null);
  const [JobList, setJobList] = useState([]);
  const [SelectedCustomer, setSelectedCustomer] = useState([]);
  //Effect
  useEffect(() => {
    setJobList([]);
  }, []);
  //Event+function
  const search = async () => {
    let param = {
      ...getValues(),
      MachineSerials: [SerialNo],
      JobStatus: defaultJobStatus,
    };
    let data = await MachineStore.actionCreators.GetJobHistoryBySerial(param);
    setJobList(data);
  };
  function groupJobsByCustomerId(jobList) {
    const groupedJobs = [];
    jobList.forEach((job) => {
      const customerId = job.CustomerId;
      const customerName = job.CustomerName;
      const existingGroup = groupedJobs.find(
        (group) => group.customerId === customerId
      );
      if (existingGroup) {
        existingGroup.jobs.push(job);
      } else {
        groupedJobs.push({
          customerId: customerId,
          customerName: customerName,
          jobs: [job],
        });
      }
    });
    return groupedJobs;
  }
  const exportPdf = async () => {
    if (!common.isEmptyArray(JobList)) {
      let groupedJobs = groupJobsByCustomerId(JobList);
      if (groupedJobs.length === 1) {
        let param = {
          ...getValues(),
          SerialsNo: SerialNo,
          JobStatus: defaultJobStatus,
        };
        await MachineStore.actionCreators.ExportHistoryBySerial(param);
      } else if (groupedJobs.length > 1) {
        customerControlDialog.clickOpen(groupedJobs);
      }
    }
  };
  const exportPdfByCustomer = async () => {
    setErrorExport(null);
    if (!common.isEmptyArray(SelectedCustomer)) {
      setisLoading(true);
      let param = {
        ...getValues(),
        SerialsNo: SerialNo,
        Customers: SelectedCustomer,
        JobStatus: defaultJobStatus,
      };
      await MachineStore.actionCreators.ExportHistoryByCustomer(param);
      setisLoading(false);
    } else {
      setErrorExport("Please select at least one customer.");
    }
  };
  const handleClose = () => {
    setSelectedCustomer([]);
    setErrorExport(null);
    customerControlDialog.onClose();
  };
  const handleChange = (e, CustomerId) => {
    if (e.target.checked) {
      setSelectedCustomer(SelectedCustomer.concat(CustomerId));
    } else {
      setSelectedCustomer(
        SelectedCustomer.filter((el) => {
          return el !== CustomerId;
        })
      );
    }
  };
  //SubComponent
  const row =
    customerControlDialog.Data &&
    customerControlDialog.Data.map((el) => (
      <tr key={el.customerId}>
        <td>
          <CustomInput
            type="checkbox"
            className="table-checkbox"
            id={el.customerId}
            checked={SelectedCustomer.includes(el.customerId)}
            onChange={(e) => {
              handleChange(e, el.customerId);
            }}
          />
        </td>
        <td>{el.customerName}</td>
      </tr>
    ));
  return (
    <Modal
      isOpen={isOpen}
      SaveText={"Search"}
      CancelHidden
      DeleteHidden={false}
      DeleteText={"export"}
      onDelete={exportPdf}
      onSave={search}
      onCancel={() => {
        onCancel();
        setJobList([]);
      }}
      ModalHeaderText={`Export active machine - ${SerialNo}`}
    >
      <FormRowInput label="Form">
        <DatePicker
          name="DateFrom"
          id="DateFrom"
          innerRef={register}
          invalid={errors.DateForm ? true : false}
          defaultValue={common.formatDate(new Date())}
        />
      </FormRowInput>
      <FormRowInput label="To">
        <DatePicker
          name="DateTo"
          id="DateTo"
          innerRef={register}
          invalid={errors.DateForm ? true : false}
          defaultValue={common.formatDate(new Date())}
        />
      </FormRowInput>
      <CommonTable keyField="JobId" data={JobList} columns={col_history} />
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={customerControlDialog.isOpen}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Please select a customer
        </DialogTitle>
        <DialogContent>
          <ResultBox HeaderText="Customer">
            <ReactTable Row={row} />
          </ResultBox>
          <br />
          {isLoading && <LinearProgress />}
          {errorExport && <Alert severity="warning">{errorExport}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            size="small"
            color="primary"
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={exportPdfByCustomer}
            size="small"
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            Export
          </Button>
        </DialogActions>
      </Dialog>
    </Modal>
  );
};
export default ActiveMachine;
