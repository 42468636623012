import React, { useState, useContext, createContext } from "react";
import { GetPermission,isEmptyStr,GetToken } from "./../CommonFunction/common-function";
import { Container, Row, Col } from "reactstrap";
import { CommonAutoCompleteCreate } from "../UI/combobox/AutoComplete";
import { Redirect } from "react-router-dom";
export const PerissionScreenContext = createContext({});

const StoreContextProvider = ({ children, permission }) => {
  const [Function, setFunction] = useState(permission);

  return (
    <PerissionScreenContext.Provider value={{ Function }}>
      {children}
    </PerissionScreenContext.Provider>
  );
};

const LoginAgain = (props) => {
  return (
   <Redirect to="/"></Redirect>
  );
};
const denied = () => {
  return (
    <>
      <Container fluid>
        <Row>
          <Col sm="12" md="12" lg="12">
            Permission denied
          </Col>
        </Row>
      </Container>
    </>
  );
};
export const PermissionComponent = (Component, Screen) => {
    //t
  let Token = GetToken()
  if(isEmptyStr(Token)) return LoginAgain;
  if(isEmptyStr(Screen)) return Component
  let permission = GetPermission();
  if (permission === []) return denied;
  let permission_screen = permission.find((el) => el.Screen === Screen);
  if (!permission_screen) return denied;
  let function_lst = permission_screen.Functions.map((el) => el.Function);
  const PassComponent = (props) => {
    return (
      <StoreContextProvider permission={function_lst}>
        <Component {...props} />
      </StoreContextProvider>
    );
  };
    return PassComponent;
  
};

  

export default PermissionComponent;
