import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";
import * as common from "../../CommonFunction/common-function";
//Store
import * as ReportFilterStore from "../../../store/ReportFilterStore";

const handleInputChange = (dispatch, event) => {
  const { name, value } = event.target;
  dispatch({
    type: ReportFilterStore.ActionType.criteriaChange,
    param: { [name]: value },
  });
};
export const SerialNoTextBox = () => {
  const dispatch = useDispatch();
  const SearchCriteria = useSelector(
    (state) => state.ReportFilterReducer.SearchCriteria
  );
  return (
    <React.Fragment>
      <div className="row head-sideNavbar">
        <div className="col-12 col-md-12">
          <Input
            type="text"
            name="SerialNo"
            id="SerialNo"
            bsSize="sm"
            value={SearchCriteria.SerialNo || ""}
            placeholder="Search Serial Number"
            onChange={(e) => {
              handleInputChange(dispatch, e);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export const PONumberTextBox = () => {
  const dispatch = useDispatch();
  const SearchCriteria = useSelector(
    (state) => state.ReportFilterReducer.SearchCriteria
  );
  return (
    <React.Fragment>
      <div className="row head-sideNavbar">
        <div className="col-12 col-md-12">
          <Input
            type="text"
            name="PoNumber"
            id="PoNumber"
            bsSize="sm"
            value={SearchCriteria.PoNumber || ""}
            placeholder="Search PO Number"
            onChange={(e) => {
              handleInputChange(dispatch, e);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export const PlanDateBox = () => {
  const dispatch = useDispatch();
  const SearchCriteria = useSelector(
    (state) => state.ReportFilterReducer.SearchCriteria
  );
  return (
    <React.Fragment>
      <div className="row head-sideNavbar">
        <div className="col-12 col-md-12">
          <Input
            type="date"
            name="PlanDate"
            id="PlanDate"
            bsSize="sm"
            value={SearchCriteria.PlanDate || ""}
            placeholder="Search PlanDate"
            onChange={(e) => {
              handleInputChange(dispatch, e);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export const DueDateBox = () => {
  const dispatch = useDispatch();
  const SearchCriteria = useSelector(
    (state) => state.ReportFilterReducer.SearchCriteria
  );

  return (
    <React.Fragment>
      <div className="row head-sideNavbar">
        <div className="col-6 col-md-6">
          <label>From</label>
          <Input
            type="date"
            name="DueDateFrom"
            id="DueDateFrom"
            bsSize="sm"
            value={SearchCriteria.DueDateFrom || ""}
            onChange={(e) => {
              handleInputChange(dispatch, e);
            }}
          />
        </div>
        <div className="col-6 col-md-6">
          <label>To</label>
          <Input
            type="date"
            name="DueDateTo"
            id="DueDateTo"
            bsSize="sm"
            value={SearchCriteria.DueDateTo || ""}
            onChange={(e) => {
              handleInputChange(dispatch, e);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export const PlandateFromTo = () => {
  const dispatch = useDispatch();
  const SearchCriteria = useSelector(
    (state) => state.ReportFilterReducer.SearchCriteria
  );
  return (
    <React.Fragment>
      <div className="row head-sideNavbar">
        <div className="col-6 col-md-6">
          <label>From</label>
          <Input
            type="date"
            name="PlandateFromToStart"
            id="PlandateFromToStart"
            bsSize="sm"
            value={SearchCriteria.PlandateFromToStart || ""}
            onChange={(e) => {
              handleInputChange(dispatch, e);
            }}
          />
        </div>
        <div className="col-6 col-md-6">
          <label>To</label>
          <Input
            type="date"
            name="PlandateFromToEnd"
            id="PlandateFromToEnd"
            bsSize="sm"
            value={SearchCriteria.PlandateFromToEnd || ""}
            onChange={(e) => {
              handleInputChange(dispatch, e);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export const RequestDateBox = () => {
  const dispatch = useDispatch();
  const SearchCriteria = useSelector(
    (state) => state.ReportFilterReducer.SearchCriteria
  );

  return (
    <React.Fragment>
      <div className="row head-sideNavbar">
        <div className="col-6 col-md-6">
          <label>From</label>
          <Input
            type="date"
            name="RequestDateFrom"
            id="RequestDateFrom"
            bsSize="sm"
            value={SearchCriteria.RequestDateFrom || ""}
            onChange={(e) => {
              handleInputChange(dispatch, e);
            }}
          />
        </div>
        <div className="col-6 col-md-6">
          <label>To</label>
          <Input
            type="date"
            name="RequestDateTo"
            id="RequestDateTo"
            bsSize="sm"
            value={SearchCriteria.RequestDateTo || ""}
            onChange={(e) => {
              handleInputChange(dispatch, e);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export const TagsTextBox = () => {
  const dispatch = useDispatch();
  const SearchCriteria = useSelector(
    (state) => state.ReportFilterReducer.SearchCriteria
  );
  return (
    <React.Fragment>
      <div className="row head-sideNavbar">
        <div className="col-12 col-md-12">
          <Input
            type="text"
            name="Tags"
            id="Tags"
            bsSize="sm"
            value={SearchCriteria.Tags || ""}
            placeholder="Search Tags"
            onChange={(e) => {
              handleInputChange(dispatch, e);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
