import { useState } from "react";
export function useOpenWithDataDialog() {
  const [isOpen, setOpen] = useState(false);
  const [Data, setData] = useState();
  return {
    isOpen,
    onClose: () => {
      setOpen(false);
      setData(null);
    },
    clickOpen: (data) => {
      //Open
      setOpen(true);
      setData(data);
    },
    toggle: () => {
      //Toggle
      setOpen((prev) => !prev);
    },
    Data,
    setData,
  };
}
useOpenWithDataDialog.propTypes = {};
