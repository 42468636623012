import React, { useState } from "react";

import * as common from "./../CommonFunction/common-function";
import moment from "moment";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";

const events = [
  {
    id: 0,
    title: "All Day Event very long title",
    allDay: true,
    start: new Date(2019, 3, 0),
    end: new Date(2019, 3, 1),
  },
  {
    id: 0,
    title: "All Day Event very long title",
    allDay: true,
    start: new Date(2020, 9, 1),
    end: new Date(2020, 9, 1),
  },
  {
    id: 0,
    title: "All Day Event very long title",
    allDay: true,
    start: new Date(2020, 9, 1),
    end: new Date(2020, 9, 1),
  },
  {
    id: 0,
    title: "All Day Event very long title",
    allDay: true,
    start: new Date(2020, 9, 1),
    end: new Date(2020, 9, 1),
  },
  {
    id: 0,
    title: "All Day Event very long title",
    allDay: true,
    start: new Date(2020, 9, 1),
    end: new Date(2020, 9, 1),
  },
];
let allViews = Object.keys(Views).map((k) => Views[k]);

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: "lightblue",
    },
  });

const Basic = () => {
  moment.locale("en-GB");
  const localizer = momentLocalizer(moment);
  return (
    <Calendar
      events={events}
      views={allViews}
      step={60}
      showMultiDayTimes
      components={{
        timeSlotWrapper: ColoredDateCellWrapper,
      }}
      localizer={localizer}
    />
  );
};

const Search = () => {
  // const [isOpen, setIsOpen] = useState(false);
  // const Icon = common.getIconTag(common.Icontype.ION, "IoIosArrowRoundForward");
  // const toggle = () => setIsOpen(!isOpen);
  return (
    <React.Fragment>
      <Basic />
    </React.Fragment>
  );
};
// const Search2 = () => {
//   return (
//     <React.Fragment>

//     </React.Fragment>
//   );
// };

export default Search;
