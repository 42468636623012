import React, { useState } from "react";
import { Container, Alert, Label, Input, Button } from "reactstrap";
import "../../assets/css/Login.css";
import { IoMdPerson, IoMdKey } from "react-icons/io";
import Logo from "./../../assets/Logo.png";
import Axios from "axios";
import * as common from "../CommonFunction/common-function";
import { API_AUTHEN } from "../../store/constants";
import { Redirect } from "react-router-dom";
import history from "./../history/history";
//Store
import * as UserStore from "../../store/UserStore";
import { format } from "react-string-format";

function Login() {
  const [dataItem, setDataItem] = useState({
    Username: "",
    Password: "",
  });
  const [isOpenChangePass, setisOpenChangePass] = useState(false);
  const [isShowIncorrect, setIsShowIncorrect] = useState(false);
  const redirectPage = () => {
    history.go("/dashboard");
  };
  const validateExpired = async (userdata) => {
    var result = await UserStore.actionCreators.ValidateLastChangePassword(
      userdata
    );
    return result;
  };
  const IncorrectPass = (flag) => {
    setIsShowIncorrect(flag);
  };
  const confirmChangePassword = async (days) => {
    let message =
      "<div>" +
      "<span>ต้องเปลี่ยน password ภายใน</span>" +
      '<span style=" color: red;"> {0} </span>' +
      "<span>วัน</span>" +
      "<br/><span>ท่านต้องการเปลี่ยน password หรือไม่?</span>" +
      "</div>";
    let { result } = await common.to(
      common.ConfirmDialog(format(message, days), false)
    );
    return result;
  };
  const login = async () => {
    IncorrectPass(false);
    let { result, error } = await common.to(
      Axios.post(API_AUTHEN, {
        UserId: dataItem.Username,
        Password: dataItem.Password,
        Aud: common.GetAud(),
      })
    );

    if (error) {
      common.InformationOKDialog("Login Fail");
      return;
    }
    if (!result) {
      IncorrectPass(true);
    } else if (result.data) {
      let userdata = result.data;
      common.SetUserData(userdata);
      let expired_change_pass_days = await validateExpired(userdata);
      if (expired_change_pass_days) {
        if (expired_change_pass_days >= 0) {
          // not expire
          if (expired_change_pass_days <= 7) {
            let result = await confirmChangePassword(expired_change_pass_days);
            if (result) {
              if (result.dismiss !== "cancel") {
                common.SetForceChangePassword();
              }
            } else {
              common.SetUserData(null);
            }
          }
        } else if (expired_change_pass_days == -1) {
          //  expire
          let message =
            '<h6 className="alert-heading">Please change your password.</h6>' +
            "<hr />" +
            '<p className="mb-0">Your password has expired.</p>';
          await common.InformationOKDialog(message);
          common.SetForceChangePassword();
        }
        redirectPage();
      } else {
        common.SetUserData(null);
      }
    }
  };
  const handleFocus = (event) => event.target.select();
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDataItem({ ...dataItem, [name]: value });
    if (event.key === "Enter") {
      login();
    }
  };

  return (
    <>
      <Container className="Login" fluid>
        {common.GetToken() ? <Redirect to="/dashboard" /> : null}
        <img src={Logo} className="Logo" />
        <div className="title">
          <Label>Maintenance and Service Management</Label>
        </div>
        <div hidden={!isShowIncorrect}>
          <Alert color="danger">Password Incorrect</Alert>
        </div>
        <div className="input-container">
          <IoMdPerson className="icon" />
          <Input
            className="input-field"
            type="text"
            name="Username"
            placeholder="Username"
            onFocus={handleFocus}
            onKeyPress={handleInputChange}
            onChange={handleInputChange}
            value={dataItem.Username}
          />
        </div>
        <div className="input-container">
          <IoMdKey className="icon" />
          <Input
            className="input-field"
            type="password"
            placeholder="Password"
            name="Password"
            onKeyPress={handleInputChange}
            onChange={handleInputChange}
            value={dataItem.Password}
          />
        </div>
        <Button color="secondary" className="btn btn-login" onClick={login}>
          Login
        </Button>
      </Container>
    </>
  );
}
export default Login;
