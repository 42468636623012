//Library
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";
import * as common from "./../CommonFunction/common-function";
import { formatDate } from "./../CommonFunction/common-function";
import { useForm, useFormContext, FormContext } from "react-hook-form";
import * as yup from "yup";
//Component
import MainScreen from "../UI/MainScreen";
import CommonTable from "../UI/CommonTables";
import ResultBox from "../UI/ResultBox";
import Modal from "../UI/MainModal";
import { DatePicker } from "../UI/DatePicker";
import { AutoComplete, Combo } from "../UI/combobox";
import FormRowInput from "../UI/FormDetail";
//Store
import * as ToolStore from "../../store/ToolStore";
import { PerissionScreenContext } from "./../Permission";
const ValidateToolsMaintenanceSchema = yup.object().shape({
  ToolsName: yup.string().nullable().trim().required("This field is required."),
  ToolsId: yup.string().nullable().trim().required("This field is required."),
  SerialNo: yup.string().nullable().trim().required("This field is required."),
  LeaveType: yup.string().nullable().trim().required("This field is required."),
  LeaveFromDate: yup.string().trim().required("This field is required."),
  LeaveToDate: yup.string().trim().required("This field is required."),
});
const fieldDate = ["LeaveFromDate", "LeaveToDate"];
export const ToolMaintenance = () => {
  //Redux Global
  const CriteriaForm = useForm();
  const ToolsMaintenanceForm = useForm({
    validationSchema: ValidateToolsMaintenanceSchema,
  });
  const { Function: fn_lst } = useContext(PerissionScreenContext);
  //state
  const [isOpen, setIsOpen] = useState(false);
  //Effect

  const Add = () => {
    setIsOpen(true);
  };
  return (
    <MainScreen>
      <FormContext
        {...{
          CriteriaForm: CriteriaForm,
          ToolsMaintenanceForm: ToolsMaintenanceForm,
        }}
      >
        <ResultBox
          HeaderText="Tools Maintenance"
          ButtonAddHidden={common.disableEdit(fn_lst)}
          onButtonAddClick={Add}
        >
          <ToolsMaintenanceTable />
        </ResultBox>
        <ToolsMaintenanceModal
          isOpen={isOpen}
          onCancel={() => {
            setIsOpen(false);
          }}
        />
      </FormContext>
    </MainScreen>
  );
};
export const ToolsMaintenanceTable = () => {
  //Redux
  const dispatch = useDispatch();
  const criteria = useSelector(
    (state) => state.ToolsReducer.ToolsMaintenanceCriteria
  );
  const ToolsMaintenanceList = useSelector(
    (state) => state.ToolsReducer.ToolsMaintenanceList
  );
  const { Function: fn_lst } = useContext(PerissionScreenContext);
  //Declare State
  const [isOpen, setIsOpen] = useState(false);
  //Effect
  useEffect(() => {
    ToolStore.actionCreators.SearchToolsMaintenance(dispatch, criteria);
  }, [dispatch, criteria]);
  // useEffect(() => {
  //   Search();
  // }, []);
  // //Event+function
  // const Search = () => {
  //   ToolStore.actionCreators.SearchToolsMaintenance(dispatch, criteria);
  // };

  //SubComponent
  const Icondelete = common.getIconTag(common.Icontype.ION, "IoMdClose");
  const onClickCol = {
    onClick: async (e, column, columnIndex, row, rowIndex) => {
      if (common.disableEdit(fn_lst)) return;
      await ToolStore.actionCreators.GetToolsMaintenanceData(
        dispatch,
        row.ToolsLeaveId
      );
      setIsOpen(true);
    },
  };

  const columns = [
    {
      dataField: "ToolsName",
      text: "Name",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "ToolsType",
      text: "Type",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "SerialNo",
      text: "Serial No",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "LeaveType",
      text: "Leave Type",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "LeaveFromDate",
      text: "Leave From",
      sort: true,
      events: onClickCol,
      formatter: (cell, row) => {
        return formatDate(row.LeaveFromDate);
      },
    },
    {
      dataField: "LeaveToDate",
      text: "Leave To",
      sort: true,
      events: onClickCol,
      formatter: (cell, row) => {
        return formatDate(row.LeaveToDate);
      },
    },
    //{
    //    dataField: 'a',
    //    text: '',
    //    formatter: (cell, row) => {
    //        return (
    //            <button onClick={async () => {
    //                await ToolStore.actionCreators.GetToolsMaintenanceData(dispatch, row.ToolsLeaveId)

    //                setIsOpen(true)

    //            }} className="btn-transparent"><Iconmore /></button>
    //        );
    //    }
    //},
    {
      dataField: "b",
      text: "",
      formatter: (cell, row) => {
        return (
          <button
            onClick={() => {
              common.ConfirmDelete(() => {
                ToolStore.actionCreators.DeleteToolsMaintenance(
                  dispatch,
                  row.ToolsLeaveId,
                  criteria
                );
              });
            }}
            className="btn-transparent"
            disabled={common.disableEdit(fn_lst)}
          >
            <Icondelete />
          </button>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <CommonTable
        keyField="ToolsLeaveId"
        data={ToolsMaintenanceList}
        columns={columns}
      />
      <ToolsMaintenanceModal
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
      />
    </React.Fragment>
  );
};

export const ToolsMaintenanceModal = (props) => {
  //Redux
  const dispatch = useDispatch();
  const ToolsMaintenanceData = useSelector(
    (state) => state.ToolsReducer.ToolsMaintenanceData
  );
  const criteria = useSelector(
    (state) => state.ToolsReducer.ToolsMaintenanceCriteria
  );
  const { ToolsMaintenanceForm } = useFormContext();
  const { handleSubmit, getValues, reset } = ToolsMaintenanceForm;
  const getdata = () => {
    return {
      ...ToolsMaintenanceData,
      ...getValues(),
      UpdatedBy: common.GetUserId(),
    };
  };
  const validate = async (data) => {
    let err = [];
    if (data.LeaveFromDate > data.LeaveToDate) {
      err.push({
        type: "range",
        name: "LeaveToDate",
        message: "invalid Range",
      });
    }
    if (!common.isEmptyArray(err)) {
      ToolsMaintenanceForm.setError(err);
      return false;
    }
    return true;
  };
  const save = async () => {
    if (!(await validate(getdata()))) {
      return;
    }
    const save = () => {
      ToolStore.actionCreators.SaveToolsMaintenance(
        dispatch,
        getdata(),
        criteria
      );
    };
    common.SaveWithConfirm(save);
  };
  const [txtHeader, settxtHeader] = useState();
  useEffect(() => {
    let txt;
    if (ToolsMaintenanceData.ToolsLeaveId !== "") {
      txt = "Edit ";
    } else txt = "Add ";
    settxtHeader(txt + "Tool Maintenance");
  }, [ToolsMaintenanceData]);
  return (
    <Modal
      isOpen={props.isOpen}
      onSave={handleSubmit(save)}
      onCancel={() => {
        reset();
        props.onCancel();
        dispatch({ type: ToolStore.ActionType.ClearToolsMaintenanceData });
      }}
      ModalHeaderText={txtHeader}
    >
      <ToolMaintenanceDetail />
    </Modal>
  );
};
export const ToolMaintenanceDetail = () => {
  //Redux
  const ToolsMaintenanceData = useSelector(
    (state) => state.ToolsReducer.ToolsMaintenanceData
  );
  const { ToolsMaintenanceForm } = useFormContext();
  const { register, setValue, errors, getValues, clearError } =
    ToolsMaintenanceForm;
  //Props
  //Declare State
  const [dataItem, setDataItem] = useState(ToolsMaintenanceData);
  const [JobList, setJobList] = useState([]);
  //Effect
  useEffect(() => {
    ToolsMaintenanceForm.register({ name: "ToolsName" });
    ToolsMaintenanceForm.register({ name: "ToolsId" });
    ToolsMaintenanceForm.register({ name: "SerialNo" });
  });
  useEffect(() => {
    setDataItem(ToolsMaintenanceData);
    setValue(common.setDataToFormHook(ToolsMaintenanceData, fieldDate));
  }, [ToolsMaintenanceData, setValue]);
  //Event+function
  const setControlValue = (name, value, isclearerr = true) => {
    setValue(name, value);
    setDataItem((prev) => ({ ...prev, [name]: value }));
    isclearerr && clearError(name);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDataItem((prev) => ({ ...prev, [name]: value }));
    setValue(name, value);
  };
  const JobAssignToolChange = () => {
    const temp = { ...dataItem, ...getValues() };

    if (temp.SerialNo && temp.LeaveFromDate && temp.LeaveToDate) {
      let criteria = {
        ToolSerials: [temp.SerialNo],
        DateFrom: temp.LeaveFromDate,
        DateTo: temp.LeaveToDate,
      };
      if (
        !(
          common.checkdatesql(temp.LeaveFromDate) &&
          common.checkdatesql(temp.LeaveToDate)
        )
      ) {
        return;
      }
      ToolStore.actionCreators
        .CheckJobAssignTool(criteria)
        .then((res) => setJobList(res));
    } else {
      setJobList([]);
    }
  };

  //SubComponent
  return (
    <div>
      <FormRowInput label="Tools Name" required errors={errors.ToolsName}>
        <AutoComplete.ToolsName
          name="ToolsName"
          id="ToolsName"
          invalid={errors.ToolsName ? true : false}
          value={dataItem.ToolsName || ""}
          setControlValue={setControlValue}
          onChange={() => {
            JobAssignToolChange();
            setControlValue("ToolsId", "", false);
            setControlValue("SerialNo", "", false);
          }}
        />
      </FormRowInput>
      <FormRowInput label="Tools Type" required errors={errors.ToolsId}>
        <AutoComplete.ToolsType
          name="ToolsId"
          id="ToolsId"
          invalid={errors.ToolsId ? true : false}
          value={dataItem.ToolsId}
          setControlValue={setControlValue}
          onChange={() => {
            JobAssignToolChange();
            setControlValue("SerialNo", "", false);
          }}
          Criteria={
            !common.isEmptyStr(dataItem.ToolsName)
              ? { ToolsName: dataItem.ToolsName }
              : {}
          }
        />
      </FormRowInput>
      <FormRowInput label="Serial No." required errors={errors.SerialNo}>
        <AutoComplete.ToolsSerial
          name="SerialNo"
          id="SerialNo"
          invalid={errors.SerialNo ? true : false}
          value={dataItem.SerialNo}
          setControlValue={setControlValue}
          onChange={JobAssignToolChange}
          Criteria={{
            ToolsName: dataItem.ToolsName,
            ToolsId: dataItem.ToolsId,
          }}
        />
      </FormRowInput>
      <FormRowInput label="Leave Type" required errors={errors.LeaveType}>
        <Combo.ToolLeaveType
          name="LeaveType"
          innerRef={register}
          invalid={errors.LeaveType ? true : false}
          value={dataItem.LeaveType || ""}
          onChange={handleInputChange}
          bsSize="sm"
        />
      </FormRowInput>
      <FormRowInput label="Leave From" required errors={errors.LeaveFromDate}>
        <DatePicker
          name="LeaveFromDate"
          id="LeaveFromDate"
          innerRef={register}
          invalid={errors.LeaveFromDate ? true : false}
          onChange={JobAssignToolChange}
        />
      </FormRowInput>
      <FormRowInput label="Leave To" required errors={errors.LeaveToDate}>
        <DatePicker
          name="LeaveToDate"
          id="LeaveToDate"
          innerRef={register}
          invalid={errors.LeaveToDate ? true : false}
          onChange={JobAssignToolChange}
        />
      </FormRowInput>
      <FormRowInput label="Leave Remark">
        <Input
          type="textarea"
          name="LeaveRemark"
          id="LeaveRemark"
          maxLength="500"
          style={{ height: 150 }}
          innerRef={register}
          onChange={handleInputChange}
        />
      </FormRowInput>
      {JobList && JobList.length > 0 ? <JobTable JobList={JobList} /> : null}
    </div>
  );
};

export const JobTable = (props) => {
  //const header = (
  //    <React.Fragment>
  //        <th className="th-table">Job Number</th>
  //        <th className="th-table">Date From</th>
  //        <th className="th-table">Date To</th>
  //    </React.Fragment>)

  //const dr = props.JobList && props.JobList.map(item => (
  //    <tr key={item.JobId} className="tr-table">
  //        <td>{item.JobNumber}</td>
  //        <td>{item.DateFrom}</td>
  //        <td>{item.DateTo}</td>
  //    </tr>
  //))
  const columns = [
    {
      dataField: "JobNumber",
      text: "Job Number",
      sort: true,
    },
    {
      dataField: "DateFrom",
      text: "Date From",
      sort: true,
      formatter: (cell, row) => {
        return formatDate(row.DateFrom);
      },
    },
    {
      dataField: "DateTo",
      text: "Date To",
      sort: true,
      formatter: (cell, row) => {
        return formatDate(row.DateTo);
      },
    },
  ];

  return (
    <React.Fragment>
      <CommonTable keyField="JobId" data={props.JobList} columns={columns} />
    </React.Fragment>
  );
};

export default ToolMaintenance;
