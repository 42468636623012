//Library
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, FormGroup, Label, Input, CustomInput } from "reactstrap";
import * as common from "./../CommonFunction/common-function";
import { disableEdit } from "./../CommonFunction/common-function";
import { useForm, FormContext, useFormContext } from "react-hook-form";
import * as yup from "yup";
//Component
import MainScreen from "../UI/MainScreen";
import CommonTable from "../UI/CommonTables";
import ResultBox from "../UI/ResultBox";
import Modal from "../UI/MainModal";
import { AutoComplete } from "../UI/combobox";
import CriteriaBox from "../UI/CriteriaBox";
import { ReactTable } from "../UI/CommonTables";
//Store
import * as UserStore from "../../store/UserStore";
import FormRowInput from "../UI/FormDetail";
import { PerissionScreenContext } from "./../Permission";
const ValidateUserSchema = yup.object().shape({
  UserId: yup.string().trim().required("This field is required."),
  Password: yup.string().trim().required("This field is required."),
  confirm: yup
    .string()
    .trim()
    .oneOf([yup.ref("Password"), null], "Passwords must match")
    .required("This field is required."),
  ReferenceId: yup
    .string()
    .nullable()
    .trim()
    .required("This field is required."),
});
const ValidateChangePasswordSchema = yup.object().shape({
  Password: yup.string().trim().required("This field is required."),
  confirm: yup
    .string()
    .trim()
    .oneOf([yup.ref("Password"), null], "Passwords must match")
    .required("This field is required."),
});
export const UserMaster = () => {
  const [isOpen, setIsOpen] = useState(false);
  const criteria = useSelector((state) => state.UserReducer.SearchCriteria);
  const dispatch = useDispatch();
  const { Function: fn_lst } = useContext(PerissionScreenContext);
  const Add = () => {
    setIsOpen(true);
  };

  const search = () => {
    UserStore.actionCreators.SearchUser(dispatch, criteria);
  };
  const clear = () => {
    dispatch({ type: UserStore.ActionType.criteriaClear });
  };

  return (
    <MainScreen>
      <CriteriaBox
        onSearch={search}
        hiddenClear={false}
        onClear={clear}
        hidden={true}
        hiddenClose={true}
      >
        <UserCriteriaSearch search={search} />
      </CriteriaBox>
      <ResultBox
        HeaderText="User"
        ButtonAddHidden={disableEdit(fn_lst)}
        onButtonAddClick={Add}
      >
        <UserTable />
      </ResultBox>
      <UserModal
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
      />
    </MainScreen>
  );
};

export const UserCriteriaSearch = (props) => {
  const SearchCriteria = useSelector(
    (state) => state.UserReducer.SearchCriteria
  );
  const [dataItem, setDataItem] = useState(SearchCriteria);
  const dispatch = useDispatch();

  useEffect(() => {
    setDataItem(SearchCriteria);
  }, [SearchCriteria]);
  useEffect(() => {}, [dataItem]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDataItem({ ...dataItem, [name]: value });
    dispatch({
      type: UserStore.ActionType.criteriaChange,
      param: { ...dataItem, [name]: value },
    });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      props.search();
    }
  };
  return (
    <React.Fragment>
      <Form
        className="criteria-div"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormGroup row>
          <Label className="Label-font" for="SearchFor" size="sm">
            Search For
          </Label>
          <Col md={4}>
            <Input
              type="text"
              bsSize="sm"
              name="FullText"
              id="FullText"
              value={dataItem.FullText}
              onKeyPress={handleKeyPress}
              onChange={handleInputChange}
            />
          </Col>
        </FormGroup>
      </Form>
    </React.Fragment>
  );
};

const Search = (dispatch, criteria) => {
  UserStore.actionCreators.SearchUser(dispatch, criteria);
};
export const UserTable = () => {
  //Redux
  const dispatch = useDispatch();
  const criteria = useSelector((state) => state.UserReducer.UserCriteria);
  const UserList = useSelector((state) => state.UserReducer.UserList);
  const { Function: fn_lst } = useContext(PerissionScreenContext);
  //Declare State
  const [isOpen, setIsOpen] = useState(false);
  //Effect
  useEffect(() => {
    Search(dispatch, criteria);
  }, [dispatch, criteria]);
  //Event+function

  //SubComponent
  const Icondelete = common.getIconTag(common.Icontype.ION, "IoMdClose");
  const onClickCol = {
    onClick: async (e, column, columnIndex, row, rowIndex) => {
      if (disableEdit(fn_lst)) return;
      await UserStore.actionCreators.GetUserData(dispatch, row.Id);
      setIsOpen(true);
    },
  };

  const columns = [
    {
      dataField: "UserId",
      text: "User Id",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "EmployeeName",
      text: "Employee Name",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "Active",
      text: "Is Active",
      sort: true,
      events: onClickCol,
      formatter: (cell, row) => {
        return row.Active ? "Yes" : "No";
      },
    },
    {
      dataField: "b",
      text: "",
      formatter: (cell, row) => {
        return (
          <button
            onClick={() => {
              common.ConfirmDelete(() => {
                UserStore.actionCreators.DeleteUser(dispatch, row.Id, criteria);
              });
            }}
            className="btn-transparent"
            disabled={disableEdit(fn_lst)}
          >
            <Icondelete />
          </button>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <CommonTable keyField="UserId" data={UserList} columns={columns} />
      <UserModal
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
      />
    </React.Fragment>
  );
};

export const UserModal = (props) => {
  //Redux
  const dispatch = useDispatch();
  const UserInput = useSelector((state) => state.UserReducer.UserInput);
  const UserData = useSelector((state) => state.UserReducer.UserData);
  const criteria = useSelector((state) => state.UserReducer.UserCriteria);
  const Role = useSelector((state) => state.UserReducer.RolesInput);
  //Props
  //Declare State
  const UserForm = useForm({ validationSchema: ValidateUserSchema });
  const [txtHeader, settxtHeader] = useState();
  //Effect
  useEffect(() => {
    let txt;
    if (!common.isEmptyStr(UserInput.Id)) {
      txt = "Edit ";
    } else txt = "Add ";
    settxtHeader(txt + "User");
  }, [UserInput]);
  //Event+function
  //SubComponent
  const validate = async (data) => {
    var { result } = await UserStore.actionCreators.ValidateUser(data);
    if (Role.length === 0 || Role === null) {
      common.InformationOKDialog("Please select role");
      return false;
    }
    if (result && result.data && result.data.isValid) {
      return true;
    } else {
      common.InformationOKDialog(
        result &&
          result.data &&
          result.data.Messages &&
          common.ArrayTextReduce(result.data.Messages)
      );

      return false;
    }
  };
  const save = async () => {
    let data = {
      ...UserData,
      ...UserForm.getValues(),
      UpdatedBy: common.GetUserId(),
    };
    if (!(await validate(data))) {
      return;
    } else {
      const save = () => {
        UserStore.actionCreators.SaveUser(dispatch, data, Role, criteria);
      };
      common.SaveWithConfirm(save);
    }
  };
  return (
    <FormContext {...UserForm}>
      <Modal
        isOpen={props.isOpen}
        onSave={UserForm.handleSubmit(save)}
        onCancel={() => {
          props.onCancel();
          UserForm.reset();
          dispatch({ type: UserStore.ActionType.ClearUserData });
        }}
        ModalHeaderText={txtHeader}
      >
        <UserDetail />
      </Modal>
    </FormContext>
  );
};

export const UserDetail = () => {
  //Redux
  const dispatch = useDispatch();
  const UserData = useSelector((state) => state.UserReducer.UserData);
  const RoleList = useSelector((state) => state.UserReducer.RolesList);
  const SelectedRoleData = useSelector(
    (state) => state.UserReducer.UserRoleList
  );
  //Props
  const { register, errors, setValue, clearError } = useFormContext();
  const ChangePasswordForm = useForm({
    validationSchema: ValidateChangePasswordSchema,
  });
  //Declare State
  const [dataItem, setDataItem] = useState(UserData);
  const [isChangePass, setisChangePass] = useState(false);
  const [SelectedRole, setSelectedRole] = useState([]);
  //Effect
  useEffect(() => {
    register({ name: "ReferenceId" });
  });
  useEffect(() => {
    dispatch({
      type: UserStore.ActionType.GetSelectedRoleInput,
      param: SelectedRole,
    });
  }, [SelectedRole, dispatch]);
  useEffect(() => {
    setValue(
      common.setDataToFormHook({ ...UserData, confirm: UserData.Password })
    );
    setDataItem(UserData);
  }, [UserData, setValue]);
  useEffect(() => {
    setSelectedRole(
      SelectedRoleData && SelectedRoleData.map((el) => el.RoleId)
    );
  }, [SelectedRoleData]);
  useEffect(() => {
    UserStore.actionCreators.GetRoleList(dispatch, {});
  }, [dispatch]);
  //Event+function
  const UpdatePassword = async () => {
    let data = {
      ...UserData,
      ...ChangePasswordForm.getValues(),
      UpdatedBy: common.GetUserId(),
    };
    var result = await UserStore.actionCreators.CheckPassword(dispatch, {
      ...data,
      Password: ChangePasswordForm.getValues().Current,
    });
    if (result) {
      common.SaveWithConfirm(async () => {
        await UserStore.actionCreators.ChangePassword(dispatch, data);
        ToggleUpdatePassword();
      });
    } else {
      common.InformationOKDialog("Password Incorect");
    }
  };
  const ToggleUpdatePassword = () => {
    setisChangePass(!isChangePass);
    //setinitpass
  };
  const setControlValue = (name, value, isclearerr = true) => {
    setValue(name, value);
    setDataItem((prev) => ({ ...prev, [name]: value }));
    isclearerr && clearError(name);
  };
  const handleChange = (e, RoleId) => {
    if (e.target.checked) {
      setSelectedRole(SelectedRole.concat(RoleId));
    } else {
      setSelectedRole(
        SelectedRole.filter((el) => {
          return el !== RoleId;
        })
      );
    }
  };
  //SubComponent

  const row =
    RoleList &&
    RoleList.map((el) => (
      <tr key={common.uuidv4()}>
        <td>
          <CustomInput
            type="checkbox"
            className="table-checkbox"
            id={common.uuidv4()}
            checked={SelectedRole.includes(el.RoleId)}
            onChange={(e) => {
              handleChange(e, el.RoleId);
            }}
          />
        </td>
        <td>{el.RoleId}</td>
      </tr>
    ));
  return (
    <div>
      <FormRowInput label="UserId" required errors={errors.UserId}>
        <Input
          type="text"
          name="UserId"
          maxLength="50"
          innerRef={register}
          bsSize="sm"
          invalid={errors.UserId ? true : false}
          autoComplete="nope"
        />
      </FormRowInput>
      <div hidden={!common.isEmptyStr(UserData.Id)}>
        <FormRowInput label="Password" required errors={errors.Password}>
          <Input
            type="password"
            name="Password"
            maxLength="50"
            innerRef={register}
            bsSize="sm"
            invalid={errors.Password ? true : false}
          />
        </FormRowInput>
        <FormRowInput label="Comfirm Password" required errors={errors.confirm}>
          <Input
            type="password"
            name="confirm"
            maxLength="50"
            innerRef={register}
            bsSize="sm"
            invalid={errors.confirm ? true : false}
          />
        </FormRowInput>
      </div>
      <input
        type="button"
        className="btn-default btnDefault btn-Temp"
        value="Change Password"
        onClick={ToggleUpdatePassword}
        hidden={common.isEmptyStr(UserData.Id)}
      />
      <div hidden={!isChangePass}>
        <FormRowInput
          label="New Password"
          errors={ChangePasswordForm.errors.Password}
          required
        >
          <Input
            type="password"
            name="Password"
            id="Password2"
            innerRef={ChangePasswordForm.register}
            bsSize="sm"
            invalid={ChangePasswordForm.errors.Password ? true : false}
          />
        </FormRowInput>
        <FormRowInput
          label="Comfirm Password"
          errors={ChangePasswordForm.errors.confirm}
          required
        >
          <Input
            type="password"
            name="confirm"
            innerRef={ChangePasswordForm.register}
            bsSize="sm"
            invalid={ChangePasswordForm.errors.confirm ? true : false}
          />
        </FormRowInput>
        <input
          type="button"
          className="btn-default btnDefault btn-Temp"
          value="Update Password"
          onClick={ChangePasswordForm.handleSubmit(UpdatePassword)}
        />
        <input
          type="button"
          className="btn-default btnDefault btn-Temp"
          value="Cancel"
          onClick={() => {
            ToggleUpdatePassword();
          }}
        />
      </div>
      <FormRowInput label="Employee Name" required errors={errors.ReferenceId}>
        <AutoComplete.Employee
          name="ReferenceId"
          innerRef={register}
          invalid={errors.ReferenceId ? true : false}
          setControlValue={setControlValue}
          value={dataItem.ReferenceId}
        />
      </FormRowInput>
      <FormRowInput label="Active">
        <CustomInput
          type="checkbox"
          id="Active"
          name="Active"
          innerRef={register}
        />
      </FormRowInput>
      <ResultBox HeaderText="Roles">
        <ReactTable Row={row} />
      </ResultBox>
    </div>
  );
};

export const ChangePasswordModal = (props) => {
  //Redux
  const dispatch = useDispatch();
  const UserData = useSelector((state) => state.UserReducer.UserData);
  //Props
  //Declare State
  const UserForm = useForm({ validationSchema: ValidateChangePasswordSchema });
  const [txtHeader, settxtHeader] = useState("Change Password");
  //Effect

  //Event+function
  //SubComponent

  const save = async () => {
    let data = {
      ...UserData,
      ...UserForm.getValues(),
      UpdatedBy: common.GetUserId(),
    };

    const save = () => {
      //UserStore.actionCreators.SaveUser(dispatch, data, Role, criteria);
    };
    common.SaveWithConfirm(save);
  };
  return (
    <FormContext {...UserForm}>
      <Modal
        isOpen={props.isOpen}
        CancelHidden
        SaveHidden
        onCancel={() => {
          props.onCancel();
          UserForm.reset();
          dispatch({ type: UserStore.ActionType.ClearUserData });
        }}
        ModalHeaderText={txtHeader}
      >
        <PasswordDetail />
      </Modal>
    </FormContext>
  );
};

export const PasswordDetail = () => {
  //Redux
  const dispatch = useDispatch();
  const UserData = useSelector((state) => state.UserReducer.UserData);
  //Props
  const {
    register,
    errors,
    setValue,
    getValues,
    clearError,
    handleSubmit,
    reset,
  } = useFormContext();

  //Declare State
  const [dataItem, setDataItem] = useState(UserData);
  //Effect

  //Event+function
  const UpdatePassword = async () => {
    let data = {
      ...UserData,
      ...getValues(),
      UpdatedBy: common.GetUserId(),
    };
    var result = await UserStore.actionCreators.CheckPassword(dispatch, {
      ...data,
      Password: getValues().Current,
    });
    if (result) {
      common.SaveWithConfirm(async () => {
        await UserStore.actionCreators.ChangePassword(dispatch, data);
        reset();
        common.RemoveForceChangePassword();
      });
    } else {
      common.InformationOKDialog("Password Incorect");
    }
  };

  //SubComponent

  return (
    <div>
      <div>
        <FormRowInput label="Current Password" errors={errors.Current}>
          <Input
            type="password"
            name="Current"
            id="Current"
            innerRef={register}
            bsSize="sm"
            invalid={errors.Current ? true : false}
          />
        </FormRowInput>
        <FormRowInput label="New Password" errors={errors.Password} required>
          <Input
            type="password"
            name="Password"
            id="Password2"
            innerRef={register}
            bsSize="sm"
            invalid={errors.Password ? true : false}
          />
        </FormRowInput>
        <FormRowInput label="Comfirm Password" errors={errors.confirm} required>
          <Input
            type="password"
            name="confirm"
            innerRef={register}
            bsSize="sm"
            invalid={errors.confirm ? true : false}
          />
        </FormRowInput>
        <input
          type="button"
          className="btn-default btnDefault btn-Temp"
          value="Update Password"
          onClick={handleSubmit(UpdatePassword)}
        />
      </div>
    </div>
  );
};
export default UserMaster;
