import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as common from "./../CommonFunction/common-function";
import { useForm, useFormContext, FormContext } from "react-hook-form";
import * as yup from "yup";
import { Input, CustomInput, Form, FormGroup, Col, Label } from "reactstrap";
//Component
import MainScreen from "../UI/MainScreen";
import CommonTable from "../UI/CommonTables";
import ResultBox from "../UI/ResultBox";
import Modal from "../UI/MainModal";
import FormRowInput from "../UI/FormDetail";
import { AutoComplete } from "../UI/combobox/";
import CriteriaBox from "../UI/CriteriaBox";
import { DatePicker } from "../UI/DatePicker";
//Store
import * as ToolStore from "../../store/ToolStore";
import { PerissionScreenContext } from "./../Permission";
const ValidateActiveToolSchema = yup.object().shape({
  ToolsName: yup.string().nullable().trim().required("This field is required."),
  ToolsId: yup.string().nullable().trim().required("This field is required."),
  SerialNo: yup.string().trim().required("This field is required."),
});
const fielddate = ["ServiceStartdate"];
export const ActiveTool = () => {
  //Redux
  const dispatch = useDispatch();
  const CriteriaForm = useForm();
  const { getValues, reset } = CriteriaForm;
  const { Function: fn_lst } = useContext(PerissionScreenContext);
  //Props
  //Declare State
  //Effect
  //Event+function
  const search = () => {
    ToolStore.actionCreators.SearchActiveTools(dispatch, getValues());
  };
  //SubComponent
  const [isOpen, setIsOpen] = useState(false);
  const Add = () => {
    setIsOpen(true);
  };

  return (
    <MainScreen>
      <FormContext {...{ CriteriaForm: CriteriaForm }}>
        <CriteriaBox
          onSearch={search}
          hiddenClear={false}
          onClear={reset}
          hidden={true}
          hiddenClose={true}
        >
          <CriteriaSearch search={search} />
        </CriteriaBox>
        <ResultBox
          HeaderText="Activate Tools"
          ButtonAddHidden={common.disableEdit(fn_lst)}
          onButtonAddClick={Add}
        >
          <ActiveToolTable />
        </ResultBox>
        <ActiveToolModal
          isOpen={isOpen}
          onCancel={() => {
            setIsOpen(false);
          }}
        />
      </FormContext>
    </MainScreen>
  );
};
export const CriteriaSearch = (props) => {
  const { CriteriaForm } = useFormContext();
  const { register } = CriteriaForm;
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      props.search();
    }
  };
  return (
    <React.Fragment>
      <Form
        className="criteria-div"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormGroup row>
          <Label className="Label-font" for="SearchFor" size="sm">
            Search For
          </Label>
          <Col md={4}>
            <Input
              type="text"
              bsSize="sm"
              name="FullText"
              id="FullText"
              innerRef={register}
              onKeyPress={handleKeyPress}
            />
          </Col>
        </FormGroup>
      </Form>
    </React.Fragment>
  );
};
export const ActiveToolTable = () => {
  //Redux
  const dispatch = useDispatch();
  const ActiveToolList = useSelector(
    (state) => state.ToolsReducer.ActiveToolList
  );

  const { CriteriaForm } = useFormContext();
  const { getValues } = CriteriaForm;
  const { Function: fn_lst } = useContext(PerissionScreenContext);
  //Props
  //Declare State
  const [isOpen, setIsOpen] = useState(false);
  //Effect
  useEffect(() => {
    ToolStore.actionCreators.SearchActiveTools(dispatch, getValues());
  }, [dispatch, getValues]);
  //Event+function
  const onClickCol = {
    onClick: async (e, column, columnIndex, row, rowIndex) => {
      if (common.disableEdit(fn_lst)) return;
      await ToolStore.actionCreators.GetActiveToolData(
        dispatch,
        row.ActiveToolsId
      );
      setIsOpen(true);
    },
  };
  //SubComponent
  const Icondelete = common.getIconTag(common.Icontype.ION, "IoMdClose");

  const columns = [
    {
      dataField: "ToolsName",
      text: "Name",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "ToolsType",
      text: "Type",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "SerialNo",
      text: "Serial No.",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "IsActive",
      text: "Is Active",
      sort: true,
      events: onClickCol,
      formatter: (cell, row) => {
        return row.IsActive ? "Yes" : "No";
      },
    },
    {
      dataField: "b",
      text: "",
      formatter: (cell, row) => {
        return (
          <button
            onClick={() => {
              ToolStore.actionCreators
                .CheckValidateDeleteActiveTool(row)
                .then((result) => {
                  if (result.isValid) {
                    common.ConfirmDelete(() => {
                      ToolStore.actionCreators.DeleteActiveTool(
                        dispatch,
                        row.ActiveToolsId,
                        getValues()
                      );
                    });
                  } else {
                    common.InformationOKDialog(
                      result.Messages && common.ArrayTextReduce(result.Messages)
                    );
                  }
                });
            }}
            className="btn-transparent"
            disabled={common.disableEdit(fn_lst)}
          >
            <Icondelete />
          </button>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <CommonTable
        keyField="ActiveToolsId"
        data={ActiveToolList}
        columns={columns}
      />
      <ActiveToolModal
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
      />
    </React.Fragment>
  );
};

export const ActiveToolModal = (props) => {
  //Redux
  const dispatch = useDispatch();
  const ActiveToolData = useSelector(
    (state) => state.ToolsReducer.ActiveToolData
  );
  const { CriteriaForm } = useFormContext();
  //Props
  //Declare State
  const ActiveToolForm = useForm({
    validationSchema: ValidateActiveToolSchema,
  });
  const { handleSubmit, getValues } = ActiveToolForm;
  const [txtHeader, settxtHeader] = useState();
  //Effect
  useEffect(() => {
    let txt;
    if (!common.isEmptyStr(ActiveToolData.ActiveToolsId)) {
      txt = "Edit ";
    } else txt = "Add ";
    settxtHeader(txt + "Activate Tool");
  }, [ActiveToolData]);
  //Event+function
  const validate = async (data) => {
    let res = await ToolStore.actionCreators.CheckDupplicateSerial(data);
    if (res.isValid) {
      return true;
    } else {
      common.InformationOKDialog(
        res.Messages && common.ArrayTextReduce(res.Messages)
      );
    }
  };
  const save = async () => {
    if (await validate(getdata()))
      common.SaveWithConfirm(() =>
        ToolStore.actionCreators.SaveActiveTool(
          dispatch,
          getdata(),
          CriteriaForm.getValues()
        )
      );
  };
  const getdata = () => {
    return { ...ActiveToolData, ...getValues(), UpdatedBy: common.GetUserId() };
  };
  //SubComponent

  return (
    <Modal
      isOpen={props.isOpen}
      onSave={handleSubmit(save)}
      onCancel={() => {
        props.onCancel();
        dispatch({ type: ToolStore.ActionType.ClearActiveToolsData });
      }}
      ModalHeaderText={txtHeader}
    >
      <FormContext {...ActiveToolForm}>
        <ActiveToolDetail />
      </FormContext>
    </Modal>
  );
};

export const ActiveToolDetail = () => {
  //Redux
  const ActiveToolData = useSelector(
    (state) => state.ToolsReducer.ActiveToolData
  );
  //Props
  const { register, errors, setValue, clearError } = useFormContext();
  //Declare State
  const [dataItem, setDataItem] = useState(ActiveToolData);
  //Effect
  useEffect(() => {
    register({ name: "ToolsName" });
    register({ name: "ToolsId" });
  });
  useEffect(() => {
    setValue(common.setDataToFormHook(ActiveToolData, fielddate));
    setDataItem(ActiveToolData);
  }, [ActiveToolData, setValue]);

  //
  const setControlValue = (name, value, isclearerr = true) => {
    setValue(name, value);
    setDataItem((prev) => ({ ...prev, [name]: value }));
    isclearerr && clearError(name);
  };
  return (
    <div>
      <FormRowInput label="Tools Name" required errors={errors.ToolsName}>
        <AutoComplete.ToolsName
          name="ToolsName"
          innerRef={register}
          invalid={errors.ToolsName ? true : false}
          value={dataItem.ToolsName || ""}
          setControlValue={setControlValue}
          onChange={() => setControlValue("ToolsId", "", false)}
        />
      </FormRowInput>
      <FormRowInput label="Tools Type" required errors={errors.ToolsId}>
        <AutoComplete.ToolsType
          name="ToolsId"
          innerRef={register}
          Criteria={
            !common.isEmptyStr(dataItem.ToolsName)
              ? { ToolsName: dataItem.ToolsName }
              : {}
          }
          invalid={errors.ToolsId ? true : false}
          value={dataItem.ToolsId || ""}
          setControlValue={setControlValue}
        />
      </FormRowInput>

      <FormRowInput label="Serial Number" required errors={errors.SerialNo}>
        <Input
          type="text"
          name="SerialNo"
          id="SerialNo"
          maxLength="50"
          innerRef={register}
          invalid={errors.SerialNo ? true : false}
        />
      </FormRowInput>
      <FormRowInput label="Is Active">
        <CustomInput
          type="checkbox"
          id="IsActive"
          name="IsActive"
          innerRef={register}
        />
      </FormRowInput>
      <FormRowInput label="Service Months">
        <Input
          min={0}
          max={100}
          type="number"
          step="1"
          name="ServiceMonths"
          id="ServiceMonths"
          innerRef={register}
          bsSize="sm"
        />
      </FormRowInput>
      <FormRowInput label="Service Start Date">
        <DatePicker
          name="ServiceStartdate"
          id="ServiceStartdate"
          innerRef={register}
        />
      </FormRowInput>
      <FormRowInput label="Remark">
        <Input
          type="textarea"
          name="Remarks"
          id="Remarks"
          maxLength="500"
          style={{ height: 150 }}
          innerRef={register}
        />
      </FormRowInput>
    </div>
  );
};
export default ActiveTool;
