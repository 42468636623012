import React, { createContext, useState, useContext, useEffect } from "react";
import { Table } from "reactstrap";
import "../../assets/css/common-style.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import "../../assets/css/Table.css";
export const ReactTableContext = createContext();
export const ReactTable = (props) => {
  return (
    <React.Fragment>
      <Table responsive hover className="border-solid">
        <thead>
          <tr>{props.Header}</tr>
        </thead>
        <tbody>{props.Row}</tbody>
      </Table>
    </React.Fragment>
  );
};
export const ChildTable = (props) => {
  return (
    <React.Fragment>
      <div className={props.classTable || "table-container"}>
        <BootstrapTable
          keyField={props.keyField}
          data={props.data}
          columns={props.columns}
          bordered={false}
          cellEdit={props.cellEdit}
          noDataIndication="Data have 0 record"
          rowStyle={props.rowStyle}
        />
      </div>
    </React.Fragment>
  );
};
const checkexistsId = (arr, id) => {
  return Array.isArray(arr) && arr.length && arr.find((el) => el.id === id);
};
export const ReactBootStrapTable = ({ id, ...props }) => {
  const context = useContext(ReactTableContext);

  const pageButtonRenderer = ({
    page,
    active,
    disable,
    title,
    onPageChange,
  }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    const activeStyle = {};
    if (active) {
      activeStyle.backgroundColor = "white";
      activeStyle.color = "#337ab7";
    } else {
      activeStyle.backgroundColor = "#1a0dab";
      activeStyle.color = "white";
    }
    if (typeof page === "string") {
      activeStyle.backgroundColor = "#1a0dab";
      activeStyle.color = "white";
    }
    return (
      <li className="page-item" key={page}>
        <a
          href="#"
          className="page-link"
          onClick={handleClick}
          style={activeStyle}
        >
          {page}
        </a>
      </li>
    );
  };

  const options = {
    custom: true,
    totalSize: props.data.length || 0,
    paginationSize: 3,
    pageButtonRenderer,
    onPageChange: (pageIndex, sizePerPage) => {
      if (!id) return;
      if (checkexistsId(context.myProperties, id)) {
        let idx = context.myProperties.findIndex(
          (element) => element.id === id
        );
        let newArray = [...context.myProperties];
        newArray[idx] = { ...newArray[idx], page: pageIndex };
        context.setmyProperties(newArray);
      } else {
        let newArray = [...context.myProperties].concat({
          sizePerPage: sizePerPage,
          page: pageIndex,
          id,
        });
        context.setmyProperties(newArray);
      }
    },
    onSizePerPageChange: (newSizePerPage, pageIndex) => {
      if (!id) return;
      if (checkexistsId(context.myProperties, id)) {
        let idx = context.myProperties.findIndex(
          (element) => element.id === id
        );
        let newArray = [...context.myProperties];
        newArray[idx] = { ...newArray[idx], sizePerPage: newSizePerPage };
        context.setmyProperties(newArray);
      } else {
        let newArray = [...context.myProperties].concat({
          sizePerPage: newSizePerPage,
          page: pageIndex,
          id,
        });
        context.setmyProperties(newArray);
      }
    },
    sizePerPage:
      (context.myProperties.find((el) => el.id === id) &&
        context.myProperties.find((el) => el.id === id).sizePerPage) ||
      30,
    // page:
    //   context.myProperties.find((el) => el.id === id) &&
    //   context.myProperties.find((el) => el.id === id).page,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "1000",
        value: 1000,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const contentPagination = (paginationProps) => (
    <div className="grid-pagination">
      <div className="grid-item">
        <SizePerPageDropdownStandalone {...paginationProps} />
      </div>
      <div className="grid-item">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </div>
  );

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <div>
      {contentPagination(paginationProps)}
      <BootstrapTable
        keyField={props.keyField}
        data={props.data}
        columns={props.columns}
        bordered={false}
        noDataIndication="Data have 0 record"
        bodyStyle={{ wordBreak: "break-all" }}
        {...paginationTableProps}
      />
      {contentPagination(paginationProps)}
      {props.data && props.data.length} rows
    </div>
  );

  return (
    <>
      <PaginationProvider pagination={paginationFactory(options)}>
        {contentTable}
      </PaginationProvider>
    </>

    // <>
    //   <div>
    //     <BootstrapTable
    //       keyField={props.keyField}
    //       data={props.data}
    //       columns={props.columns}
    //       bordered={false}
    //       noDataIndication="Data have 0 record"
    //       bodyStyle={{ wordBreak: "break-all" }}
    //       //onDataSizeChange={()=>{context.setmyProperties()}}
    //       pagination={paginationFactory(options)}
    //     />
    //     {props.data && props.data.length} rows
    //   </div>
    // </>
  );
};

export const ReactTableContextProvider = ({ children }) => {
  const [myProperties, setmyProperties] = useState([]);
  return (
    <ReactTableContext.Provider value={{ myProperties, setmyProperties }}>
      {children}
    </ReactTableContext.Provider>
  );
};
export const CommonTable = (props) => {
  return <ReactBootStrapTable {...props} />;
};
export default CommonTable;
