import React from "react";
import { Input } from "reactstrap";
import DateTimePicker from "react-datepicker";
import { formatDate } from "./../CommonFunction/common-function";
import "react-datepicker/dist/react-datepicker.css";

export const DatePicker = (props) => {
  //For Control with react-hook-form
  return (
    <React.Fragment>
      <Input type="date" bsSize="sm" {...props}  />
    </React.Fragment>
  );
};
export const DatePicker2 = (props) => {
  //For Control with state
  return (
    <React.Fragment>
      <Input
        type="date"
        bsSize="sm"
        {...props}
        value={formatDate(props.value)}
      />
    </React.Fragment>
  );
};
export const DateTimePick = (props) => {
  return (
    <React.Fragment>
      <DateTimePicker
        selected={props.value === "" ? null : new Date(props.value)}
        onChange={props.onChange}
        dateFormat="yyyy-MM-dd"
      />
    </React.Fragment>
  );
};

export default DatePicker;
