//Library
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
//Component
import CommonTable from "../UI/CommonTables";
import * as Tooltip from "../UI/LinkText/Tooltip";
import * as LinkModal from "./../UI/LinkText/LinkModal";
import { formatDate, collectText } from "./../CommonFunction/common-function";
//Store
import * as JobMonitorStore from "../../store/JobMonitorStore";

const JobMonitorTable = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  const onClickCol = {
    onClick: async (e, column, columnIndex, row, rowIndex) => {
      await JobMonitorStore.actionCreators.LoadDetail(dispatch, row.JobId);
      setIsOpen(true);
    },
  };

  const columns = [
    {
      dataField: "DateFrom",
      text: "Operation Date",
      sort: true,
      events: onClickCol,
      formatter: (cell, row) => {
        if (row.DateFrom && row.DateTo) {
          if (row.DateFrom === row.DateTo) {
            return formatDate(row.DateFrom);
          }
          return `${formatDate(row.DateFrom)} to ${formatDate(row.DateTo)}`;
        }
        return "";
      },
      style: (colum, colIndex) => {
        return { width: "200px", maxWidth: "200px" };
      },
    },
    {
      dataField: "CustomerName",
      text: "Customer",
      sort: true,
      formatter: (cell, row) => {
        return (
          <LinkModal.Customer Text={row.CustomerName} Value={row.CustomerId} />
        );
      },
      style: (colum, colIndex) => {
        return { width: "200px", maxWidth: "200px" };
      },
    },
    {
      dataField: "EngineerText",
      text: "Engineer",
      sort: true,
      events: onClickCol,
      style: (colum, colIndex) => {
        return {
          width: "200px",
          maxWidth: "200px",
        };
      },
    },
    {
      dataField: "MachineText",
      text: "Machine",
      sort: true,
      events: onClickCol,
      style: (colum, colIndex) => {
        return { width: "200px", maxWidth: "200px" };
      },
      formatter: (cell, row) => {
        return collectText(row.MachineText);
      },
    },
    {
      dataField: "MachineModelText",
      text: "Model",
      sort: true,
      events: onClickCol,
      style: (colum, colIndex) => {
        return { width: "200px", maxWidth: "200px" };
      },
      formatter: (cell, row) => {
        return collectText(row.MachineModelText);
      },
    },
    {
      dataField: "JobType",
      text: "Type",
      sort: true,
      events: onClickCol,
      style: (colum, colIndex) => {
        return { width: "100px", maxWidth: "100px" };
      },
    },
    {
      dataField: "Company",
      text: "Company",
      sort: true,
      events: onClickCol,
      style: (colum, colIndex) => {
        return { width: "50px", maxWidth: "50px" };
      },
    },
    {
      dataField: "Brief",
      text: "Brief",
      sort: true,
      events: onClickCol,
      style: (colum, colIndex) => {
        return { width: "50px", maxWidth: "50px" };
      },
    },
    {
      dataField: "PoNumber",
      text: "Po No.",
      sort: true,
      formatter: (cell, row) => {
        return (
          <LinkModal.ServiceRequest
            Text={row.PoNumber}
            Value={row.ServiceRequestId}
          />
        );
      },
      style: (colum, colIndex) => {
        return { width: "150px", maxWidth: "150px" };
      },
    },

    {
      dataField: "JobStatus",
      text: "Status",
      events: onClickCol,
      sort: true,
      formatter: (cell, row) => {
        return <Tooltip.JobStatusToolTip JobStatus={row.JobStatus} />;
      },
      style: (colum, colIndex) => {
        return { width: "100px", maxWidth: "100px" };
      },
    },

    {
      dataField: "isFollow",
      text: "Follow Up",
      events: onClickCol,
      sort: true,
      formatter: (cell, row) => {
        return row.isFollow ? "Yes" : "No";
      },
      style: (colum, colIndex) => {
        return { width: "50px", maxWidth: "50px" };
      },
    },
    {
      dataField: "isUploadCertificate",
      text: "Certificate Upload",
      events: onClickCol,
      sort: true,
      formatter: (cell, row) => {
        return row.isUploadCertificate ? "Yes" : "No";
      },
      style: (colum, colIndex) => {
        return { width: "50px", maxWidth: "50px" };
      },
    },
    {
      dataField: "ConflictReason",
      text: "Conflict Reason",
      events: onClickCol,
      sort: true,
      formatter: (cell, row) => {
        return props.conflict ? row.ConflictReason : null;
      },
      hidden: props.conflict ? false : true,

      style: (colum, colIndex) => {
        return { width: "100px", maxWidth: "100px" };
      },
    },
    //{
    //    dataField: 'a',
    //    text: '',
    //    formatter: (cell, row) => {
    //        return (
    //            <button onClick={async () => {
    //                await JobMonitorStore.actionCreators.LoadDetail(dispatch, row.JobId)

    //                setIsOpen(true)

    //            }} className="btn-transparent"><Iconmore /></button>
    //        );
    //    },

    //}
  ];

  return (
    <React.Fragment>
      {isOpen ? (
        props.conflict ? (
          <Redirect
            to={{
              pathname: "/JobMonitor",
              state: { BackLink: "/JobConflict" },
            }}
          />
        ) : (
          <Redirect to="/JobMonitor" />
        )
      ) : null}
      <CommonTable
        keyField="JobId"
        data={props.dataTable}
        columns={columns}
        id={props.conflict ? "JobMonitorTableConFlict" : "JobMonitorTable"}
      />
    </React.Fragment>
  );
};
export default JobMonitorTable;
