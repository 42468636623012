import React, { useState } from "react";
import * as common from "../CommonFunction/common-function";
import { AddMachineModal, EditMachineModal } from "../addmodal/AddMachineModal";

import { useDispatch, useSelector } from "react-redux";
import * as JobStore from "../../store/jobStore";
import { ChildTable } from "../UI/CommonTables";

function Machine() {
  const IoIosAdd = common.getIconTag(common.Icontype.ION, "IoIosAdd");
  const Icondelete = common.getIconTag(common.Icontype.ION, "IoMdClose");
  const Iconmore = common.getIconTag(common.Icontype.ION, "IoIosMore");
  const [isOpen, setIsOpen] = useState();
  const [isOpenEdit, setIsOpenEdit] = useState();
  const [MachineData, setMachineData] = useState();
  const Jobinfo = useSelector((state) => state.jobReducer.Form.Jobdata);
  const Machine = useSelector((state) => state.jobReducer.Form.Machine);
  const dispatch = useDispatch();

  //const dataMachine = Machine && Machine.map((item) => (
  //    <tr key={common.uuidv4()}>

  //        <td>{item.SerialNo}</td>
  //        <td>{item.MachineName}</td>
  //        <td>{item.MachineModel}</td>
  //        <td>{item.CalPoint}</td>
  //        <td>{item.WiDisplay}</td>
  //        <td>{item.Resolution}</td>
  //        <td>{item.Uncertainly}</td>
  //        <td>{item.Certificate ? "Yes" : "No"}</td>
  //        <td>
  //            <button className="btn-transparent" onClick={() => {
  //                setIsOpenEdit(true)
  //                setMachineData(item)
  //            }}><Iconmore /></button>
  //            <button onClick={() => {
  //                JobStore.actionCreators.RemoveMachine(dispatch, item)
  //            }} className="btn-transparent"><Icondelete /></button>
  //        </td>
  //    </tr>
  //))
  //2.Click Add
  const Add = () => {
    setIsOpen(true);
  };
  //3.ForSave
  const getValue = (machineList) => {
    //New Api For List
    if (machineList && machineList.length > 0) {
      const machineList2 = machineList.map((item) => ({
        JobId: Jobinfo.JobId,
        SerialNo: item,
        User: common.GetUserId(),
      }));
      JobStore.actionCreators.UpdateMachineList(
        dispatch,
        Jobinfo.JobId,
        machineList2
      );
    } else {
      JobStore.actionCreators.RemoveAllMachine(dispatch, Jobinfo.JobId);
    }
    setIsOpen(!isOpen);
  };
  //4.ForEditDetail
  const getValueDetail = (machine) => {
    //New Api For List

    JobStore.actionCreators.UpdateMachineDetail(
      dispatch,
      Jobinfo.JobId,
      machine
    );

    setIsOpenEdit(!isOpenEdit);
  };

  const columns = [
    {
      dataField: "SerialNo",
      text: "Serial No",
      sort: true,
    },
    {
      dataField: "MachineName",
      text: "Name",
      sort: true,
    },
    {
      dataField: "MachineModel",
      text: "Model",
      sort: true,
    },
    {
      dataField: "CalPoint",
      text: "Cal Point",
      sort: true,
    },
    {
      dataField: "WiDisplay",
      text: "WI",
      sort: true,
    },
    {
      dataField: "Resolution",
      text: "Resolution",
      sort: true,
    },
    {
      dataField: "Uncertainly",
      text: "Uncertainly",
      sort: true,
    },
    {
      dataField: "Certificate",
      text: "Certificate",
      sort: true,
      formatter: (cell, row) => {
        return row.Certificate ? "Yes" : "No";
      },
    },
    {
      dataField: "a",
      text: "",
      formatter: (cell, row) => {
        return (
          <button
            className="btn-transparent"
            onClick={() => {
              setIsOpenEdit(true);
              setMachineData(row);
            }}
          >
            <Iconmore />
          </button>
        );
      },
    },
    {
      dataField: "b",
      text: "",
      formatter: (cell, row) => {
        return (
          <button
            onClick={() => {
              JobStore.actionCreators.RemoveMachine(dispatch, row);
            }}
            className="btn-transparent"
          >
            <Icondelete />
          </button>
        );
      },
    },
  ];

  return (
    <div>
      {Jobinfo && Jobinfo.JobStatus !== "CLOSE" && (
        <div className="row detail-head">
          <div className="col-2 col-md-2">
            <button className="btn-transparent" onClick={Add}>
              <IoIosAdd className="icon" />
            </button>
          </div>
        </div>
      )}

      <ChildTable keyField="SerialNo" data={Machine} columns={columns} />

      <AddMachineModal
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(!isOpen);
        }}
        SelectedData={Machine && Machine.map((a) => a.SerialNo)}
        GetValue={getValue}
        CustomerID={Jobinfo.CustomerId}
        Jobdata={Jobinfo}
      />
      <EditMachineModal
        isOpen={isOpenEdit}
        onCancel={() => {
          setIsOpenEdit(!isOpenEdit);
        }}
        Data={MachineData}
        GetValue={getValueDetail}
      />
    </div>
  );
}

export default Machine;
