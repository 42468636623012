//Library
import React, { useState, useEffect,useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, FormGroup, Label, Input } from "reactstrap";
import * as common from "./../CommonFunction/common-function";
import {disableEdit} from "./../CommonFunction/common-function";
import { useForm, useFormContext, FormContext } from "react-hook-form";
import * as yup from "yup";
//Component
import { PerissionScreenContext } from "./../Permission/";
import MainScreen from "../UI/MainScreen";
import CommonTable from "../UI/CommonTables";
import ResultBox from "../UI/ResultBox";
import Modal from "../UI/MainModal";
import FormRowInput from "../UI/FormDetail";
import { AutoComplete, Combo } from "../UI/combobox";
import CriteriaBox from "../UI/CriteriaBox";

//Store
import * as MasterStore from "../../store/MasterStore";
const ValidateToolsSchema = yup.object().shape({
  ToolsName: yup.string().trim().required("This field is required."),
  ToolsType: yup.string().trim().required("This field is required."),
});

export const ToolMaster = () => {
  //Redux Global
  const criteria = useSelector((state) => state.MasterReducer.SearchCriteria);
  const CriteriaForm = useForm();
  const ToolForm = useForm({
    validationSchema: ValidateToolsSchema,
  });
  const {Function:fn_lst} = useContext(PerissionScreenContext)
  const [isOpen, setIsOpen] = useState(false);
  
  useEffect(() => {
    ToolForm.register({ name: "ToolsName" });
    ToolForm.register({ name: "ToolsType" });
  });
  const dispatch = useDispatch();
  const Add = () => {
    setIsOpen(true);
  };
  const search = () => {
    MasterStore.actionCreators.SearchTool(dispatch, criteria);
  };
  const clear = () => {
    dispatch({ type: MasterStore.ActionType.criteriaClear });
  };
  return (
    <MainScreen>
      <FormContext {...{ CriteriaForm: CriteriaForm, ToolForm: ToolForm }}>
        <CriteriaBox
          onSearch={search}
          hiddenClear={false}
          onClear={clear}
          hidden={true}
          hiddenClose={true}
        >
          <ToolCriteriaSearch search={search} />
        </CriteriaBox>
        <ResultBox
          HeaderText="Tools"
          ButtonAddHidden={disableEdit(fn_lst)}
          onButtonAddClick={Add}
        >
          <ToolTable />
        </ResultBox>
        <ToolModal
          isOpen={isOpen}
          onCancel={() => {
            setIsOpen(false);
          }}
        />
      </FormContext>
    </MainScreen>
  );
};

export const ToolCriteriaSearch = (props) => {
  const SearchCriteria = useSelector(
    (state) => state.MasterReducer.SearchCriteria
  );
  const [dataItem, setDataItem] = useState(SearchCriteria);
  const dispatch = useDispatch();

  useEffect(() => {
    setDataItem(SearchCriteria);
  }, [SearchCriteria]);
  useEffect(() => {}, [dataItem]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDataItem({ ...dataItem, [name]: value });
    dispatch({
      type: MasterStore.ActionType.criteriaChange,
      param: { ...dataItem, [name]: value },
    });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      props.search();
    }
  };
  return (
    <React.Fragment>
      <Form
        className="criteria-div"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormGroup row>
          <Label className="Label-font" for="SearchFor" size="sm">
            Search For
          </Label>
          <Col md={4}>
            <Input
              type="text"
              bsSize="sm"
              name="FullText"
              id="FullText"
              value={dataItem.FullText}
              onKeyPress={handleKeyPress}
              onChange={handleInputChange}
            />
          </Col>
        </FormGroup>
      </Form>
    </React.Fragment>
  );
};

export const ToolTable = () => {
  //Redux
  const dispatch = useDispatch();
  const criteria = useSelector((state) => state.MasterReducer.ToolCriteria);
  const ToolList = useSelector((state) => state.MasterReducer.ToolList);
  const {Function:fn_lst} = useContext(PerissionScreenContext)
  //Declare State
  const [isOpen, setIsOpen] = useState(false);
  //Effect
  useEffect(() => {
    MasterStore.actionCreators.SearchTool(dispatch, criteria);
  }, [dispatch, criteria]);
  //Event+function
  
  //SubComponent
  const Icondelete = common.getIconTag(common.Icontype.ION, "IoMdClose");
  const onClickCol = {
    onClick: async (e, column, columnIndex, row, rowIndex) => {
      if (disableEdit(fn_lst)) return
      await MasterStore.actionCreators.GetToolData(dispatch, row.ToolsId);
      setIsOpen(true);
    },
  };

  const columns = [
    {
      dataField: "ToolsName",
      text: "Name",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "ToolsType",
      text: "Type",
      sort: true,
      events: onClickCol,
    },
    //{
    //    dataField: 'a',
    //    text: '',
    //    formatter: (cell, row) => {
    //        return (
    //            <button onClick={async () => {
    //                await MasterStore.actionCreators.GetToolData(dispatch, row.ToolsId)
    //                setIsOpen(true)
    //            }} className="btn-transparent"><Iconmore /></button>
    //        );
    //    }
    //},
    {
      dataField: "b",
      text: "",
      formatter: (cell, row) => {
        return (
          <button
            onClick={() => {
              MasterStore.actionCreators
                .CheckValidateDeleteTool(row)
                .then((result) => {
                  if (result.isValid) {
                    common.ConfirmDelete(() => {
                      MasterStore.actionCreators.DeleteTool(
                        dispatch,
                        row.ToolsId,
                        criteria
                      );
                    });
                  } else {
                    common.InformationOKDialog(
                      result.Messages && common.ArrayTextReduce(result.Messages)
                    );
                  }
                });
            }}
            className="btn-transparent"
            disabled={disableEdit(fn_lst)}
          >
            <Icondelete />
          </button>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <CommonTable keyField="ToolsId" data={ToolList} columns={columns} />
      <ToolModal
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
      />
    </React.Fragment>
  );
};

export const ToolModal = (props) => {
  //Redux
  const dispatch = useDispatch();
  const ToolData = useSelector((state) => state.MasterReducer.ToolData);
  const criteria = useSelector((state) => state.MasterReducer.ToolCriteria);
  const { ToolForm } = useFormContext();
  const { handleSubmit, getValues, reset } = ToolForm;
  const validate = async (data) => {
    let { result: checkresult } = await MasterStore.actionCreators.ValidateTool(
      data
    );
    if (checkresult) {
      let result = checkresult.data;
      if (!result.isValid) {
        common.InformationOKDialog(
          result.Messages && common.ArrayTextReduce(result.Messages)
        );
        return false;
      }
    }
    return true;
  };
  const save = async () => {
    if (!(await validate(getdata()))) {
      return;
    }
    const save = () => {
      MasterStore.actionCreators.SaveTool(dispatch, getdata(), criteria);
    };
    common.SaveWithConfirm(save);
  };
  const [txtHeader, settxtHeader] = useState();
  useEffect(() => {
    let txt;
    if (!common.isEmptyStr(ToolData.ToolsId)) {
      txt = "Edit ";
    } else txt = "Add ";
    settxtHeader(txt + "Tool");
  }, [ToolData]);
  const getdata = () => {
    return { ...ToolData, ...getValues(), UpdatedBy: common.GetUserId() };
  };
  return (
    <Modal
      isOpen={props.isOpen}
      onSave={handleSubmit(save)}
      onCancel={() => {
        props.onCancel();
        reset();
        dispatch({ type: MasterStore.ActionType.ClearToolData });
      }}
      ModalHeaderText={txtHeader}
    >
      <ToolDetail />
    </Modal>
  );
};

export const ToolDetail = () => {
  //Redux
  const ToolData = useSelector((state) => state.MasterReducer.ToolData);
  const { ToolForm } = useFormContext();
  const { getValues, register, setValue, errors, clearError } = ToolForm;

  //Props
  //Declare State
  const [dataItem, setDataItem] = useState(ToolData);
  //Effect
  useEffect(() => {
    setDataItem(ToolData);
    setValue(common.setDataToFormHook(ToolData));
  }, [ToolData]);
  const setControlValue = (name, value, isclearerr = true) => {
    setValue(name, value);
    setDataItem((prev) => ({ ...prev, [name]: value }));
    isclearerr && clearError(name);
  };

  return (
    <div>
      <FormRowInput label="Tools Name" required errors={errors.ToolsName}>
        <AutoComplete.ToolsNameCreate
          name="ToolsName"
          id="ToolsName"
          innerRef={register}
          selected={[getValues().ToolsName || ""]}
          setControlValue={setControlValue}
          invalid={errors.ToolsName ? true : false}
          value={dataItem.ToolsName}
        />
      </FormRowInput>
      <FormRowInput label="Tools Type" required errors={errors.ToolsType}>
        <AutoComplete.ToolsTypeCreate
          name="ToolsType"
          id="ToolsType"
          innerRef={register}
          selected={[getValues().ToolsType || ""]}
          setControlValue={setControlValue}
          isInvalid={errors.ToolsType ? true : false}
        />
      </FormRowInput>
      <FormRowInput label="Brand" >
        <Input
          type="text"
          name="Brand"
          maxLength="50"
          innerRef={register}
          bsSize="sm"
        />
      </FormRowInput>
      <FormRowInput label="Tags" >
        <Input
          type="text"
          name="Tags"
          maxLength="50"
          innerRef={register}
          bsSize="sm"
        />
      </FormRowInput>
    </div>
  );
};

export default ToolMaster;
