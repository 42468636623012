//Library
import React, { useState, useEffect } from "react";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../assets/css/calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import * as common from "../CommonFunction/common-function";
import { withRouter } from "react-router-dom";
//Component
import { SideNavBar } from "../UI/SideNavBar/SideNavBar";
import { FormRow } from "../UI/FormDetail";
import Modal from "../UI/MainModal";
//Store
import * as JobStore from "./../../store/jobStore";
import * as ReportFilterStore from "./../../store/ReportFilterStore";

const CustomToolbar = (toolbar) => {
  const [ViewState, setViewState] = useState("");
  const goToDayView = () => {
    toolbar.onView("day");
    setViewState("day");
  };
  const goToWeekView = () => {
    toolbar.onView("week");
    setViewState("week");
  };
  const goToMonthView = () => {
    toolbar.onView("month");
    setViewState("month");
  };
  const goToAgendaView = () => {
    toolbar.onView("agenda");
    setViewState("agenda");
  };
  const goToBack = () => {
    toolbar.onNavigate("PREV");
  };
  const goToNext = () => {
    toolbar.onNavigate("NEXT");
  };
  const goToCurrent = () => {
    toolbar.onNavigate("TODAY");
  };
  return (
    <>
      <div className="rbc-toolbar">
        <span className="rbc-toolbar-label">{toolbar.label}</span>
        <span className="rbc-btn-group">
          <button type="button" className="btn-transparent" onClick={goToBack}>
            {"<< Back"}
          </button>
          <button
            type="button"
            className="btn-transparent"
            onClick={goToCurrent}
          >
            Today
          </button>
          <button type="button" className="btn-transparent" onClick={goToNext}>
            {"Next >>"}
          </button>
        </span>

        <span className="rbc-btn-group">
          <button
            type="button"
            className={ViewState === "day" ? "rbc-active" : ""}
            onClick={goToDayView}
          >
            Day
          </button>
          <button
            type="button"
            className={ViewState === "week" ? "rbc-active" : ""}
            onClick={goToWeekView}
          >
            Week
          </button>
          <button
            type="button"
            className={ViewState === "month" ? "rbc-active" : ""}
            onClick={goToMonthView}
          >
            Month
          </button>
          <button
            type="button"
            className={ViewState === "agenda" ? "rbc-active" : ""}
            onClick={goToAgendaView}
          >
            Agenda
          </button>
        </span>
      </div>
    </>
  );
};
export const ScheduleCalendar = ({ history }) => {
  //Redux
  const dispatch = useDispatch();
  const EmployeeFilterList = useSelector(
    (state) => state.ReportFilterReducer.EmployeeFilterList
  );
  const CustomersFilterList = useSelector(
    (state) => state.ReportFilterReducer.CustomersFilterList
  );
  const ToolFilterList = useSelector(
    (state) => state.ReportFilterReducer.ToolFilterList
  );
  const JobStatusFilterList = useSelector(
    (state) => state.ReportFilterReducer.JobStatusFilterList
  );

  const SearchCriteriaNav = useSelector(
    (state) => state.ReportFilterReducer.SearchCriteria
  );
  // const JobStatusList = useSelector(
  //   (state) => state.ReportFilterReducer.JobStatusList
  // );
  //Props
  //Declare State
  moment.locale("en-GB");
  const localizer = momentLocalizer(moment);
  const eventlist = useSelector((state) => state.jobReducer.TaskJobData);
  const [isOpen, setIsOpen] = useState();
  const [data, setdata] = useState();
  //Effect
  useEffect(() => {
    // let { CARRIED, CLOSE, COMMITED, ONDUTY, PENDING } = common.Constants;
    // let def_status = [CARRIED, CLOSE, COMMITED, ONDUTY, PENDING];
    /*let add_status = JobStatusList.filter(
      (el) => def_status && def_status.includes(el.JobStatus)
    ); */
    let add_status = [
      { JobStatus: "CARRIED", Sequence: 5, Remark: null },
      { JobStatus: "CLOSE", Sequence: 8, Remark: null },
      { JobStatus: "COMMITED", Sequence: 4, Remark: null },
      { JobStatus: "ON-DUTY", Sequence: 6, Remark: null },
      { JobStatus: "PENDING", Sequence: 7, Remark: null },
    ];
    dispatch({
      type: ReportFilterStore.ActionType.AddJobStatusFilterList,
      param: add_status,
    });
    Search();
    //JobStore.actionCreators.getTaskJob(dispatch, {JobStatus:def_status});
  }, [dispatch]);
  //Event+function
  const Search = () => {
    var criteria = {
      Customers: CustomersFilterList.map((el) => el.CustomerId),
      EngineerIds: EmployeeFilterList.map((el) => el.EmployeeId),
      ToolIds: ToolFilterList.map((el) => el.ToolsId),
      JobStatus: JobStatusFilterList.map((el) => el.JobStatus),
      PlandateFromToStart: SearchCriteriaNav.PlandateFromToStart,
      PlandateFromToEnd: SearchCriteriaNav.PlandateFromToEnd,
    };
    JobStore.actionCreators.getTaskJob(dispatch, criteria);
  };
  const CreateEvent = async (date) => {
    let txt = `Create Event
     ${common.formatDate(date.start)} to ${common.formatDate(date.end)} `;
    let { result } = await common.to(common.ConfirmDialog(txt));
    if (!result.value) return;

    let job = {
      ...JobStore.initial_state.Form.Jobdata,
      DateFrom: common.formatDate(date.start),
      DateTo: common.formatDate(date.end),
      JobType: common.Constants.EVENT,
      JobStatus: common.Constants.DRAFT,
      UpdatedBy: common.GetUserId(),
    };
    await JobStore.actionCreators.CreateJob(dispatch, job);

    //dispatch({type:JobStore.ActionType.LoadDetail,param:job})
    history.push({
      pathname: "/Job",
      state: { BackLink: "/calendar" },
    });
  };
  const ShowDetail = async (event) => {
    setdata(event);
    //await JobStore.actionCreators.dataToJobform(dispatch, event.id)
    setIsOpen(true);
  };
  const eventStyleGetter = (event, start, end, isSelected) => {
    var BackgroundColor = "#" + event.hexColor;
    if (!event != null) {
      if (!event.color != null) {
        BackgroundColor = event.color;
      }
    }
    var style = {
      backgroundColor: BackgroundColor,
      borderRadius: "0px",
      opacity: 0.8,
      color: "black",
      border: "0px",
      // display: "block",
    };
    return {
      style: style,
    };
  };

  const Event = ({ event }) => (
    <span>
      <strong>{event.title}</strong>
    </span>
  );

  const CustomDateHeader = ({ label, drilldownView, onDrillDown }) => (
    <span>
      <a href="#" onClick={onDrillDown}>
        {label}
      </a>
    </span>
  );

  const TooltipStatus = ({ status }) => status;

  return (
    <>
      <SideNavBar
        Search={Search}
        collapse
        Customer
        Employee
        Tools
        JobStatus
        PlandateFromTo
      >
        <div className="calendar-view">
          <Calendar
            localizer={localizer}
            events={eventlist}
            selectable
            onSelectSlot={CreateEvent}
            startAccessor="start"
            endAccessor="end"
            defaultDate={moment().toDate()}
            //step={60}
            onSelectEvent={ShowDetail}
            eventPropGetter={eventStyleGetter}
            tooltipAccessor={TooltipStatus}
            //formats={{monthHeaderFormat:(date, culture, localizer) => localizer.format(date, 'MMM', culture)}}
            //hideTimeIndicator
            components={{
              event: Event,
              day: {
                header: CustomDateHeader,
              },
              month: {
                dateHeader: CustomDateHeader,
              },
              week: {
                header: CustomDateHeader,
              },
              agenda: {
                header: CustomDateHeader,
              },
              toolbar: CustomToolbar,
            }}
          />
        </div>

        <CalendarModal
          isOpen={isOpen}
          data={data}
          onCancel={() => {
            setIsOpen(!isOpen);
          }}
        />
      </SideNavBar>
    </>
  );
};

export const CalendarModal = (props) => {
  //Redux
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const editjob = async () => {
    if (props.data) {
      await JobStore.actionCreators.dataToJobform(dispatch, props.data.JobId);
      setIsOpen(true);
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      ModalHeaderText="Schedule Task Detail"
      onCancel={() => {
        props.onCancel();
      }}
      SaveHidden={true}
      CancelHidden={true}
      button1hidden={
        props.data
          ? props.data.JobId !== "" &&
            ![common.Constants.PREPLAN, common.Constants.CALLED].includes(
              props.data.status
            )
            ? false
            : true
          : true
      }
      onbutton1Click={editjob}
    >
      {common.isEmptyStr(props.data && props.data.JobId) ? (
        <CalendarLeaveDetail data={props.data} />
      ) : (
        <CalendarJobDetail data={props.data} />
      )}
      {isOpen ? <Redirect to="/JobCalendar" /> : null}
    </Modal>
  );
};
export const CalendarLeaveDetail = ({ data, ...props }) => {
  return (
    <div>
      <br />
      <FormRow label="Leave Name">{(data && data.title) || ""}</FormRow>
      <FormRow label="Date">
        {`${common.formatDate(
          (data && data.DateFrom) || ""
        )} to  ${common.formatDate((data && data.DateTo) || "")}`}
      </FormRow>
      <FormRow label="Remark">{(data && data.Remark) || ""}</FormRow>
    </div>
  );
};
export const CalendarJobDetail = ({ data, ...props }) => {
  const [Jobdata, setJobdata] = useState({});
  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await JobStore.actionCreators.GetJob(data && data.JobId);
      // ...
      setJobdata(response);
    }
    fetchData();
  }, [data]);
  return (
    <div>
      <br />
      <FormRow label="Task Name">{(data && data.title) || ""}</FormRow>
      <FormRow label="Plan Date From - To">{`${common.formatDate(
        (Jobdata && Jobdata.DateFrom) || ""
      )} to  ${common.formatDate((Jobdata && Jobdata.DateTo) || "")}`}</FormRow>
      <FormRow label="Customer Name">
        {(Jobdata && Jobdata.CustomerName) || ""}
      </FormRow>
      <FormRow label="Machine Name">
        {common.collectText((Jobdata && Jobdata.MachineText) || "")}
      </FormRow>
      <FormRow label="Engineer Name">
        {(Jobdata && Jobdata.EngineerText) || ""}
      </FormRow>
      {/* <FormRowInput label="Task Name">
                <Input type="text" name="Brief" value={props.data &&props.data.title || ''} readOnly bsSize="sm" />
            </FormRowInput>
            <div className="row detail-form-input">
                <div className="col-12 col-md-4">
                    <label htmlFor="StartTime" className="col-label-bold" readOnly>Start Time</label>
                </div>
                <div className="col-12 col-md-4">
                    <Input type="datetime" name="start" value={props.data&&props.data.DateFrom || ''} readOnly  bsSize="sm" />
                </div>
                <div className="col-12 col-md-4">

                </div>
            </div>
            <div className="row detail-form-input">
                <div className="col-12 col-md-4">
                    <label htmlFor="Duration" className="col-label-bold" readOnly>To</label>
                </div>
                <div className="col-12 col-md-4">
                    <Input type="datetime" name="DateTo"  value={props.data && props.data.DateTo || ''} readOnly bsSize="sm" />
                </div>
                <div className="col-12 col-md-4">

                </div>
            </div>
            <FormRowInput label="Description">
                <Input type="textarea" name="Remark"  value={props.data && props.data.Remark || ''} style={{ height: 150 }} readOnly />
            </FormRowInput> */}
    </div>
  );
};
// const events = [
//   {
//     id: 0,
//     title: "All Day Event very long title",
//     allDay: true,
//     start: new Date(2019, 3, 0),
//     end: new Date(2019, 3, 1),
//   },
//   {
//     id: 0,
//     title: "All Day Event very long title",
//     allDay: true,
//     start: new Date(2020, 9, 1),
//     end: new Date(2020, 9, 1),
//   },
//   {
//     id: 0,
//     title: "All Day Event very long title",
//     allDay: true,
//     start: new Date(2020, 9, 1),
//     end: new Date(2020, 9, 1),
//   },
//   {
//     id: 0,
//     title: "All Day Event very long title",
//     allDay: true,
//     start: new Date(2020, 9, 1),
//     end: new Date(2020, 9, 1),
//   },
//   {
//     id: 0,
//     title: "All Day Event very long title",
//     allDay: true,
//     start: new Date(2020, 9, 1),
//     end: new Date(2020, 9, 1),
//   },
// ];
export default withRouter(ScheduleCalendar);
