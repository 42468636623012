//Library
import React, { useEffect, useState } from "react";
import "../../../assets/css/SideNavBar.css";
import { useDispatch } from "react-redux";
import * as common from "../../CommonFunction/common-function";
import { Input, Button, FormGroup } from "reactstrap";
import Sidebar from "react-sidebar";
//Component
import * as CheckList from "./CriteriaList";
import * as FilterList from "./FilterList";
import * as CriteriaText from "./CriteriaText";
//Store
import * as ReportFilterStore from "../../../store/ReportFilterStore";

export const SideNavBar = ({ children, ...props }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const Icon = common.getIconTag(common.Icontype.ION, "IoIosArrowRoundForward");
  const onSetSidebarOpen = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const Search = () => {
    if (common.isFunction(props.Search)) {
      props.Search();
    }
  };
  return (
    <>
      <div>
        <Sidebar
          sidebar={
            <SideNavBarContent
              Search={Search}
              Toggle={onSetSidebarOpen}
              {...props}
            />
          }
          open={sidebarOpen}
          onSetOpen={onSetSidebarOpen}
          styles={{
            sidebar: {
              background: "#ffffff",
              position: "fixed",
              zIndex: 10001,
              minWidth: "300px",
              width: "40%",
              overflowX: "unset",
            },
          }}
        >
          <>
            <div className="body-page">{children}</div>
          </>
        </Sidebar>
        <button className="btn-sidebar" onClick={onSetSidebarOpen}>
          <Icon />
        </button>
      </div>
    </>
  );
};

export const SideNavBarContent = (props) => {
  //Redux
  const dispatch = useDispatch();
  //Props
  //Declare State

  const [dataItem2, setDataItem2] = useState({ FilterText: "" });
  //Effect
  useEffect(() => {
    ReportFilterStore.actionCreators.SearchEmployee(dispatch, {});
    ReportFilterStore.actionCreators.SearchCustomer(dispatch, {});
    ReportFilterStore.actionCreators.SearchMachine(dispatch, {});
    ReportFilterStore.actionCreators.SearchJobType(dispatch, {});
    ReportFilterStore.actionCreators.SearchJobStatus(dispatch, {});
    ReportFilterStore.actionCreators.SearchTool(dispatch, {});
    ReportFilterStore.actionCreators.SearchMachineModels(dispatch, {});
  }, [dispatch]);

  const handleInputChange2 = (event) => {
    const { name, value } = event.target;
    setDataItem2({ ...dataItem2, [name]: value });
    //dispatch({ type: ReportFilterStore.ActionType.criteriaChange, param: { ...dataItem, [name]: value } })
  };

  //SubComponent

  return (
    <>
      <div className="sidepanel">
        {/* Header */}
        <div className="row header-navbar">
          {/* Search TextBox */}
          <div className="col-12 col-md-12 search-sideNavbar">
            <p className="close-button" onClick={props.Toggle}>
              ×
            </p>
            <Input
              type="text"
              name="FilterText"
              id="FilterText"
              value={dataItem2.FilterText}
              onChange={handleInputChange2}
              bsSize="sm"
              placeholder="Search For"
            />
          </div>
        </div>
        {/* Content */}
        <div className="content-navbar">
          {/* Filter List */}
          <div className="innercontent-navbar">
            {props.PlandateFromTo ? (
              <BlockList collapse={props.collapse} Header="Job Plan Date">
                <CriteriaText.PlandateFromTo />
              </BlockList>
            ) : null}
            {props.Employee ? (
              <BlockList
                collapse={props.collapse}
                Header={
                  props.HeaderEmployee ? props.HeaderEmployee : "Engineer"
                }
              >
                <CheckList.EmployeeCheckbox FilterText={dataItem2.FilterText} />
              </BlockList>
            ) : null}
            {props.Customer ? (
              <BlockList collapse={props.collapse} Header="Customers">
                <CheckList.CustomerCheckbox FilterText={dataItem2.FilterText} />
              </BlockList>
            ) : null}
            {props.Machine ? (
              <BlockList collapse={props.collapse} Header="Machines">
                <CheckList.MachineCheckbox FilterText={dataItem2.FilterText} />
              </BlockList>
            ) : null}
            {props.MachineModels ? (
              <BlockList collapse={props.collapse} Header="Machine Models">
                <CheckList.MachineModelsCheckbox
                  FilterText={dataItem2.FilterText}
                />
              </BlockList>
            ) : null}
            {props.JobType ? (
              <BlockList collapse={props.collapse} Header="Job Type">
                <CheckList.JobTypeCheckbox FilterText={dataItem2.FilterText} />
              </BlockList>
            ) : null}
            {props.JobStatus ? (
              <BlockList collapse={props.collapse} Header="Job Status">
                <CheckList.JobStatusCheckbox
                  FilterText={dataItem2.FilterText}
                />
              </BlockList>
            ) : null}
            {props.Tools ? (
              <BlockList collapse={props.collapse} Header="Tools Type">
                <CheckList.ToolCheckbox FilterText={dataItem2.FilterText} />
              </BlockList>
            ) : null}
            {props.PONumber ? (
              <BlockList collapse={props.collapse} Header="PO Number">
                <CriteriaText.PONumberTextBox />
              </BlockList>
            ) : null}
            {props.Plandate ? (
              <BlockList collapse={props.collapse} Header="Plandate">
                <CriteriaText.PlanDateBox />
              </BlockList>
            ) : null}
            {props.SerialNo ? (
              <BlockList collapse={props.collapse} Header="Serial Number">
                <CriteriaText.SerialNoTextBox />
              </BlockList>
            ) : null}
            {props.RequestDate ? (
              <BlockList collapse={props.collapse} Header="Request date">
                <CriteriaText.RequestDateBox />
              </BlockList>
            ) : null}
            {props.DueDate ? (
              <BlockList collapse={props.collapse} Header="Due date">
                <CriteriaText.DueDateBox />
              </BlockList>
            ) : null}
            {props.Tags ? (
              <BlockList collapse={props.collapse} Header="Tags">
                <CriteriaText.TagsTextBox />
              </BlockList>
            ) : null}
          </div>
        </div>
        {/* Footer */}
        <div className="footer-navbar">
          {/* Criteria */}
          <div className="criteria-navbar">
            <div className="border-solid criteria-SideNavbar">
              Criteria
              <button
                className="btn-transparent clear-all-button"
                onClick={() => {
                  dispatch({
                    type: ReportFilterStore.ActionType.ClearFilter,
                  });
                  setDataItem2({ FilterText: "" });
                }}
              >
                Clear All
              </button>
              <div className="grid-container">
                <FilterList.ListEmployeeFilter />
                <FilterList.ListCustomerFilter />
                <FilterList.ListMachineFilter />
                <FilterList.ListMachineModelsFilter />
                <FilterList.ListJobTypeFilter />
                <FilterList.ListJobStatusFilter />
                <FilterList.ListToolFilter />
              </div>
            </div>
          </div>
          {/* Button Search */}
          <div className="div-search-navbar">
            <Button
              className="btn btn-default btnDefault btn-Temp"
              onClick={props.Search}
            >
              Search
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export const BlockList = ({ collapse = false, ...props }) => {
  const [show, setShow] = useState(false);
  const [style, setStyle] = useState();
  const IconArrowDown = common.getIconTag(
    common.Icontype.ION,
    "IoIosArrowDown"
  );
  const IconArrowUp = common.getIconTag(common.Icontype.ION, "IoIosArrowUp");
  const EnabledBox = () => {
    setShow(!show);
  };
  useEffect(() => {
    setShow(collapse);
  }, [collapse]);
  useEffect(() => {
    if (!show) {
      setStyle({ display: "block" });
    } else {
      setStyle({ display: "none" });
    }
  }, [show]);

  return (
    <React.Fragment>
      <ul className="sidebar-navigation">
        <li className="header" onClick={EnabledBox}>
          <div className="row">
            <div className="col-10">{props.Header}</div>
            <div className="col-2">
              {show ? <IconArrowDown /> : <IconArrowUp />}
            </div>
          </div>
        </li>
        <FormGroup style={style}>{props.children}</FormGroup>
      </ul>
      {/* <ul className="sidebar-navigation">
        <li className="header" onClick={EnabledBox}>
          {props.Header} {show ? <IconArrowDown /> : <IconArrowUp />}
        </li>
        <FormGroup style={style}>{props.children}</FormGroup>
      </ul> */}
    </React.Fragment>
  );
};
export default SideNavBar;
