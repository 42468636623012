import React from "react";
import { Container, Row, Col } from "reactstrap";
import MenuBox from "../UI/MenuBox";

export const Master = () => {
  return (
    <Container fluid>
      <Row>
        <Col sm="12" md="12" lg="12">
          <MenuBox data={menu} />
        </Col>
        <Col sm="12" md="12" lg="12">
          <MenuBox data={menu2} />
        </Col>
        <Col sm="12" md="12" lg="12">
          <MenuBox data={menu3} />
        </Col>
      </Row>
    </Container>
  );
};

const menu = [
  {
    link: "/master/user",
    icon: { type: "ion", name: "IoIosContact" },
    header: "Account",
    //, detail: "ผู้ใช้งาน"
  },
  {
    link: "/master/engineer",
    icon: { type: "ion", name: "IoIosContact" },
    header: "Engineer/Employee",
    //, detail: "วิศวกร/พนักงาน"
  },
  {
    link: "/master/skills",
    icon: { type: "ion", name: "IoMdFlashlight" },
    header: "Skills",
    //, detail: "ทักษะความรู้ในการปฏิบัติงาน"
  },
  {
    link: "/employee-leave",
    icon: { type: "ion", name: "IoIosCalendar" },
    header: "Employee Holiday Leave",
    //, detail: "Edit Employee maintenance schedule Mask disable/enable"
  }
  
];
const menu2 = [
  
  {
    link: "/master/customers",
    icon: { type: "ion", name: "IoIosCheckmarkCircle" },
    header: "Customers",
    //, detail: "ข้อมูลลูกค้า"
  },
  {
    link: "/master/machines",
    icon: { type: "ion", name: "IoMdCube" },
    header: "Machine",
    //, detail: "เครื่องทดสอบรุ่นต่างๆ"
  },
  {
    link: "/activate-machine",
    icon: { type: "ion", name: "IoIosCheckmarkCircle" },
    header: "Activate Machine",
    //, detail: "Edit customer's machine and activate with serial number"
  },
  {
    link: "/master/machines-categories",
    icon: { type: "ion", name: "IoIosJournal" },
    header: "Machine Categories",
    //, detail: "Edit customer's machine and activate with serial number"
  }
];
const menu3 = [
  
  {
    link: "/master/tools",
    icon: { type: "ion", name: "IoIosBuild" },
    header: "Tools",
    //, detail: "เครื่องมือช่าง เครื่องมือ Calibrate"
  },
  {
    link: "/activate-tools",
    icon: { type: "ion", name: "IoIosCheckmarkCircle" },
    header: "Activate Tools",
    //, detail: "Edit serial number's tools and activate"
  },
  {
    link: "/tool-maintenance",
    icon: { type: "ion", name: "IoIosCalendar" },
    header: "Tools Maintenance",
    //, detail: "Edit tools maintenance schedule Mask disable/enable"
  },
];
export default Master;
