import React, { useEffect, useState } from "react";
import ComboData from "./comboData";
import { useDispatch, useSelector } from "react-redux";
import * as comboStore from "../../../store/comboStore";
import { Typeahead } from "react-bootstrap-typeahead";
import { Input } from "reactstrap";
import SelectMachine from "./.././../addmodal/SelectMachine";
import * as common from "./../../CommonFunction/common-function";
export const Customer = (props) => {
  //Redux
  const dispatch = useDispatch();
  const CutomerList = useSelector((state) => state.ComboReducer.CustomerList);
  //Props
  //Declare State
  //Effect
  useEffect(() => {
    comboStore.actionCreators.GetCustomer(dispatch, props.Criteria || {});
  }, [props, dispatch]);
  //Event+function
  //SubComponent
  return (
    <Input type="select" className="form-control" {...props}>
      <ComboData data={CutomerList}></ComboData>
    </Input>
  );
};

export const ToolsType = ({ Criteria, ...props }) => {
  //Redux
  const dispatch = useDispatch();
  const ToolsTypeList = useSelector(
    (state) => state.ComboReducer.ToolsTypeList
  );
  //Props
  //Declare State
  //Effect
  useEffect(() => {
    comboStore.actionCreators.GetToolsType(dispatch, Criteria || {});
  }, [Criteria, dispatch]);
  //Event+function
  //SubComponent
  return (
    <Input type="select" className="form-control" {...props}>
      <ComboData data={ToolsTypeList}></ComboData>
    </Input>
  );
};
export const ToolsName = ({ Criteria, ...props }) => {
  //Redux
  const dispatch = useDispatch();
  const ToolsNameList = useSelector(
    (state) => state.ComboReducer.ToolsNameList
  );

  //Props
  //Declare State
  //Effect
  useEffect(() => {
    comboStore.actionCreators.GetToolsName(dispatch, Criteria || {});
  }, [props, dispatch]);
  //Event+function
  //SubComponent
  return (
    <Input type="select" className="form-control" {...props}>
      <ComboData data={ToolsNameList}></ComboData>
    </Input>
  );
};
export const ToolsSerial = ({ Criteria, ...props }) => {
  //Redux
  const dispatch = useDispatch();
  const ToolsSerialList = useSelector(
    (state) => state.ComboReducer.ToolsSerialList
  );
  //Props
  //Declare State
  //Effect
  useEffect(() => {
    comboStore.actionCreators.GetToolsSerialNo(dispatch, Criteria || {});
  }, [props, dispatch]);
  //Event+function
  //SubComponent
  return (
    <Input type="select" className="form-control" {...props}>
      <ComboData data={ToolsSerialList}></ComboData>
    </Input>
  );
};
export const MachineName = ({ setControlValue, ...props }) => {
  //Redux
  const dispatch = useDispatch();
  const MachineNameList = useSelector(
    (state) => state.ComboReducer.MachineNameList
  );

  //Props
  //Declare State
  const [isOpenMachine, setisOpenMachine] = useState(false);
  //Effect
  useEffect(() => {
    comboStore.actionCreators.GetMachineName(dispatch, props.Criteria || {});
  }, [props.Criteria, dispatch]);
  //Event+function
  //SubComponent
  const IconSearch = common.getIconTag(common.Icontype.ION, "IoIosArrowDown");
  const getvalue = (val) => {
    setControlValue(props.name, val.MachineId);
  };

  return (
    <>
      <div className="row" style={{ marginLeft: "0", marginRight: "0" }}>
        <div className="col-11 col-md-11">
          <Input
            {...props}
            type="select"
            className="form-control selectDisableCaret"
            style={{ verticalAlign: "middle" }}
          >
            <ComboData data={MachineNameList} blankText=""></ComboData>
          </Input>
        </div>
        <div className="col-1 col-md-1">
          <button
            style={{ verticalAlign: "middle", fontSize: "30px" }}
            className="btn-transparent"
            onClick={(e) => {
              e.preventDefault();
              setisOpenMachine(true);
            }}
          >
            <IconSearch />
          </button>
        </div>
      </div>

      <SelectMachine
        isOpen={isOpenMachine}
        onCancel={() => setisOpenMachine(false)}
        GetValue={getvalue}
      />
    </>
  );
};
export const JobType = (props) => {
  //Redux
  const dispatch = useDispatch();
  const JobTypeList = useSelector((state) => state.ComboReducer.JobTypeList);
  //Props
  //Declare State
  //Effect
  useEffect(() => {
    comboStore.actionCreators.GetJobType(dispatch, props.Criteria || {});
  }, [props.Criteria, dispatch]);
  //Event+function
  //SubComponent
  return (
    <Input type="select" className="form-control" {...props}>
      <ComboData data={JobTypeList}></ComboData>
    </Input>
  );
};
export const WI = (props) => {
  //Redux
  const dispatch = useDispatch();
  const WIList = useSelector((state) => state.ComboReducer.WIList);
  //Props
  //Declare State
  //Effect
  useEffect(() => {
    comboStore.actionCreators.GetWIList(dispatch, props.Criteria || {});
  }, [props.Criteria, dispatch]);
  //Event+function
  //SubComponent
  return (
    <Input type="select" className="form-control" {...props}>
      <ComboData data={WIList}></ComboData>
    </Input>
  );
};
export const Employee = (props) => {
  //Redux
  const dispatch = useDispatch();
  const EmployeeList = useSelector((state) => state.ComboReducer.EmployeeList);
  //Props
  //Declare State
  //Effect
  useEffect(() => {
    comboStore.actionCreators.GetEmployee(dispatch, props.Criteria || {});
  }, [props, dispatch]);
  //Event+function
  //SubComponent
  return (
    <Input type="select" className="form-control" {...props}>
      <ComboData data={EmployeeList}></ComboData>
    </Input>
  );
};

export const CustomerType = (props) => {
  //Redux
  const dispatch = useDispatch();
  const CustomerTypeList = useSelector(
    (state) => state.ComboReducer.CustomerTypeList
  );
  //Props
  //Declare State
  //Effect
  useEffect(() => {
    comboStore.actionCreators.GetCustomerType(dispatch, props.Criteria || {});
  }, [props, dispatch]);
  //Event+function
  //SubComponent
  return (
    <Input type="select" className="form-control" {...props}>
      <ComboData data={CustomerTypeList}></ComboData>
    </Input>
  );
};
export const EmployeeType = (props) => {
  //Redux
  const dispatch = useDispatch();
  const EmployeeTypeList = useSelector(
    (state) => state.ComboReducer.EmployeeTypeList
  );
  //Props
  //Declare State
  //Effect
  useEffect(() => {
    comboStore.actionCreators.GetEmpType(dispatch, props.Criteria || {});
  }, [props, dispatch]);
  //Event+function
  //SubComponent
  return (
    <Input type="select" className="form-control" {...props}>
      <ComboData data={EmployeeTypeList}></ComboData>
    </Input>
  );
};

export const MachineType = (props) => {
  //Redux
  const dispatch = useDispatch();
  const MachineTypeList = useSelector(
    (state) => state.ComboReducer.MachineTypeList
  );
  //Props
  //Declare State
  //Effect
  useEffect(() => {
    comboStore.actionCreators.GetMachineType(dispatch, props.Criteria || {});
  }, [props, dispatch]);
  //Event+function
  //SubComponent
  return (
    <Input type="select" className="form-control" {...props}>
      <ComboData data={MachineTypeList}></ComboData>
    </Input>
  );
};

export const ToolLeaveType = (props) => {
  //Redux
  const dispatch = useDispatch();
  const ToolsLeaveTypeList = useSelector(
    (state) => state.ComboReducer.ToolsLeaveTypeList
  );
  //Props
  //Declare State
  //Effect
  useEffect(() => {
    comboStore.actionCreators.GetToolLeaveType(dispatch, props.Criteria || {});
  }, [props, dispatch]);
  //Event+function
  //SubComponent
  return (
    <Input type="select" className="form-control" {...props}>
      <ComboData data={ToolsLeaveTypeList}></ComboData>
    </Input>
  );
};

export const EmployeeLeaveType = (props) => {
  //Redux
  const dispatch = useDispatch();
  const EmpLeaveTypeList = useSelector(
    (state) => state.ComboReducer.EmpLeaveTypeList
  );
  //Props
  //Declare State
  //Effect
  useEffect(() => {
    comboStore.actionCreators.GetEmpLeaveType(dispatch, props.Criteria || {});
  }, [props, dispatch]);
  //Event+function
  //SubComponent
  return (
    <Input type="select" className="form-control" bsSize="sm" {...props}>
      <ComboData data={EmpLeaveTypeList}></ComboData>
    </Input>
  );
};

export const MachineModel = (props) => {
  //Redux
  const dispatch = useDispatch();
  const MachineModelList = useSelector(
    (state) => state.ComboReducer.MachineModelList
  );
  //Props
  //Declare State
  //Effect
  useEffect(() => {
    comboStore.actionCreators.GetMachineModel(dispatch, props.Criteria || {});
  }, [props, dispatch]);
  //Event+function
  //SubComponent
  return (
    <Input type="select" className="form-control" {...props}>
      <ComboData data={MachineModelList}></ComboData>
    </Input>
  );
};

export const MachineBrand = (props) => {
  //Redux
  const dispatch = useDispatch();
  const MachineBrandList = useSelector(
    (state) => state.ComboReducer.MachineBrandList
  );
  //Props
  //Declare State
  //Effect
  useEffect(() => {
    comboStore.actionCreators.GetMachineBrand(dispatch, props.Criteria || {});
  }, [props, dispatch]);
  //Event+function
  //SubComponent
  return (
    <Input type="select" className="form-control" {...props}>
      <ComboData data={MachineBrandList}></ComboData>
    </Input>
  );
};

export const OnsiteBy = (props) => {
  //Redux
  const dispatch = useDispatch();
  const ONSITE_BYList = useSelector(
    (state) => state.ComboReducer.ONSITE_BYList
  );
  //Props
  //Declare State
  //Effect
  useEffect(() => {
    comboStore.actionCreators.GetOnsiteBy(dispatch, props.Criteria || {});
  }, [props, dispatch]);
  //Event+function
  //SubComponent
  return (
    <Input type="select" className="form-control" {...props}>
      <ComboData data={ONSITE_BYList}></ComboData>
    </Input>
  );
};
export const MachineCategoryType = (props) => {
  //Redux
  const [Data, setData] = useState([]);
  //Props
  //Declare State
  //Effect
  useEffect(() => {
    (async function(){
      let data = await comboStore.actionCreators.GetCategoryType()
      setData(data)
    })()
  }, [setData]);
  //Event+function
  //SubComponent
  return (
    <Input type="select" className="form-control" {...props}>
      <ComboData data={Data}></ComboData>
    </Input>
  );
};
export const Company = (props) => {
  //Redux
  const dispatch = useDispatch();
  const CompanyList = useSelector((state) => state.ComboReducer.CompanyList);
  //Props
  //Declare State
  //Effect
  useEffect(() => {
    comboStore.actionCreators.GetCompany(dispatch, props.Criteria || {});
  }, [props, dispatch]);
  //Event+function
  //SubComponent
  return (
    <Input type="select" className="form-control" {...props}>
      <ComboData data={CompanyList}></ComboData>
    </Input>
  );
};

// Auto Complete
export const AutoCompleteCustomerContact = (props) => {
  //Redux
  const dispatch = useDispatch();
  const CustomerContactList = useSelector(
    (state) => state.ComboReducer.CustomerContactList
  );
  //Props
  //Declare State
  //Effect
  useEffect(() => {
    comboStore.actionCreators.GetCustomerContact(
      dispatch,
      props.Criteria || {}
    );
  }, [props, dispatch]);
  //Event+function
  //SubComponent
  return (
    <React.Fragment>
      <Typeahead
        labelKey="ContactPerson"
        id="Sequence"
        options={CustomerContactList}
        {...props}
        selectHintOnEnter
        disabled={props.readOnly}
      />
    </React.Fragment>
  );
};

export default Customer;
