//Library
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, FormGroup, Label, Input } from "reactstrap";
import * as common from "./../CommonFunction/common-function";
import { disableEdit } from "./../CommonFunction/common-function";
import { useForm, useFormContext, FormContext } from "react-hook-form";
import * as yup from "yup";
//Component
import MainScreen from "../UI/MainScreen";
import CommonTable from "../UI/CommonTables";
import ResultBox from "../UI/ResultBox";
import Modal from "../UI/MainModal";
import * as Combo from "../UI/combobox/Combo";
import { AutoComplete } from "../UI/combobox";
import CriteriaBox from "../UI/CriteriaBox";
//Store
import * as MasterStore from "../../store/MasterStore";
import FormRowInput from "../UI/FormDetail";
import { PerissionScreenContext } from "./../Permission";
const ValidateEngineerSchema = yup.object().shape({
  EmployeeType: yup
    .string()
    .nullable()
    .trim()
    .required("This field is required."),
  EmployeeCode: yup.string().trim().required("This field is required."),
  EmployeeName: yup.string().trim().required("This field is required."),
  EmployeeNameTh: yup.string().trim().required("This field is required."),
  Telephone: yup.string().trim().required("This field is required."),
  Email: yup
    .string()
    .email("Invalid format")
    .required("This field is required."),
});
export const EngineerMaster = () => {
  //Redux Global
  const CriteriaForm = useForm();
  const EngineerForm = useForm({
    validationSchema: ValidateEngineerSchema,
  });
  const { Function: fn_lst } = useContext(PerissionScreenContext);
  const [isOpen, setIsOpen] = useState(false);
  const criteria = useSelector((state) => state.MasterReducer.SearchCriteria);
  const dispatch = useDispatch();
  const Add = () => {
    setIsOpen(true);
  };
  const search = () => {
    MasterStore.actionCreators.SearchEmployee(dispatch, criteria);
  };
  const clear = () => {
    dispatch({ type: MasterStore.ActionType.criteriaClear });
  };
  return (
    <MainScreen>
      <FormContext
        {...{ CriteriaForm: CriteriaForm, EngineerForm: EngineerForm }}
      >
        <CriteriaBox
          onSearch={search}
          hiddenClear={false}
          onClear={clear}
          hidden={true}
          hiddenClose={true}
        >
          <EngineerCriteriaSearch search={search} />
        </CriteriaBox>
        <ResultBox
          HeaderText="Engineer/Employee"
          ButtonAddHidden={disableEdit(fn_lst)}
          onButtonAddClick={Add}
        >
          <EngineerTable />
        </ResultBox>
        <EngineerModal
          isOpen={isOpen}
          onCancel={() => {
            setIsOpen(false);
          }}
        />
      </FormContext>
    </MainScreen>
  );
};
export const EngineerCriteriaSearch = (props) => {
  const SearchCriteria = useSelector(
    (state) => state.MasterReducer.SearchCriteria
  );
  const [dataItem, setDataItem] = useState(SearchCriteria);
  const dispatch = useDispatch();

  useEffect(() => {
    setDataItem(SearchCriteria);
  }, [SearchCriteria]);
  useEffect(() => {}, [dataItem]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDataItem({ ...dataItem, [name]: value });
    dispatch({
      type: MasterStore.ActionType.criteriaChange,
      param: { ...dataItem, [name]: value },
    });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      props.search();
    }
  };
  return (
    <React.Fragment>
      <Form
        className="criteria-div"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormGroup row>
          <Label className="Label-font" for="SearchFor" size="sm">
            Search For
          </Label>
          <Col md={4}>
            <Input
              type="text"
              bsSize="sm"
              name="FullText"
              id="FullText"
              value={dataItem.FullText}
              onKeyPress={handleKeyPress}
              onChange={handleInputChange}
            />
          </Col>
        </FormGroup>
      </Form>
    </React.Fragment>
  );
};
export const EngineerTable = () => {
  //Redux
  const dispatch = useDispatch();
  const criteria = useSelector((state) => state.MasterReducer.EmployeeCriteria);
  const EmployeeList = useSelector((state) => state.MasterReducer.EmployeeList);
  const { Function: fn_lst } = useContext(PerissionScreenContext);
  //Declare State
  const [isOpen, setIsOpen] = useState(false);
  //Effect
  useEffect(() => {
    Search();
  }, []);
  //Event+function
  const Search = () => {
    MasterStore.actionCreators.SearchEmployee(dispatch, criteria);
  };

  //SubComponent
  // const Iconmore = common.getIconTag(common.Icontype.ION, "IoIosMore");
  const Icondelete = common.getIconTag(common.Icontype.ION, "IoMdClose");
  const onClickCol = {
    onClick: async (e, column, columnIndex, row, rowIndex) => {
      if (disableEdit(fn_lst)) return;
      await MasterStore.actionCreators.GetEmployeeData(
        dispatch,
        row.EmployeeId
      );
      setIsOpen(true);
    },
  };

  const columns = [
    {
      dataField: "EmployeeType",
      text: "Type",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "EmployeeCode",
      text: "Code",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "EmployeeName",
      text: "Name",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "Telephone",
      text: "Tel",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "Email",
      text: "Email",
      sort: true,
      events: onClickCol,
    },
    //{
    //    dataField: 'a',
    //    text: '',
    //    formatter: (cell, row) => {
    //        return (
    //            <button onClick={async () => {
    //                await MasterStore.actionCreators.GetEmployeeData(dispatch, row.EmployeeId)
    //                setIsOpen(true)

    //            }} className="btn-transparent"><Iconmore /></button>
    //        );
    //    }
    //},
    {
      dataField: "b",
      text: "",
      formatter: (cell, row) => {
        return (
          <button
            onClick={() => {
              MasterStore.actionCreators
                .CheckValidateDeleteEmployee(row)
                .then((result) => {
                  if (result.isValid) {
                    common.ConfirmDelete(() => {
                      MasterStore.actionCreators.DeleteEmployee(
                        dispatch,
                        row.EmployeeId,
                        criteria
                      );
                    });
                  } else {
                    common.InformationOKDialog(
                      result.Messages && common.ArrayTextReduce(result.Messages)
                    );
                  }
                });
            }}
            className="btn-transparent"
            disabled={disableEdit(fn_lst)}
          >
            <Icondelete />
          </button>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <CommonTable
        keyField="EmployeeId"
        data={EmployeeList}
        columns={columns}
      />
      <EngineerModal
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
      />
    </React.Fragment>
  );
};

export const EngineerModal = (props) => {
  //Redux
  const dispatch = useDispatch();
  const EmployeeData = useSelector((state) => state.MasterReducer.EmployeeData);
  const criteria = useSelector((state) => state.MasterReducer.EmployeeCriteria);
  const { EngineerForm } = useFormContext();
  const { handleSubmit, getValues } = EngineerForm;
  const validate = async (data) => {
    let result = await MasterStore.actionCreators.CheckDupplicateEmployee(data);
    if (result === undefined) return;
    if (result.isValid) {
      return true;
    } else {
      common.InformationOKDialog(
        result.Messages && common.ArrayTextReduce(result.Messages)
      );
    }
  };
  const save = async () => {
    if (!(await validate(getdata()))) {
      return;
    }
    const save = () => {
      MasterStore.actionCreators.SaveEmployee(dispatch, getdata(), criteria);
    };
    common.SaveWithConfirm(save);
  };
  const [txtHeader, settxtHeader] = useState();
  useEffect(() => {
    let txt;
    if (!common.isEmptyStr(EmployeeData.EmployeeId)) {
      txt = "Edit ";
    } else txt = "Add ";
    settxtHeader(txt + "Employee");
  }, [EmployeeData]);
  const getdata = () => {
    return { ...EmployeeData, ...getValues(), UpdatedBy: common.GetUserId() };
  };
  return (
    <Modal
      isOpen={props.isOpen}
      onSave={handleSubmit(save)}
      onCancel={() => {
        props.onCancel();
        dispatch({ type: MasterStore.ActionType.ClearEmployeeData });
      }}
      ModalHeaderText={txtHeader}
    >
      <EmployeeDetail />
    </Modal>
  );
};

export const EmployeeDetail = () => {
  //Redux
  // const dispatch = useDispatch();
  const EmployeeData = useSelector((state) => state.MasterReducer.EmployeeData);
  const { EngineerForm } = useFormContext();
  const { register, setValue, errors } = EngineerForm;
  //Props
  //Declare State
  const [dataItem, setDataItem] = useState(EmployeeData);
  //Effect
  useEffect(() => {
    register({ name: "Tags" });
  });
  useEffect(() => {
    setDataItem(EmployeeData);
    setValue(common.setDataToFormHook(EmployeeData));
  }, [EmployeeData, setValue]);
  //Event+function
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDataItem({ ...dataItem, [name]: value });
  };
  const setControlValue = (name, value) => {
    setValue(name, value);
    setDataItem((prev) => ({ ...prev, [name]: value }));
  };
  //SubComponent
  return (
    <div>
      <FormRowInput label="Employee Type" required errors={errors.EmployeeType}>
        <Combo.EmployeeType
          name="EmployeeType"
          invalid={errors.EmployeeId ? true : false}
          innerRef={register}
          value={dataItem.EmployeeType || ""}
          onChange={handleInputChange}
          bsSize="sm"
        />
      </FormRowInput>
      <FormRowInput label="Employee Code" required errors={errors.EmployeeCode}>
        <Input
          type="text"
          name="EmployeeCode"
          id="EmployeeCode"
          maxLength="50"
          invalid={errors.EmployeeCode ? true : false}
          innerRef={register}
          bsSize="sm"
        />
      </FormRowInput>
      <FormRowInput label="Employee Name" required errors={errors.EmployeeName}>
        <Input
          type="text"
          name="EmployeeName"
          id="EmployeeName"
          maxLength="200"
          invalid={errors.EmployeeName ? true : false}
          innerRef={register}
          bsSize="sm"
        />
      </FormRowInput>
      <FormRowInput
        label="Employee Name TH"
        required
        errors={errors.EmployeeNameTh}
      >
        <Input
          type="text"
          name="EmployeeNameTh"
          id="EmployeeNameTh"
          maxLength="200"
          invalid={errors.EmployeeNameTh ? true : false}
          innerRef={register}
          bsSize="sm"
        />
      </FormRowInput>
      <FormRowInput label="Telephone" required errors={errors.Telephone}>
        <Input
          type="text"
          name="Telephone"
          id="Telephone"
          maxLength="20"
          invalid={errors.Telephone ? true : false}
          innerRef={register}
          bsSize="sm"
        />
      </FormRowInput>
      <FormRowInput label="Email" required errors={errors.Email}>
        <Input
          type="email"
          name="Email"
          id="Email"
          maxLength="100"
          invalid={errors.Email ? true : false}
          innerRef={register}
          bsSize="sm"
        />
      </FormRowInput>
      <FormRowInput label="Skill">
        <AutoComplete.MultiSkill
          name="Tags"
          id="Tags"
          selected={dataItem.Tags || []}
          setControlValue={setControlValue}
        />
      </FormRowInput>
      <FormRowInput label="Remark">
        <Input
          type="textarea"
          name="Remark"
          id="Remark"
          maxLength="500"
          style={{ height: 150 }}
          innerRef={register}
          onChange={handleInputChange}
        />
      </FormRowInput>
    </div>
  );
};
export default EngineerMaster;
