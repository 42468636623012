import React, { useState, useEffect } from "react";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Badge,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../../assets/css/NavMenu.css";
import * as common from "./../CommonFunction/common-function";
import { ChangePasswordModal } from "./../master/UserMaster";
import * as UserStore from "./../../store/UserStore";
import * as ReportFilterStore from "./../../store/ReportFilterStore";
export const Name = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const IconPerson = common.getIconTag(common.Icontype.ION, "IoIosPerson");
  const edit = async () => {
    await UserStore.actionCreators.GetUserDataByToken(dispatch);
    setIsOpen(true);
  };

  const checkForceChangePassword = async () => {
    let result = common.GetForceChangePassword();
    if (result) {
      await UserStore.actionCreators.GetUserDataByToken(dispatch);
      setIsOpen(true);
    }
  };

  useEffect(() => {
    checkForceChangePassword();
  }, [checkForceChangePassword]);
  return (
    <>
      <button onClick={edit} className="btn-transparent">
        <NavLink
          //tag={Link}

          className="text-light text-font-menubar"
          to=""
        >
          <IconPerson /> {common.GetUserId() || ""}
          <ChangePasswordModal
            isOpen={isOpen}
            onCancel={() => {
              setIsOpen(false);
            }}
          />
        </NavLink>
      </button>
    </>
  );
};
export const Conflict = () => {
  const JobConflictData = useSelector(
    (state) => state.JobMointorReducer.JobConflictData
  );
  return (
    <React.Fragment>
      {JobConflictData.length > 0 ? (
        <Badge color="danger" pill>
          {JobConflictData.length}
        </Badge>
      ) : null}
    </React.Fragment>
  );
};

export const NavMenu = () => {
  const [isOpen, setisOpen] = useState(false);
  const dispatch = useDispatch();
  const clearfilter = () => {
    dispatch({ type: ReportFilterStore.ActionType.ClearFilter });
  };
  const toggle = () => {
    setisOpen((lst) => !lst);
  };
  const logout = async () => {
    let { result } = await common.to(common.ConfirmDialog("Confirm logout?"));
    if (!result.value) return;
    common.SetUserData(null);
    common.GoHome();
  };

  return (
    <>
      <Navbar
        id="navmenu"
        className="navbar-expand-lg navbar-toggleable-lg box-shadow mb-3 bg-navbar"
      >
        <Container fluid>
          <NavbarBrand tag={Link} to="/dashboard" onClick={clearfilter}>
            <img
              src={require("./../../assets/Logo.png")}
              width="50"
              height="40"
            />
          </NavbarBrand>
          <NavbarToggler onClick={toggle} className="mr-2 custom-toggler" />
          <Collapse
            className="d-lg-inline-flex flex-lg-row-reverse"
            isOpen={isOpen}
            navbar
          >
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <NavLink
                  tag={Link}
                  className="text-light text-font-menubar"
                  to="/JobConflict"
                  onClick={clearfilter}
                >
                  Conflict
                  <Conflict />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  className="text-light text-font-menubar"
                  to="/dashboard"
                  onClick={clearfilter}
                >
                  Dashboard
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  className="text-light text-font-menubar"
                  to="/UpcomingList"
                  onClick={clearfilter}
                >
                  Pre-Plan
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  to="/master"
                  className="text-light text-font-menubar"
                >
                  Master Maintenance
                </NavLink>
              </NavItem>
              {/* <NavItem>
                                    <NavLink className="text-light text-font-menubar" >Account</NavLink>
                                </NavItem> */}
              <NavItem>
                <button
                  onClick={() => {
                    logout();
                  }}
                  className="btn-transparent"
                >
                  <NavLink
                    tag={Link}
                    className="text-light text-font-menubar"
                    to=""
                    disabled
                  >
                    Logout
                  </NavLink>
                </button>
              </NavItem>
              <NavItem>
                <Name />
              </NavItem>
            </ul>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};
export default NavMenu;
