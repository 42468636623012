import React, { useState, useEffect } from "react";
import { AddMultiRequestItem } from "./../addmodal/AddRequestItem";
import { ChildTable } from "../UI/CommonTables";
import { useDispatch, useSelector } from "react-redux";
import * as ServiceRequestStore from "./../../store/ServiceRequestStore";
import * as common from "./../CommonFunction/common-function";

function ServiceRequestItem() {
  //Redux
  const dispatch = useDispatch();
  const requestItem = useSelector(
    (state) => state.ServiceRequestReducer.Form.RequestItemData
  );
  const serviceRequestdata = useSelector(
    (state) => state.ServiceRequestReducer.Form.serviceRequestdata
  );
  //Props
  //Declare State
  const [isOpen, setIsOpen] = useState();
  const [CurrentItem, setCurrentItem] = useState();
  //Effect
  useEffect(() => {
    if (CurrentItem && CurrentItem.ServiceRequestId) {
      setIsOpen(true);
    }
  }, [CurrentItem]);
  //Event+function
  const Add = () => {
    setIsOpen(true);
  };
  const getValue = async (RequestItems) => {
    //Update
    if (!common.isEmptyArray(RequestItems)) {
      RequestItems = RequestItems.map((obj) => ({
        ...obj,
        ServiceRequestId: serviceRequestdata.ServiceRequestId,
      }));
    }
    await ServiceRequestStore.actionCreators.AddServiceRequestItem({
      ServiceRequestID: serviceRequestdata.ServiceRequestId,
      requestItems: RequestItems,
    });
    //Load Again
    load();
    setIsOpen(false);
  };
  const load = () => {
    ServiceRequestStore.actionCreators.getServiceRequestItem(
      dispatch,
      serviceRequestdata.ServiceRequestId
    );
  };
  //SubComponent
  const IoIosAdd = common.getIconTag(common.Icontype.ION, "IoIosAdd");
  //   const Iconmore = common.getIconTag(common.Icontype.ION, "IoIosMore");
  const Icondelete = common.getIconTag(common.Icontype.ION, "IoMdClose");
  //const dataRequestItem = requestItem && requestItem.map((item) => (
  //    <tr key={common.uuidv4()}>
  //        <td>{item.ItemNo}</td>
  //        <td>{item.ItemText}</td>
  //        <td>{item.UnitCount}</td>
  //        <td>{item.UnitAmount}</td>
  //        <td>
  //            <button className="btn-transparent" onClick={() => {
  //                setCurrentItem(item)
  //            }}><Iconmore /></button>
  //            <button onClick={() => {
  //                ServiceRequestStore.actionCreators.deleteServiceRequestItem(item)
  //                setTimeout(() => {
  //                    load()
  //                }, 1000);
  //            }} className="btn-transparent"><Icondelete /></button>
  //        </td>
  //    </tr>
  //))
  const columns = [
    {
      dataField: "ItemNo",
      text: "No.",
      sort: true,
    },
    {
      dataField: "ItemText",
      text: "Item",
      sort: true,
      formatter: (cell, row) => {
        return <p style={{ whiteSpace: "pre-line" }}>{row.ItemText}</p>;
      },
    },
    {
      dataField: "UnitCount",
      text: "Unit",
      sort: true,
    },
    {
      dataField: "UnitAmount",
      text: "Price/Unit",
      sort: true,
    },
    // {
    //     dataField: 'a',
    //     text: '',
    //     formatter: (cell, row) => {
    //         return (
    //             <button className="btn-transparent" onClick={() => {
    //                 if(serviceRequestdata.PreventEdit)
    //                     return
    //                 setCurrentItem(row)
    //             }} ><Iconmore /></button>
    //         );
    //     }
    // },
    {
      dataField: "b",
      text: "",
      formatter: (cell, row) => {
        return (
          <button
            onClick={async () => {
              if (serviceRequestdata.PreventEdit) return;
              await ServiceRequestStore.actionCreators.deleteServiceRequestItem(
                row
              );
              load();
            }}
            className="btn-transparent"
          >
            <Icondelete />
          </button>
        );
      },
    },
  ];

  return (
    <div
      className="serviceRequestItem"
      style={serviceRequestdata.PreventEdit ? { "pointer-events": "none" } : {}}
    >
      <div className="col-2 col-md-2">
        <button
          className="btn-transparent"
          hidden={
            common.isEmptyStr(serviceRequestdata.ServiceRequestId) ||
            serviceRequestdata.PreventEdit
          }
          onClick={Add}
        >
          <IoIosAdd className="icon" />
        </button>
      </div>
      <ChildTable
        keyField="ServiceRequestItemId"
        data={requestItem}
        columns={columns}
      />
      <AddMultiRequestItem
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(!isOpen);
          setCurrentItem();
        }}
        SelectedData={requestItem}
        GetValue={getValue}
      />
    </div>
  );
}

export default ServiceRequestItem;
