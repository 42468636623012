import * as Constants from "./constants";
import * as constant from "./../components/CommonFunction/constant";
import * as common from "./../components/CommonFunction/common-function";
import Axios from "axios";
//Store
import * as MasterStore from "../store/MasterStore";
import * as ReportFilterStore from "../store/ReportFilterStore";

export const ActionType = {
  CREATE: "CreateJobMointor",
  READ: "JobMointor_Read",
  READ_Confilct: "JobMointor_READ_Confilct",
  UPDATE: "UpdateJobMointor",
  DELETE: "DeleteJobMointor",
  CLEAR: "ClearJobMointor",
  fADD: "AddJobMointor",
  fView: "ViewJobMointor",
  LoadDetail: "JobMointor_LoadDetail",
  LoadEngineer: "JobMointor_LoadEngineer",
  LoadMachine: "JobMointor_LoadMachine",
  LoadTools: "JobMointor_LoadTools",
  LoadActualList: "JobMointor_LoadActualList",
  ActualListChange: "JobMointor_ActualListChange",
  LoadAttachment: "JobMointor_LoadAttachment",
  LoadInternalAttachment: "JobMointor_LoadInternalAttachment",
  LoadInternal: "JobMointor_LoadInternal",
  CLEAR_DETAIL: "JobMointor_ClearDetail",
  fChange: "JobMointor_FormChange",
  fActualyChange: "JobMointor_fActualyChange",
  fInternalChange: "JobMointor_fInternalChange",
  criteriaChange: "JobMointor_criteriaChange",
  criteriaClear: "JobMointor_criteriaClear",
};
const initial_state = {
  //State ของ reducer นี้ประกอบไปด้วย
  //1.
  JobMointorData: [],
  JobConflictData: [],
  Form: {
    Jobdata: {},
    Machine: [],
    Engineer: [],
    Tools: [],
    JobActualList: [],
    JobActualInputList: [],
    JobActualData: {
      JobActualId: "",
      JobId: "",
      JobStatus: "",
      ActualWorkFromDate: "",
      ActualWorkToDate: "",
      AcceptedBy: "",
      AcceptedDate: "",
      SignatureId: "",
      DocPicture1Id: "",
      DocPicture2Id: "",
      Remark1: "",
      Remark2: "",
      CreatedDate: "",
      CreatedBy: "",
      UpdatedDate: "",
      UpdatedBy: "",
    },
    FormActualInput: {},
    Internal: {
      JobId: "",
      ActionShort: "",
      Awareness: "",
      FollowUp: "",
      Opportunity: "",
      Remark: "",
      Link: "",
      CreatedDate: "",
      CreatedBy: "",
      UpdatedDate: "",
      UpdatedBy: "",
    },
    InternalPhoto: [],
    InternalInput: {},
  },
  SearchCriteriaInitial: {
    FullText: "",
  },
  SearchCriteria: {
    FullText: "",
  },
};

let defaultJobStatus = [
  constant.CLOSE,
  constant.COMMITED,
  constant.DRAFT,
  constant.ONDUTY,
  constant.PENDING,
  constant.CARRIED,
  //constant.PREPLAN,
  //constant.CALLED,
  //constant.CANCELED,
];

export const actionCreators = {
  LoadDetail: async (dispatch, JobMointorID) => {
    let url = `${Constants.API_URL}/api/Job/GetJob`;
    var promise1 = Axios.post(url, { id: JobMointorID })
      .then((response) => {
        if (response.data) {
          dispatch({ type: ActionType.LoadDetail, param: response.data });
        }
      })
      .catch((error) => {
        common.apiError(error);
      });

    url = `${Constants.API_URL}/api/Job/GetJobMachineList`;
    Axios.post(url, { id: JobMointorID })
      .then((response) => {
        if (response.data) {
          dispatch({ type: ActionType.LoadMachine, param: response.data });
        }
      })
      .catch((error) => {
        common.apiError(error);
      });
    url = `${Constants.API_URL}/api/Job/GetJobEmployeeAllocateList`;
    Axios.post(url, { id: JobMointorID })
      .then((response) => {
        if (response.data) {
          dispatch({ type: ActionType.LoadEngineer, param: response.data });
        }
      })
      .catch((error) => {
        common.apiError(error);
      });
    url = `${Constants.API_URL}/api/Job/GetJobToolsAllocateList`;
    Axios.post(url, { id: JobMointorID })
      .then((response) => {
        if (response.data) {
          dispatch({ type: ActionType.LoadTools, param: response.data });
        }
      })
      .catch((error) => {
        common.apiError(error);
      });

    actionCreators.GetJobActualList(dispatch, JobMointorID);

    actionCreators.LoadInternalAttachmentList(dispatch, JobMointorID);
    url = `${Constants.API_URL}/api/Job/GetJobInternal`;
    Axios.post(url, { id: JobMointorID })
      .then((response) => {
        if (response.data) {
          dispatch({ type: ActionType.LoadInternal, param: response.data });
        }
      })
      .catch((error) => {
        common.apiError(error);
      });

    return await promise1;
  },
  GetJobActualList: (dispatch, JobMointorID) => {
    let url = `${Constants.API_URL}/api/Job/GetJobActualList`;
    Axios.post(url, { id: JobMointorID })
      .then((response) => {
        if (response.data) {
          dispatch({ type: ActionType.LoadActualList, param: response.data });
        }
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  GetJob: (dispatch, JobMointorID) => {
    let url = `${Constants.API_URL}/api/Job/GetJob`;
    Axios.post(url, { id: JobMointorID })
      .then((response) => {
        if (response.data) {
          dispatch({ type: ActionType.LoadDetail, param: response.data });
        }
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  GetJobActual: (JobId, SerialNo) => {
    let url = `${Constants.API_URL}/api/Job/GetJobActual`;
    return Axios.post(url, { JobId, SerialNo })
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  LoadInternalAttachmentList: (dispatch, JobId) => {
    let url = `${Constants.API_URL}/api/Job/GetJobAttachmentList`;
    return Axios.post(url, { JobId: JobId, Section: "Internal" })
      .then((response) => {
        if (response.data) {
          dispatch({
            type: ActionType.LoadInternalAttachment,
            param: response.data,
          });
        }
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  LoadAttachmentList_Return: (criteria, fn) => {
    let url = `${Constants.API_URL}/api/Job/GetJobAttachmentList`;
    return Axios.post(url, criteria)
      .then((response) => {
        if (response.data) {
          fn(response.data);
        }
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  Search: (dispatch, criteria) => {
    let url = `${Constants.API_URL}/api/Job/GetJobList`;
    let new_criteria = criteria;
    if (common.isEmptyArray(criteria.JobStatus)) {
      new_criteria = { ...criteria, JobStatus: defaultJobStatus };
    }
    Axios.post(url, new_criteria)
      .then((response) => {
        if (response.data) {
          //let resultsort = response.data.sort((a, b) =>
          //  a.CreatedDate < b.CreatedDate ? 1 : -1
          //);
          dispatch({ type: ActionType.READ, param: response.data });
        }
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  SearchDefualtByEngineer: async (dispatch, criteria, employeeId) => {
    let url = `${Constants.API_URL}/api/Job/GetJobList`;
    let new_criteria = criteria;
    let currentEmployee =
      await MasterStore.actionCreators.GetEmployeeDataByEmpId(employeeId);
    if (currentEmployee) {
      // add criteria checkbox
      dispatch({
        type: ReportFilterStore.ActionType.AddEmployeeFilterList,
        param: currentEmployee,
      });
      // default criteria
      new_criteria = {
        ...criteria,
        JobStatus: defaultJobStatus,
        EngineerIds: [currentEmployee.EmployeeId],
      };
      Axios.post(url, new_criteria)
        .then((response) => {
          if (response.data) {
            dispatch({ type: ActionType.READ, param: response.data });
          }
        })
        .catch((error) => {
          common.apiError(error);
        });
    }
  },
  SearchConflict: (dispatch, criteria) => {
    let url = `${Constants.API_URL}/api/Job/GetJobConflictList`;
    return Axios.post(url, {})
      .then((response) => {
        if (response.data) {
          //let resultsort = response.data.sort((a, b) =>
          //  a.CreatedDate < b.CreatedDate ? 1 : -1
          //);
          dispatch({ type: ActionType.READ_Confilct, param: response.data });
        }
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  SendEmailActual: async (dispatch, JobId, isSendMail) => {
    let url = `${Constants.API_URL}/api/Job/CreateServiceReport`;
    if (isSendMail) {
      url = `${Constants.API_URL}/api/Job/CreateServiceReportEmail`;
    }
    var { result, error } = await common.to(Axios.post(url, { id: JobId }));
    if (error) {
      return;
    }
    if (result.data && !isSendMail) {
      common.PreviewReport(result.data, "ServiceReport.pdf");
      // common.DownloadReport(result.data, "ServiceReport.pdf");
    }
  },
  SendReportToEmailList: async (
    dispatch,
    JobId,
    EmailToList,
    EmailCcList,
    serviceReport,
    deliveryReport,
  ) => {
    let url = `${Constants.API_URL}/api/Job/CreateReportSendToEmail`;
    var { result, error } = await common.to(
      Axios.post(url, {
        JOB_ID: JobId,
        EMAIL_TO: EmailToList,
        EMAIL_CC: EmailCcList,
        SERVICE_REPORT: serviceReport,
        DELIVERY_REPORT: deliveryReport,
      })
    );
    if (error) {
      return;
    }

    return result.data;
  },
  MonitorInbox: async (MessageId) => {
    let url = `${Constants.API_NOTIFICATION}/api/Notification/MonitorInbox`;
    var { result, error } = await common.to(
      Axios.post(
        url,
        {
          MESSAGE_ID: MessageId,
        },
        {
          timeout: 60 * 1000, // 60 seconds
        }
      )
    );
    if (error) {
      if (error.code === "ECONNABORTED" && error.message.includes("timeout")) {
        return {
          timeout: true,
          message: error.message,
        };
      }
      return { timeout: false, message: error.message };
    }
    return result.data;
  },
  CheckValidateSendEmail: (jobId) => {
    let url = `${Constants.API_URL}/api/Job/ValidateServiceReport`;
    return common.to(Axios.post(url, { id: jobId }));
  },
  AddDeliveryReport: async (JobId) => {
    let url = `${Constants.API_URL}/api/Job/CreateDeliveryReport`;
    var { result, error } = await common.to(Axios.post(url, { id: JobId }));
    if (error) {
      return;
    }
    if (result.data) {
      if (result.data.ex) {
        common.InformationOKDialog(result.data.ex);
      }
      common.PreviewReport(result.data, "DeliveryReport.pdf");
      // common.DownloadReport(result.data, "DeliveryReport.pdf");
    }
  },
  CheckCanClose: async (Jobdata) => {
    let url = `${Constants.API_URL}/api/Job/ValidateCloseJob`;
    return common.to(Axios.post(url, Jobdata));
  },
  SaveJob: (dispatch, Jobdata) => {
    let url = `${Constants.API_URL}/api/Job/UpdateJobInfo`;
    Axios.post(url, Jobdata)
      .then((response) => {
        if (response.data) {
          dispatch({ type: ActionType.LoadDetail, param: response.data });
        }
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  SaveActual: (dispatch, Actualdata) => {
    let url = `${Constants.API_URL}/api/Job/UpdateJobActual`;
    Axios.post(url, Actualdata)
      .then((response) => {
        if (response.data) {
        }
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  validate_actual: async (actuallst) => {
    let faillst = actuallst.filter((el) => {
      return !common.checkdateRange(el.ActualWorkFromDate, el.ActualWorkToDate);
    });

    if (!common.isEmptyArray(faillst)) {
      let serial = faillst.map((el) => el.SerialNo).join(",");
      let err_msg = `Invalid date range serial :${serial} `;
      common.InformationOKDialog(err_msg);
      return false;
    }

    for (var i = 0; i < actuallst.length; i++) {
      if (actuallst[i].Symtom && actuallst[i].Symtom.length > 1000) {
        common.InformationOKDialog(
          "[Job Actual Result :" +
            actuallst[i].SerialNo +
            "] The Symtom text is too long."
        );
        return false;
      }
      if (actuallst[i].ActionTaken && actuallst[i].ActionTaken.length > 2000) {
        common.InformationOKDialog(
          "[Job Actual Result :" +
            actuallst[i].SerialNo +
            "] Action Taken text is too long."
        );
        return false;
      }
      if (actuallst[i].Cause && actuallst[i].Cause.length > 1000) {
        common.InformationOKDialog(
          "[Job Actual Result :" +
            actuallst[i].SerialNo +
            "] The Cause text is too long."
        );
        return false;
      }
      if (actuallst[i].Result && actuallst[i].Result.length > 1000) {
        common.InformationOKDialog(
          "[Job Actual Result :" +
            actuallst[i].SerialNo +
            "] The Result text is too long."
        );
        return false;
      }
      if (actuallst[i].Comment && actuallst[i].Comment.length > 1000) {
        common.InformationOKDialog(
          "[Job Actual Result :" + actuallst[i].SerialNo + "] Comment too long."
        );
        return false;
      }
    }

    return true;
  },
  SaveActualList: (dispatch, ActualdataList) => {
    let url = `${Constants.API_URL}/api/Job/UpdateJobActualList`;
    return Axios.post(url, ActualdataList)
      .then((response) => {
        if (response.data) {
          dispatch({ type: ActionType.LoadActualList, param: response.data });
        }
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  SaveInternal: (dispatch, Internaldata) => {
    let url = `${Constants.API_URL}/api/Job/UpdateJobInternal`;
    Axios.post(url, Internaldata)
      .then((response) => {
        if (response.data) {
          dispatch({ type: ActionType.LoadInternal, param: response.data });
        }
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  saveAttachMedia: (dispatch, filedata, attatchdata) => {
    let url = `${Constants.API_URL_MEDIA}/api/Media/CreateMedia`;
    return Axios.post(url, filedata)
      .then(async (response) => {
        if (response.data) {
          attatchdata = {
            ...attatchdata,
            AttachmentName: response.data.Name,
            MediaId: response.data.Media_ID,
          };
          await actionCreators.saveJobAttachment(dispatch, attatchdata);
          return true;
          //dispatch({ type: ActionType.GetFileDocumentData, param: response.data });
        } else {
          return false;
        }
      })
      .catch((error) => common.apiError(error));
  },
  saveJobAttachment: (dispatch, data) => {
    let url = `${Constants.API_URL}/api/Job/AddJobAttachment`;
    return common.to(Axios.post(url, data));
  },
  saveJobAttachmentList: (dispatch, attachList) => {
    let url = `${Constants.API_URL}/api/Job/AddJobAttachmentList`;
    return Axios.post(url, attachList)
      .then((response) => {
        if (response.data) {
        }
      })
      .catch((error) => common.apiError(error));
  },
  saveSignatureActualList: (attach_list, actualList, isEngineer = false) => {
    let url = `${Constants.API_URL}/api/Job/SaveSignatureWithAttachmentList`;
    let param = {
      attachment: attach_list,
      actual: actualList,
      isEngineer: isEngineer,
    };
    return common.to(Axios.post(url, param));
  },
  SaveSignatureMedia: async (
    dispatch,
    signature,
    selected_serial,
    attatchdata,
    isEngineer
  ) => {
    var convert = signature.replace("data:image/png;base64,", "");
    let url = `${Constants.API_URL_MEDIA}/api/Media/CreateBase64File`;
    if (common.isEmptyStr(convert)) return;
    let { result, error } = await common.to(
      Axios.post(url, { base64: convert, typeFile: ".png" })
    );
    if (error) return;
    if (result.data) {
      attatchdata = {
        ...attatchdata,
        AttachmentName: result.data.Name,
        MediaId: result.data.Media_ID,
      };
    }
    if (!selected_serial) return;
    let attach_list = selected_serial.map((item) => {
      return { ...attatchdata, SerialNo: item };
    });
    let actual_list = selected_serial.map((item) => {
      return {
        JobId: attatchdata.JobId,
        SerialNo: item,
        CustomerIncharge: attatchdata.CustomerIncharge,
        UpdatedBy: attatchdata.UpdatedBy,
      };
    });
    await actionCreators.saveSignatureActualList(
      attach_list,
      actual_list,
      isEngineer
    );
    actionCreators.GetJobActualList(dispatch, attatchdata.JobId);
    actionCreators.GetJob(dispatch, attatchdata.JobId);
  },
  SaveFile64: async (dispatch, base64, file, attatchdata) => {
    let url = `${Constants.API_URL_MEDIA}/api/Media/CreateBase64File`;
    if (common.isEmptyStr(base64)) return;
    if (file && file.ext) {
      var { result, error } = await common.to(
        Axios.post(url, { base64: base64, typeFile: `.${file.ext}` })
      );
      if (error) {
        return false;
      }
      if (result) {
        attatchdata = {
          ...attatchdata,
          AttachmentName: `${file.fileName}.${file.ext}`,
          MediaId: result.data.Media_ID,
        };
        let { error: errorAttachment } = await actionCreators.saveJobAttachment(
          dispatch,
          attatchdata
        );
        if (errorAttachment) {
          return false;
        }
      }
      return true;
    }
  },
  DeleteAttachment: (dispatch, criteria) => {
    let url = `${Constants.API_URL}/api/Job/DeleteJobAttachment`;
    return Axios.post(url, criteria)
      .then((response) => {
        return response; //actionCreators.SearchEmployee(dispatch, criteria)
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
};

//reducer Name
export const JobMointorReducer = (
  state = JSON.parse(JSON.stringify(initial_state)),
  action
) => {
  switch (action.type) {
    case ActionType.READ:
      state.JobMointorData = action.param;
      break;
    case ActionType.READ_Confilct:
      state.JobConflictData = action.param;
      break;
    case ActionType.CLEAR_DETAIL:
      state.Form = initial_state.Form;
      break;
    case ActionType.CLEAR:
      state.SearchCriteria = initial_state.SearchCriteriaInitial;
      break;
    case ActionType.LoadDetail:
      state.Form.Jobdata = action.param;
      break;
    case ActionType.LoadMachine:
      state.Form.Machine = action.param;
      break;
    case ActionType.LoadEngineer:
      state.Form.Engineer = action.param;
      break;
    case ActionType.LoadTools:
      state.Form.Tools = action.param;
      break;
    case ActionType.LoadActualList:
      state.Form.JobActualList = action.param;
      state.Form.JobActualInputList = action.param;
      break;
    case ActionType.ActualListChange:
      state.Form.JobActualInputList = state.Form.JobActualInputList.map((el) =>
        el.JobId === action.param.JobId && el.SerialNo === action.param.SerialNo
          ? { ...action.param }
          : el
      );
      break;
    case ActionType.LoadAttachment:
      state.Form.ActualAttachment = action.param;
      break;
    case ActionType.LoadInternal:
      state.Form.Internal = action.param;
      state.Form.InternalInput = action.param;
      break;
    case ActionType.LoadInternalAttachment:
      state.Form.InternalPhoto = action.param;
      break;
    case ActionType.fActualyChange:
      state.Form.FormActualInput = action.param;
      break;
    case ActionType.fInternalChange:
      state.Form.InternalInput = action.param;
      break;
    case ActionType.criteriaChange:
      state.SearchCriteria = action.param;
      break;
    case ActionType.criteriaClear:
      state.SearchCriteria = initial_state.SearchCriteriaInitial;
      state.SearchCriteriaInitial = {
        ...initial_state.SearchCriteriaInitial,
        Ran: common.uuidv4(),
      };
      break;
    default:
      break;
  }
  return state;
};
