import React, { useState, useEffect } from "react";
import { Input, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import * as common from "./../CommonFunction/common-function";
import CreatableSelect from "react-select/creatable";
import * as MasterStore from "./../../store/MasterStore";
export default function Uploader({
  onUpload,
  disabled,
  hidden,
  acceptFile,
  hiddenTags,
  ...props
}) {
  //onUpload(FileInfo:obj,base64, Tags:[])
  const dispatch = useDispatch();
  const AllTags = useSelector((state) => state.MasterReducer.TagsAttachment);
  const [fileKey, setFileKey] = useState(Date.now());
  const [Tags, setTags] = useState();
  const [FileData, setFileData] = useState();
  useEffect(() => {
    MasterStore.actionCreators.GetAttachmentTagsList(dispatch);
  }, []);
  const Upload = async () => {
    var FileInfo = common.getFileNameWithExt2(FileData);
    var base64 = await common.uploadWithJSON(FileData);
    setFileKey(Date.now());
    if (common.isFunction(onUpload)) {
      let tag = Tags && Tags.map((el) => el.value || el.label || el);
      tag = tag && tag.join(",");
      await onUpload(FileInfo, base64, tag);
    }
    setTags([]);
    setFileData();
    MasterStore.actionCreators.GetAttachmentTagsList(dispatch);
  };
  const handleChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setFileData();
      return;
    }
    if (file.size > 30000000) {
      common.InformationOKDialog("file bigger than 30Mb");
      setFileKey(Date.now());
      return;
    }
    setFileData(file); // you get all the files object here
  };
  const handleChangetag = (newValue, actionMeta) => {
    setTags(newValue);
  };

  return (
    <div>
      {hidden ? null : (
        <>
          <Input
            type="file"
            onChange={handleChange}
            key={fileKey}
            disabled={disabled}
            accept={acceptFile}
          />
          {hiddenTags ? null : (
            <CreatableSelect
              isClearable
              isMulti
              onChange={handleChangetag}
              placeholder="Select Tags"
              value={Tags}
              options={AllTags}
              isDisabled={disabled}
              isH
            />
          )}

          <Button
            size="sm"
            outline
            color="primary"
            onClick={Upload}
            disabled={!FileData || disabled}
          >
            Upload!
          </Button>
        </>
      )}
    </div>
  );
}
