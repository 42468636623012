//Library
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";
import * as common from "../CommonFunction/common-function";
import { formatDate } from "../CommonFunction/common-function";
import { useForm, useFormContext, FormContext } from "react-hook-form";
import * as yup from "yup";
//Component
import MainScreen from "../UI/MainScreen";
import CommonTable from "../UI/CommonTables";
import ResultBox from "../UI/ResultBox";
import Modal from "../UI/MainModal";
import { Combo, AutoComplete } from "../UI/combobox";
import FormRowInput from "../UI/FormDetail";
import { DatePicker } from "../UI/DatePicker";
import { SideNavBar } from "../UI/SideNavBar/SideNavBar";
//Store
import * as EmployeeLeaveStore from "../../store/EmployeeLeaveStore";
import { PerissionScreenContext } from "./../Permission";
//React Hook Form guide
//1.import "react-hook-form" , "yup"
//2.create Validate schema ,creat fielddate name list ex const fieldDate = ['DateFrom','DateTo']
//3. create Form
//if has uncontrol add manual register ex.AutoComplete
//4.add to FormContext
//5.use Context in FormDetail
//6. innerRef , invalid , error
//7.onClick Save use Event handleSubmit(fn) for validate if Manual register on cancel use reset()
//8. getValues + getUser for send to api ex { ...Datadb, ...getValues(), UpdatedBy: common.GetUserId() };
//Manual Validate can use setError before save function
const ValidateHolidayLeaveSchema = yup.object().shape({
  EmployeeId: yup
    .string()
    .nullable()
    .trim()
    .required("This field is required."),
  LeaveType: yup.string().trim().required("This field is required."),
  LeaveStartDate: yup.string().trim().required("This field is required."),
  LeaveEndDate: yup.string().trim().required("This field is required."),
});
const fieldDate = ["LeaveStartDate", "LeaveEndDate"];
export const EmployeeLeave = () => {
  const dispatch = useDispatch();
  //Redux Global
  const CriteriaForm = useForm();
  const HolidayLeaveForm = useForm({
    validationSchema: ValidateHolidayLeaveSchema,
  });
  // const { Function: fn_lst } = useContext(PerissionScreenContext);
  //Props
  //Declare State
  const [isOpen, setIsOpen] = useState(false);
  //Effect
  useEffect(() => {
    HolidayLeaveForm.register({ name: "EmployeeId" });
  });
  //Event+function

  const Add = () => {
    setIsOpen(true);
  };

  const EmployeeLeaveList = useSelector(
    (state) => state.EmployeeLeaveReducer.EmployeeLeaveList
  );

  const EmployeeFilterList = useSelector(
    (state) => state.ReportFilterReducer.EmployeeFilterList
  );
  let criterianav = {
    EmployeeIds: EmployeeFilterList.map((el) => el.EmployeeId),
  };

  const searchnav = () => {
    EmployeeLeaveStore.actionCreators.SearchEmployeeLeave(
      dispatch,
      criterianav
    );
  };
  return (
    <>
      <SideNavBar
        Search={searchnav}
        Employee
        collapse
        HeaderEmployee={"Employee"}
      >
        <MainScreen>
          <FormContext
            {...{
              CriteriaForm: CriteriaForm,
              HolidayLeaveForm: HolidayLeaveForm,
            }}
          >
            <ResultBox
              HeaderText="Employee Leave"
              ButtonAddHidden={false}
              onButtonAddClick={Add}
            >
              <EmployeeLeaveTable dataTable={EmployeeLeaveList} />
            </ResultBox>
            <EmployeeLeaveModal
              isOpen={isOpen}
              onCancel={() => {
                setIsOpen(false);
              }}
            />
          </FormContext>
        </MainScreen>
      </SideNavBar>
    </>
  );
};
export const EmployeeLeaveTable = (props) => {
  //Redux
  const dispatch = useDispatch();
  const criteria = useSelector(
    (state) => state.EmployeeLeaveReducer.EmployeeLeaveCriteria
  );
  const { Function: fn_lst } = useContext(PerissionScreenContext);
  //Declare State
  const [isOpen, setIsOpen] = useState(false);
  //Effect
  useEffect(() => {
    EmployeeLeaveStore.actionCreators.SearchEmployeeLeave(dispatch, {});
  }, [dispatch]);
  //Event+function
  // const Search = () => {
  //   EmployeeLeaveStore.actionCreators.SearchEmployeeLeave(dispatch, criteria);
  // };
  //SubComponent
  const Icondelete = common.getIconTag(common.Icontype.ION, "IoMdClose");
  const onClickCol = {
    onClick: async (e, column, columnIndex, row, rowIndex) => {
      if (row.EmployeeId !== common.GetEmpId())
        if (common.disableEdit(fn_lst)) return;

      await EmployeeLeaveStore.actionCreators.GetEmployeeLeaveData(
        dispatch,
        row.HolidayLeaveId
      );

      setIsOpen(true);
    },
  };

  const columns = [
    {
      dataField: "EmployeeName",
      text: "Employee Name",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "LeaveType",
      text: "Leave Type",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "LeaveStartDate",
      text: "Leave From",
      sort: true,
      events: onClickCol,
      formatter: (cell, row) => {
        return formatDate(row.LeaveStartDate);
      },
    },
    {
      dataField: "LeaveEndDate",
      text: "Leave To",
      sort: true,
      events: onClickCol,
      formatter: (cell, row) => {
        return formatDate(row.LeaveEndDate);
      },
    },
    {
      dataField: "LeaveRemark",
      text: "Remark",
      sort: true,
      events: onClickCol,
    },
    //{
    //    dataField: 'b',
    //    text: '',
    //    formatter: (cell, row) => {
    //        return (
    //            <button onClick={async () => {
    //                await EmployeeLeaveStore.actionCreators.GetEmployeeLeaveData(dispatch, row.HolidayLeaveId)

    //                setIsOpen(true)

    //            }} className="btn-transparent"><Iconmore /></button>
    //        );
    //    }
    //},
    {
      dataField: "a",
      text: "",
      formatter: (cell, row) => {
        return (
          <button
            onClick={() => {
              common.ConfirmDelete(() => {
                EmployeeLeaveStore.actionCreators.DeleteEmployeeLeave(
                  dispatch,
                  row.HolidayLeaveId,
                  criteria
                );
              });
            }}
            className="btn-transparent"
            disabled={
              row.EmployeeId !== common.GetEmpId() && common.disableEdit(fn_lst)
            }
          >
            <Icondelete />
          </button>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <CommonTable
        keyField="HolidayLeaveId"
        data={props.dataTable}
        columns={columns}
      />
      <EmployeeLeaveModal
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
      />
    </React.Fragment>
  );
};

export const EmployeeLeaveModal = (props) => {
  //Redux
  const dispatch = useDispatch();
  const criteria = useSelector(
    (state) => state.EmployeeLeaveReducer.EmployeeLeaveCriteria
  );
  const EmployeeLeaveData = useSelector(
    (state) => state.EmployeeLeaveReducer.EmployeeLeaveData
  );
  const { HolidayLeaveForm } = useFormContext();
  const { handleSubmit, getValues, reset } = HolidayLeaveForm;
  //Props
  //Declare State
  const [txtHeader, settxtHeader] = useState();
  //Effect
  useEffect(() => {
    let txt;
    if (!common.isEmptyStr(EmployeeLeaveData.HolidayLeaveId)) {
      txt = "Edit ";
    } else txt = "Add ";
    settxtHeader(txt + "Employee Leave");
  }, [EmployeeLeaveData]);
  //Event+function
  const validate = async (data) => {
    //#todo check overlap
    let err = [];
    if (data.LeaveStartDate > data.LeaveEndDate) {
      err.push({
        type: "range",
        name: "LeaveEndDate",
        message: "invalid Range",
      });
    }
    if (!common.isEmptyArray(err)) {
      HolidayLeaveForm.setError(err);
      return false;
    }
    return true;
  };
  const save = async () => {
    if (!(await validate(getdata()))) {
      return;
    }
    const save = () => {
      EmployeeLeaveStore.actionCreators.SaveEmployeeLeave(
        dispatch,
        getdata(),
        criteria
      );
    };
    common.SaveWithConfirm(save);
  };
  const getdata = () => {
    return {
      ...EmployeeLeaveData,
      ...getValues(),
      UpdatedBy: common.GetUserId(),
    };
  };
  //SubComponent

  return (
    <Modal
      isOpen={props.isOpen}
      onSave={handleSubmit(save)}
      onCancel={() => {
        props.onCancel();
        reset();
        dispatch({
          type: EmployeeLeaveStore.ActionType.ClearEmployeeLeaveData,
        });
      }}
      ModalHeaderText={txtHeader}
    >
      <EmployeeLeaveDetail />
    </Modal>
  );
};

export const EmployeeLeaveDetail = () => {
  //Redux
  // const dispatch = useDispatch();
  const EmployeeLeaveData = useSelector(
    (state) => state.EmployeeLeaveReducer.EmployeeLeaveData
  );
  const { HolidayLeaveForm } = useFormContext();
  const { register, setValue, errors, getValues, clearError } =
    HolidayLeaveForm;
  const { Function: fn_lst } = useContext(PerissionScreenContext);
  //Props
  //Declare State
  const [dataItem, setDataItem] = useState(EmployeeLeaveData);
  const [JobList, setJobList] = useState([]);
  //Effect
  useEffect(() => {
    let empleave = EmployeeLeaveData;
    //For no Permission mode Add
    if (
      common.disableEdit(fn_lst) &&
      common.isEmptyStr(EmployeeLeaveData.HolidayLeaveId)
    ) {
      empleave = { ...EmployeeLeave, EmployeeId: common.GetEmpId() };
    }
    setDataItem(empleave);
    setValue(common.setDataToFormHook(empleave, fieldDate));
  }, [EmployeeLeaveData, fn_lst, setValue]);
  //Event+function
  const setControlValue = (name, value, isclearerr = true) => {
    setValue(name, value);
    setDataItem((prev) => ({ ...prev, [name]: value }));
    isclearerr && clearError(name);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDataItem((prev) => ({ ...prev, [name]: value }));
  };
  const JobAssignEmployeeChange = () => {
    const temp = { ...dataItem, ...getValues() };

    if (temp.EmployeeId && temp.LeaveStartDate && temp.LeaveEndDate) {
      let criteria = {
        EngineerIds: [temp.EmployeeId],
        DateFrom: temp.LeaveStartDate,
        DateTo: temp.LeaveEndDate,
      };
      if (
        !(
          common.checkdatesql(temp.LeaveStartDate) &&
          common.checkdatesql(temp.LeaveEndDate)
        )
      ) {
        return;
      }
      EmployeeLeaveStore.actionCreators
        .CheckJobAssignEmployee(criteria)
        .then((res) => setJobList(res));
    } else {
      setJobList([]);
    }
  };

  //SubComponent
  return (
    <div>
      <FormRowInput label="Employee Name" required errors={errors.EmployeeId}>
        <AutoComplete.Employee
          name="EmployeeId"
          invalid={errors.EmployeeId ? true : false}
          readOnly={common.disableEdit(fn_lst)}
          value={dataItem.EmployeeId || ""}
          setControlValue={setControlValue}
          onChange={JobAssignEmployeeChange}
        />
      </FormRowInput>
      <FormRowInput label="Leave Type" required errors={errors.LeaveType}>
        <Combo.EmployeeLeaveType
          name="LeaveType"
          innerRef={register}
          invalid={errors.LeaveType ? true : false}
          value={dataItem.LeaveType || ""}
          onChange={handleInputChange}
        />
      </FormRowInput>
      <FormRowInput label="Leave From" required errors={errors.LeaveStartDate}>
        <DatePicker
          name="LeaveStartDate"
          id="LeaveStartDate"
          innerRef={register}
          invalid={errors.LeaveStartDate ? true : false}
          onChange={JobAssignEmployeeChange}
        />
      </FormRowInput>
      <FormRowInput label="Leave To" required errors={errors.LeaveEndDate}>
        <DatePicker
          name="LeaveEndDate"
          id="LeaveEndDate"
          innerRef={register}
          invalid={errors.LeaveEndDate ? true : false}
          onChange={JobAssignEmployeeChange}
        />
      </FormRowInput>
      <FormRowInput label="Remark">
        <Input
          type="textarea"
          name="LeaveRemark"
          id="LeaveRemark"
          style={{ height: 130 }}
          innerRef={register}
        />
      </FormRowInput>
      {JobList && JobList.length > 0 ? <JobTable JobList={JobList} /> : null}
    </div>
  );
};
export const JobTable = (props) => {
  const columns = [
    {
      dataField: "JobNumber",
      text: "Job Number",
      sort: true,
    },
    {
      dataField: "DateFrom",
      text: "Date From",
      sort: true,
      formatter: (cell, row) => {
        return formatDate(row.DateFrom);
      },
    },
    {
      dataField: "DateTo",
      text: "Date To",
      sort: true,
      formatter: (cell, row) => {
        return formatDate(row.DateTo);
      },
    },
  ];

  return (
    <React.Fragment>
      <CommonTable keyField="JobId" data={props.JobList} columns={columns} />
    </React.Fragment>
  );
};

export default EmployeeLeave;
