import React, { useEffect } from "react";
import NavMenu from "./NavMenu";
import "../../assets/css/common-style.css";

export default (props) => {
  return (
    <>
      <NavMenu/>
      <div className="body-page">{props.children}</div>
    </>
  );
};
