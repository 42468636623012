import React, { useState, useEffect } from "react";
import {  useSelector } from 'react-redux'
import Modal from "../UI/MainModal";
import * as common from "./../CommonFunction/common-function";
import * as LinkModal from './../UI/LinkText/LinkModal'
import { FormRow } from "./../UI/FormDetail";
import { ChildTable } from "./../UI/CommonTables";
export const POModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const defaultProps = {
    onClosed: () => {},
  };
  const currentProps = {
    ...defaultProps,
    ...props,
  };
  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props]);

  return (
    <Modal
      isOpen={isOpen}
      SaveHidden={true}
      CancelHidden={true}
      onClosed={() => {
        currentProps.onClosed();
      }}
      ModalHeaderText="Service Information"
    >
      <PoDetail />
    </Modal>
  );
};
export const PoDetail = () => {
  //Redux
  const ServiceRequestData = useSelector(
    (state) => state.ServiceRequestReducer.Form.serviceRequestdata
  );
  const ServiceRequestAttachment = useSelector(state => state.ServiceRequestReducer.Form.ServiceRequestAttachment)

  return (
    <>
    <div>
      <FormRow label="PO No.">{ServiceRequestData.PoNumber}</FormRow>
      <FormRow label="PO Date">{common.formatDate(ServiceRequestData.PoDate)}</FormRow>
      <FormRow label="Type">{ServiceRequestData.JobType}</FormRow>
      <FormRow label="Company">{ServiceRequestData.Company}</FormRow>
      <FormRow label="Request Date">{common.formatDate(ServiceRequestData.RequestedDate)}</FormRow>
      <FormRow label="Due Date">{common.formatDate(ServiceRequestData.DueDate)}</FormRow>
      <FormRow label="Customer">{ServiceRequestData.CustomerName}</FormRow>
      <FormRow label="Contact Person">{ServiceRequestData.ContactPerson}</FormRow>
      <FormRow label="Tel.">{ServiceRequestData.ContactPhone}</FormRow>
      <FormRow label="Department">{ServiceRequestData.Department}</FormRow>
      <FormRow label="Email">{ServiceRequestData.ContactEmail}</FormRow>
      <FormRow label="Remark">{ServiceRequestData.Remark}</FormRow>
      <FormRow label="Document"></FormRow>
      <div>
          <div className="col-12 col-md-8 col-detailForm">
            {ServiceRequestAttachment &&
              ServiceRequestAttachment.map((item) => (
                <div key={common.uuidv4()}>
                  <LinkModal.MediaFileName
                    Display={item.AttachmentName}
                    FileNameLink={item.FileNameLink}
                    hidden = {true}
                  />
                </div>
              ))}
          </div>
        </div>
    </div>
    <hr/>
    <ServiceRequestItems/>
    </>
  );
};
const ServiceRequestItems = ()=>{
  const requestItem = useSelector(state => state.ServiceRequestReducer.Form.RequestItemData)
  const columns = [
    {
        dataField: 'ItemNo',
        text: 'No.',
        sort: true
    },
    {
        dataField: 'ItemText',
        text: 'Item',
        sort: true
    },
    {
        dataField: 'UnitCount',
        text: 'Unit',
        sort: true,
    },
    {
        dataField: 'UnitAmount',
        text: 'Price/Unit',
        sort: true,
    },
    
]
return (<ChildTable keyField="ServiceRequestItemId" data={requestItem} columns={columns} />)
}
export default POModal;
