import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReactTable } from "../UI/CommonTables";
import Modal from "../UI/MainModal";
import { Input, Button } from "reactstrap";
import * as common from "./../CommonFunction/common-function";

export const AddRequestItem = (props) => {
  //Redux
  // const requestItem = useSelector(
  //   (state) => state.ServiceRequestReducer.Form.RequestItemData
  // );
  //Props
  const defaultProps = {
    onClosed: () => {},
  };
  const currentProps = {
    ...defaultProps,
    ...props,
  };
  //Declare State
  const init = {
    ServiceRequestId: "",
    ItemNo: 0,
    ItemText: "",
    UnitCount: "",
    UnitAmount: "",
  };
  const [isOpen, setIsOpen] = useState(false);
  const [dataItem, setDataItem] = useState(init);
  //Effect
  useEffect(() => {
    setIsOpen(props.isOpen);
    if (props.SelectedData) setDataItem(props.SelectedData);
    else setDataItem(init);
  }, [props]);

  //Event+function
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDataItem({ ...dataItem, [name]: value });
  };
  const get = () => {
    props.GetValue(dataItem);
  };
  //SubComponent
  const header = (
    <React.Fragment>
      <th>Item</th>
      <th>Unit</th>
      <th>Price/Unit</th>
    </React.Fragment>
  );
  const row = (
    <tr key={dataItem.ItemNo}>
      <td>
        <Input
          type="text"
          name="ItemText"
          id="ItemText"
          value={dataItem.ItemText || ""}
          onChange={handleInputChange}
          bsSize="sm"
        />
      </td>
      <td>
        <Input
          type="number"
          name="UnitCount"
          id="UnitCount"
          value={dataItem.UnitCount || ""}
          onChange={handleInputChange}
          bsSize="sm"
        />
      </td>
      <td>
        <Input
          type="number"
          name="UnitAmount"
          id="UnitAmount"
          value={dataItem.UnitAmount || ""}
          onChange={handleInputChange}
          bsSize="sm"
        />
      </td>
    </tr>
  );

  return (
    <Modal
      onSave={get}
      isOpen={isOpen}
      onCancel={props.onCancel}
      onClosed={() => {
        currentProps.onClosed();
      }}
      SaveText="OK"
      ModalHeaderText="Add Service Request item"
    >
      <div className="row detail-form">
        <div className="col-12 col-md-12">
          <ReactTable Header={header} Row={row} />
        </div>
      </div>
    </Modal>
  );
};
export const AddMultiRequestItem = (props) => {
  //Redux
  const requestItem = useSelector(
    (state) => state.ServiceRequestReducer.Form.RequestItemData
  );
  //Props
  const defaultProps = {
    onClosed: () => {},
  };
  const currentProps = {
    ...defaultProps,
    ...props,
  };
  //Declare State
  const init = {
    ServiceRequestId: "",
    ItemNo: 1,
    ItemText: "",
    UnitCount: "",
    UnitAmount: "",
  };
  const [isOpen, setIsOpen] = useState(false);
  const [dataItem, setDataItem] = useState(init);
  const [RequestItems, setRequestItems] = useState([init]);
  //Effect
  useEffect(() => {
    setIsOpen(props.isOpen);
    if (props.SelectedData) setRequestItems(props.SelectedData);
    else setRequestItems(init);
  }, [props]);

  //Event+function
  const handleInputChange = (idx) => (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    // const newShareholders = RequestItems.map((el, Requestidx) => {
    //     if (idx !== Requestidx) return el;
    //     return { ...RequestItems, [name]: value };
    //   });
    // // const { name, value } = event.target
    setRequestItems((el) =>
      el.map((el, Requestidx) => {
        if (idx !== Requestidx) return el;
        return { ...el, [name]: value };
      })
    );
  };
  const handleRemoveShareholder = (idx) => () => {
    setRequestItems((el) => el.filter((s, sidx) => idx !== sidx));
  };
  const insert = (arr, index, newItem) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index),
  ];
  const handleKeyPress = (idx) => (event) => {
    const { name, value } = event.target;
    setDataItem({ ...dataItem, [name]: value });
    if (event.key === "Enter") {
      event.preventDefault();
      setRequestItems((el) => insert(el, idx + 1, init));
    }
  };
  const get = () => {
    props.GetValue(RequestItems);
  };
  //SubComponent
  const Icondelete = common.getIconTag(common.Icontype.ION, "IoMdClose");
  const header = (
    <React.Fragment>
      <th>No</th>
      <th>Item</th>
      <th>Unit</th>
      <th>Price/Unit</th>
    </React.Fragment>
  );
  const row = RequestItems.map((el, idx) => (
    <tr key={idx}>
      <td>{idx + 1}</td>
      <td>
        <Input
          type="textarea"
          name="ItemText"
          value={el.ItemText || ""}
          onChange={handleInputChange(idx)}
          bsSize="sm"
        />
      </td>
      <td>
        <Input
          type="number"
          name="UnitCount"
          value={el.UnitCount || ""}
          onChange={handleInputChange(idx)}
          onKeyPress={handleKeyPress(idx)}
          bsSize="sm"
        />
      </td>
      <td>
        <Input
          type="number"
          name="UnitAmount"
          value={el.UnitAmount || ""}
          onChange={handleInputChange(idx)}
          bsSize="sm"
          onKeyPress={handleKeyPress(idx)}
        />
      </td>
      <td>
        <button
          type="button"
          onClick={handleRemoveShareholder(idx)}
          className="btn-transparent"
        >
          <Icondelete />
        </button>
      </td>
    </tr>
  ));

  return (
    <Modal
      onSave={get}
      isOpen={isOpen}
      onCancel={props.onCancel}
      onClosed={() => {
        currentProps.onClosed();
      }}
      SaveText="OK"
      ModalHeaderText="Add Service Request item"
    >
      <div className="row detail-form">
        <div className="col-12 col-md-12">
          <ReactTable Header={header} Row={row} />
          {RequestItems.length === 0 ? (
            <Button
              type="button"
              onClick={() => setRequestItems((el) => el.concat(init))}
            >
              Add
            </Button>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};
export default AddRequestItem;
