//Library
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, FormGroup, Label, Input } from "reactstrap";
import * as common from "./../CommonFunction/common-function";

//Component
import MainScreen from "../UI/MainScreen";
import CommonTable from "../UI/CommonTables";
import ResultBox from "../UI/ResultBox";
import Modal from "../UI/MainModal";
import * as Combo from "../UI/combobox/Combo";
import CriteriaBox from "../UI/CriteriaBox";
//Store
import * as MasterStore from "../../store/MasterStore";
import FormRowInput from "../UI/FormDetail";
import { PerissionScreenContext } from "./../Permission";

export const CustomerMaster = () => {
  const [isOpen, setIsOpen] = useState(false);
  const criteria = useSelector((state) => state.MasterReducer.SearchCriteria);
  const dispatch = useDispatch();
  const { Function: fn_lst } = useContext(PerissionScreenContext);

  const Add = () => {
    setIsOpen(true);
  };
  const search = () => {
    MasterStore.actionCreators.SearchCustomer(dispatch, criteria);
  };
  const clear = () => {
    dispatch({ type: MasterStore.ActionType.criteriaClear });
  };
  return (
    <MainScreen>
      <CriteriaBox
        onSearch={search}
        hiddenClear={false}
        onClear={clear}
        hidden={true}
        hiddenClose={true}
      >
        <CustomerCriteriaSearch search={search} />
      </CriteriaBox>
      <ResultBox
        HeaderText="Customer"
        ButtonAddHidden={common.disableEdit(fn_lst)}
        onButtonAddClick={Add}
      >
        <CustomerTable></CustomerTable>
      </ResultBox>
      <CustomerModal
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
      ></CustomerModal>
    </MainScreen>
  );
};
export const CustomerCriteriaSearch = (props) => {
  const SearchCriteria = useSelector(
    (state) => state.MasterReducer.SearchCriteria
  );
  const [dataItem, setDataItem] = useState(SearchCriteria);
  const dispatch = useDispatch();

  useEffect(() => {
    setDataItem(SearchCriteria);
  }, [SearchCriteria]);
  useEffect(() => {}, [dataItem]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDataItem({ ...dataItem, [name]: value });
    dispatch({
      type: MasterStore.ActionType.criteriaChange,
      param: { ...dataItem, [name]: value },
    });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      props.search();
    }
  };
  return (
    <React.Fragment>
      <Form
        className="criteria-div"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormGroup row>
          <Label className="Label-font" for="SearchFor" size="sm">
            Search For
          </Label>
          <Col md={4}>
            <Input
              type="text"
              bsSize="sm"
              name="FullText"
              id="FullText"
              value={dataItem.FullText}
              onKeyPress={handleKeyPress}
              onChange={handleInputChange}
            />
          </Col>
        </FormGroup>
      </Form>
    </React.Fragment>
  );
};
export const CustomerTable = () => {
  const dispatch = useDispatch();
  const criteria = useSelector((state) => state.MasterReducer.CustomerCriteria);
  const CustomerList = useSelector((state) => state.MasterReducer.CustomerList);
  const { Function: fn_lst } = useContext(PerissionScreenContext);
  const search = () => {
    MasterStore.actionCreators.SearchCustomer(dispatch, criteria);
  };
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    search();
  }, []);

  // const Iconmore = common.getIconTag(common.Icontype.ION, "IoIosMore");
  const Icondelete = common.getIconTag(common.Icontype.ION, "IoMdClose");

  //const header = (
  //    <React.Fragment>
  //        <th className="th-table">Customer Type</th>
  //        <th className="th-table">Short Name</th>
  //        <th className="th-table">Full Name</th>
  //        <th className="th-table">Contact Name 1</th>
  //        <th className="th-table">Contact Name 2</th>
  //        <th className="th-table">{' '}</th>
  //    </React.Fragment>
  //)

  //const row = CustomerList && CustomerList.map(item => (
  //    <tr key={item.CustomerId} className="tr-table">
  //        <td>{item.CustomerType}</td>
  //        <td>{item.ShortName}</td>
  //        <td>{item.FullName}</td>
  //        <td>{item.ContactName1}</td>
  //        <td>{item.ContactName2}</td>
  //        <td><button onClick={() => {
  //            MasterStore.actionCreators.GetCustomerData(dispatch, item.CustomerId)
  //            setTimeout(() => {
  //                setIsOpen(true)
  //            }, 1000)
  //        }} className="btn-transparent"><Iconmore /></button>
  //            <button onClick={() => {
  //                common.ConfirmDelete(() => { MasterStore.actionCreators.DeleteCustomer(dispatch, item.CustomerId, criteria) })
  //            }} className="btn-transparent"><Icondelete /></button>
  //        </td>
  //    </tr>
  //))

  const onClickCol = {
    onClick: async (e, column, columnIndex, row, rowIndex) => {
      if (common.disableEdit(fn_lst)) return;
      MasterStore.actionCreators.GetCustomerData(dispatch, row.CustomerId);
      setIsOpen(true);
    },
  };

  const columns = [
    {
      dataField: "CustomerType",
      text: "Type",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "ShortName",
      text: "Short Name",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "FullName",
      text: "Full Name",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "ContactName1",
      text: "Contact Name 1",
      sort: true,
      events: onClickCol,
    },
    {
      dataField: "ContactName2",
      text: "Contact Name 2",
      sort: true,
      events: onClickCol,
    },
    //{
    //    dataField: 'a',
    //    text: '',
    //    formatter: (cell, row) => {
    //        return (
    //            <button onClick={async () => {
    //                await MasterStore.actionCreators.GetCustomerData(dispatch, row.CustomerId)
    //                setIsOpen(true)
    //            }} className="btn-transparent"><Iconmore /></button>
    //        );
    //    }
    //},
    {
      dataField: "b",
      text: "",
      formatter: (cell, row) => {
        return (
          <button
            onClick={() => {
              MasterStore.actionCreators
                .CheckValidateDeleteCustomer(row)
                .then((result) => {
                  if (result.isValid) {
                    common.ConfirmDelete(() => {
                      MasterStore.actionCreators.DeleteCustomer(
                        dispatch,
                        row.CustomerId,
                        criteria
                      );
                    });
                  } else {
                    common.InformationOKDialog(
                      result.Messages && common.ArrayTextReduce(result.Messages)
                    );
                  }
                });
            }}
            className="btn-transparent"
            disabled={common.disableEdit(fn_lst)}
          >
            <Icondelete />
          </button>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <CommonTable
        keyField="CustomerId"
        data={CustomerList}
        columns={columns}
      />
      <CustomerModal
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
      ></CustomerModal>
    </React.Fragment>
  );
};

export const CustomerModal = (props) => {
  //Redux
  const dispatch = useDispatch();
  const CustomerInput = useSelector(
    (state) => state.MasterReducer.CustomerInput
  );
  const criteria = useSelector((state) => state.MasterReducer.CustomerCriteria);

  const save = () => {
    MasterStore.actionCreators.SaveCustomer(
      dispatch,
      { ...CustomerInput, UpdatedBy: common.GetUserId() },
      criteria
    );
  };
  const [txtHeader, settxtHeader] = useState();
  useEffect(() => {
    let txt;
    if (CustomerInput.CustomerId !== "") {
      txt = "Edit ";
    } else txt = "Add ";
    settxtHeader(txt + "Customer");
  }, [CustomerInput]);

  return (
    <Modal
      isOpen={props.isOpen}
      onSave={() => {
        MasterStore.actionCreators
          .CheckDupplicateCustomer(CustomerInput)
          .then((result) => {
            if (result.isValid) {
              common.SaveWithConfirm(save);
            } else {
              common.InformationOKDialog(
                result.Messages && common.ArrayTextReduce(result.Messages)
              );
            }
          });
      }}
      onCancel={() => {
        props.onCancel();
        dispatch({ type: MasterStore.ActionType.ClearCustomerData });
      }}
      ModalHeaderText={txtHeader}
    >
      <CustomerDetail />
    </Modal>
  );
};

export const CustomerDetail = () => {
  //Redux
  const dispatch = useDispatch();
  const CustomerData = useSelector((state) => state.MasterReducer.CustomerData);
  const [dataItem, setDataItem] = useState(CustomerData);

  useEffect(() => {
    dispatch({ type: MasterStore.ActionType.GetCustomerForm, param: dataItem });
  }, [dispatch, dataItem]);

  useEffect(() => {
    setDataItem(CustomerData);
  }, [CustomerData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDataItem({ ...dataItem, [name]: value });
  };

  return (
    <div>
      <FormRowInput label="Customer Type">
        <Combo.CustomerType
          name="CustomerType"
          value={dataItem.CustomerType || ""}
          onChange={handleInputChange}
          bsSize="sm"
        />
      </FormRowInput>
      <FormRowInput label="Short Name" required>
        <Input
          type="text"
          name="ShortName"
          id="ShortName"
          maxLength="100"
          value={dataItem.ShortName || ""}
          onChange={handleInputChange}
          bsSize="sm"
          required
        />
      </FormRowInput>
      <FormRowInput label="Full Name" required>
        <Input
          type="text"
          name="FullName"
          id="FullName"
          maxLength="200"
          value={dataItem.FullName || ""}
          onChange={handleInputChange}
          bsSize="sm"
          required
        />
      </FormRowInput>
      <FormRowInput label="Full Name TH">
        <Input
          type="text"
          name="FullNameTh"
          id="FullNameTh"
          maxLength="200"
          value={dataItem.FullNameTh || ""}
          onChange={handleInputChange}
          bsSize="sm"
        />
      </FormRowInput>
      <FormRowInput label="Short Name TH">
        <Input
          type="text"
          name="ShortNameTh"
          id="ShortNameTh"
          maxLength="100"
          value={dataItem.ShortNameTh || ""}
          onChange={handleInputChange}
          bsSize="sm"
        />
      </FormRowInput>
      <FormRowInput label="Bill Address" required>
        <Input
          type="textarea"
          name="BillAddress"
          id="BillAddress"
          maxLength="500"
          style={{ height: 80 }}
          value={dataItem.BillAddress || ""}
          onChange={handleInputChange}
          required
        />
      </FormRowInput>
      <FormRowInput label="Certificate Address" required>
        <Input
          type="textarea"
          name="CertificateAddress"
          id="CertificateAddress"
          maxLength="500"
          style={{ height: 80 }}
          value={dataItem.CertificateAddress || ""}
          onChange={handleInputChange}
          required
        />
      </FormRowInput>
      <FormRowInput label="Contact Name 1">
        <Input
          type="text"
          name="ContactName1"
          id="ContactName1"
          maxLength="100"
          value={dataItem.ContactName1 || ""}
          onChange={handleInputChange}
          bsSize="sm"
        />
      </FormRowInput>
      <FormRowInput label="Contact Tel 1">
        <Input
          type="text"
          name="ContactTel1"
          id="ContactTel1"
          maxLength="50"
          value={dataItem.ContactTel1 || ""}
          onChange={handleInputChange}
          bsSize="sm"
        />
      </FormRowInput>
      <FormRowInput label="Department 1">
        <Input
          type="text"
          name="Department1"
          id="Department1"
          maxLength="100"
          value={dataItem.Department1 || ""}
          onChange={handleInputChange}
          bsSize="sm"
        />
      </FormRowInput>
      <FormRowInput label="Contact Email 1">
        <Input
          type="email"
          name="ContactEmail1"
          id="ContactEmail1"
          maxLength="100"
          value={dataItem.ContactEmail1 || ""}
          onChange={handleInputChange}
          bsSize="sm"
        />
      </FormRowInput>
      <FormRowInput label="Contact Name 2">
        <Input
          type="text"
          name="ContactName2"
          id="ContactName2"
          maxLength="100"
          value={dataItem.ContactName2 || ""}
          onChange={handleInputChange}
          bsSize="sm"
        />
      </FormRowInput>
      <FormRowInput label="Contact Tel 2">
        <Input
          type="text"
          name="ContactTel2"
          id="ContactTel2"
          maxLength="50"
          value={dataItem.ContactTel2 || ""}
          onChange={handleInputChange}
          bsSize="sm"
        />
      </FormRowInput>
      <FormRowInput label="Department 2">
        <Input
          type="text"
          name="Department2"
          id="Department2"
          maxLength="100"
          value={dataItem.Department2 || ""}
          onChange={handleInputChange}
          bsSize="sm"
        />
      </FormRowInput>
      <FormRowInput label="Contact Email 2">
        <Input
          type="email"
          name="ContactEmail2"
          id="ContactEmail2"
          maxLength="100"
          value={dataItem.ContactEmail2 || ""}
          onChange={handleInputChange}
          bsSize="sm"
        />
      </FormRowInput>
      <FormRowInput label="Remark">
        <Input
          type="textarea"
          name="Remark"
          id="Remark"
          maxLength="500"
          style={{ height: 130 }}
          value={dataItem.Remark || ""}
          onChange={handleInputChange}
        />
      </FormRowInput>
    </div>
  );
};

export default CustomerMaster;
