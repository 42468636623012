//Library
import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
//Component
import Modal from "../UI/MainModal";
import { FormRow } from "./../UI/FormDetail";
//Store
export const CustomerModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const defaultProps = {
    onClosed: () => {},
  };
  const currentProps = {
    ...defaultProps,
    ...props,
  };
  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props]);

  return (
    <Modal
      isOpen={isOpen}
      SaveHidden={true}
      CancelHidden={true}
      onClosed={() => {
        currentProps.onClosed();
      }}
      ModalHeaderText="Customer Information"
    >
      <CustomerDetail/>
    </Modal>
  );
};
export const CustomerDetail = () => {
  //Redux
  const CustomerData = useSelector(
    (state) => state.MasterReducer.CustomerData
  );
  

  return (
    <>
      <FormRow label="Customer Type">{CustomerData.CustomerType || ''}</FormRow>
      <FormRow label="Short Name">{CustomerData.ShortName || ''}</FormRow>
      <FormRow label="Full Name">{CustomerData.FullName || ''}</FormRow>
      <FormRow label="Full Name TH">{CustomerData.FullNameTh || ''}</FormRow>
      <FormRow label="Short Name TH">{CustomerData.ShortNameTh || ''}</FormRow>
      <FormRow label="Bill Address">{CustomerData.BillAddress || ''}</FormRow>
      <FormRow label="Certificate Address">{CustomerData.CertificateAddress || ''}</FormRow>
      <FormRow label="Contact Name 1">{CustomerData.ContactName1 || ''}</FormRow>
      <FormRow label="Contact Tel 1">{CustomerData.ContactTel1 || ''}</FormRow>
      <FormRow label="Department 1">{CustomerData.Department1 || ''}</FormRow>
      <FormRow label="Contact Email 1">{CustomerData.ContactEmail1 || ''}</FormRow>
      <FormRow label="Contact Name 2">{CustomerData.ContactName2 || ''}</FormRow>
      <FormRow label="Contact Tel 2">{CustomerData.ContactTel2 || ''}</FormRow>
      <FormRow label="Department 2">{CustomerData.Department1 || ''}</FormRow>
      <FormRow label="Contact Email 2">{CustomerData.ContactEmail2 || ''}</FormRow>
      <FormRow label="Remark">{CustomerData.CustomerType || ''}</FormRow>
    </>
  );
};
export default CustomerModal;
