import React from 'react';
function ComboData({data,blankText="-- SELECT --"}){
    const filteredArr = data&&data.reduce((acc, current) => {
        const x = acc.find(item => item.value === current.value);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    return(
        <React.Fragment>
            <option value="">{blankText}</option>
            {filteredArr && filteredArr.map(item => (
                <option key={item.value} value={item.value}>{item.label}</option>
            ))}
        </React.Fragment>
    )
}

export default ComboData