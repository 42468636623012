import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import * as common from "../CommonFunction/common-function";
const ResultBox = (props) => {
  const defaultProps = {
    HeaderText: "Header Text Result Box",
    onButtonAddClick: () => {},
    ButtonAddHidden: true,
    headcomponent: () => {
      return <></>;
    },
  };
  const currentProps = {
    ...defaultProps,
    ...props,
  };
  const Addtag = common.getIconTag("ion", "IoIosAdd");

  return (
    <Card className="card-br">
      <CardBody>
        <CardTitle>
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <span className="topic-table">{currentProps.HeaderText}</span>
            </div>
            <div className="col-xs-12 col-md-6 btnBoxResult">
              <div className="row justify-content-end">
                {currentProps.headcomponent()}
                <div className="col-xs-12 col-md-auto text-right">
                  <button
                    className="btn-transparent"
                    hidden={currentProps.ButtonAddHidden}
                    onClick={currentProps.onButtonAddClick}
                  >
                    {" "}
                    <Addtag />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </CardTitle>
        {props.children}
      </CardBody>
    </Card>
  );
};
export default ResultBox;
