import React, { useState, useEffect } from "react";
import Modal from "../UI/MainModal";
import * as common from "../CommonFunction/common-function";
import { useDispatch, useSelector } from "react-redux";
import * as AddComponentStore from "../../store/AddComponentStore";
import { Input, CustomInput } from "reactstrap";
import { ReactTable } from "../UI/CommonTables";
import FormRowInput from "../UI/FormDetail";
import * as Combo from "../UI/combobox/Combo";
import { PlandateDisplay } from "./../addmodal";
export const AddMachineModal = (props) => {
  //Props
  const defaultProps = {
    onClosed: () => {},
  };
  const currentProps = {
    ...defaultProps,
    ...props,
  };
  //Declare
  const [isOpen, setIsOpen] = useState(false);
  const [Serial, setSerial] = useState([]);
  const [dataItem, setDataItem] = useState({ FullText: "" });
  //Redux
  const dispatch = useDispatch();
  const Machine = useSelector((state) => state.AddComponentReducer.MachineList);
  //Effect
  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props]);
  useEffect(() => {
    AddComponentStore.actionCreators.GetMachine(dispatch, {
      ...dataItem,
      CustomerId: props.CustomerID || "",
    });
  }, [props, dataItem, dispatch]);
  useEffect(() => {
    //GetValueSelected
    setSerial(props.SelectedData);
  }, [props.SelectedData]);
  //Event
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDataItem({ ...dataItem, [name]: value });
  };
  const get = () => {
    props.GetValue(Serial);
    // props.onCancel()
  };
  const handleChange = (e, SerialNo) => {
    if (e.target.checked) {
      setSerial(Serial.concat(SerialNo));
    } else {
      setSerial(
        Serial.filter((id) => {
          return id !== SerialNo;
        })
      );
    }
  };

  //SubComponent

  const header = (
    <React.Fragment>
      <th></th>
      <th>Serial No</th>
      <th>Name</th>
      <th>Model</th>
    </React.Fragment>
  );

  const row =
    Machine &&
    Machine.map((item) => (
      <tr key={common.uuidv4()}>
        <td>
          {" "}
          <CustomInput
            type="checkbox"
            className="table-checkbox"
            id={item.SerialNo || common.uuidv4()}
            checked={Serial.includes(item.SerialNo)}
            onChange={(e) => {
              handleChange(e, item.SerialNo);
            }}
          />
        </td>
        <td>{item.SerialNo}</td>
        <td>{item.MachineName}</td>
        <td>{item.MachineModel}</td>
      </tr>
    ));

  return (
    <Modal
      onCancel={currentProps.onCancel}
      isOpen={isOpen}
      onSave={get}
      SaveText="OK"
      ModalHeaderText={`Add Machines ${PlandateDisplay(
        props.Jobdata && props.Jobdata.DateFrom,
        props.Jobdata && props.Jobdata.DateTo
      )} `}
      onClosed={() => {
        currentProps.onClosed();
      }}
    >
      <FormRowInput label="Machine">
        <Input
          type="text"
          name="FullText"
          id="FullText"
          value={dataItem.FullText}
          onChange={handleInputChange}
          bsSize="sm"
        />
      </FormRowInput>
      <ReactTable Header={header} Row={row} />
    </Modal>
  );
};

export const EditMachineModal = (props) => {
  //Props
  const defaultProps = {
    onClosed: () => {},
  };
  const currentProps = {
    ...defaultProps,
    ...props,
  };
  //Declare
  const [isOpen, setIsOpen] = useState(false);
  const [dataItem, setDataItem] = useState({
    JobId: "",
    SerialNo: "",
    CalPoint: "",
    WiId: "",
    Resolution: "",
    Uncertainly: "",
    Certificate: "",
  });
  //Redux
  //Effect
  useEffect(() => {
    if (props.Data) {
      setDataItem(props.Data);
    }

    setIsOpen(props.isOpen);
  }, [props]);
  //Event
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDataItem({ ...dataItem, [name]: value });
  };
  const get = () => {
    props.GetValue(dataItem);
    // props.onCancel()
  };
  const handleChange = (e) => {
    const { name, checked } = e.target;
    setDataItem({ ...dataItem, [name]: checked });
  };

  //SubComponent

  const header = (
    <React.Fragment>
      <th>Serial No</th>
      <th>Name</th>
      <th>Model</th>
      <th>CalPoint</th>
      <th>WI</th>
      <th>Resolution</th>
      <th>Uncertainly</th>
      <th>Certificate</th>
    </React.Fragment>
  );

  const row = (
    <tr key={dataItem.SerialNo}>
      <td>{dataItem.SerialNo}</td>
      <td>{dataItem.MachineName}</td>
      <td>{dataItem.MachineModel}</td>
      <td>
        {" "}
        <Input
          type="text"
          name="CalPoint"
          id="CalPoint"
          value={dataItem.CalPoint}
          onChange={handleInputChange}
          bsSize="sm"
        />
      </td>
      <td>
        <Combo.WI
          name="WiId"
          value={dataItem.WiId}
          onChange={handleInputChange}
        />
      </td>
      <td>
        {" "}
        <Input
          type="text"
          name="Resolution"
          id="Resolution"
          value={dataItem.Resolution}
          onChange={handleInputChange}
          bsSize="sm"
        />
      </td>
      <td>
        {" "}
        <Input
          type="text"
          name="Uncertainly"
          id="Uncertainly"
          value={dataItem.Uncertainly}
          onChange={handleInputChange}
          bsSize="sm"
        />
      </td>
      <td>
        <CustomInput
          type="checkbox"
          className="table-checkbox"
          id={dataItem.SerialNo || common.uuidv4()}
          name="Certificate"
          checked={dataItem.Certificate}
          onChange={(e) => {
            handleChange(e);
          }}
        />
      </td>
    </tr>
  );

  return (
    <Modal
      onCancel={currentProps.onCancel}
      isOpen={isOpen}
      onSave={get}
      SaveText="OK"
      ModalHeaderText="Edit Machines"
      onClosed={() => {
        currentProps.onClosed();
      }}
    >
      <ReactTable Header={header} Row={row} />
    </Modal>
  );
};

export default AddMachineModal;
