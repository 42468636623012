import * as Constants from "./constants";
import Axios from "axios";
import moment from "moment";
import * as common from "./../components/CommonFunction/common-function";
moment.locale("en-GB");

export const ActionType = {
  LoadDetail: "JobReducer_LoadDetail",
  LoadMachine: "JobReducer_LoadMachine",
  LoadEmployee: "JobReducer_LoadEmployee",
  LoadEmployeeRowData: "JobReducer_LoadEmployeeRowData",
  LoadEmployeeBusyRowData: "JobReducer_LoadEmployeeBusyRowData",
  LoadEmployeeHolidayRowData: "JobReducer_LoadEmployeeHolidayRowData",
  LoadTools: "JobReducer_LoadTools",
  CLEAR_DETAIL: "JobReducer_CLEARDETAIL",
  JobDetailChange: "JobReducer_JobDetailChange",
  EngineerChange: "JobReducer_EngineerChange",
  TaskJob: "JobReducer_TaskJob",
  TaskJobDetail: "JobReducer_TaskJobDetail",
  GetTaskJobForm: "JobReducer_GetTaskJobForm",
};
export const initial_state = {
  //State ของ reducer นี้ประกอบไปด้วย
  //1.
  Form: {
    Jobdata: {
      JobId: "",
      JobNumber: "",
      JobType: "",
      JobStatus: "",
      DateFrom: "",
      DateTo: "",
      ServiceRequestId: "",
      PoNumber: "",
      Brief: "",
      RefJobId: "",
      CustomerId: "",
      CustomerName: "",
      MachineText: "",
      Link: "",
      Remark: "",
      OnsiteBy: false,
      CreatedDate: "",
      CreatedBy: "",
      UpdatedDate: "",
      UpdatedBy: "",
    },
    JobdataInput: {},
    Machine: [],
    Engineer: [],
    Tools: [],
    EngineerRowData: [],
    EngineerBusyRowData: [],
    EngineerHolidayRowData: [],
  },
  TaskJobData: [],
  TaskJobDetail: {
    start: "",
    end: "",
    title: "",
    remark: "",
    status: "",
  },
};

//action
export const actionCreators = {
  CreateJob: async (dispatch, data) => {
    let url = `${Constants.API_URL}/api/Job/CreateJob`;
    let { result } = await common.to(Axios.post(url, data));
    if (result && result.data)
      await actionCreators.dataToJobform(dispatch, result.data.JobId);
  },
  UpdateJob: (dispatch, data) => {
    let url = `${Constants.API_URL}/api/Job/UpdateJobInfo`;
    Axios.post(url, data)
      .then((response) => {
        if (response.data) {
          actionCreators.dataToJobform(dispatch, data.JobId);
        }
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  dataToJobform: async (dispatch, JobId) => {
    let url = `${Constants.API_URL}/api/Job/GetJob`;
    //return

    let { result, error } = await common.to(Axios.post(url, { id: JobId }));
    if (error) return;
    if (result.data) {
      dispatch({ type: ActionType.LoadDetail, param: result.data });
    }

    await actionCreators.GetJobMachineList(dispatch, JobId);
    await actionCreators.GetJobEmployeeAllocateList(dispatch, JobId);
    await actionCreators.GetJobToolsAllocateList(dispatch, JobId);
  },
  GetJob: async (JobId) => {
    let url = `${Constants.API_URL}/api/Job/GetJob`;
    let { result } = await common.to(Axios.post(url, { id: JobId }));
    if (result.data) return result.data;
    return {};
  },
  //Machine
  GetJobMachineList: (dispatch, JobId) => {
    return Axios.post(`${Constants.API_URL}/api/Job/GetJobMachineList`, {
      id: JobId,
    })
      .then((response) => {
        if (response.data) {
          dispatch({ type: ActionType.LoadMachine, param: response.data });
        }
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  UpdateMachineList: (dispatch, JobId, data) => {
    let url = `${Constants.API_URL}/api/Job/UpdateJobMachine`;
    Axios.post(url, data)
      .then((response) => {
        actionCreators.GetJobMachineList(dispatch, JobId);
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  UpdateMachineDetail: (dispatch, JobId, data) => {
    let url = `${Constants.API_URL}/api/Job/UpdateJobMachineDetail`;
    Axios.post(url, data)
      .then((response) => {
        actionCreators.GetJobMachineList(dispatch, JobId);
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  RemoveMachine: async (dispatch, data) => {
    let url = `${Constants.API_URL}/api/Job/ValidateDeleteJobMachine`;

    let { result, error } = await common.to(Axios.post(url, data));
    if (error) {
      return;
    }
    if (result && result.data) {
      if (!result.data.isValid) {
        common.InformationOKDialog(
          result.data.Messages && common.ArrayTextReduce(result.data.Messages)
        );
        return;
      }
    }
    url = `${Constants.API_URL}/api/Job/DeleteJobMachine`;
    Axios.post(url, data)
      .then((response) => {
        actionCreators.GetJobMachineList(dispatch, data.JobId);
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  RemoveAllMachine: (dispatch, JobId) => {
    let url = `${Constants.API_URL}/api/Job/DeleteAllJobMachine`;
    Axios.post(url, { JobId: JobId })
      .then((response) => {
        actionCreators.GetJobMachineList(dispatch, JobId);
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  //Employee
  GetEmployeeAllocateList: async (JobId) => {
    let url = `${Constants.API_URL}/api/Job/GetJobEmployeeAllocateList`;
    let { result, error } = await common.to(Axios.post(url, { id: JobId }));
    if (error) {
      return;
    }
    return result.data;
  },
  GetJobEmployeeAllocateList: (dispatch, JobId) => {
    return Axios.post(
      `${Constants.API_URL}/api/Job/GetJobEmployeeAllocateList`,
      { id: JobId }
    )
      .then((response) => {
        if (response.data) {
          dispatch({ type: ActionType.LoadEmployee, param: response.data });
        }
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  GetJobEmployeeAllocate: async (dispatch, EmployeeAllocate) => {
    return Axios.post(
      `${Constants.API_URL}/api/Job/GetJobEmployeeAllocate`,
      EmployeeAllocate
    )
      .then((response) => {
        if (response.data) {
          dispatch({
            type: ActionType.LoadEmployeeRowData,
            param: response.data,
          });
        }
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  GetEmployeeBusy: async (
    dispatch,
    EmployeeAllocate,
    PlanDateFrom,
    PlanDateTo
  ) => {
    let param = {
      ...EmployeeAllocate,
      DateFrom: PlanDateFrom,
      DateTo: PlanDateTo,
    };
    return Axios.post(`${Constants.API_URL}/api/Job/GetEmployeeBusy`, param)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: ActionType.LoadEmployeeBusyRowData,
            param: response.data,
          });
        }
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  GetEmployeeHoliday: async (
    dispatch,
    EmployeeAllocate,
    PlanDateFrom,
    PlanDateTo
  ) => {
    let param = {
      ...EmployeeAllocate,
      DateFrom: PlanDateFrom,
      DateTo: PlanDateTo,
    };
    return Axios.post(`${Constants.API_URL}/api/Job/GetEmployeeHoliday`, param)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: ActionType.LoadEmployeeHolidayRowData,
            param: response.data,
          });
        }
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  AddEmployee: (data) => {
    let url = `${Constants.API_URL}/api/Job/AddJobEmployeeAllocate`;
    Axios.post(url, data)
      .then((response) => {})
      .catch((error) => {
        common.apiError(error);
      });
  },
  UpdateJobEmployeeList: (dispatch, JobId, data) => {
    let url = `${Constants.API_URL}/api/Job/UpdateJobEmployeeAllocateList`;
    Axios.post(url, data)
      .then((response) => {
        actionCreators.GetJobEmployeeAllocateList(dispatch, JobId);
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  UpdateEmployeeAllocateByEngineers: (dispatch, JobId, data) => {
    let url = `${Constants.API_URL}/api/Job/UpdateEmployeeAllocateByEngineers`;
    Axios.post(url, data)
      .then((response) => {
        actionCreators.GetJobEmployeeAllocateList(dispatch, JobId);
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  RemoveEmployee: (dispatch, EmployeeAllocate, JobId) => {
    let url = `${Constants.API_URL}/api/Job/DeleteJobEmployeeAllocate`;
    return Axios.post(url, EmployeeAllocate)
      .then((response) => {
        actionCreators.GetJobEmployeeAllocateList(dispatch, JobId);
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  RemoveAllEmployee: (dispatch, JobId) => {
    let url = `${Constants.API_URL}/api/Job/DeleteAllEmployeeAllocateList`;
    Axios.post(url, { JobId: JobId })
      .then((response) => {
        actionCreators.GetJobEmployeeAllocateList(dispatch, JobId);
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  //Tools
  GetJobToolsAllocateList: (dispatch, JobId) => {
    return Axios.post(`${Constants.API_URL}/api/Job/GetJobToolsAllocateList`, {
      id: JobId,
    })
      .then((response) => {
        if (response.data) {
          dispatch({ type: ActionType.LoadTools, param: response.data });
        }
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  UpdateJobToolsAllocate: (dispatch, JobId, data) => {
    let url = `${Constants.API_URL}/api/Job/UpdateJobToolsAllocate`;
    Axios.post(url, data)
      .then((response) => {
        actionCreators.GetJobToolsAllocateList(dispatch, JobId);
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  AddTools: (data) => {
    let url = `${Constants.API_URL}/api/Job/AddJobToolsAllocate`;
    Axios.post(url, data)
      .then((response) => {})
      .catch((error) => {
        common.apiError(error);
      });
  },
  RemoveTools: (ToolsAllocate) => {
    let url = `${Constants.API_URL}/api/Job/DeleteJobToolsAllocate`;
    return Axios.post(url, ToolsAllocate)
      .then((response) => {})
      .catch((error) => {
        common.apiError(error);
      });
  },
  RemoveAllTools: (dispatch, JobId) => {
    let url = `${Constants.API_URL}/api/Job/DeleteAllToolsAllocateList`;
    Axios.post(url, { JobId: JobId })
      .then((response) => {
        actionCreators.GetJobToolsAllocateList(dispatch, JobId);
      })
      .catch((error) => {
        common.apiError(error);
      });
  },
  getTaskJob: (dispatch, criteria) => {
    let url = `${Constants.API_URL}/api/Job/GetTaskJobList`;
    Axios.post(url, criteria)
      .then((response) => {
        if (response.data) {
          var eventlist = response.data;
          for (let i = 0; i < eventlist.length; i++) {
            eventlist[i].start = new Date(
              moment.utc(eventlist[i].start).toDate()
            );
            eventlist[i].end = new Date(moment.utc(eventlist[i].end).toDate());
            eventlist[i].allDay = true;
          }
          // let data = [
          //   {
          //     id: 0,
          //     title: "All Day Event very long title",
          //     allDay: true,
          //     start: new Date(2020, 6, 20),
          //     end: new Date(2020, 6, 20),
          //   },
          //   {
          //     id: 0,
          //     title: "All Day Event very long title 2",
          //     allDay: true,
          //     start: new Date(2020, 6, 20),
          //     end: new Date(2020, 6, 20),
          //   },
          //   {
          //     id: 0,
          //     title: "All Day Event very long title 3",
          //     allDay: true,
          //     start: new Date(2020, 6, 20),
          //     end: new Date(2020, 6, 20),
          //   },
          // ];
          dispatch({ type: ActionType.TaskJob, param: eventlist });
        }
      })
      .catch((error) => common.apiError(error));
  },
};
//reducer Name
export const jobReducer = (
  state = JSON.parse(JSON.stringify(initial_state)),
  action
) => {
  switch (
    action.type //Action type แยกไว้ในไฟล์เดียวกันกับฝั่งเรียกใช้
  ) {
    case ActionType.CLEAR_DETAIL:
      state.Form.data = initial_state.Form.data;
      break;
    case ActionType.LoadDetail:
      state.Form.Jobdata = action.param;
      state.Form.JobdataInput = action.param;
      break;
    case ActionType.LoadMachine:
      state.Form.Machine = action.param;
      state.Form.MachineInput = action.param;
      break;
    case ActionType.LoadEmployee:
      state.Form.Engineer = action.param;
      state.Form.EngineerInput = action.param;
      break;
    case ActionType.LoadEmployeeRowData:
      state.Form.EngineerRowData = action.param;
      state.Form.EngineerRowDataInput = action.param;
      break;
    case ActionType.LoadEmployeeBusyRowData:
      state.Form.EngineerBusyRowData = action.param;
      state.Form.EngineerBusyRowDataInput = action.param;
      break;
    case ActionType.LoadEmployeeHolidayRowData:
      state.Form.EngineerHolidayRowData = action.param;
      state.Form.EngineerHolidayRowDataInput = action.param;
      break;
    case ActionType.LoadTools:
      state.Form.Tools = action.param;
      state.Form.ToolsInput = action.param;
      break;
    case ActionType.JobDetailChange:
      state.Form.JobdataInput = action.param;
      break;
    case ActionType.EngineerChange:
      state.Form.EngineerInput = action.param;
      break;
    case ActionType.TaskJob:
      state.TaskJobData = action.param;
      break;
    case ActionType.TaskJobDetail:
      state.TaskJobDetail = action.param;
      break;
    default:
      break;
  }
  return state;
};
