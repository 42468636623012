//Library
import React, { useState, useEffect, createContext, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, CustomInput, Form, FormGroup, Col, Label } from "reactstrap";
import { Redirect } from "react-router-dom";
import { useForm, useFormContext, FormContext } from "react-hook-form";
//Component
import { disableEdit } from "./../CommonFunction/common-function";
import ResultBox from "../UI/ResultBox";
import CriteriaBox from "../UI/CriteriaBox";
import MainScreen from "../UI/MainScreen";
import CommonTable from "../UI/CommonTables";
import * as common from "../CommonFunction/common-function";
import { formatDate, collectText } from "../CommonFunction/common-function";
import * as LinkModal from "../UI/LinkText/LinkModal";
import Modal from "../UI/CommonForm";
import DetailBox from "../UI/DetailBox";
import { FormRow } from "../UI/FormDetail";
import { SideNavBar } from "../UI/SideNavBar/SideNavBar";
//Store
import { PerissionScreenContext } from "./../Permission";
import * as TaskStore from "../../store/TaskStore";
import * as ServiceRequestStore from "../../store/ServiceRequestStore";

export const UpcomingListScreen = () => {

  const CriteriaForm = useForm();
  const { getValues, reset } = CriteriaForm;

  const dispatch = useDispatch();
  const SearchCriteria = useSelector(
    (state) => state.TaskReducer.SearchCriteria
  );

  const SearchCriteriaNav = useSelector(
    (state) => state.ReportFilterReducer.SearchCriteria
  );

  const criteria = {
    ...SearchCriteria,
    DateFrom: SearchCriteriaNav.DueDateFrom,
    DateTo: SearchCriteriaNav.DueDateTo,
  };

  // Effect
  useEffect(() => {
    TaskStore.actionCreators.Search(dispatch, criteria);
  }, [dispatch]);

  const search = () => {
    let new_criteria = { ...criteria, FullText: getValues().FullText };
    TaskStore.actionCreators.Search(dispatch, new_criteria);
    dispatch({ type: TaskStore.ActionType.criteriaChange, param: getValues() });
  };

  return (
    <SideNavBar Search={search} DueDate collapse>
      <MainScreen>
        <FormContext {...{ CriteriaForm: CriteriaForm }}>
          <CriteriaBox
            onSearch={search}
            hiddenClear={false}
            onClear={() => {
              reset();
              dispatch({ type: TaskStore.ActionType.CLEAR });
            }}
            hidden={true}
            hiddenClose={true}
          >
            <CriteriaSearch search={search} />
          </CriteriaBox>
          <ResultBox HeaderText="Upcoming Task">
            <UpcomingTable />
          </ResultBox>
        </FormContext>
      </MainScreen>
    </SideNavBar>
  );
};
export const CriteriaSearch = (props) => {
  const { CriteriaForm } = useFormContext();
  const { setValue } = CriteriaForm;
  const SearchCriteria = useSelector(
    (state) => state.TaskReducer.SearchCriteria
  );
  const { register } = CriteriaForm;
  useEffect(() => {
    setValue(common.setDataToFormHook(SearchCriteria));
  }, [SearchCriteria]);
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      props.search();
    }
  };
  return (
    <React.Fragment>
      <Form
        className="criteria-div"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormGroup row>
          <Label className="Label-font" for="SearchFor" size="sm">
            Search For
          </Label>
          <Col md={4}>
            <Input
              type="text"
              bsSize="sm"
              name="FullText"
              id="FullText"
              innerRef={register}
              onKeyPress={handleKeyPress}
            />
          </Col>
        </FormGroup>
      </Form>
    </React.Fragment>
  );
};
const UpcomingTable = () => {
  const dispatch = useDispatch();

  // const onDelete=(id)=>{
  //     common.ConfirmDelete(id, ()=>{JobStore.actionCreators.DeleteJob(dispatch,id,()=>{TaskStore.actionCreators.Search(dispatch, {})})

  // })
  // }
  const [isOpenModal, setIsOpenModal] = useState();
  const dataTable = useSelector((state) => state.TaskReducer.TaskData);
  const onClickCol = {
    onClick: async (e, column, columnIndex, row, rowIndex) => {
      await TaskStore.actionCreators.dataToTaskform(dispatch, row.JobId);
      setIsOpenModal(true);
    },
  };

  const columns = [
    {
      dataField: "DateFrom",
      text: "Due Date",
      sort: true,
      events: onClickCol,
      formatter: (cell, row) => {
        return formatDate(row.DateFrom);
      },

      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
    },
    {
      dataField: "CustomerName",
      text: "Customer",
      sort: true,
      formatter: (cell, row) => {
        return (
          <LinkModal.Customer Text={row.CustomerName} Value={row.CustomerId} />
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "200px" };
      },
    },
    {
      dataField: "JobType",
      text: "Type",
      sort: true,
      events: onClickCol,
      headerStyle: (colum, colIndex) => {
        return { width: "200px" };
      },
    },
    {
      dataField: "MachineText",
      text: "Machine",
      sort: true,
      events: onClickCol,
      headerStyle: (colum, colIndex) => {
        return { width: "200px" };
      },
      formatter: (cell, row) => {
        return collectText(row.MachineText);
      },
    },
    {
      dataField: "MachineModelText",
      text: "Model",
      sort: true,
      events: onClickCol,
      headerStyle: (colum, colIndex) => {
        return { width: "200px" };
      },
      formatter: (cell, row) => {
        return collectText(row.MachineModelText);
      },
    },
    {
      dataField: "JobStatus",
      text: "Status",
      sort: true,
      events: onClickCol,
      headerStyle: (colum, colIndex) => {
        return { width: "200px" };
      },
    },

    //{
    //    dataField: 'b',
    //    text: '',
    //    formatter: (cell, row) => {
    //        return (
    //            <button onClick={async () => {
    //                var res = await TaskStore.actionCreators.dataToTaskform(dispatch, row.JobId)
    //                setIsOpenModal(true)
    //            }} className="btn-transparent"><Iconmore /></button>
    //        );
    //    }
    //}
  ];

  return (
    <React.Fragment>
      {isOpenModal ? (
        <Redirect
          to={{
            pathname: "/UpcomingTask",
            state: { BackLink: "/UpcomingList" },
          }}
        />
      ) : null}
      <CommonTable
        keyField="JobId"
        data={dataTable}
        columns={columns}
        id="UpcomingTable"
      />
    </React.Fragment>
  );
};
export const UpcomingScreen = (props) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.TaskReducer.Form.data);
  const [txtbtn, settxtbtn] = useState("");
  const { Function: fn_lst } = useContext(PerissionScreenContext);
  const [isredirect, setredirect] = useState(false);
  useEffect(() => {
    if (data.MarkasCall) settxtbtn("Mark as call");
    else settxtbtn("Cancel called");
  }, [data]);
  const onClickMarkasCall = (iscancel) => {
    common.SaveWithConfirm(() => {
      TaskStore.actionCreators.MarkAsCall(
        dispatch,
        {
          ...data,
          UpdatedBy: common.GetUserId(),
        },
        iscancel
      );
    });
  };
  const createServiceRequest = () => {
    let dat = {
      JobType: data.JobType,
      PoNumber: data.PoNumber,
      DueDate: data.DateFrom,
      CustomerId: data.CustomerId,
      Remark: data.Remark,
      PoDate: data.DateFrom,
      RequestedDate: data.DateFrom,
    };
    dispatch({ type: ServiceRequestStore.ActionType.GetDetail, param: dat });
    setredirect(true);
  };
  return (
    <Modal
      isOpen={UpComingContext.isOpen}
      toggle={() => {
        UpComingContext.setOpen(!UpComingContext.isOpen);
      }}
      Button1Text={txtbtn}
      onButton1Click={() => onClickMarkasCall(!data.MarkasCall)}
      Button1Hidden={disableEdit(fn_lst)}
      Button2Text={"Create Service Request"}
      onButton2Click={() => createServiceRequest()}
      Button2Hidden={disableEdit(fn_lst)}
      BackLink={
        props.Backlink ||
        (props.location.state && props.location.state.BackLink) ||
        "/"
      }
    >
      {data.JobId ? null : <Redirect to="/UpcomingList" />}
      {isredirect ? (
        <Redirect
          to={{
            pathname: "/RequestForm",
            state: { BackLink: "/UpcomingTask" },
          }}
        />
      ) : null}
      <DetailBox HeaderText="Task Detail">
        <TaskDataForm />
      </DetailBox>
    </Modal>
  );
};

export const TaskDataForm = () => {
  const data = useSelector((state) => state.TaskReducer.Form.data);
  const IconP = common.getIconTag(common.Icontype.ION, "IoIosPerson");
  const Machine = useSelector((state) => state.TaskReducer.Form.Machine);
  const Contact = useSelector((state) => state.TaskReducer.Form.Contact);
  const Engineer = useSelector((state) => state.TaskReducer.Form.Engineer);
  return (
    <div>
      <FormRow label="Job Type">
        <label htmlFor="Job_Number_Value">{data.JobType}</label>
      </FormRow>
      <FormRow label="Due Date">
        <label htmlFor="Job_Number_Value">{data.DateFrom}</label>
      </FormRow>
      <FormRow label="Customer">
        <LinkModal.Customer
          Text={data && data.CustomerName}
          Value={data && data.CustomerId}
        />
      </FormRow>
      <div className="row detail-form">
        <div className="col-12 col-md-4">
          <label htmlFor="Machine" className="col-label-bold">
            Machine
          </label>
        </div>
        <div className="col-12 col-md-8 col-detailForm">
          {Machine &&
            Machine.map((item) => (
              <div className="col-12 detail-form" key={common.uuidv4()}>
                <label>
                  {item.MachineName} (S/N: {item.SerialNo})
                </label>
                <br />
              </div>
            ))}
        </div>
      </div>
      <div className="row detail-form">
        <div className="col-12 col-md-4">
          <label htmlFor="Machine" className="col-label-bold">
            Contact
          </label>
        </div>
        <div className="col-12 col-md-8 col-detailForm">
          {Contact &&
            Contact.map((item) => (
              <div className="col-12 detail-form" key={common.uuidv4()}>
                <IconP />
                <label>{item.ContactPerson}</label>
                <br />
                <label>{item.Telephone}</label>
                <br />
              </div>
            ))}
        </div>
      </div>
      <div className="row detail-form">
        <div className="col-12 col-md-4">
          <label htmlFor="Engineers" className="col-label-bold">
            Planed Engineers
          </label>
        </div>
        <div className="col-12 col-md-8 col-detailForm">
          {Engineer &&
            Engineer.map((item) => (
              <div className="col-12 detail-form" key={common.uuidv4()}>
                <LinkModal.Engineer Value={item} />
                <br />
                <label htmlFor="Engineers_Value">{item.Telephone}</label>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
export const UpComingContext = createContext({});
export const UpComingProvider = ({ children }) => {
  const [isOpen, setisOpen] = useState(false);
  const store = {
    isOpen,
    setOpen: (isOpen) => {
      setisOpen(isOpen);
    },
  };
  return (
    <UpComingContext.Provider value={store}>
      {children}
    </UpComingContext.Provider>
  );
};
export default UpcomingTable;
