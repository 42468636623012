//Library
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormContext } from "react-hook-form";
import moment from "moment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DateRangeIcon from "@material-ui/icons/DateRange";
//Component
import AddEnigineerModal from "./../addmodal/AddEngineerModal";
import { CalendarDatePickerModal } from "../UI/MainModal";
import { ChildTable } from "../UI/CommonTables";
//Store
import * as JobStore from "./../../store/jobStore";
//Function
import * as common from "./../CommonFunction/common-function";
import {
  InformationOKDialog,
  validateDateRage,
} from "../CommonFunction/common-function";
import { PlandateDisplay } from "./../addmodal";
import { format } from "react-string-format";

function Engineers() {
  //Props
  //Declare State
  const methods = useForm();
  const [engineers, setEngineers] = useState([]);

  const IoIosAdd = common.getIconTag(common.Icontype.ION, "IoIosAdd");

  const [isOpen, setIsOpen] = useState();
  const [isOpenEigineerDate, setIsOpenEigineerDate] = useState();

  //Redux
  const dispatch = useDispatch();
  const EngineersInfo = useSelector((state) => state.jobReducer.Form.Engineer);
  const Jobinfo = useSelector((state) => state.jobReducer.Form.Jobdata);
  //Effect
  useEffect(() => {
    var mappedEngineers = common.groupAndMapEngineers(EngineersInfo);
    setEngineers(mappedEngineers);
  }, [EngineersInfo]);

  //Event
  const Add = () => {
    setIsOpen(true);
  };
  const getValue = (EmpList) => {
    //New Api For List
    if (EmpList && EmpList.length > 0) {
      const EmpList2 = EmpList.map((item) => ({
        JobId: Jobinfo.JobId,
        EmployeeId: item,
        UpdatedBy: common.GetUserId(),
        EmployeeDateFrom: Jobinfo.DateFrom,
        EmployeeDateTo: Jobinfo.DateTo,
      }));
      JobStore.actionCreators.UpdateJobEmployeeList(
        dispatch,
        Jobinfo.JobId,
        EmpList2
      );
    } else {
      JobStore.actionCreators.RemoveAllEmployee(dispatch, Jobinfo.JobId);
    }
    setIsOpen(!isOpen);
  };
  const onSaveEmpAllocateEngineers = async (empAllocate) => {
    JobStore.actionCreators.UpdateEmployeeAllocateByEngineers(
      dispatch,
      Jobinfo.JobId,
      empAllocate
    );
    setIsOpenEigineerDate(!isOpenEigineerDate);
  };
  //SubComponent

  // Function
  const rowStyle = { cursor: "pointer" };

  const onClickCol = {
    onClick: async (e, column, columnIndex, row, rowIndex) => {
      await JobStore.actionCreators.GetJobEmployeeAllocate(dispatch, row);
      await JobStore.actionCreators.GetEmployeeBusy(
        dispatch,
        row,
        Jobinfo.DateFrom,
        Jobinfo.DateTo
      );
      await JobStore.actionCreators.GetEmployeeHoliday(
        dispatch,
        row,
        Jobinfo.DateFrom,
        Jobinfo.DateTo
      );
      setIsOpenEigineerDate(true);
    },
  };
  const getFormattedItemList = (dateServices) => {
    return (
      <List>
        {dateServices.map((service, index) => {
          const startDate = common.getFormattedDate(service.Date[0]);
          const endDate = common.getFormattedDate(service.Date[1]);
          const isOverlap = service.isOverlap;

          let listItem;
          if (common.isEmptyArray(service.Date)) {
            listItem = (
              <ListItem key={index} style={isOverlap ? { color: "red" } : {}}>
                <DateRangeIcon style={{ fontSize: 15, marginRight: 10 }} />
                {"dd-----yyyy "}
              </ListItem>
            );
          } else if (startDate === endDate) {
            listItem = (
              <ListItem key={index} style={isOverlap ? { color: "red" } : {}}>
                <DateRangeIcon style={{ fontSize: 15, marginRight: 5 }} />{" "}
                {startDate} {isOverlap ? "(ไม่ว่าง)" : ""}
              </ListItem>
            );
          } else {
            listItem = (
              <ListItem key={index} style={isOverlap ? { color: "red" } : {}}>
                <DateRangeIcon style={{ fontSize: 15, marginRight: 5 }} />{" "}
                {startDate} to {endDate} {isOverlap ? "(ไม่ว่าง)" : ""}
              </ListItem>
            );
          }
          return <React.Fragment key={index}>{listItem}</React.Fragment>;
        })}
      </List>
    );
  };

  const columns = [
    {
      dataField: "EmployeeName",
      text: "Name",
      sort: true,
      events: onClickCol,
      formatter: (cell, row) => {
        return (
          <p style={row.isOverlap ? { color: "red" } : {}}>
            {row.EmployeeName}
          </p>
        );
      },
      style: {
        verticalAlign: "middle",
        minWidth: "200px",
        paddingTop: "25px",
      },
    },
    {
      dataField: "DateServices",
      text: "Date Service",
      sort: true,
      events: onClickCol,
      formatter: (cell, row) => {
        return getFormattedItemList(row.DateServices);
      },
      style: {
        minWidth: "310px",
        verticalAlign: "middle",
      },
    },
    {
      dataField: "Telephone",
      text: "Tel",
      sort: true,
      events: onClickCol,
      formatter: (cell, row) => {
        return (
          <p style={row.isOverlap ? { color: "red" } : {}}>{row.Telephone}</p>
        );
      },
      style: {
        minWidth: "120px",
        paddingTop: "25px",
        verticalAlign: "middle",
      },
    },
    {
      dataField: "Tags",
      text: "Skill",
      sort: true,
      events: onClickCol,
      formatter: (cell, row) => {
        return <p style={row.isOverlap ? { color: "red" } : {}}>{row.Tags}</p>;
      },
      style: {
        verticalAlign: "middle",
        paddingTop: "25px",
      },
    },
    {
      dataField: "a",
      text: "",
      formatter: (cell, row) => {
        return (
          <IconButton
            onClick={async () => {
              await JobStore.actionCreators.RemoveEmployee(
                dispatch,
                row,
                Jobinfo.JobId
              );
              JobStore.actionCreators.dataToJobform(dispatch, Jobinfo.JobId);
            }}
            color="default"
            aria-label="delete row"
            component="span"
          >
            <CloseIcon style={{ fontSize: "medium" }} />
          </IconButton>
        );
      },
      style: {
        verticalAlign: "middle",
      },
    },
  ];

  return (
    <div>
      {Jobinfo && Jobinfo.JobStatus !== "CLOSE" && (
        <div className="row detail-head">
          <div className="col-2 col-md-2">
            <button className="btn-transparent" onClick={Add}>
              <IoIosAdd className="icon" />
            </button>
          </div>
        </div>
      )}
      <ChildTable
        classTable={"table-container table-hover"}
        keyField="EmployeeId"
        data={engineers}
        columns={columns}
        rowStyle={rowStyle}
      />
      <AddEnigineerModal
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(!isOpen);
        }}
        SelectedData={engineers && engineers.map((a) => a.EmployeeId)}
        GetValue={getValue}
        Jobdata={Jobinfo}
      />
      <FormContext {...methods}>
        <EnigineerDateModal
          isOpen={isOpenEigineerDate}
          onCancel={() => {
            setIsOpenEigineerDate(!isOpenEigineerDate);
          }}
          onSave={onSaveEmpAllocateEngineers}
          Jobdata={Jobinfo}
          PandDateFrom={Jobinfo.DateFrom}
          PandDateTo={Jobinfo.DateTo}
        />
      </FormContext>
    </div>
  );
}

const EnigineerDateModal = (props) => {
  //Props
  const { isOpen, Jobdata, PandDateFrom, PandDateTo } = props;
  const defaultProps = {
    onClosed: () => {},
  };
  const currentProps = {
    ...defaultProps,
    ...props,
  };
  // Redux
  const EngineerRow = useSelector(
    (state) => state.jobReducer.Form.EngineerRowData
  );
  const EngineerBusyRow = useSelector(
    (state) => state.jobReducer.Form.EngineerBusyRowData
  );
  const EngineerHolidayRow = useSelector(
    (state) => state.jobReducer.Form.EngineerHolidayRowData
  );
  // Declare

  // Effect
  useEffect(() => {
    if (!isOpen) return;
  }, [isOpen]);

  //Event+function
  function DateConverter({ dates }) {
    const result = dates.map(([startDate, endDate], index) => [
      common.formatDate(new Date(startDate)),
      common.isEmptyObj(endDate)
        ? common.formatDate(new Date(startDate))
        : common.formatDate(new Date(endDate)),
    ]);
    return result;
  }

  const getNewsValues = (dateValues) => {
    let serviceDate = DateConverter({ dates: dateValues });
    if (EngineerRow.length === 0 && serviceDate.length === 0) {
      return;
    }
    const param = serviceDate.map((dateRange) => ({
      JobId: EngineerRow[0].JobId,
      EmployeeId: EngineerRow[0].EmployeeId,
      EmployeeName: EngineerRow[0].EmployeeName,
      CreatedBy: EngineerRow[0].CreatedBy,
      CreatedDate: EngineerRow[0].CreatedDate,
      UpdatedBy: EngineerRow[0].UpdatedBy,
      UpdatedDate: EngineerRow[0].UpdatedDate,
      EmployeeDateFrom: dateRange[0],
      EmployeeDateTo: dateRange[1],
    }));
    return param;
  };

  const validateSave = (dateValues) => {
    var empAllocate = getNewsValues(dateValues);
    for (let i = 0; i < empAllocate.length; i++) {
      const row = empAllocate[i];
      const empDateFrom = moment(row.EmployeeDateFrom).format("DD-MMM-YYYY");
      const empDateTo = moment(row.EmployeeDateTo).format("DD-MMM-YYYY");
      const messageRage = "{0} must between {1} to {2}";

      if (!common.isEmptyStr(PandDateFrom) && !common.isEmptyStr(PandDateTo)) {
        const planDateFrom = moment(PandDateFrom).format("DD-MMM-YYYY");
        const planDateTo = moment(PandDateTo).format("DD-MMM-YYYY");

        if (
          !validateDateRage(planDateFrom, planDateTo, empDateFrom) ||
          !validateDateRage(planDateFrom, planDateTo, empDateTo)
        ) {
          InformationOKDialog(
            format(
              messageRage,
              "Engineer Date Service",
              planDateFrom,
              planDateTo
            )
          );
          return false;
        }
      }
    }
    return true;
  };

  const handleSave = (dateValues) => {
    props.onSave(getNewsValues(dateValues));
  };

  return (
    <CalendarDatePickerModal
      ModalHeaderText={`${PlandateDisplay(
        Jobdata && Jobdata.DateFrom,
        Jobdata && Jobdata.DateTo
      )}`}
      validateSave={validateSave}
      onSave={handleSave}
      isOpen={isOpen}
      onCancel={props.onCancel}
      onClosed={() => {
        currentProps.onClosed();
      }}
      initData={EngineerRow.filter(
        (row) => row.EmployeeDateFrom !== null && row.EmployeeDateTo !== null
      ).map((row) => [
        moment.utc(row.EmployeeDateFrom).toDate(),
        moment.utc(row.EmployeeDateTo).toDate(),
      ])}
      required
      initSuggestData={common.getDateRange(PandDateFrom, PandDateTo)}
      redTitle={"Engineer Busy"}
      initHighlightRedData={common
        .getDistinctValues(
          EngineerBusyRow.flatMap((item) =>
            common.getDateRange(item.EmployeeDateFrom, item.EmployeeDateTo)
          )
        )
        .sort()}
      orangeTitle={"Enginee Leave"}
      initHighlightOrangeData={common
        .getDistinctValues(
          EngineerHolidayRow.flatMap((item) =>
            common.getDateRange(item.LeaveStartDate, item.LeaveEndDate)
          )
        )
        .sort()}
    />
  );
};
export default Engineers;
