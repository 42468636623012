import React, { useEffect, useState, useContext } from "react";
//Component
import * as common from "./../CommonFunction/common-function";
import { Input } from "reactstrap";
import FormRowInput from "../UI/FormDetail";
import * as LinkModal from "../UI/LinkText/LinkModal";
import Uploader from "../UI/Uploader";
//Redux
import { useDispatch, useSelector } from "react-redux";
import * as JobMonitorStore from "../../store/JobMonitorStore";
import { PerissionScreenContext } from "./../Permission";
function InternalServiceReport() {
  const initialFormState = useSelector(
    (state) => state.JobMointorReducer.Form.Internal
  );
  const [dataItem, setDataItem] = useState(initialFormState);
  const InternalPhoto = useSelector(
    (state) => state.JobMointorReducer.Form.InternalPhoto
  );
  const { Function: fn_lst } = useContext(PerissionScreenContext);
  const dispatch = useDispatch();
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDataItem({ ...dataItem, [name]: value });
  };
  const attatchdataPhoto = {
    JobId: dataItem.JobId,
    Section: "Internal",
    DocumentType: "Photo",
  };
  const Upload = async (FileInfo, base64, Tags, attatchdata) => {
    let result = await JobMonitorStore.actionCreators.SaveFile64(
      dispatch,
      base64,
      FileInfo,
      { ...attatchdata, Tags: Tags }
    );
    if (result)
      JobMonitorStore.actionCreators.LoadInternalAttachmentList(
        dispatch,
        dataItem.JobId
      );
    else common.InformationOKDialog("Upload fail");
  };
  useEffect(() => {
    setDataItem(initialFormState);
  }, [initialFormState]);
  useEffect(() => {
    dispatch({
      type: JobMonitorStore.ActionType.fInternalChange,
      param: dataItem,
    });
  }, [dataItem, dispatch]);
  const denied = () => {
    return common.disableEdit(fn_lst);
  };
  return (
    <div>
      <FormRowInput label="Action (Short)">
        <Input
          type="textarea"
          name="ActionShort"
          id="ActionShort"
          style={{ height: 150 }}
          value={dataItem.ActionShort || ""}
          onChange={handleInputChange}
          readOnly={denied()}
        />
      </FormRowInput>
      <FormRowInput label="ข้อควรระวัง">
        <Input
          type="textarea"
          name="Awareness"
          id="Awareness"
          style={{ height: 150 }}
          value={dataItem.Awareness || ""}
          onChange={handleInputChange}
          readOnly={denied()}
        />
      </FormRowInput>
      <FormRowInput label="Follow up">
        <Input
          type="textarea"
          name="FollowUp"
          id="FollowUp"
          style={{ height: 150 }}
          value={dataItem.FollowUp || ""}
          onChange={handleInputChange}
          readOnly={denied()}
        />
      </FormRowInput>
      <FormRowInput label="โอกาสทางการขาย">
        <Input
          type="textarea"
          name="Opportunity"
          id="Opportunity"
          style={{ height: 150 }}
          value={dataItem.Opportunity || ""}
          onChange={handleInputChange}
          readOnly={denied()}
        />
      </FormRowInput>
      <FormRowInput label="Link">
        <Input
          type="text"
          name="Link"
          id="Link"
          value={dataItem.Link || ""}
          onChange={handleInputChange}
          readOnly={denied()}
        />
        <a target="_blank" rel="noopener noreferrer" href={dataItem.Link}>
          {dataItem.Link}
        </a>
      </FormRowInput>
      <FormRowInput label="Remark">
        <Input
          type="textarea"
          name="Remark"
          id="Remark"
          style={{ height: 150 }}
          value={dataItem.Remark || ""}
          onChange={handleInputChange}
          readOnly={denied()}
        />
      </FormRowInput>
      <FormRowInput label="Document"></FormRowInput>
      <div className="row detail-form-input">
        <div className="col-12 col-md-4">
          <label htmlFor="photo" className="col-label-bold">
            Photo
          </label>
        </div>
        <div className="col-12 col-md-8 col-detailForm">
          <div className="col-12 detail-form">
            <Uploader
              hidden={denied()}
              disabled={denied()}
              onUpload={(FileInfo, base64, Tags) =>
                Upload(FileInfo, base64, Tags, attatchdataPhoto)
              }
            />
          </div>
          {InternalPhoto &&
            InternalPhoto.map((item) => (
              <div key={common.uuidv4()}>
                <LinkModal.MediaFileName
                  Display={item.AttachmentName}
                  FileNameLink={item.FileNameLink}
                  Delete={async () => {
                    await JobMonitorStore.actionCreators.DeleteAttachment(
                      dispatch,
                      item
                    );
                    JobMonitorStore.actionCreators.LoadInternalAttachmentList(
                      dispatch,
                      dataItem.JobId
                    );
                  }}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default InternalServiceReport;
