//Library
import React, { useEffect, useState, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, CustomInput, FormGroup, Label } from "reactstrap";
import { useForm } from "react-hook-form";
//Component
import * as common from "./../CommonFunction/common-function";
import FormRowInput from "../UI/FormDetail";
import * as LinkModal from "../UI/LinkText/LinkModal";
import { ReactTable } from "../UI/CommonTables";
import { SignatureMainModal } from "../UI/MainModal";
import { DatePicker } from "../UI/DatePicker";
import SignaturePad from "react-signature-canvas";
import Uploader from "../UI/Uploader";
//Store
import * as JobMonitorStore from "../../store/JobMonitorStore";
import { PerissionScreenContext } from "./../Permission";
const fieldDate = ["ActualWorkFromDate", "ActualWorkToDate"];
export const JobActualResult = React.memo(({ Data, index, ...props }) => {
  //Redux
  const dispatch = useDispatch();
  const { Function: fn_lst } = useContext(PerissionScreenContext);

  const { register, setValue, getValues } = useForm();
  //Props
  //Declare State
  const [ActualPhoto, setActualPhoto] = useState([]);
  const [ActualCertificate, setActualCertificate] = useState([]);
  const [ActualReport, setActualReport] = useState([]);
  const [ImagesSerialReport, setActualImagesSerialReport] = useState([]);
  const attatchdataPhoto = {
    JobId: Data.JobId,
    Section: "Actual",
    SerialNo: Data.SerialNo,
    DocumentType: "Photo",
  };
  const attatchdataCertificate = {
    JobId: Data.JobId,
    Section: "Actual",
    SerialNo: Data.SerialNo,
    DocumentType: "Certificate",
  };
  const attatchdataReport = {
    JobId: Data.JobId,
    Section: "Actual",
    SerialNo: Data.SerialNo,
    DocumentType: "Report",
  };
  const attatchdataImageSerial = {
    JobId: Data.JobId,
    Section: "Actual",
    SerialNo: Data.SerialNo,
    DocumentType: "Image_Serial_Report",
  };
  //Effect
  useEffect(() => {
    setValue(common.setDataToFormHook(Data, fieldDate));
    if (Data) {
      if (Data.JobId === "") return;
      //LoadAttachment()
    }
    let didCancel = false;
    async function fetchData() {
      if (!didCancel)
        try {
          var criteria = {
            JobId: Data.JobId,
            Section: "Actual",
            SerialNo: Data.SerialNo,
          };

          JobMonitorStore.actionCreators.LoadAttachmentList_Return(
            { ...criteria, DocumentType: "Photo" },
            (data) => {
              !didCancel && setActualPhoto(data);
            }
          );
          JobMonitorStore.actionCreators.LoadAttachmentList_Return(
            { ...criteria, DocumentType: "Certificate" },
            (data) => {
              !didCancel && setActualCertificate(data);
            }
          );
          JobMonitorStore.actionCreators.LoadAttachmentList_Return(
            { ...criteria, DocumentType: "Report" },
            (data) => {
              !didCancel && setActualReport(data);
            }
          );
          JobMonitorStore.actionCreators.LoadAttachmentList_Return(
            { ...criteria, DocumentType: "Image_Serial_Report" },
            (data) => {
              !didCancel && setActualImagesSerialReport(data);
            }
          );
        } catch (error) {
          // Do something with error
        } finally {
        }
    }
    fetchData();
    return () => {
      didCancel = true;
    };
  }, [Data, setValue]);
  //Event+function
  const LoadAttachment = () => {
    var criteria = {
      JobId: Data.JobId,
      Section: "Actual",
      SerialNo: Data.SerialNo,
    };

    JobMonitorStore.actionCreators.LoadAttachmentList_Return(
      { ...criteria, DocumentType: "Photo" },
      (data) => {
        setActualPhoto(data);
      }
    );
    JobMonitorStore.actionCreators.LoadAttachmentList_Return(
      { ...criteria, DocumentType: "Certificate" },
      (data) => {
        setActualCertificate(data);
      }
    );
    JobMonitorStore.actionCreators.LoadAttachmentList_Return(
      { ...criteria, DocumentType: "Report" },
      (data) => {
        setActualReport(data);
      }
    );
    JobMonitorStore.actionCreators.LoadAttachmentList_Return(
      { ...criteria, DocumentType: "Image_Serial_Report" },
      (data) => {
        setActualImagesSerialReport(data);
      }
    );
  };
  const getFormValue = () => {
    return { ...Data, ...getValues() };
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    dispatch({
      type: JobMonitorStore.ActionType.ActualListChange,
      param: { ...getFormValue(), [name]: value },
    });
  };

  const Upload = async (FileInfo, base64, Tags, attatchdata) => {
    let attatchdata_withTags = { ...attatchdata, Tags: Tags };
    var result = await JobMonitorStore.actionCreators.SaveFile64(
      dispatch,
      base64,
      FileInfo,
      attatchdata_withTags
    );
    if (result) {
      LoadAttachment();
    } else {
      common.InformationOKDialog("Upload fail");
    }
  };
  const isLock = () => {
    return !common.isEmptyStr(Data.AcceptedDate) || common.disableEdit(fn_lst);
  };

  //SubComponent
  return (
    <div>
      {}
      <FormRowInput label="Model">
        <label htmlFor="Model_Value">{Data.MachineModel}</label>
      </FormRowInput>
      <FormRowInput label="Type">
        <label htmlFor="Type_Value">{Data.MachineType}</label>
      </FormRowInput>
      <FormRowInput label="Name">
        <label htmlFor="Type_Value">{Data.MachineName}</label>
      </FormRowInput>
      <FormRowInput label="Working Time From">
        <DatePicker
          name="ActualWorkFromDate"
          innerRef={register}
          onChange={handleInputChange}
          readOnly={isLock()}
        />
      </FormRowInput>
      <FormRowInput label="Working Time To">
        <DatePicker
          name="ActualWorkToDate"
          innerRef={register}
          onChange={handleInputChange}
          readOnly={isLock()}
        />
      </FormRowInput>
      <FormRowInput label="Customer In-charge">
        <Input
          className="form-control"
          type="text"
          readOnly={true}
          name="CustomerIncharge"
          innerRef={register}
          onChange={handleInputChange}
        />
      </FormRowInput>
      <FormRowInput label="Signed Date">
        <Input
          className="form-control"
          type="text"
          readOnly={true}
          name="AcceptedDate"
          innerRef={register}
          onChange={handleInputChange}
        />
      </FormRowInput>
      <FormRowInput label="Symptom">
        <Input
          type="textarea"
          name="Symtom"
          id="Symtom"
          style={{ height: 150 }}
          innerRef={register}
          onChange={handleInputChange}
          readOnly={isLock()}
        />
      </FormRowInput>
      <FormRowInput label="Action Taken">
        <Input
          type="textarea"
          name="ActionTaken"
          id="ActionTaken"
          style={{ height: 150 }}
          innerRef={register}
          onChange={handleInputChange}
          readOnly={isLock()}
        />
      </FormRowInput>
      <FormRowInput label="Cause">
        <Input
          type="textarea"
          name="Cause"
          id="Cause"
          style={{ height: 150 }}
          innerRef={register}
          onChange={handleInputChange}
          readOnly={isLock()}
        />
      </FormRowInput>
      <FormRowInput label="Result">
        <Input
          type="textarea"
          name="Result"
          id="Result"
          style={{ height: 150 }}
          innerRef={register}
          onChange={handleInputChange}
          readOnly={isLock()}
        />
      </FormRowInput>
      <FormRowInput label="Comment">
        <Input
          type="textarea"
          name="Comment"
          id="Comment"
          style={{ height: 150 }}
          innerRef={register}
          onChange={handleInputChange}
          readOnly={isLock()}
        />
      </FormRowInput>
      <FormRowInput label="Document"></FormRowInput>
      <div className="row detail-form-input">
        <div className="col-12 col-md-4">
          <label htmlFor="photo" className="col-label-bold">
            Photo
          </label>
        </div>
        <div className="col-12 col-md-8 col-detailForm">
          <div className="col-12 detail-form">
            <Uploader
              hidden={isLock()}
              disabled={isLock()}
              onUpload={(FileInfo, base64, Tags) =>
                Upload(FileInfo, base64, Tags, attatchdataPhoto)
              }
            />
          </div>
          {ActualPhoto &&
            ActualPhoto.map((item) => (
              <div key={common.uuidv4()}>
                <LinkModal.MediaFileName
                  Display={item.AttachmentName}
                  FileNameLink={item.FileNameLink}
                  Delete={() => {
                    JobMonitorStore.actionCreators
                      .DeleteAttachment(dispatch, item)
                      .then((res) => {
                        LoadAttachment();
                      });
                  }}
                />
              </div>
            ))}
        </div>
      </div>
      <div className="row detail-form-input">
        <div className="col-12 col-md-4">
          <label htmlFor="photo" className="col-label-bold">
            Certificate
          </label>
        </div>
        <div className="col-12 col-md-8 col-detailForm">
          <div className="col-12 detail-form">
            <Uploader
              onUpload={(FileInfo, base64, Tags) =>
                Upload(FileInfo, base64, Tags, attatchdataCertificate)
              }
            />
          </div>
          {ActualCertificate &&
            ActualCertificate.map((item) => (
              <div key={common.uuidv4()}>
                <LinkModal.MediaFileName
                  Display={item.AttachmentName}
                  FileNameLink={item.FileNameLink}
                  Delete={() => {
                    JobMonitorStore.actionCreators
                      .DeleteAttachment(dispatch, item)
                      .then((res) => {
                        LoadAttachment();
                      });
                  }}
                />
              </div>
            ))}
        </div>
      </div>
      <div className="row detail-form-input">
        <div className="col-12 col-md-4">
          <label htmlFor="photo" className="col-label-bold">
            Report
          </label>
        </div>
        <div className="col-12 col-md-8 col-detailForm">
          <div className="col-12 detail-form">
            <Uploader
              hidden={isLock()}
              disabled={isLock()}
              onUpload={(FileInfo, base64, Tags) =>
                Upload(FileInfo, base64, Tags, attatchdataReport)
              }
            />
          </div>
          {ActualReport &&
            ActualReport.map((item) => (
              <div key={common.uuidv4()}>
                <LinkModal.MediaFileName
                  Display={item.AttachmentName}
                  FileNameLink={item.FileNameLink}
                  Delete={() => {
                    JobMonitorStore.actionCreators
                      .DeleteAttachment(dispatch, item)
                      .then((res) => {
                        LoadAttachment();
                      });
                  }}
                />
              </div>
            ))}
        </div>
      </div>
      <div className="row detail-form-input">
        <div className="col-12 col-md-4">
          <label htmlFor="photo" className="col-label-bold">
            Image by Serial No.
          </label>
        </div>
        <div className="col-12 col-md-8 col-detailForm">
          <div className="col-12 detail-form">
            <Uploader
              hiddenTags={true}
              acceptFile={"image/png, image/jpeg"}
              onUpload={(FileInfo, base64, Tags) =>
                Upload(FileInfo, base64, Tags, attatchdataImageSerial)
              }
            />
          </div>
          {ImagesSerialReport &&
            ImagesSerialReport.map((item) => (
              <div key={common.uuidv4()}>
                <LinkModal.MediaFileName
                  Display={item.AttachmentName}
                  FileNameLink={item.FileNameLink}
                  Delete={() => {
                    JobMonitorStore.actionCreators
                      .DeleteAttachment(dispatch, item)
                      .then((res) => {
                        LoadAttachment();
                      });
                  }}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
});

export const Signature = (props) => {
  //Redux
  const JobActualList = useSelector(
    (state) => state.JobMointorReducer.Form.JobActualList
  );
  const Data = useSelector((state) => state.JobMointorReducer.Form);
  const jobdata = useSelector((state) => state.JobMointorReducer.Form.Jobdata);
  const { Function: fn_lst } = useContext(PerissionScreenContext);
  //Props

  //Declare State
  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [SelectedSerial, setSelectedSerial] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [dataItem, setDataItem] = useState({
    JobId: "",
    JobStatus: "",
    ActualWorkFromDate: "",
    ActualWorkToDate: "",
    AcceptedBy: "",
    AcceptedDate: "",
    SignatureId: "",
    DocPicture1Id: "",
    DocPicture2Id: "",
    Remark1: "",
    Remark2: "",
    CreatedDate: "",
    CreatedBy: "",
    UpdatedDate: "",
    UpdatedBy: "",
  });
  const dispatch = useDispatch();

  //Effect
  useEffect(() => {
    if (props.isEngineer) {
      var jobEngineer = JobActualList.find((x) => {
        return x.ShowDlvReportEng;
      });
      if (!jobEngineer) return;
      setSelectedOption(jobEngineer.SerialNo);
    } else {
      var jobCustomer = JobActualList.find((x) => {
        return x.ShowDlvReportCus;
      });
      if (!jobCustomer) return;
      setSelectedOption(jobCustomer.SerialNo);
    }
  }, [props.isEngineer, JobActualList, setSelectedOption]);

  //Event+function
  const getactuallst = () => {
    return Data.JobActualInputList.map((item) => {
      return { ...item, UpdatedBy: common.GetUserId() };
    });
  };
  const getdata = (url) => {
    setImage(url);
  };
  const handleChange = (e, Serial) => {
    if (e.target.checked) {
      setSelectedSerial(SelectedSerial.concat(Serial));
    } else {
      setSelectedSerial(
        SelectedSerial.filter((id) => {
          return id !== Serial;
        })
      );
    }
  };
  const handleShowDlvReportClick = (e, value) => {
    if (value === selectedOption) {
      setSelectedOption(null); // Uncheck the radio button
      saveUseDlyReport(null);
    } else {
      setSelectedOption(value); // Check the radio button
    }
  };
  const handleShowDlvReportChange = (e, value) => {
    saveUseDlyReport(value);
  };
  const saveUseDlyReport = (serial) => {
    var actualList = getactuallst();
    if (props.isEngineer) {
      actualList = actualList.map((obj) => ({
        ...obj,
        ShowDlvReportEng: obj.SerialNo === serial,
      }));
    } else {
      actualList = actualList.map((obj) => ({
        ...obj,
        ShowDlvReportCus: obj.SerialNo === serial,
      }));
    }

    const updateUseDlyReport = async () => {
      JobMonitorStore.actionCreators.SaveActualList(dispatch, actualList);
    };

    updateUseDlyReport();
  };
  const denied = () => {
    return common.disableEdit(fn_lst);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDataItem({ ...dataItem, [name]: value });
  };
  const onUpload = async () => {
    let doctype = "Signature";
    let customer = dataItem.AcceptedBy;
    if (props.isEngineer) {
      doctype = "Signature_Engineer";
    }

    if (image === null) {
      common.InformationOKDialog("Please sign your signature.");
      return;
    }

    if (SelectedSerial.length === 0) {
      common.InformationOKDialog("Please select Machines.");
      return;
    }

    var attatchdata = {
      JobId: jobdata.JobId,
      Section: "Actual",
      DocumentType: doctype,
      CustomerIncharge: customer,
      UpdatedBy: common.GetUserId(),
    };

    let res = await JobMonitorStore.actionCreators.validate_actual(
      getactuallst()
    );

    if (!res) return;

    const uploadfile = async () => {
      JobMonitorStore.actionCreators.SaveActualList(dispatch, getactuallst());
      JobMonitorStore.actionCreators
        .SaveSignatureMedia(
          dispatch,
          image,
          SelectedSerial,
          attatchdata,
          props.isEngineer
        )
        .then((res) => {
          setImage(null);
          setDataItem({ ...dataItem, AcceptedBy: "" });
          setSelectedSerial([]);
        });
    };

    common.SaveWithConfirm(uploadfile);
  };
  const onReset = () => {
    setImage(null);
  };
  //SubComponent
  const columnCenter = {
    textAlign: "center",
  };

  const IconChecked = common.getIconTag(common.Icontype.ION, "IoMdCheckbox");
  const IconUnChecked = common.getIconTag(
    common.Icontype.ION,
    "IoMdSquareOutline"
  );

  const header = (
    <React.Fragment>
      <th></th>
      <th>Type</th>
      <th>Model</th>
      <th>Serial</th>
      <th style={columnCenter}>Signed</th>
      <th style={columnCenter}>Use in Delivery Report</th>
    </React.Fragment>
  );
  const styleCheck = { color: "green", fontSize: "1.5em" };
  const styleUnCheck = { fontSize: "1.5em" };
  const styleSave = { color: "#1a0dab", fontSize: "1.5em" };
  const styleHide = { color: "rgb(0 0 0 / 0%)", fontSize: "1.5em" };
  const row = JobActualList.map((item) => (
    <tr key={common.uuidv4()}>
      <td>
        <CustomInput
          type="checkbox"
          className="table-checkbox"
          id={common.uuidv4()}
          checked={SelectedSerial.includes(item.SerialNo)}
          onChange={(e) => {
            handleChange(e, item.SerialNo);
          }}
        />
      </td>
      <td>{item.MachineType}</td>
      <td>{item.MachineModel}</td>
      <td>{item.SerialNo}</td>
      <td style={columnCenter}>
        {props.isEngineer ? (
          item.EmployeeSigndate != null ? (
            <IconChecked style={styleCheck} />
          ) : (
            <IconUnChecked style={styleUnCheck} />
          )
        ) : item.AcceptedDate != null ? (
          <IconChecked style={styleCheck} />
        ) : (
          <IconUnChecked style={styleUnCheck} />
        )}
      </td>
      <td style={columnCenter}>
        <FormGroup check>
          <Label>
            <Input
              type="radio"
              name={props.isEngineer ? "ShowDlvReportEng" : "ShowDlvReportCus"}
              checked={selectedOption === item.SerialNo}
              onClick={(e) => {
                handleShowDlvReportClick(e, item.SerialNo);
              }}
              onChange={(e) => {
                handleShowDlvReportChange(e, item.SerialNo);
              }}
            />
          </Label>
        </FormGroup>
      </td>
    </tr>
  ));
  if (denied()) return <>denied</>;
  return (
    <div>
      <label className="col-label-bold">Select Machines</label>
      <ReactTable Header={header} Row={row} />
      {props.isEngineer ? null : (
        <FormRowInput label="Customer In-charge">
          <input
            className="form-control"
            type="text"
            name="AcceptedBy"
            value={dataItem.AcceptedBy}
            onChange={handleInputChange}
          />
        </FormRowInput>
      )}
      <FormRowInput label="Signature">
        <input
          type="button"
          onClick={() => {
            setIsOpen(true);
          }}
          value="Sign Now"
        />
        <SignatureModal
          GetData={getdata}
          isOpen={isOpen}
          onCancel={() => {
            setIsOpen(false);
          }}
        />
        {image ? (
          <img
            src={image}
            alt="My picture"
            style={{
              display: "block",
              marginTop: "5px",
              border: "1px solid #ced4da",
              width: "300px",
              height: "170px",
            }}
          />
        ) : null}
      </FormRowInput>
      <input
        type="button"
        className="btn btn-default btnDefault btn-Temp"
        value="Uploads"
        onClick={onUpload}
      />
      <input
        type="button"
        className="btn btn-default btnDefault btn-Temp"
        value="Reset"
        onClick={onReset}
      />
    </div>
  );
};

export const SignatureModal = (props) => {
  //Redux
  // const dispatch = useDispatch();
  const sigCanvas = useRef({});
  const clear = () => sigCanvas.current.clear();
  const save = () => {
    props.GetData(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    props.onCancel();
  };

  return (
    <SignatureMainModal
      isOpen={props.isOpen}
      onSave={save}
      onCancel={() => {
        props.onCancel();
      }}
      ClearHidden={false}
      onClear={clear}
      ModalHeaderText="Signature"
    >
      <SignaturePad
        ref={sigCanvas}
        canvasProps={{ className: "SignatureCanvas" }}
        clearOnResize={false}
      />
    </SignatureMainModal>
  );
};

export default JobActualResult;
