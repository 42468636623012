//Library
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";
import { Redirect } from "react-router-dom";
import { useForm, FormContext, useFormContext } from "react-hook-form";
import * as yup from "yup";
import { format } from "react-string-format";
//Component
import DetailBox from "../UI/DetailBox";
import * as common from "./../CommonFunction/common-function";
import * as ServiceRequestStore from "../../store/ServiceRequestStore";
import ServiceRequestItem from "./ServiceRequestItem";
import AddLoadTask from "./../addmodal/AddLoadTask";
import * as LinkModal from "./../UI/LinkText/LinkModal";
import * as Combo from "../UI/combobox/Combo";
import { AutoComplete } from "../UI/combobox";
import FormRowInput from "../UI/FormDetail";
import DatePicker from "../UI/DatePicker";
import CommonForm from "../UI/CommonForm";
import Uploader from "../UI/Uploader";
//Store
import * as JobStore from "../../store/jobStore";
import * as JobMonitorStore from "../../store/JobMonitorStore";

const ValidateServiceRequestSchema = yup.object().shape({
  JobType: yup.string().trim().required("This field is required."),
  Company: yup.string().trim().required("This field is required."),
  RequestedDate: yup.string().trim().required("This field is required."),
  DueDate: yup.string().trim().required("This field is required."),
  CustomerId: yup
    .string()
    .nullable()
    .trim()
    .required("This field is required."),
  ContactEmail: yup.string().trim().email(),
});
const fieldDate = ["PoDate", "RequestedDate", "DueDate"];
export const ServiceRequestScreen = (props) => {
  //Redux
  const dispatch = useDispatch();
  const ServiceRequestdata = useSelector(
    (state) => state.ServiceRequestReducer.Form.serviceRequestdata
  );
  const CutomerList = useSelector((state) => state.ComboReducer.CustomerList);
  //Props
  //Declare State
  const myForm = useForm({ validationSchema: ValidateServiceRequestSchema });
  const [isOpen, setIsOpen] = useState(false);
  const [buttons, setButtons] = useState();

  //Effect
  useEffect(() => {
    let c_buttons = [];

    if (ServiceRequestdata.CurrentJobId) {
      c_buttons.push(EditJobbutton, Print);
      if (!ServiceRequestdata.PreventEdit) c_buttons.push(Savebutton);
    } else {
      c_buttons.push(Savebutton);
    }
    setButtons(c_buttons);
    myForm.setValue(common.setDataToFormHook(ServiceRequestdata, fieldDate));
  }, [ServiceRequestdata]);
  //Event+function
  const getCustomerName = (customerID) => {
    const item = CutomerList.find((entry) => entry.value === customerID);
    return item ? item.label : "";
  };

  const save = async () => {
    let service = {
      ...ServiceRequestdata,
      ...myForm.getValues(),
      UpdatedBy: common.GetUserId(),
    };

    await checkDupplicate(service);

    let isChangeCustomer =
      !common.isEmptyStr(ServiceRequestdata.CustomerId) &&
      ServiceRequestdata.CustomerId !== myForm.getValues().CustomerId;

    if (isChangeCustomer) {
      isChangeCustomer = await checkMachineCustomer(service);
    }

    if (isChangeCustomer) {
      let result = await SaveWithConfirmChangeCustomer(service);
      if (result.value) {
        let resDel = await delecteMachineCustomer(service);
        if (resDel !== false) {
          let res = await ServiceRequestStore.actionCreators.saveServiceRequest(
            dispatch,
            service
          );
          if (res !== false) common.DoneDialog();
        }
      }
    } else {
      common.SaveWithConfirm(() =>
        ServiceRequestStore.actionCreators.saveServiceRequest(dispatch, service)
      );
    }
  };

  const SaveWithConfirmChangeCustomer = async (data) => {
    let message =
      "<div>" +
      "<span>พบรายการ Machine ที่ไม่เป็นของ Customer</span>" +
      '<br/><span style=" color: red;"> {0} </span>' +
      "<br/>" +
      "<br/><span>หากยืนยันการบันทึก ระบบจะทำการบันทึกและ Clear Machine ที่ไม่เป็นของ</span>" +
      '<br/><span style=" color: red;"> {0} </span>' +
      "</div>";
    let { result } = await common.to(
      common.ConfirmDialog(
        format(message, getCustomerName(data.CustomerId)),
        false
      )
    );
    return result;
  };
  const checkMachineCustomer = async (data) => {
    var { result } =
      await ServiceRequestStore.actionCreators.CheckMachineCustomer(data);
    return result && result.data;
  };
  const delecteMachineCustomer = async (data) => {
    var { result } =
      await ServiceRequestStore.actionCreators.delecteMachineCustomer(data);
    return result && result.data;
  };
  const checkDupplicate = async (data) => {
    if (data.ServiceRequestId !== "") {
      return;
    } else {
      var { result } =
        await ServiceRequestStore.actionCreators.CheckDupplicateServiceRequest(
          data
        );
      if (result && result.data) {
        await common.InformationOKDialog("Service Request Duplicate");
      }
    }
  };
  const editjob = async () => {
    let cur_data = { ...ServiceRequestdata, ...myForm.getValues() };
    if (
      !common.isEquivalent(
        cur_data,
        common.setDataDate(ServiceRequestdata, fieldDate)
      )
    ) {
      var res = await common.ConfirmBox("Do you want to leave without save");
      if (!res) {
        return;
      }
    }
    await JobStore.actionCreators.dataToJobform(
      dispatch,
      ServiceRequestdata.CurrentJobId
    );
    setIsOpen(true);
  };
  const PreviewDelivery = () => {
    JobMonitorStore.actionCreators.AddDeliveryReport(
      ServiceRequestdata.CurrentJobId
    );
  };
  const Savebutton = { text: "Save", function: myForm.handleSubmit(save) };
  const EditJobbutton = {
    text: "Edit Job",
    function: () => {
      editjob();
    },
  };
  const Print = {
    text: "Preview Delivery Report",
    function: () => {
      PreviewDelivery();
    },
  };
  //SubComponent
  return (
    <>
      <CommonForm
        BackLink={
          props.Backlink ||
          (props.location.state && props.location.state.BackLink) ||
          "/service-request"
        }
        ButtonJson={buttons}
      >
        <FormContext {...myForm}>
          <RequestForm />
        </FormContext>
      </CommonForm>
      {isOpen ? <Redirect to="/JobService" /> : null}
    </>
  );
};
export const RequestForm = () => {
  //Redux
  const data = useSelector(
    (state) => state.ServiceRequestReducer.Form.serviceRequestdata
  );
  //Props
  //Declare State
  // const { setValue } = useFormContext();
  const [isOpen, setIsOpen] = useState();
  const [txtHeader, settxtHeader] = useState();
  const [Task, setTask] = useState({});
  //EffectinitialState
  useEffect(() => {
    let txt;
    if (data.ServiceRequestId !== "") {
      txt = " Edit";
    } else txt = " New";
    settxtHeader("Request Form (PO) -" + txt);
  }, [data]);
  //Event+function
  const LoadFormTask = () => {
    setIsOpen(true);
  };
  const getValue = (task) => {
    setTask(task);
  };
  //SubComponent

  return (
    <React.Fragment>
      <DetailBox
        HeaderText={txtHeader}
        button1txt="Load From Task"
        button1hidden={data.PreventEdit}
        onbutton1Click={LoadFormTask}
      >
        <RequestDataForm Task={Task} />
        <ServiceRequestItem />
      </DetailBox>
      {data.ServiceRequestId !== "" ? (
        <DetailBox HeaderText="Planned Engineers" button1hidden={true}>
          {" "}
          <PlanEngineerDataForm />
        </DetailBox>
      ) : null}
      <AddLoadTask
        isOpen={isOpen}
        GetValue={getValue}
        onCancel={() => {
          setIsOpen(!isOpen);
        }}
      />
    </React.Fragment>
  );
};

export const RequestDataForm = ({ Task }) => {
  //Redux
  const dispatch = useDispatch();
  const ServiceRequestData = useSelector(
    (state) => state.ServiceRequestReducer.Form.serviceRequestdata
  );
  const ServiceRequestAttachment = useSelector(
    (state) => state.ServiceRequestReducer.Form.ServiceRequestAttachment
  );

  //Props
  //Declare State
  const { register, errors, setValue, getValues } = useFormContext();
  const [isDupplicatePO, setIsDupplicatePO] = useState(false);
  const attatchdataPhoto = {
    ServiceRequestId: ServiceRequestData.ServiceRequestId,
    DocumentType: "Photo",
  };
  const [dataItem, setDataItem] = useState(ServiceRequestData);
  //Effect
  useEffect(() => {
    //Uncontrol
    register({ name: "ContactPerson" });
    register({ name: "CustomerId" });
    register({ name: "JobType" });
  });
  useEffect(() => {
    if (common.isEmptyObj(Task)) {
      return;
    }
    let dat = {
      JobType: Task.JobType,
      PoNumber: Task.PoNumber,
      DueDate: Task.DateFrom,
      CustomerId: Task.CustomerId,
      Remark: Task.Remark,
      PoDate: Task.DateFrom,
      RequestedDate: Task.DateFrom,
    };
    setValue(common.setDataToFormHook(dat, fieldDate), true);
    setDataItem({ ...dataItem, ...dat });
  }, [Task]);
  useEffect(() => {
    setDataItem(ServiceRequestData);
    setValue(common.setDataToFormHook(ServiceRequestData));
  }, [ServiceRequestData]);
  //Event+function
  const handleSearchChange = (selected) => {
    if (selected && selected.length > 0) {
      setValue([
        { ContactPerson: selected[0].ContactPerson },
        { ContactPhone: selected[0].Telephone },
        { ContactEmail: selected[0].Email },
        { Department: selected[0].Department },
      ]);
    }
  };
  const handleInputChangeAuto = (input, event) => {
    setValue("ContactPerson", input);
  };
  const setControlValue = (name, value) => {
    setValue(name, value);
    setDataItem((prev) => ({ ...prev, [name]: value }));
  };
  const PoCheck = (event) => {
    const { value } = event.target;
    ServiceRequestStore.actionCreators
      .CheckPoDupplicate(ServiceRequestData.ServiceRequestId, value)
      .then((res) => setIsDupplicatePO(res));
  };

  const Upload = async (FileInfo, base64, Tags) => {
    await ServiceRequestStore.actionCreators.saveAttachMedia(
      dispatch,
      base64,
      FileInfo,
      { ...attatchdataPhoto, Tags: Tags }
    );
  };

  return (
    <div>
      <FormRowInput label="PO No.">
        {isDupplicatePO ? <span>PO is already exists</span> : null}
        <Input
          type="text"
          name="PoNumber"
          id="PoNumber"
          innerRef={register}
          onChange={(e) => {
            PoCheck(e);
          }}
          bsSize="sm"
          readOnly={ServiceRequestData.PreventEdit}
        />
      </FormRowInput>
      <FormRowInput label="PO Date">
        <DatePicker
          name="PoDate"
          id="PoDate"
          innerRef={register}
          readOnly={ServiceRequestData.PreventEdit}
        />
      </FormRowInput>
      <FormRowInput label="Type" required errors={errors.JobType}>
        <Combo.JobType
          name="JobType"
          id="JobType"
          onChange={(event) => {
            setDataItem((prev) => ({
              ...prev,
              [event.target.name]: event.target.value,
            }));
          }}
          value={dataItem.JobType}
          innerRef={register}
          invalid={errors.JobType ? true : false}
          disabled={ServiceRequestData.PreventEdit}
        />
      </FormRowInput>
      <FormRowInput label="Company" required errors={errors.Company}>
        <Combo.Company
          name="Company"
          id="Company"
          innerRef={register}
          invalid={errors.Company ? true : false}
          disabled={ServiceRequestData.PreventEdit}
        />
      </FormRowInput>
      <FormRowInput label="Request Date" required errors={errors.RequestedDate}>
        <DatePicker
          name="RequestedDate"
          id="RequestedDate"
          innerRef={register}
          invalid={errors.RequestedDate ? true : false}
          readOnly={ServiceRequestData.PreventEdit}
        />
      </FormRowInput>
      <FormRowInput label="Due Date" required errors={errors.DueDate}>
        <DatePicker
          name="DueDate"
          id="DueDate"
          innerRef={register}
          invalid={errors.DueDate ? true : false}
          readOnly={ServiceRequestData.PreventEdit}
        />
      </FormRowInput>
      <FormRowInput label="Customer" required errors={errors.CustomerId}>
        <AutoComplete.Customer
          name="CustomerId"
          innerRef={register}
          invalid={errors.CustomerId ? true : false}
          value={dataItem.CustomerId}
          setControlValue={setControlValue}
          readOnly={ServiceRequestData.PreventEdit}
        />
      </FormRowInput>
      <FormRowInput label="Contact Person">
        <Combo.AutoCompleteCustomerContact
          Criteria={{ id: dataItem.CustomerId || "" }}
          innerRef={register}
          onChange={handleSearchChange}
          onInputChange={handleInputChangeAuto}
          selected={[getValues().ContactPerson || ""]}
          readOnly={ServiceRequestData.PreventEdit}
        />
      </FormRowInput>
      <FormRowInput label="Tel.">
        <Input
          type="text"
          name="ContactPhone"
          id="ContactPhone"
          innerRef={register}
          bsSize="sm"
          readOnly={ServiceRequestData.PreventEdit}
        />
      </FormRowInput>
      <FormRowInput label="Department">
        <Input
          type="text"
          name="Department"
          id="Department"
          innerRef={register}
          bsSize="sm"
          readOnly={ServiceRequestData.PreventEdit}
        />
      </FormRowInput>
      <FormRowInput label="Email" errors={errors.ContactEmail}>
        <Input
          type="email"
          name="ContactEmail"
          id="ContactEmail"
          innerRef={register}
          bsSize="sm"
          invalid={errors.ContactEmail ? true : false}
          readOnly={ServiceRequestData.PreventEdit}
        />
      </FormRowInput>
      <FormRowInput label="Remark">
        <Input
          type="textarea"
          name="Remark"
          id="Remark"
          style={{ height: 150 }}
          innerRef={register}
          readOnly={ServiceRequestData.PreventEdit}
        />
      </FormRowInput>
      <div>
        <FormRowInput label="Document"></FormRowInput>
        <div className="row detail-form-input">
          <div className="col-12 col-md-4">
            <label htmlFor="photo" className="col-label-bold">
              Attachment
            </label>
          </div>
          <div className="col-12 col-md-8 col-detailForm">
            <div className="col-12 detail-form">
              <Uploader
                hidden={common.isEmptyStr(ServiceRequestData.ServiceRequestId)}
                // disabled={ServiceRequestData.PreventEdit}
                onUpload={Upload}
              />
            </div>
            {ServiceRequestAttachment &&
              ServiceRequestAttachment.map((item) => (
                <div key={common.uuidv4()}>
                  <LinkModal.MediaFileName
                    Display={item.AttachmentName}
                    FileNameLink={item.FileNameLink}
                    Delete={() => {
                      ServiceRequestStore.actionCreators.DeleteAttachment(
                        dispatch,
                        item
                      );
                    }}
                    hidden={ServiceRequestData.PreventEdit}
                  />
                </div>
              ))}
          </div>
        </div>
        {}
        <FormRowInput label="Request Item"></FormRowInput>
      </div>
    </div>
  );
};
export const PlanEngineerDataForm = () => {
  const Machine = useSelector(
    (state) => state.ServiceRequestReducer.Form.MachineData
  );
  const Engineer = useSelector(
    (state) => state.ServiceRequestReducer.Form.EngineerData
  );
  return (
    <div>
      <div className="row detail-form">
        <div className="col-12 col-md-4">
          <label htmlFor="Machine" className="col-label-bold">
            Machine
          </label>
        </div>
        <div className="col-12 col-md-8 col-detailForm">
          {Machine &&
            Machine.map((item) => (
              <div className="col-12 detail-form" key={common.uuidv4()}>
                <label htmlFor="Engineers_Value">{item.MachineModel}</label>
                <br />
                <label
                  className="text-detail"
                  htmlFor="Engineers_Value"
                >{`SN:${item.SerialNo}`}</label>
              </div>
            ))}
        </div>
      </div>
      <div className="row detail-form">
        <div className="col-12 col-md-4">
          <label htmlFor="Engineers" className="col-label-bold">
            Engineers
          </label>
        </div>
        <div className="col-12 col-md-8 col-detailForm">
          {Engineer &&
            Engineer.map((item) => (
              <div className="col-12 detail-form" key={common.uuidv4()}>
                <LinkModal.Engineer Value={item} />
                <br />
                <label htmlFor="Engineers_Value">{item.Telephone}</label>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
export default RequestForm;
