//Library
import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { withRouter } from 'react-router-dom';
//Component
import history from "./components/history/history";
import ServiceRequest from "./components/ServiceRequest/ServiceRequest";
import Dashboard from "./components/Dashboard/Dashboard";
import { UpcomingScreen,UpcomingListScreen } from "./components/Upcoming/Upcoming";
import JobMonitor from "./components/Dashboard/JobMoinitorScreen";
import {
  JobScreen,
  JobServiceRequest,
  JobCalendar,
} from "./components/Job/JobScreen";
import Login from "./components/Login/Login";
import ErrorBoundary from "./components/template/ErrorBoundary";
import { ServiceRequestScreen } from "./components/ServiceRequest/RequestForm";
import Search from "./components/Search/Search";
import Calendar from "./components/Calendar/Calendar";
import Master from "./components/master/master";
import EngineerMaster from "./components/master/EngineerMaster";
import UserMaster from "./components/master/UserMaster";
import CustomerMaster from "./components/master/CustomerMaster";
import MachineMaster from "./components/master/MachineMaster";
import MachineCategoriesMaster from "./components/master/MachineCategories";
import ToolMaster from "./components/master/ToolMaster";
import SkillMaster from "./components/master/SkillMaster";
import ToolMaintenance from "./components/maintenance/ToolMaintenance";
import EmployeeLeave from "./components/maintenance/EmployeeLeave";
import ActiveMachine from "./components/activate/ActiveMachine";
import ToolActivate from "./components/activate/ActiveTool";
import JobConflict from "./components/Dashboard/JobConflict";
import { PermissionComponent } from "./components/Permission/Permision";
import RouteLayout from "./components/template/RouteLayout";

export default () => (
  <ErrorBoundary>
    <Router basename={'/msm'} history={history}>
      <Route exact path="/" component={Login} />
      <RouteLayout
        path={`/dashboard`}
        component={PermissionComponent(withRouter(Dashboard), "Dashboard")}
      />
      <RouteLayout
        path="/UpcomingList"
        component={PermissionComponent(UpcomingListScreen, "")}
      />
      <RouteLayout
        path="/UpcomingTask"
        component={PermissionComponent(UpcomingScreen, "PrePlan")}
      />
      <RouteLayout
        path="/JobMonitor"
        component={PermissionComponent(withRouter(JobMonitor), "Job Detail")}
      />
      <RouteLayout path="/Job" component={PermissionComponent(withRouter(JobScreen), "Job")} />
      <RouteLayout
        path="/JobService"
        component={PermissionComponent(JobServiceRequest, "Job")}
      />
      <RouteLayout
        path="/JobCalendar"
        component={PermissionComponent(JobCalendar, "Job")}
      />
      <RouteLayout
        path="/service-request"
        component={PermissionComponent(withRouter(ServiceRequest), "Service Request")}
      />
      <RouteLayout
        path="/employee-leave"
        component={PermissionComponent(EmployeeLeave, "Holiday")}
      />
      <RouteLayout
        path="/RequestForm"
        component={PermissionComponent(withRouter(ServiceRequestScreen), "Request Form")}
      />
      <RouteLayout path="/Search" component={PermissionComponent(Search, "")} />
      <RouteLayout
        path="/calendar"
        component={PermissionComponent(Calendar, "")}
      />
      <RouteLayout
        path="/master"
        exact
        component={PermissionComponent(Master, "Master")}
      />
      <RouteLayout
        path="/master/engineer"
        component={PermissionComponent(EngineerMaster, "Employee")}
      />
      <RouteLayout
        path="/master/user"
        component={PermissionComponent(UserMaster, "User")}
      />
      <RouteLayout
        path="/master/customers"
        component={PermissionComponent(CustomerMaster, "Customer")}
      />
      <RouteLayout
        path="/master/machines"
        component={PermissionComponent(MachineMaster, "Machine")}
      />
      <RouteLayout
        path="/master/machines-categories"
        component={PermissionComponent(MachineCategoriesMaster, "")}
      />
      <RouteLayout
        path="/master/tools"
        component={PermissionComponent(ToolMaster, "Tools")}
      />
      <RouteLayout
        path="/master/skills"
        component={PermissionComponent(SkillMaster, "Skill")}
      />
      <RouteLayout
        path="/tool-maintenance"
        component={PermissionComponent(ToolMaintenance, "Tools Maintenance")}
      />
      <RouteLayout
        path="/activate-machine"
        component={PermissionComponent(ActiveMachine, "Activate Machine")}
      />
      <RouteLayout
        path="/activate-tools"
        component={PermissionComponent(ToolActivate, "Activate Tools")}
      />
      <RouteLayout
        path="/JobConflict"
        component={PermissionComponent(JobConflict, "Conflict")}
      />
      
    </Router>
  </ErrorBoundary>
);
